// 仪表板菜单
import { statisticsTypes } from "@/config/reportDataType/unitData"; // 统计类型

const report = {
  state: {
    themeColor: 'roma', // 仪表板全屏时切换的主题颜色
    isFullScreen: false, // 是否全屏：仪表板、图表
    groupOneLabelName: '', // 一级分组label
    valueDataLabelName: '', // 统计字段label
    valueDataTypeLabelName: '', // 统计值类型label
    tableDetailGroupOneData: {}, // 表格图表一级分组的数据
    tableDetailStatistical: [], // 表格图表已选择的统计字段的统计类型数据
    chartInfoObject: {}, // 图表数据
    chartLinkageContent: '',//仪表板联动提示内容
  },
  mutations: {
    // 设置主题颜色
    setThemeColor(state, data) {
      state.themeColor = data;
    },
    // 设置全屏
    setIsFullScreen(state, data) {
      state.isFullScreen = data;
    },
    // 设置一级分组label
    setGroupOneLabelName(state, data) {
      // 判断字段集合是否为空，不为空则根据id匹配对应的labelname
      if (data && data.groupList.length && data.id) {
        state.groupOneLabelName = data.groupList.find((item) => item.id === data.id).labelName;
      } else {
        // 为空直接赋值为空串
        state.groupOneLabelName = '';
      }
    },
    // 设置统计字段label
    setValueDataLabelName(state, data) {
      if (data && data.statisticsFields.length && data.id) {
        state.valueDataLabelName = data.statisticsFields.find((item) => item.id === data.id) ?
          data.statisticsFields.find((item) => item.id === data.id).labelName : "";
      } else {
        state.valueDataLabelName = '';
      }
      state.valueDataTypeLabelName = ''; // 给统计值类型清空
    },
    // 设置统计值类型label
    setValueDataTypeLabelName(state, data) {
      if (data.statisticsTypes.length) {
        state.valueDataTypeLabelName = data.statisticsTypes.find((item) => item.value === data.id) ?
          data.statisticsTypes.find((item) => item.value === data.id).label : '';
      } else {
        state.valueDataTypeLabelName = '';
      }
    },
    // 设置表格图表一级分组的数据
    setTableDetailGroupOneData(state, data) {
      state.tableDetailGroupOneData = data;
    },
    // 设置表格图表已选择的统计字段的统计类型数据
    setTableDetailStatistical(state, data) {
      if (data.type === 'apply') {
        // 表示在应用
        if (state.tableDetailStatistical.some((item) => item.id === data.item.id)) {
          // 先判断该字段是否已统计过，若是统计过则先删除该字段
          state.tableDetailStatistical = state.tableDetailStatistical.filter((item) =>
            item.id !== data.item.id)
        }
        // 遍历该统计字段的所有应用的统计类型，添加进去
        data.summaryTypes.map((item) => {
          let dataItem = JSON.parse((JSON.stringify((data.item))))
          dataItem.labelName = `${dataItem.labelName} ${statisticsTypes().find((items) => items.value === item).label}`;
          dataItem.schemefield_name_alias = `${dataItem.schemefield_name_alias}$${item}`;
          state.tableDetailStatistical.push(dataItem);
        })
      } else if (data.type === 'delete') {
        // 表示在删除统计字段
        state.tableDetailStatistical = state.tableDetailStatistical.filter((item) =>
          item.id !== data.item.id)
      } else if (data.type === 'empty') {
        // 表示要清空数据
        state.tableDetailStatistical = [];
      } else if (data.type === 'totalrecord') {
        // 表示是记录计数，先删除掉已添加的记录数
        state.tableDetailStatistical = state.tableDetailStatistical.filter((item) =>
          item.id !== 'totalrecord')
        if (JSON.stringify(data.item) === '{}') return
        state.tableDetailStatistical.push(data.item)
      }
    },
    // 设置图表数据
    setChartInfoObject(state, data) {
      state.chartInfoObject = data;
    },
    // 设置仪表板联动提示内容
    setChartLinkageContent(state, data) {
      state.chartLinkageContent = data;
    },
  },
  getters: {
    // 获取主题颜色
    getThemeColor(state) {
      return state.themeColor;
    },
    // 获取是否设置全屏
    getIsFullScreen(state, data) {
      return state.isFullScreen;
    },
    // 获取一级分组label
    getGroupOneLabelName(state) {
      return state.groupOneLabelName;
    },
    // 获取统计字段label
    getValueDataLabelName(state) {
      return state.valueDataLabelName;
    },
    // 获取统计值类型label
    getValueDataTypeLabelName(state) {
      return state.valueDataTypeLabelName;
    },
    // 获取表格图表一级分组的数据
    getTableDetailGroupOneData(state) {
      return state.tableDetailGroupOneData;
    },
    // 获取表格图表已选择的统计字段的统计类型数据
    getTableDetailStatistical(state) {
      return state.tableDetailStatistical;
    },
    // 获取图表数据
    getChartInfoObject(state) {
      return state.chartInfoObject;
    },
    // 获取仪表板联动提示内容
    getChartLinkageContent(state) {
      return state.chartLinkageContent;
    },
  },
  actions: {}
}
export default report;