/*
  关于接口返回数据处理：
    1.接口状态码为200，表示接口正常；
      需要区分result和returnCode是否为true和1；
      result为false表示接口数据未能正常返回，均提示对应的提示语信息；
    2.接口状态码非200，表示接口异常；
      其中为400表示系统运行时异常，也就是后端进行的处理为400，会有明确的提示语信息；
      其他状态码直接提示错误弹框即可。
  关于接口请求：请求头中需要添加token，token两种情况：
    1.token没有或被人为清除；前端直接在请求拦截中进行控制，不需再进行请求；
    2.token超时未操作导致失效，由于是在后端设置的失效时间，所以仍需通过请求接口来确定是否已失效；

    data:{},数据体
    requestId: "62c64d69-e1b9-466c-81ac-b637050e93da",请求ID唯一标识
    result: true,true表示成功,false表示失败
    returnCode: "1","1"成功码
    returnInfo: null
*/
import Vue from "vue"
import axios from 'axios'
import Utils from "@/utils/utils"
import VueCookies from 'vue-cookies'
// import NProgress from '@/utils/progressBar'
import encry from './encryption'
import { loadLanguageAsync } from "@/utils/i18n";
import { message } from "@/utils/resetMessage";
import { reportError, reportInfo } from "@/config/reportErrorLog"
// 需要加密的接口名单
const encryptList = ['sysconfig/auth/pc/1.0/post/unifiedLogin', 'sysconfig/auth/pc/1.0/get/getUserInfo']
// 格式换请求体
const formateData = data => {
  return {
    head: {
      appType: "lightning-main",
      appVersion: "1.1.1",
      accessToken: Utils.getBinding(),
      source: "lightning-main"
    },
    body: {
      ...data
    }
  }
}
let service = axios.create({
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
})

service.interceptors.request.use(async config => {
  // NProgress.startProgressBar()
  config.headers.requestId = Utils.getUuid()
  config.headers.requestIdProducer = "browser"
  if (!Utils.getBinding()) {
    return Promise.reject()
  }
  if(config.url.indexOf("/file/upload") > -1){
    // 配置文件上传接口的请求头格式
    config.headers["Content-Type"] = "multipart/form-data"
    
    
  }
  // 如果url中不包含网关地址，那么需要设置默认的baseURL
  if (!config.url.startsWith("https://") && !config.url.startsWith("https://")) {
    config.baseURL = window.Glod['ccex-apitsf'] + '/api'
  }
  // 将请求加密
  encryptList.forEach((item) => {
    if (config.url.indexOf(item) != -1) { config.data = encry.en(JSON.stringify(config.data)) }
  })
  config.headers['accessToken'] = Utils.getBinding()
  return config
}, error => {
  return Promise.reject(error)
})

service.interceptors.response.use(
  async response => {
    // 加密的接口需要解密处理
    encryptList.forEach((item) => {
      if (response.config.url.indexOf(item) != -1 && 200 == response.status && response.data) {
        response.data = JSON.parse(encry.de(response.data))
        return response.data;
      }
    })
    // 接口成功时返回请求结果
    // 审批中的记录需要二次确认才能删除,returnCode返回isConfirmDel时，说明时删除正在审批中的记录
    // 通用删除接口：objectdetail/delete
    if (response.data.result || response.data.returnCode == 'Manual' || response.data.returnCode == 'isConfirmDel') {
      reportInfo(response)
      return response.data
    }
    // 返回图片
    else if (response.data instanceof Blob && !response.data.result) {
      reportInfo(response)
      return response.data
    }
    // 合并数据
    else if (response.config.url.indexOf('mergeReData') !== -1 && !response.data.result) {
      reportInfo(response)
      return response.data
    }
    // 导出导入失败的文件
    else if (response.config.url.indexOf('/importFile/downloadFailFile') !== -1 && !response.data.result) {
      reportInfo(response)
      return response.data
    }
    // 如果返回状态码为10002，表示登录token失效，提示需要重新登录
    else if (response.data && response.data.returnCode === '10002') {
      reportInfo(response)
      Utils.reLogin(VueCookies)
      return Promise.reject(response.data)
    }  // 如果返回10905状态码，那么需要业务自行处理错误
    else if (response.data && response.data.returnCode === '10905') {
      reportInfo(response)
      return Promise.reject(response.data)
    } else if (response.data && response.data.returnCode === '11400') {
      // 触发器提示(后期如果需要再各个接口中内部处理再传入返回体，目前统一在拦截器中处理)
      message.error(response.data.returnInfo)
      // return Promise.reject(response.data)
    }
    /*
     提示错误信息，部分接口虽然返回状态是false，但不需提示错误弹框
       1.排除列宽调整接口updateViewFieldMemory；
       2.报表、视图被引用时删除；
    */
    else if (response.config.url.indexOf('updateViewFieldMemory') === -1
      && response.config.url.indexOf('resettingViewFieldMemory') === -1
      && response.config.url.indexOf('deleteReport') === -1
      && response.config.url.indexOf('deleteView') === -1
      && response.config.url.indexOf('submitApproval') === -1
      && response.config.url.indexOf('getGotoApprovalPreview') === -1
      && response.config.url.indexOf('getWorkOrderLineItemProcess') === -1) {
      await loadLanguageAsync();
      Vue.prototype.$ErrorDialog({
        response: response
      })
    }
    if (response.data.returnInfo.indexOf('此记录不满足任何有效批准过程的项目条件或初始提交') == -1) {
      reportError(response)
    }
    return Promise.reject(response.data)
  },
  async error => {
    await loadLanguageAsync();
    reportError(error)
    Vue.prototype.$ErrorDialog({
      response: error?.response
    })
    return Promise.reject(error?.response)
  }
)

export default {
  get: (url, data = {}, responseType = '') => {
    return service({
      url: url,
      method: 'get',
      params: data,
      responseType: responseType
    })
  },

  post: (url, data = {}, envType = "") => {
    // 公有云专用接口，在私有云不请求
    if ("public" == envType && "private" == window.Glod["ENV_TYPE"]) {
      return new Promise(() => { })
    }
    // 私有云专用接口，在公有云不请求
    if ("private" == envType && "private" != window.Glod["ENV_TYPE"]) {
      return new Promise(() => { })
    }
    return service({
      url: url,
      method: 'post',
      data: data,
    })
  },

  postFormat: (url, data = {}, responseType = '') => {
    return service({
      url: url,
      method: 'post',
      data: formateData(data),
      responseType: responseType
    })
  },

  postParams: (url, data = {}) => {
    return service({
      url: url,
      method: 'post',
      params: data
    })
  },
}
