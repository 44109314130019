import WebIM from '@/utils/WebIM'
import Vue from 'vue'
import Bus from "@/utils/event_bus.js";
import i18n from '@/utils/i18n.js'



const Chat = {
  state: {
    //获取的聊天列表
    userList: {
      //一对一消息列表
      contactUserList: [],
      //群组列表
      groupUserList: [],
      //群策列表
      groupstrategyUserList: []
    },
    //获取的聊天记录
    msgList: {
      contact: {},
      group: {},
      groupstrategy: {},
    },
    //获取的当前正在聊天的记录
    currentMsgs: ''
  },
  getter: {
    // onGetContactUserList (state) {
    //   return state.userList.contactUserList;
    // },
  },
  mutations: {
    updateCurrentMsgList (state, payload) {
      state.currentMsgs = payload;

    },
    updateUserList (state, payload) {
      const {
        userList,
        type
      } = payload;
      state.userList[type] = userList;
    },
    updateMsgList (state, payload) {
      const {
        chatType,
        chatId,
        msg,
        bySelf,
        type,
        id
      } = payload;
      const {
        params
      } = Vue.$route;
      let status = "unread";
      if (payload.chatType == "contact") {
        if (params.id == payload.from) {
          status = "read";
        }
      } else if (payload.chatType == "group") {
        if (params.id == payload.chatId) {
          status = "read";
        }
      }

      if (!state.msgList[chatType][chatId]) {
        state.msgList[chatType][chatId] = [{
          msg,
          bySelf,
          type: type || "",
          mid: id,
          status: status,
          ...payload
        }];
      } else {
        state.msgList[chatType][chatId].push({
          msg,
          bySelf,
          type: type || "",
          mid: id,
          status,
          ...payload
        });
        state.msgList[chatType][chatId] = state.msgList[chatType][chatId].sort((a, b) => {
          return a.time - b.time;
        });
        // state.msgList[chatType][chatId] = _unique(state.msgList[chatType][chatId])
      }

      // if(chatType === "chatroom" && !bySelf){ // 聊天室消息去重处理
      // 	state.currentMsgs = _.uniqBy(state.msgList[chatType][chatId], "mid");
      // }
      // else{
      // 	state.currentMsgs = Object.assign({}, state.msgList[chatType][params.id || chatId]); // 这里params.id在路由跳转的时候会undefind，取chatId兼容
      // }
      // state.msgList = Object.assign({}, state.msgList);
    },


  },
  actions: {
    // onGetContactUserList: function(){
		// 	try{
		// 		WebIM.conn.getRoster({
		// 			success: function(roster){
		// 				const userList = roster.filter(user => ["both", "to"].includes(user.subscription));
		// 				context.commit("updateUserList", {
		// 					userList,
		// 					type: "contactUserList",
		// 					black: payload
		// 				});
		// 			}
		// 		});
		// 	}
		// },

    // onGetCurrentMsgList: function (context, payload) {
    //   var options = {
    //     queue: payload.name,
    //     isGroup: payload.isGroup,
    //     count: 100,
    //     success: function (msgs) {
    //       try {
    //         if (msgs.length > 0) {
    //           let listArr = []
    //           let indexVoice = msgs.findIndex(res=>res.contentsType == "VOICE")
    //           msgs.forEach((historyItem,i) => {
    //             (function (i) {
    //               setTimeout(async () => {
    //             let objMsg = {}
    //             let chatId = historyItem.from == JSON.parse(localStorage.getItem("userInfo")).userId ? '2' : '1';
    //             //判断是邀请消息和命令消息不在聊天框内显示
    //             if ((historyItem.contentsType == 'TEXT' && Object.keys(historyItem.ext).length > 0) || historyItem.contentsType == 'COMMAND') {
    //               objMsg = {}
    //             } else {
    //               let contentsType = ''
    //               if(historyItem.contentsType == 'CUSTOM' && historyItem.customEvent == 'CARD'){
    //                 contentsType = 'CARD'
    //               }else{
    //                 contentsType = historyItem.contentsType
    //               }
    //               if (contentsType == "VOICE") {
    //                 var options = { url: historyItem.url };
    //                 options.headers = {
    //                   Accept: "audio/mp3"
    //                 };
    //                 options.onFileDownloadComplete = response => {
    //                   let objectURL = WebIM.utils.parseDownloadResponse.call(
    //                     WebIM.conn,
    //                     response
    //                   );
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "voice",
    //                     data: {
    //                       url: objectURL,
    //                       size: Math.floor(response.size / 1000),
    //                       initialUrl: historyItem.url
    //                     },
    //                     isListen: false,
    //                     isplay: false,
    //                     timestamp: historyItem.time
    //                   };
    //                   listArr.push(objMsg)
    //                 };
    //                 await WebIM.utils.download.call(WebIM.conn, options);
    //                 return;
    //               }
      
    //               switch (contentsType) {
    //                 case "TEXT":
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "text",
    //                     data: historyItem.data,
    //                     timestamp: historyItem.time
    //                   };
    //                   break;
    //                 case "IMAGE":
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "image",
    //                     data: historyItem.url,
    //                     timestamp: historyItem.time
    //                   };
    //                   break;
    //                 case "FILE":
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "file",
    //                     data: {
    //                       url: historyItem.url,
    //                       filename: historyItem.filename,
    //                       filelength: historyItem.file_length
    //                     },
    //                     timestamp: historyItem.time
    //                   };
    //                   break;
    //                 case "LOCATION":
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "location",
    //                     data: historyItem.addr,
    //                     timestamp: historyItem.time
    //                   };
    //                   break;
    //                 case "CARD":
    //                   objMsg = {
    //                     id: chatId,
    //                     type: "card",
    //                     data: historyItem.customExts,
    //                     timestamp: historyItem.time,
    //                     name: ''
    //                   };
    //                   break;
    //               }
    //               if (historyItem.type == 'chat') {
    //                 //如果是单聊
    //                 objMsg.userid = context.rootState.list.isChatID.id
    //               } else if (historyItem.type == 'groupchat') {
    //                 //如果是群聊
    //                 let index = context.rootState.list.chatList.findIndex(
    //                   res => res.imId == context.rootState.list.isChatID.imId
    //                 );
    //                 context.rootState.list.chatList[index].members.forEach(res => {
    //                   if (res.easemobImId == historyItem.from) {
    //                     objMsg.name = res.memberUserName;
    //                     objMsg.userid = res.memberUserId
    //                   }
    //                 });
    //               }
    //               listArr.push(objMsg)
    //             }
    //             }, 500 * (indexVoice >= 0 ? i : 0));
    //         })(i);
    //           })
    //           let findIndex = context.rootState.list.chatList.findIndex(
    //             res => res.imId == context.rootState.list.isChatID.imId
    //           );
    //           context.commit('updateMsgCurrent',{index:findIndex,content:listArr})
    //           context.commit('changeTimeWindow',context.rootState.list.isChatID.imId + new Date().getTime())
            
    //         }

    //       } 
    //     },
    //     fail: function () { }
    //   }
    //   // WebIM.conn.mr_cache = [];
    //   WebIM.conn.fetchHistoryMessages(options);

    // },
    //发送图片消息
    sendImgMessage: function (context, payload) {
      const {
        chatType,
        // chatId,
        roomType,
        file,
        callback
      } = payload;
      const id = WebIM.conn.getUniqueId();
      let obj = {};
      obj.id = 2;
      obj.type = 'image';
      obj.timestamp = new Date().getTime();
      obj.img = localStorage.getItem("ownerimg");
      obj.data = '';
      obj.userid = localStorage.getItem('myid');
      let index = context.rootState.list.chatList.findIndex(res => res.imId == context.rootState.list.isChatID.imId);
      context.commit('updateMsg', { index: index, obj: obj })
      context.commit('updateChatListLastWord', `[${i18n.t('label.icon.155')}]`);
      context.commit("changeSendMessageTime", index);
      context.commit("changeListPosition", index);
      Bus.$emit("changePosition", 0);

      const msgObj = new WebIM.message("img", id);
      msgObj.set({
        apiUrl: WebIM.config.apiURL,
        file: file,
        to: context.rootState.list.isChatID.imId,
        roomType: roomType,
        onFileUploadError: function (error) {
          Bus.$emit('file-upload-error', error);
          callback();
        },
        onFileUploadComplete: function (res) {
          let obj = {};
          obj.id = 2;
          obj.type = 'image';
          obj.timestamp = new Date().getTime();
          obj.img = localStorage.getItem("ownerimg");
          obj.data = res.uri + '/' + res.entities[0].uuid;
          obj.userid = localStorage.getItem('myid');
          let index = context.rootState.list.chatList.findIndex(res => res.imId == context.rootState.list.isChatID.imId);
          if (file.data.size >= 1000000) {
            setTimeout(() => {
              context.commit('updateMsg', { index: index, obj: obj })
              context.commit('updateChatListLastWord', `[${i18n.t('label.icon.155')}]`);
              context.commit("changeSendMessageTime", index);
              context.commit("changeListPosition", index);
              Bus.$emit("changePosition", 0);
              context.commit("deleteEmptyFile");
            }, 50000);
          } else {
            context.commit('updateMsg', { index: index, obj: obj })
            context.commit('updateChatListLastWord', `[${i18n.t('label.icon.155')}]`);
            context.commit("changeSendMessageTime", index);
            context.commit("changeListPosition", index);
            Bus.$emit("changePosition", 0);
            context.commit("deleteEmptyFile");
          }
          callback();
        },
        success: function (res) {

        }
      });
      if (chatType === "group" || chatType === "chatroom") {
        msgObj.setGroup("groupchat");
      }
      WebIM.conn.send(msgObj.body);
    },
    //发送文件消息
    sendFileMessage: function (context, payload) {
      const {
        chatType,
        roomType,
        file,
        callback
      } = payload;
      const id = WebIM.conn.getUniqueId();
      let obj = {};
      obj.id = 2;
      obj.type = 'file';
      obj.data = {};
      obj.data.url = '';
      obj.data.filename = file.data.name;
      obj.data.filelength = file.data.size;
      obj.timestamp = new Date().getTime();
      obj.img = localStorage.getItem("ownerimg");
      obj.userid = localStorage.getItem('myid');
      let index = context.rootState.list.chatList.findIndex(res => res.imId == context.rootState.list.isChatID.imId);
      context.commit('updateMsg', { index: index, obj: obj });
      context.rootState.list.chatList[index].lastWord = `[${i18n.t('FL')}]`;
      context.commit("changeSendMessageTime", index);
      context.commit("changeListPosition", index);
      Bus.$emit("changePosition", 0);
      const msgObj = new WebIM.message("file", id);
      msgObj.set({
        apiUrl: WebIM.config.apiURL,
        file: file,
        ext: {
          file_length: file.data.size
        },
        to: context.rootState.list.isChatID.imId,
        roomType: roomType,
        onFileUploadError: function (error) {
          Bus.$emit('file-upload-error', error);
          callback();
        },
        onFileUploadComplete: function (res) {
          let obj = {};
          obj.id = 2;
          obj.type = 'file';
          obj.data = {};
          obj.data.url = res.uri + '/' + res.entities[0].uuid;
          obj.data.filename = file.data.name;
          obj.data.filelength = file.data.size;
          obj.timestamp = new Date().getTime();
          obj.img = localStorage.getItem("ownerimg");
          obj.userid = localStorage.getItem('myid');
          let index = context.rootState.list.chatList.findIndex(res => res.imId == context.rootState.list.isChatID.imId);
          context.commit('updateMsg', { index: index, obj: obj });
          context.rootState.list.chatList[index].lastWord = `[${i18n.t('FL')}]`;
          context.commit("changeSendMessageTime", index);
          context.commit("changeListPosition", index);
          Bus.$emit("changePosition", 0);
          context.commit("deleteEmptyFile");
          callback();
        },
        success: function (res) {

        }
      });
      if (chatType === "group" || chatType === "chatroom") {
        msgObj.setGroup("groupchat");
      }
      WebIM.conn.send(msgObj.body);
    },

  }



}
export default Chat
