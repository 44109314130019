/**
 * 日历对象路由
 */
const calendar = [
    {
        path: "calendarheader",
        component: () => import("@/views/calendar/CalendarHeader.vue"),
        name: "日历",
    },
];

export default calendar;