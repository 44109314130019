/**
 * 实际工作清单详情对象路由
 */
const workList = [
    {
        name: "workListDetails",
        path: "workListDetails",
        component: () => import("@/views/workListDetails/taskDetailsPage.vue"),
        // name: " 实际工作清单详情页",
    },
];

export default workList;
