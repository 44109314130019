/*
 * @Author: your name
 * @Date: 2022-01-25 17:11:59
 * @LastEditTime: 2022-03-28 17:48:52
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \clould_dev\lightning-web\src\views\marketList\marketListRouter.js
 */
const marketListRouter = [
  {
    path: "marketingChannelListShow",
    component: () => import("@/views/marketList/marketingChannel/marketingChannelListShow.vue"),
    name: "营销渠道列表展示",
  },
  {
    path: "activeMember/:id",
    name: "活动成员",
    component: () => import("@/views/marketList/marketingChannel/activeMember.vue"),
  },
  {
    path: "invitation/:type/:objectApi/:objId/:prefix",
    component: () => import("@/views/marketList/marketingChannel/invitation.vue"),
    name: "invitation",
  },
  {
    path: "sendMail/:id/:userIds",
    name: "sendMail",
    component: () => import("@/views/marketList/marketingChannel/sendMail.vue"),
  },
  {
    path: 'marketingPostersListShow',
    name: 'marketingPostersListShow',
    component: () => import("@/views/marketList/marketingPosters/marketingPostersListShow.vue"),
  },
  {
    path: 'marketingSocialListShow',
    name: 'marketingSocialListShow',
    component: () => import("@/views/marketList/marketingSocial/marketingSocialListShow.vue"),
  },
  {
    path: 'marketingInvitationShow',
    name: 'marketingInvitationShow',
    component: () => import("@/views/marketList/marketingInvitation/marketingInvitationShow.vue"),
  },
  {
    path: 'marketingLandPageListShow',
    name: 'marketingLandPageListShow',
    component: () => import("@/views/marketList/marketingLandPage/marketingLandPageListShow.vue"),
  },
  {
    path: 'marketingFlowListShow',
    name: 'marketingFlowListShow',
    component: () => import("@/views/marketList/marketingFlow/marketingFlowListShow.vue"),
  }
]
  
  export default marketListRouter
  