// 主模块的getters

export default {
  getToken: (state) => {
    state.token = sessionStorage.getItem('token');
    return state.token;
  },
  // 从运行页到编辑页时将选择的行和列分组赋值
  get_reloadRow_Column(state) {
    return {
      chooseRowFileds: state.chooseRowFileds,
      choosedColumnFileds: state.choosedColumnFileds
    }
  },
  // 获取权限信息
  getReportOperation: (state) => {
    return state.reportOperation;
  },
  // 获取币种类型
  getCurrencyCode: (state) => {
    return state.currencyCode;
  },
  // 获取仪表板筛选项配置的筛选条件
  getDashboardCondition: (state) => {
    return state.dashboardCondition;
  },
}
