/**
 * 派工台对象路由
 */
const dispatchingUnits = [
    {
      path: "dispatchingUnits",
      component: () => import("@/views/dispatchingUnits/dispatchingUnits.vue"),
      name: "服务云派工台首页",
    },
    //服务云派工台缺省页
    {
      path: "DispatchDesk",
      component: () => import("@/views/dispatchingUnits/DefaultPage.vue"),
      name: "服务云派工台缺省页",
    },
  ];
  
  export default dispatchingUnits;
  