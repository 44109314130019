//  进入全局搜索页面前的路径需要保存

const menuList = {
    state: {
      fromGlobalSearchPath: '', // 进入全局搜索页面前的路径
    },
    mutations: {
      // 设置路由
      setFromGlobalSearchPath(state, data) {
        state.fromGlobalSearchPath = data;
      },
    },
    getters: {
      // 获取路由
      getFromGlobalSearchPath(state) {
        return state.fromGlobalSearchPath;
      },
    },
    actions: {}
  }
  export default menuList;