
let fetchData = (url, opts, callback,fail) => {
	fetch(url, {
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json',
		},
		method: 'post',
		body: JSON.stringify(opts)
	})
		.then(response => response.json())
		.then(res => {
			callback(res);
		}).catch(res=>{
			// 操作失败
			// Message.success(i18n.t('message.actionfailed'))
			// if(fail){
			// 	fail(res)
			// }
			
		})
}

export default fetchData;
