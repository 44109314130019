import {
       consoleSaveTab
} from "@/views/home/api.js";
/**
 * 打开menu2
 * @param {*} vue vue实例
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function add_menu2(vue, options) {
       if (!vue.$store.state.navigationStyle) {
              vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
              return
       }
       // 新打开菜单之前，关闭所有打开的dialog
       // vue.$bus.$emit('closeDialog')
       // 导航样式为控制台样式
       if (vue.$store.state.navigationStyle) {
              // 传一级菜单id了 将二级菜单添加到对应的一级菜单中  没有传添加到当前选中的一级菜单中
              let menu1PageId = ""
              if (options.menu1PageId) {
                     menu1PageId = options.menu1PageId
              } else {
                     menu1PageId = vue.$store.state.consoleData.data.level1Id
              }
              // 将当前点击数据的id和name 放到当前选中的一级tab下的children里
              // 当前一级tab的对象
              let currentLevelTab = {};
              vue.$store.state.consoleData.data.list.forEach((item) => {
                     if (item.id == menu1PageId) {
                            // 设置二级带单选中样式
                            item.level2Id = options.id;
                            // 组装当前一级菜单tabitem
                            // 当前一级带单tabid
                            currentLevelTab.id = item.id;
                            // 当前一级带单tabname
                            currentLevelTab.name = item.name;
                            // 当前一级菜单路由
                            currentLevelTab.routerName = item.routerName || "";
                            currentLevelTab.routerPath = item.routerPath || "";
                            // 当前一级菜单路由参数
                            currentLevelTab.params = item.params || "";
                            currentLevelTab.query = item.query || "";
                            item.children = item.children ? item.children : [];
                            // 判断children里是否存在此点击项 不存在再添加避免重复添加
                            // 判断是否存在此点击项
                            let res = item.children.some((cItem) => {
                                   return cItem.id == options.id;
                            });
                            if (!res) {
                                   // 如果不存在再push
                                   item.children.push({
                                          ...options
                                   });
                                   // 二级tab要展示当前一级tab  判断如果没有当前一级tab 则添加到第一项
                                   let flag = item.children.some((cItem) => {
                                          return cItem.id == menu1PageId;
                                   });
                                   if (!flag) {
                                          item.children.unshift(currentLevelTab);
                                   }
                            }
                     }
              });
              vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
              // 将数据保存到后端
              consoleSaveTab({
                     appid: vue.$store.state.home.homeApplicationId,
                     opentab: JSON.stringify(vue.$store.state.consoleData)
              });
       }

}

/**
 * 替换menu2
 * @param {*} vue vue实例
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function replace_menu2(vue, options) {
       if (!vue.$store.state.navigationStyle) {
              vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
              return
       }
       // 导航样式为控制台样式
       if (vue.$store.state.navigationStyle) {
              // 找到当前选中的一级菜单下的当前选中的二级菜单进行替换
              vue.$store.state.consoleData.data.list.forEach((item) => {
                     // 先过滤出当前选中的一级菜单item
                     if (item.id == vue.$store.state.consoleData.data.level1Id) {
                            item.children = item.children ? item.children : [];
                            // 再在当前选中的一级菜单中的二级菜单列表  过滤出当前选中的二级菜单
                            let tabIndex = item.children.findIndex((cItem) => {
                                   return (cItem.id == item.level2Id)
                            })
                            if (tabIndex == -1) {
                                   // vue.$message.warning('您当前一级菜单下没有可以替换的二级菜单，所以为您生成了一个新的二级菜单')
                                   add_menu2(vue, options)
                            } else {
                                   // 设置二级带单选中样式
                                   item.level2Id = options.id;
                                   // 替换当前选中的一级菜单
                                   item.children[tabIndex] = options
                            }
                     }
              });
              vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
              // 将数据保存到后端
              consoleSaveTab({
                     appid: vue.$store.state.home.homeApplicationId,
                     opentab: JSON.stringify(vue.$store.state.consoleData)
              });
       }

}


/**
 * 定位到已经打开的一级菜单
 * @param {*} vue vue实例
 * @param {*} options 菜单信息  id
 */
function reopen_menu2(vue, options) {
       if (!vue.$store.state.navigationStyle) {
              vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
              return
       }
       // 判断当前要刷新的是不是当前选中的二级tab
       vue.$store.state.consoleData.data.list.forEach((item) => {
              if (item.id == vue.$store.state.consoleData.data.level1Id) { // 当前选中的一级list
                     item.children.forEach(cItem => {
                            if (cItem.id == options.id) {
                                   // 通过id过滤出Item
                                   vue.$bus.$emit('clickTab2', cItem)
                            }
                     })
              }
       })
}

/**
 * 关闭menu1
 * @param {*} vue vue实例
 * @param {*} options 菜单信息 id
 */
function delete_menu2(vue, options) {
       if (!vue.$store.state.navigationStyle) {
              vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
              return
       }
       localStorage.removeItem(options.id)
       // 关闭 tabItem, tabIndex
       let tabItem = options
       vue.$store.state.consoleData.data.list.forEach((item) => {
              // 当前选中一级tab下的children  即二级teb列表
              if (item.id == vue.$store.state.consoleData.data.level1Id) {
                     // 过滤出当前要关闭的菜单的所在索引
                     let tabIndex = item.children && item.children.findIndex((cItem) => {
                            return (cItem.id == options.id)
                     })
                     if (tabIndex == -1) {
                            // vue.$message.warning('没有找到你要关闭的二级菜单，请检查传的id是否正确')
                     } else {
                            vue.$bus.$emit('closeTab2', tabItem, tabIndex)
                     }
                     // 关闭的是当前选中的二级Tab
                     // if (options.id == item.level2Id) {
                     //        // 关闭的是最后一位
                     //        if (tabIndex == item.children.length - 1) {
                     //               item.children.splice(tabIndex, 1);
                     //               // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
                     //               if (
                     //                      item.children.length == 1 &&
                     //                      item.children[0].id == vue.$store.state.consoleData.data.level1Id
                     //               ) {
                     //                      item.children = [];
                     //               }
                     //               // 设置当前默认选中的二级菜单
                     //               item.level2Id = item.children[item.children.length - 1]?.id || "";
                     //        } else {
                     //               // 关闭的不是最后一位
                     //               // 删除
                     //               item.children.splice(tabIndex, 1);
                     //               // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
                     //               if (
                     //                      item.children.length == 1 &&
                     //                      item.children[0].id == vue.$store.state.consoleData.data.level1Id
                     //               ) {
                     //                      item.children = [];
                     //               }
                     //               // 设置当前默认选中的二级菜单
                     //               item.level2Id = item.children[tabIndex]?.id || "";
                     //        }
                     //        vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
                     //        // 将数据保存到后端
                     //        consoleSaveTab({
                     //               appid: vue.$store.state.home.homeApplicationId,
                     //               opentab: JSON.stringify(vue.$store.state.consoleData),
                     //        });
                     //        // 有二级跳二级  没有二级跳当前选中的一级
                     //        if (item.level2Id) {
                     //               // 有二级的情况下 判断当前选中的二级tab 是自定义页面 还是 标准（自定义）对象
                     //               // 过滤出当前选中的二级tab  (因为是关闭后重新设置的当前选中的二级Tab 所以需要过滤一下  不能用当前关闭项的tabItem)
                     //               let level2Item = item.children.find((citem) => {
                     //                      return citem.id == item.level2Id;
                     //               });
                     //               // 有routerName说明是调用ccsdk生成的菜单
                     //               if (level2Item.routerName) {
                     //                      vue.$router.push({
                     //                             name: level2Item.routerName,
                     //                             params: level2Item.params || ""
                     //                      })
                     //               } else {
                     //                      // 判断当前的二级是自定义页面 还是  标准（自定义）对象
                     //                      if (level2Item.customPage == "1") {
                     //                             // 新版自定义页面
                     //                             vue.$router.push({
                     //                                    path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                     //                             });
                     //                      } else {
                     //                             vue.$router.push({
                     //                                    path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
                     //                                    query: {
                     //                                           dataId: item.level2Id,
                     //                                    },
                     //                             });
                     //                      }
                     //               }
                     //        } else {
                     //               // 二级都关闭之后 跳转当前选中的一级tab
                     //               // 还需要判断当前的一级tab是否是通过ccdk添加的
                     //               if (item.routerName) {
                     //                      vue.$router.push({
                     //                             name: item.routerName,
                     //                             params: item.params || ""
                     //                      })
                     //               } else {
                     //                      vue.$router.push({
                     //                             path: `/commonObjects/console-multi-screen/console-detail/${vue.$store.state.consoleData.data.level1Id}/DETAIL`,
                     //                             query: {
                     //                                    dataId: vue.$store.state.consoleData.data.level1Id,
                     //                             },
                     //                      });
                     //               }
                     //        }
                     // } else {
                     //        // 关闭的不是当前选中的直接关闭即可
                     //        item.children.splice(tabIndex, 1);
                     //        // 如果二级tab列表中只有一项，且这一项是当前一级tab  那么当前二级tablist置空
                     //        if (
                     //               item.children.length == 1 &&
                     //               item.children[0].id == vue.$store.state.consoleData.data.level1Id
                     //        ) {
                     //               item.children = [];
                     //        }
                     //        vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
                     //        // 将数据保存到后端
                     //        consoleSaveTab({
                     //               appid: vue.$store.state.home.homeApplicationId,
                     //               opentab: JSON.stringify(vue.$store.state.consoleData),
                     //        });
                     // }
              }
       });
}

/**
 * 刷新menu2
 * @param {*} vue vue实例
 * @param {*} options 菜单信息 id
 */
function refresh_menu2(vue, options) {
       if (!vue.$store.state.navigationStyle) {
              vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
              return
       }
       // 判断当前要刷新的是不是当前选中的二级tab
       vue.$store.state.consoleData.data.list.forEach((item) => {
              if (item.id == vue.$store.state.consoleData.data.level1Id) { // 当前选中的一级list
                     if (item.level2Id == options.id) { // 当前一级list下选中的二级list
                            // 通过id过滤出Item
                            let level2Item = item.children.find((citem) => {
                                   return citem.id == item.level2Id;
                            });
                            vue.$bus.$emit('refreshTab2', level2Item)
                            // 有routerName说明是调用ccsdk生成的菜单
                            // if (level2Item.routerName) {
                            //        vue.$router.push({
                            //               name: level2Item.routerName,
                            //               params: level2Item.params || "",
                            //               query: {
                            //                      t: new Date().getTime()
                            //               }
                            //        })
                            // } else {
                            //        // 判断当前的二级是自定义页面 还是  标准（自定义）对象
                            //        if (level2Item.customPage == "1") {
                            //               // 新版自定义页面
                            //               vue.$router.push({
                            //                      path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
                            //               });
                            //        } else {
                            //               vue.$router.push({
                            //                      path: `/commonObjects/console-multi-screen/console-detail/${level2Item.id}/DETAIL`,
                            //                      query: {
                            //                             dataId: level2Item.id,
                            //                             t: new Date(),
                            //                      },
                            //               });
                            //        }
                            // }
                     }
              }
       })
}
export {
       add_menu2,
       replace_menu2,
       reopen_menu2,
       delete_menu2,
       refresh_menu2
}
