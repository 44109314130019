const social = [
  {
    path: "social/social",
    component: () => import("@/views/social/index.vue"),
    name: "Social",
    meta: { title: "Social" },
  },
  {
    path: "marketSetHost",
    component: () => import("@/views/systemSettings/components/marketSetting/marketSetHost/index.vue"),
    name: "链接域",
 }
];

export default social;
