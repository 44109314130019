// 短信路由文件
const noteSms = [
  {
    path: "noteSms/noteSmsList",
    component: () => import("@/views/noteSms/index.vue"),
    name: "短信列表页",
  },
];

export default noteSms;
