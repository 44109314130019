<template>
  <div class="editableCell" :style="{ width: width }">
    <!-- 普通文本/打分 -->
    <div
      v-if="
          fieldName === 'xsjg' ||
          fieldName === 'unitprice' ||
          fieldName === 'rate' ||
          fieldName === 'zongjia' ||
          fieldName === 'totalamount' ||
          fieldName === 'totalprice' ||
          fieldName === 'total' ||
          fieldName === 'amount' ||
          fieldName === 'xiaoji'
      "
      class="cellInput rowheight"
    >
      <el-input-number
        v-model="editValue"
        @change="changeEvent(arguments[0])"
        :readonly="true"
        :precision="2"
        :disabled="
          fieldName === 'xsjg' ||
          fieldName === 'unitprice' ||
          fieldName === 'rate'
            ? false
            : true
        "
        :controls="false"
      >
      </el-input-number>
      <!-- 产品单价图标 -->
      <!-- <svg
        v-show="
            fieldName === 'xsjg' ||
            fieldName === 'unitprice' ||
            fieldName === 'rate' ||
            fieldName === 'unit'
            "
        style="position: absolute;right: 8px;top: 10px;width:15px;height:15px;"
        class="icon pricebook"
        aria-hidden="true"
        >
        <use href="#icon-a-pricebook"></use>
      </svg> -->
    </div>
    <!-- 添加子产品 - 价格手册 -->
    <el-select
      v-model="editValue"
      v-else-if="fieldName === 'pricebook2id'"
      :placeholder="$t('label.select.please')"
      v-focusPlaceholder="'请输入价格手册名称'"
      filterable
      @change="changeChildPricebook2id(arguments[0])"
    >
      <el-option
        v-for="item in options"
        :key="item.value"
        :label="item.label"
        :value="item.value"
      >
      <span style="float: left; margin-right:40px">{{ item.label }}</span>
      <span style="float: right; color: #8492a6; font-size: 13px">{{ item.listprice || "-" }}</span>
      </el-option>
    </el-select>
    <!-- ------------------------ -->
    <el-input
      class="rowheight"
      v-model="editValue"
      v-else-if="
        inputType === 'text' || inputType === 'input' || inputType === 'rate'
      "
      @change="changeEvent(arguments[0])"
      @keyup.enter.native="searchEnterBtn"
      :placeholder="placeholder"
      :disabled="(fieldName == 'productcode' || fieldName == 'productspecification') ? true : disabled"
    >
    </el-input>
    <!-- 过滤器中的地理定位字段 -->
    <div
      v-if="inputType === 'location' && originType === 'filter'"
      class="location"
    >
      <el-input> </el-input>
      <el-input> </el-input>
    </div>
    <!-- 折扣选择对象 -->
    <div
      v-else-if="
        (fieldName === 'customerdiscount' ||
          fieldName === 'totalcustomer' ||
          fieldName === 'discountamount' ||
          fieldName === 'discount' ||
          fieldName === 'zhekou') &&
        (relatedApiname === 'invoice' ||
          relatedApiname === 'bjdmc' ||
          relatedApiname === 'orderid' ||
          relatedApiname === 'purchaseorders' ||
          relatedApiname === 'opportunity')
      "
      class="cellInput rowheight"
    >
      <el-input
        v-model="editValue"
        :readonly="true"
        @change="changeEditValue(editValue)"
        @focus="switchShow($event, true, 'price')"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-edit input-position"
          @click="switchShow($event, true, 'price')"
        ></i>
      </el-input>
      <el-card v-show="tipShow.zhekouFlg" class="cellCard cellCardZhekou">
        <div style="margin: 10px 0px">{{ $t("c26") }}</div>
        <div class="discountCardInput">
          <el-radio-group v-model="radioData.radio">
            <el-radio label="1"
              ><p>{{ $t("label.percent") }}</p>
              <el-input-number
                size="mini"
                style="width: 80px"
                v-show="radioData.radio === '1'"
                :controls="false"
                v-model="radioData.percentageValue"
              >
              </el-input-number>
              <span
                v-show="radioData.radio === '1'"
                style="color: #303133; margin-left: 5px; font-size: 14px"
                >%</span
              >
            </el-radio>

            <el-radio label="2" style="margin: 10px 0px"
              v-show="objectApi !== 'opportunitypdt'"
              ><p>{{ $t("label.diedirect.sale") }}</p>
              <el-input-number
                size="mini"
                style="width: 80px"
                v-show="radioData.radio === '2'"
                v-model="radioData.cutValue"
                :controls="false"
                :min="min"
                :max="max"
                :precision="2"
              ></el-input-number>
            </el-radio>
          </el-radio-group>
        </div>

        <div class="btBox">
          <el-button type="primary" size="mini" @click="discoundSave(0)">{{
            $t("label.emailtocloudcc.button.complete")
          }}</el-button>
          <el-button size="mini" @click="switchShow($event, true, 'price')">{{
            $t("label.tabpage.cancel")
          }}</el-button>
        </div>
      </el-card>
    </div>
    <!-- 税率对象选择 -->
    <div
      v-else-if="fieldName === 'tax' || fieldName === 'taxamount'"
      class="cellInput"
    >
      <el-input
        v-model="editValue"
        :readonly="true"
        class="rowheight"
        @change="changeEditValue(editValue)"
        @focus="switchShow($event, false, 'tax', 'click')"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-edit input-position"
          @click="switchShow($event, false, 'tax', 'click')"
        ></i>
      </el-input>
      <el-card v-show="tipShow.taxFlg" class="cellCard cellCardTax">
        <div>{{ $t("label.choose.is.tax") }}</div>
        <div class="taxCheckBox" v-if="taxValueD.taxCheckList&&taxValueD.taxCheckList.length > 0">
          <!-- key不能绑定引用类型值 -->
          <div
            v-for="tax in taxValueD.taxCheckList"
            :key="tax.id"
            class="taxList"
          >
            <el-checkbox v-model="tax.checked">
              <div class="taxName">{{ tax.name }}</div>
            </el-checkbox>
            <el-input
              size="mini"
              style="width: 50px"
              v-model="tax.ratio"
              :disabled="true"
            />
            <span style="margin-left: 10px; color: #606266">%</span>
          </div>
        </div>
        <div v-else class="taxCheckBox">
          <p class="unLink">该产品未关联任何税种。</p>
        </div>
        <div class="btBox">
          <el-button type="primary" size="mini" @click="discoundSave('1')">{{
            $t("label.emailtocloudcc.button.complete")
          }}</el-button>
          <el-button size="mini" @click="switchShow($event, true, 'tax')">{{
            $t("label.tabpage.cancel")
          }}</el-button>
        </div>
      </el-card>
    </div>
    <!-- 数字或百分数 -->
    <el-input-number
      clearable
      v-model="editValue"
      :min="
        fieldName === 'quotedquantity' ||
        fieldName === 'shuliang' ||
        fieldName === 'qty' ||
        fieldName === 'quantity'
          ? 1
          : min
      "
      :max="
        fieldName === 'quotedquantity' ||
        fieldName === 'shuliang' ||
        fieldName === 'qty' ||
        fieldName === 'quantity'
          ? 99999999999999999
          : max
      "
      :controls="false"
      :precision="precision"
      v-else-if="
        (inputType === 'number' || inputType === 'percent-number') &&
        fieldName !== 'amount' && fieldName !== 'unitprice'
      "
      :class="originType === 'masterSlaveEntry' ? 'text-right-input' : ''"
      @change="changeEvent(arguments[0])"
      :disabled=" fieldName === 'subtotal'? true : disabled"
    >
    </el-input-number>
    <!-- 过滤器下的评分字段用数字输入框显示 -->
    <el-input-number
      clearable
      v-model="editValue"
      :min="min"
      :max="max"
      :controls="false"
      :precision="precision"
      v-if="inputType === 'score' && originType === 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-input-number>
    <!-- 评分 -->
    <div
      class="stars editStarts"
      :class="starsClassName"
      v-if="inputType === 'score' && originType === undefined"
    >
      <span
        v-for="(star, idx) in schemefieldLength + 1"
        v-show="idx !== 0"
        :class="{ show2: idx <= editValue }"
        @mouseover="setStar(idx)"
        @mouseout="setStar('active')"
        @click="setClick(idx)"
        :key="idx"
      >
      </span>
    </div>
    <!-- 文本区 -->
    <el-input
      v-model="editValue"
      type="textarea"
      :rows="rows"
      v-if="inputType === 'textarea'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-input>
    <!-- 日期 -->
    <el-date-picker
      v-model="editValue"
      :type="inputType"
      :placeholder="$t('label_tabpage_selectdate')"
      value-format="yyyy-MM-dd"
      :format="dateFormat"
      v-else-if="inputType === 'date' && originType !== 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-date-picker>
    <!-- 日期时间 -->
    <el-date-picker
      v-model="editValue"
      type="datetime"
      value-format="yyyy-MM-dd HH:mm:ss"
      :format="datetimeFormat"
      :placeholder="$t('vue_label_commonobject_selectdatatime')"
      v-else-if="inputType === 'datetime' && originType !== 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-date-picker>
    <!-- 时间 -->
    <el-time-select
      v-model="editValue"
      value-format="HH:mm:ss"
      :format="timeFormat"
      :picker-options="{ start: '00:00', step: '00:15', end: '23:45' }"
      :placeholder="$t('vue_label_commonobject_anytime')"
      v-else-if="inputType === 'time'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-time-select>
    <!-- 自定义时间组件-过滤器中的时间选择 -->
    <TimePerson
      v-model="editValue"
      v-else-if="
        (inputType === 'date' || inputType === 'datetime') &&
        originType === 'filter'
      "
      :inputType="inputType"
      :editValue="value.value"
      @getChartsByCondition="getChartsByCondition"
    >
    </TimePerson>
    <!-- 下拉框 -->
    <el-select
      v-model="editValue"
      filterable
      :clearable="
        value !== undefined &&
        value.value ==
          ('0%' ||
            '10%' ||
            '20%' ||
            '30%' ||
            '40%' ||
            '50%' ||
            '60%' ||
            '70%' ||
            '80%' ||
            '90%' ||
            '100%')
          ? false
          : true
      "
      :multiple="inputType === 'multi-select'"
      :placeholder="$t('label.select.please')"
      v-else-if="inputType === 'select' || inputType === 'multi-select'"
      @visible-change="clk($event)"
      @change="changeEvent(arguments[0])"
      :allow-create="originType == 'filter' ? true : false"
      :class="originType == 'filter' ? 'filtetClass' : ''"
      :disabled="
        fieldName == 'currency' && objectApi == 'opportunitypdt'
          ? true
          : disabled
      "
      :loading="selectloading"
    >
      <template v-if="options&&options.length > 0">
        <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value ? item.value : item.codevalue"
        >
        </el-option>
      </template>
    </el-select>
    <!-- 查找/查找多选字段 -->
    <el-select
      ref="addmanual"
      v-else-if="
        (inputType === 'remote-select' ||
          inputType === 'remote-multi-select') &&
        fieldName !== 'tax' &&
        fieldName !== 'taxamount' &&
        fieldName !== 'pricebook2id'
      "
      v-model="editValue"
      filterable
      :multiple="inputType === 'remote-multi-select' ? true : false"
      :remote-method="
        (query) =>
          originType === 'filter'
            ? filterRemoteMethod(query)
            : remoteMethod(query)
      "
      :clearable="
        this.fieldName !== 'chanpin' &&
        this.fieldName !== 'product2id' &&
        this.fieldName !== 'plan' &&
        this.fieldName !== 'product'
      "
      remote
      class="no-suffix rowheight"
      style="width: 100%"
      @change="changeEvent(arguments[0])"
      @blur="
        originType == 'filter' ? selectBlur($event) : ''
      "
      @focus="getProduct"
      :disabled="disabled"
    >
      <p class="searchTipBox">
        <span @click="remoteSearch" style="display: inline-block; width: 100%">
          <i class="el-icon-search"></i>
          <span style="padding-left: 10px">
            <!-- 前往精准搜索 -->
            {{ $t("vue_label_commonobjects_detail_to_precise_search") }}
          </span>
        </span>
      </p>
      <el-option
        v-for="item in options"
        v-show="!newAddData||newAddData.length<=0"
        :key="item.value"
        :label="item.label"
        :value="item.value"
        @click.native="originType === 'filter' ? optionsClick(item) : ''"
      >
      </el-option>
      <el-option
        class="search-dialog"
        v-for="(item,index) in newAddData"
        infinite-scroll-immediate="true"
        :key="`${item.id}-${index}`"
        :label="item.name"
        :value="item.id"
        @click.native="changeListData(item)"
      >
      </el-option>
      <el-option
        class="display-none"
        key="new"
        :label="$t('label.new')"
        :value="$t('label.new')"
      >
      </el-option>
    </el-select>
    <span
      v-if="
        (inputType === 'remote-select' ||
          inputType === 'remote-multi-select') &&
        fieldName !== 'tax' &&
        fieldName !== 'taxamount' &&
        fieldName !== 'pricebook2id'
      "
      :class="originType === 'table' ? 'remoteBtn' : 'remoteBtn1'"
      @click="remoteSearch"
    >
      <!-- [查找] -->
      <i class="el-icon-search"></i>
    </span>
    <!-- 标签 -->
    <div v-else-if="inputType === 'tag'" style="text-align: left">
      <el-tag
        v-for="tag in editValue"
        :key="tag.name"
        closable
        :type="
          tag.color === 'lan'
            ? ''
            : tag.color === 'hong'
            ? 'danger'
            : tag.color === 'lv'
            ? 'success'
            : tag.color === 'hui'
            ? 'info'
            : tag.color === 'huang'
            ? 'warning'
            : ''
        "
        effect="dark"
        class="tag"
        >{{ tag.name }}</el-tag
      >
    </div>
    <!-- 过滤器地址字段 -->
    <el-input
      v-model="editValue"
      v-if="inputType === 'address' && originType === 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-input>
    <!-- 地址字段: 包含邮编、国家或地区、省/辖区、城市/城镇、街道 -->
    <div class="address-box" v-else-if="inputType === 'address'">
      <label for>
        <!-- 邮编 -->
        {{ $t("System_AddressFieldType_Post") }}
        :
      </label>
      <el-input v-model="editValue" clearable></el-input>
      <label for>
        <!-- 国家或地区: -->
        {{ $t("label.address.country") }}
      </label>
      <!-- 请选择 -->
      <el-select
        v-model="editValue"
        clearable
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
      >
        <template v-if="nationOptions.length > 0">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </template>
      </el-select>
      <label for>
        <!-- 省/辖区: -->
        {{ $t("label.address.province") }}
      </label>
      <!-- 请选择 -->
      <el-select
        clearable
        v-model="editValue"
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
      >
        <template v-if="provinceOptions.length > 0">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </template>
      </el-select>
      <label for>
        <!-- 城市/城镇: -->
        {{ $t("label.address.city") }}
      </label>
      <!-- 请选择 -->
      <el-select
        v-model="editValue"
        clearable
        :placeholder="$t('label.select.please')"
        :disabled="disabled"
      >
        <template v-if="cityOptions.length > 0">
          <el-option
            v-for="item in options"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          ></el-option>
        </template>
      </el-select>
      <label for>
        <!-- 街道: -->
        {{ $t("label.address.street") }}
      </label>
      <el-input v-model="editValue" clearable :disabled="disabled"></el-input>
    </div>
    <!-- 复选框 -->
    <el-checkbox
      v-model="editValue"
      v-else-if="inputType === 'checkbox'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-checkbox>
    <!-- 过滤器富文本字段 -->
    <el-input
      clearable
      v-model="editValue"
      type="textarea"
      :rows="rows"
      v-if="inputType === 'kindeditor' && originType === 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    />
    <!-- 过滤器文件字段 -->
    <el-input
      v-model="editValue"
      v-if="inputType === 'file' && originType === 'filter'"
      @change="changeEvent(arguments[0])"
      :disabled="disabled"
    >
    </el-input>
    <!-- 错误信息提示 -->
    <div class="errTip" v-if="showTip">
      {{ errTip }}
    </div>

    <!-- 弹窗 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      style="height: 91%"
      :visible.sync="serchTableShow"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      append-to-body
    >
      <listTable
        ref="listTable"
        :tableAttr="tableAttr"
        :tableData="tableData"
        @clickTax="clickTax"
      ></listTable>
    </el-dialog>
  </div>
</template>

<script>
/**
 * @description: 内联编辑 编辑框
 * 功能：
 * 1、根据不同字段类型展示不同字段编辑样式
 * 2、错误信息提示
 * 逻辑：
 * 1、根据传入字段类型，支持展示普通文本、过滤器地理定位字段、折扣选择对象、税率对象选择、数字、评分、文本区、日期、
 * 日期时间、时间、自定义时间组件、下拉款、查找/查找多选、标签、过滤器地址字段、地址字段、复选框、
 * 过滤器富文本字段
 * 2、编辑后对数据进行规制校验，校验不通过则显示传入的提示信息
 */
import TimePerson from "../TimePerson/index";
import * as Time from "@/utils/time";
import listTable from "@/views/systemSettings/components/marketSetting/listTable.vue";

export default {
  name: "editable-cell",
  props: {
    selectloading: Boolean,
    showDiy: {
      type: String,
      default: "none",
    },
    isSpecialCreate: {
      type: Boolean,
      default: false,
    },
    tipShow: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 税率
     */
    taxValue: {
      type: Object,
      default: () => ({}),
    },
    /**
     * 字段编辑权限
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * 输入框占位符
     */
    placeholder: {
      type: String,
      default: "",
    },
    /**
     * 字段所在行下标 批量操作时用
     */
    lineIndex: {
      type: Number,
      default: 0,
    },
    /**
     * 主从录入时定位 从对象位置
     */
    subordinateIndex: {
      type: Number,
      default: -1,
    },
    /**
     * 组件宽度
     */
    width: {
      type: String,
      default: "100%",
    },
    /**
     * 字段值 因类型不固定 用对象形式传递 value:{value:''}
     */
    value: {
      type: [Object, String],
      default: () => {},
    },
    /**
     * 字段label
     */
    field: {
      type: String,
      default: "",
    },
    /**
     * 字段名称
     */
    fieldName: {
      type: String,
    },
    /**
     * 字段id
     */
    fieldId: {
      type: String,
    },
    /**
     * 文本区字段行数
     */
    rows: {
      type: Number,
      default: 1,
    },
    /**
     * 字段类型
     */
    inputType: {
      type: String,
      default: "input",
    },
    /**
     * 评分字段最大值
     */
    schemefieldLength: {
      type: Number,
    },
    /**
     *  数字小数点位数
     */
    precision: {
      type: Number,
      default: 0,
    },
    /**
     * 数字最小值
     */
    min: {
      type: Number,
      default: 0 - Math.pow(10, 18) + 1,
    },
    /**
     * 数字最大值
     */
    max: {
      type: Number,
      default: Math.pow(10, 18) - 1,
    },
    /**
     * 下拉框选项
     */
    options: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 国家/地区选项
     */
    nationOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 省/辖区选项
     */
    provinceOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 地址字段 城市/城镇选项
     */
    cityOptions: {
      type: Array,
      default: () => [],
    },
    /**
     * 控制错误信息显隐
     */
    showTip: {
      type: Boolean,
      default: false,
    },
    /**
     * 错误信息
     */
    errTip: {
      type: String,
      //   default: "错误信息..."
      default() {
        return this.$i18n.t("label.vlidaterule.message");
      },
    },
    /**
     * 下拉框远程搜索方法
     */
    remote: {
      type: String,
      default: "",
    },
    /**
     * 字段绑定动态规则事件
     */
    changeEvents: {
      type: String || Array,
    },
    /**
     * 父组件类型 table 表格 filter 过滤器
     */
    originType: {
      type: String,
      default: "",
    },
    /**
     * 筛选器所选字段对象
     */
    objid: {
      type: String,
    },
    /**
     * 批量操作记录字段所在对象api,父组件使用
     */
    objectApi: {
      type: String,
      default: "",
    },
    // 判断是否是业务机会产品用的
    relatedApiname: {
      type: String,
      default: "",
    },
    // 不可删除，create-edit-obj文件进行相关列表匹配时需要该组件上的relatedId，只有在props中声明的属性才会挂在组件dom上
    relatedId: {
      type: String,
      default: "",
      
    },
    /**
     * 字段是否必填，父组件使用
     */
    required: {
      type: Boolean,
      default: false,
    },
    /**
     * 主从录入当前产品id
     */
    productid: {
      type: String,
      default: "",
    },
    /**
     * 父组件状态
     */
    editFlg: {
      type: Object,
      default: () => {},
    },
    /**
     * 编辑折扣数据回显
     */
    radioDataP: {
      type: Object,
      default: () => {},
    },
    addmanualData: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    listTable,
    TimePerson,
  },
  data() {
    return {
      tableData: [],
      tableAttr: [
        {
          aa: "价格手册名称",
          bb: "pricebookname",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "产品名称",
          bb: "productname",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "价目表价格",
          bb: "listprice",
          cc: false,
          dd: true,
          ee: "E",
          width: "",
        },
        {
          aa: "已启用",
          bb: "isenable",
          cc: false,
          dd: true,
          ee: "F",
          width: "",
        },
      ],
      serchTableShow: false,
      taxInputValueNumber: this.editValue ? this.editValue : "0.00",
      radioData: {
        radio: "1",
        percentageValue: 0,
        cutValue: 0,
      }, // 折扣对象 折扣信息
      taxInputValue: this.editValue ? this.editValue : "0.00",
      starsClassName: this.fieldName + this.lineIndex, // 评分字段通过字段和所在位置区分
      // editValue: this.value && this.value.value ? this.value.value : "",
      editValue: "",
      dateFormat: Time.dateFormat(this.$cookies.get("countryCode")), // 日期格式化
      datetimeFormat: Time.datetimeFormat(this.$cookies.get("countryCode")), // 日期时间格式化
      timeFormat: Time.timeFormat(this.$cookies.get("countryCode")), // 时间格式化
      addData: [],
      pageNum: 8,
    };
  },
  directives: {
    // el-select设置focus时placeholder的文字提示
    // 单选：componentInstance.$refs.reference
    focusPlaceholder: {
      inserted (el, { value }, { componentInstance }) {
        const { $refs: { reference } } = componentInstance;
        reference.focus = () => {
          if (!componentInstance.value?.length) { 
            componentInstance.currentPlaceholder = value;
          }
        }
      },
    },
  },
  created() {
    this.setInputValue();
    this.listenStorage();
    if (
      this.editFlg &&
      this.editFlg.type &&
      this.editFlg.type !== "EDIT" &&
      this.editFlg.order !== true &&
      this.showDiy === "none" &&
      !this.isSpecialCreate
    ) {
      //设置默认值
      if (
        ["shuliang", "quotedquantity", "qty", "quantity"].includes(
          this.fieldName
        )
      ) {
        this.$emit(
          "editValueChangeTax",
          1,
          this.fieldName,
          this.lineIndex,
          this.subordinateIndex
        );
      }
      if (
        [
          "discountamount",
          "tax",
          "taxamount",
          "rate",
          "unitprice",
          "xsjg",
          "listprice",
          "discount"
        ].includes(this.fieldName)
      ) {
        this.$emit(
          "editValueChangeTax",
          0.0,
          this.fieldName,
          this.lineIndex,
          this.subordinateIndex
        );
      }
    }
  },
  beforeDestroy(){
    window.removeEventListener("storage", this.storageChangeCallback);
  },
  methods: {
    /**
     * changeEditValue:处理editValue，保留n位小数,因为v-model不可以绑定条件值或计算值
     *
     * @param {String} val:当前值
     * @param {Number} num:保留的小数位，默认为2
     */
    changeEditValue(val, num = 2) {
      this.editValue = val.toFixed(num);
    },
    /**
     * 折扣/税字段显示/隐藏气泡
     * 根据e获取点击位置
     * @param {event} e (点击dom对象)
     * @param {Boolean} flg (true:折扣 false:税)
     * @param {String} type (price:折扣 tax:税)
     */
    switchShow(e, flg, type, movement) {
      if(e) {
        /** 折扣和税的弹窗相对于视口定位，浮于滚动条上方 */
        let inputdom = ""; //input元素
        let carddom = ""; //card元素
        let sub = "";
        if (flg == true) {
          // 折扣
          sub = "cellCardZhekou";
        } else if (flg == false) {
          // 税
          sub = "cellCardTax";
        }
        if (e.target) {
          inputdom = e.target.getBoundingClientRect();
        }
        if (document.getElementsByClassName(sub)[this.lineIndex]) {
          carddom = document.getElementsByClassName(sub)[this.lineIndex];
          if(inputdom){
            carddom.style.left = inputdom.x - 130 + "px";
            carddom.style.top = inputdom.y + 40 + "px";
          }
        }
      }
      this.$emit("switchShow", this.lineIndex, flg, type, movement);
    },
    /**
     * 计算税率
     */
    clickTax(row) {
      this.serchTableShow = false;
      this.editValue = row.listprice;
      this.$emit(
        "editValueChangeTax",
        row.listprice,
        this.fieldName,
        this.lineIndex,
        this.subordinateIndex
      );
    },
    /**
     * 折扣/税计算
     * @param {Number} type 标识是折扣还是税字段,type为0时表示是折扣字段,type为1时是税
     */
    discoundSave(type) {
      if (type === 0) {
        //百分比
        let text = "";
        if (this.radioData.radio === "1") {
          this.radioData.cutValue = 0;
          if (
            this.radioData.percentageValue < 0 ||
            this.radioData.percentageValue > 100
          ) {
            this.$message.warning(this.$i18n.t("c744"));
          } else {
            if (
              !/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(
                this.radioData.percentageValue
              )
            ) {
              this.$message.warning(this.$i18n.t("c745"));
            } else {
              if (this.taxValueD.xiaoji) {
                text = Number(
                  this.taxValueD.xiaoji.value *
                    (this.radioData.percentageValue * 0.01)
                ).toFixed(2);
              } else if(this.taxValueD.subtotal) {
                // 业务机会下更新折扣，总计计算
                text = Number(
                  this.taxValueD.subtotal.value *
                    (this.radioData.percentageValue * 0.01)
                ).toFixed(2);
              }else {
                text = Number(
                  this.taxValueD.amount.value *
                    (this.radioData.percentageValue * 0.01)
                ).toFixed(2);
              }
              this.$emit(
                "editValueZhekouChange",
                this.radioData,
                this.lineIndex
              );
              this.$emit(
                "editValueChangeTax",
                text,
                this.fieldName,
                this.lineIndex,
                this.subordinateIndex,
                true
              );
              this.switchShow(null, true, "price");
            }
          }
        } else {
          //直接减价
          // 最多两位小数
          if (
            !/^(([1-9]{1}\d*)|(0{1}))(\.\d{1,2})?$/.test(
              this.radioData.cutValue
            )
          ) {
            this.$message.warning(this.$i18n.t("c745"));
          } else {
            text = Number(this.radioData.cutValue).toFixed(2);
            this.$emit(
              "editValueZswitchShowhekouChange",
              this.radioData,
              this.lineIndex
            );
            this.$emit(
              "editValueChangeTax",
              text,
              this.fieldName,
              this.lineIndex,
              this.subordinateIndex,
              false
            );
            this.switchShow( null, true, "price");
          }
        }
      } else {
        // 选择税种后计算 税 = （小计 - 折扣) *  (税率1 +税率2 + ... + 税率n)
        let number = 0;
        // 此处只将选中的税种纳入计算
        if (this.taxValueD.taxCheckList !== [] && this.taxValueD.taxCheckList) {
          this.taxValueD.taxCheckList.forEach((item) => {
            if (item.checked) {
              number += item.ratio * 0.01;
            }
          });
        }

        // 折扣值
        let discountamount =
          this.taxValueD.discountamount && this.taxValueD.discountamount.value
            ? Number(this.taxValueD.discountamount.value)
            : 0;

        // text为计算后的税
        let text = "";
        if (this.taxValueD.xiaoji) {
          text = Number(
            (this.taxValueD.xiaoji.value - discountamount) * number
          ).toFixed(2);
        } else {
          text = Number(
            (this.taxValueD.amount.value - discountamount) * number
          ).toFixed(2);
        }
        this.$emit(
          "editValueChangeTax",
          text,
          this.fieldName,
          this.lineIndex,
          this.subordinateIndex,
          this.taxValueD.taxCheckList
        );
        this.switchShow(null,false, "tax", "save");
      }
    },
    /**
     * 处理字段显示值
     */
    setInputValue() {
      if (this.inputType && this.inputType === "number") {
        if (
          this.value.value === "" ||
          this.value.value === null ||
          this.value.value === undefined
        ) {
          this.editValue = undefined;
        } else {
          this.editValue = Number(this.value.value);

          // 回显折扣编辑弹框数据
          if (
            this.taxValueD &&
            this.taxValueD.discount &&
            this.taxValueD.discount.value
          ) {
            this.radioData.percentageValue = parseFloat(
              this.taxValueD.discount.value
            ).toFixed(2);
          }
          this.radioData.cutValue = this.taxValueD?.discountamount?.value
            ? Number(this.taxValueD?.discountamount?.value).toFixed(2)
            : "";
          this.radioData.radio = this.taxValueD?.radioData?.radio;

          // 回显税之前的用的数据不对修改
          //处理数据格式
          let arr = this.taxValueD?.ratetype?.value?.split(";").slice(0, -1);
          const arr2 = [];
          arr &&
            arr.forEach((item) => {
              let arr3 = item.split(":");
              let item1 = {
                name: arr3[0],
                ratio: parseFloat(arr3[1]),
                checked: true,
              };
              arr2.push(item1);
            });
          this.taxValueD.taxCheckList = arr2;
        }
      } else if (this.inputType === "percent-number") {
        if (this.value.value && this.value.value.split("%")[0] !== null) {
          this.editValue = Number(
            // eslint-disable-next-line no-useless-escape
            this.value.value.split("%")[0].replace(/\,/g, "")
          );
        } else if (
          this.value.value === "" ||
          this.value.value === null ||
          this.value.value === undefined
        ) {
          this.editValue = undefined;
        }
      } else if (this.inputType === "checkbox") {
        if (this.value.value === "true") {
          this.editValue = true;
        } else {
          this.editValue = false;
        }
      } else {
        if (this.value !== undefined && this.value !== null) {
          this.editValue = this.value.value;
          // 这里处理时间值
          if (this.inputType == "date") {
            if (isNaN(new Date(this.value.value).getTime())) {
              this.editValue = "";
            } else {
              let d = new Date(this.value.value);
              let m =
                d.getMonth() + 1 < 10
                  ? "0" + (d.getMonth() + 1)
                  : d.getMonth() + 1;
              let da = d.getDate() < 10 ? "0" + d.getDate() : d.getDate();
              let datetime = d.getFullYear() + "-" + m + "-" + da;
              if (!isNaN(datetime)) {
                this.editValue = datetime;
              }
            }
          }
        }
      }
    },
    /**
     * 评分字段的星星鼠标悬浮和离开事件
     * @param {nub} 评分星星所在位置
     */
    setStar(nub) {
      this.stars = document.querySelectorAll(
        `.${this.fieldName}${this.lineIndex} span`
      );

      if (nub === "active") {
        nub = Number(this.editValue);
      }
      let name = "";
      name = "show2";
      for (var i = 0; i < this.stars.length; i++) {
        // 区分鼠标悬浮和鼠标离开
        this.stars[i].className = i <= nub ? name : "";
      }
    },
    /**
     * 设置评分字段的值
     * @param {nub} 评分星星所在位置
     */
    setClick(idx) {
      this.editValue = idx;
    },
    clk(e) {
      if (e) {
        this.$emit("selectes");
      }
    },
    /**
     * 输入框enter事件
     */
    searchEnterBtn() {
      this.$emit("searchEnterBtn", this.editValue);
    },
    /**
     * 监听查找字段新增事件
     */
    listenStorage() {
      window.addEventListener("storage", this.storageChangeCallback);
    },
    storageChangeCallback(event){
      // 监听addRelevantObjectType变化
      if (event.key === "relevantObjectDataId") {
        let option = {
          value: localStorage.getItem("relevantObjectDataId"),
          label: localStorage.getItem("relevantObjectDataName"),
        };
        this.$emit(
          "addFieldOption",
          localStorage.getItem("relevantObjectApi"),
          option
        );
      }
    },
    /**
     * 字段值变化触发的事件
     * @param {selectVal} 字段值
     */
    changeEvent(selectVal) {
      if (
        (this.fieldName === "complete_percentage" ||
          this.fieldName === "fulfill_percent") &&
        selectVal == "0%"
      ) {
        this.$emit("editValueChange", "未开始", this.lineIndex, "status");
        this.$emit("editValueChange", "未开始", this.lineIndex, "task_status");
      } else if (
        (this.fieldName === "complete_percentage" ||
          this.fieldName === "fulfill_percent") &&
        selectVal == "100%"
      ) {
        this.$emit("editValueChange", "完成", this.lineIndex, "status");
        this.$emit("editValueChange", "完成", this.lineIndex, "task_status");
      } else if (
        this.fieldName === "complete_percentage" ||
        this.fieldName === "fulfill_percent"
      ) {
        this.$emit("editValueChange", "进行中", this.lineIndex, "status");
        this.$emit("editValueChange", "进行中", this.lineIndex, "task_status");
      } else if (
        this.fieldName === "xsjg" ||
        this.fieldName === "shuliang" ||
        this.fieldName === "unitprice" ||
        this.fieldName === "quotedquantity" ||
        this.fieldName === "rate" ||
        this.fieldName === "qty" ||
        this.fieldName === "quantity"
      ) {
        // 数量的边界
        if (
          this.fieldName === "quotedquantity" ||
          this.fieldName === "rate" ||
          this.fieldName === "qty" ||
          this.fieldName === "quantity"
        ) {
          if (selectVal > 99999999999999999 || selectVal < 1) {
            this.$message.error(this.$i18n.t("c743"));
          }
          if (selectVal) {
            this.editValue = selectVal;
          }
        }
        this.$emit(
          "editValueChangeTax",
          selectVal,
          this.fieldName,
          this.lineIndex,
          this.subordinateIndex
        );
      }
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent =
        this.changeEvents !== undefined ? this.changeEvents.split(",") : [];
      changeEvent.forEach((eventItem) => {
        if (eventItem === "control") {
          eventItem &&
            this.$emit(
              eventItem,
              selectVal,
              this.lineIndex,
              this.fieldId,
              this.subordinateIndex
            );
        } else {
          eventItem && this.$emit(eventItem, selectVal, this.fieldName);
        }
      });
      // 失去焦点时搜索
      this.$emit("searchEnter", this.editValue);
      if (
        this.fieldName == "quotedquantity" ||
        this.fieldName == "qty" ||
        this.fieldName == "quantity" ||
        this.fieldName == "shuliang"
      ) {
        // 数量超过17位提示
        if (selectVal.toString().length > 17) {
          this.$message.error(this.$i18n.t("c743"));
        }
      }
    },
    // 远程搜索方法
    remoteMethod(query) {
      //添加产品需求四个对象
      if (
        this.objectApi == "quotedetail" ||
        this.objectApi == "cloudccorderitem" ||
        this.objectApi == "InvoicesItems" ||
        this.objectApi == "purchaseorderitem" ||
        this.objectApi == "opportunitypdt"
      ) {
        this.$emit("remoteMethodCell", query);
        this.$emit("remoteSearchCount", this.editValue);
      }
      this.$emit(this.remote, query);
    },
    //查找搜索弹框数据点击更改
    changeListData(item) {
      this.$emit("selectPric", {
        id: item.id,
        name: item.name,
        defaultquantity: item.defaultquantity,
      });
    },
    // 查找/查找多选
    remoteSearch(type) {
      // 需要判断是否禁用
      if(this.disabled)return;
      this.$emit("addmanualInit");
      // 报价单、订单、发票、采购订单从对象中的产品字段特殊处理
      if (
        [
          "purchaseorderitem",
          "cloudccorderitem",
          "InvoicesItems",
          "quotedetail",
          "opportunitypdt",
        ].indexOf(this.objectApi) !== -1 &&
        (this.fieldName === "chanpin" ||
          this.fieldName === "product2id" ||
          this.fieldName === "plan" ||
          this.fieldName === "product" ||
          this.fieldName === "product2")
      ) {
        this.$emit("remoteSearchPric", this.editValue);
        // if (type == "zhucong") {
        //   this.$emit("remoteSearchCount", this.editValue);
        // } else {
        //   this.$emit("remoteSearchPric", this.editValue);
        //   // this.$emit("remoteMethodCell", "");
        // }
      } else {
        // 如果type是zhucong，不需要重新调用remoteSearch
        if (type !== "zhucong") {
          this.$emit("remoteSearch", this.editValue);
        }
      }
      // 解决1623 在线工单-公有云-浙江迅实-lightning：报价单对象主从录入报价单明细时存在带值计算问题
      this.getProduct()
    },
    //获取焦点后获取产品数据
    getProduct() {
      // 报价单、订单、发票、采购订单从对象中的产品字段特殊处理
      if (
        [
          "purchaseorderitem",
          "cloudccorderitem",
          "InvoicesItems",
          "quotedetail",
          "opportunitypdt",
        ].indexOf(this.objectApi) !== -1
      ) {
        this.$emit("remoteSearchCount", this.editValue);
        this.$emit("remoteMethodCell", "");
      } else {
        this.$emit(this.remote, this.editValue);
      }
    },
    /**
     * 自定义时间组件 选择自定义时间时赋值
     * @param {val} 自定义时间的值
     */
    getChartsByCondition(val) {
      this.editValue = val;
    },
    /**
     * 查找多选字段失去焦点时赋值
     */
    selectBlur(e) {
      this.editValue = e.target.value;
    },
    /**
     * 查找多选字段失去焦点时赋值
     * @param {query} 查询条件
     */
    filterRemoteMethod(query) {
      this.$emit("remoteMethods", query, this.fieldId, this.objid);
    },
    /**
     * 筛选器中的下拉框选项点击事件
     * @param {val} 选项信息,包含lable和value
     */
    optionsClick(val) {
      //筛选器赋值不能赋id只能赋label值
      this.editValue = val.label;
    },
    /**
     * 金额对象 -- 子产品切换价格手册事件
     * @param {val} 选项信息,包含value
     */
    changeChildPricebook2id(val) {
      this.editValue = val;
      this.$emit("changeChildPricebook2id", this.editValue);
    },
  },
  computed: {
    newAddData() {
      let data = [];
      // 否则使用原来数组，不进行切片处理
      data = this.addData;
      return data;
    },
    taxValueD() {
      return this.taxValue;
    }
  },
  watch: {
    value: {
      handler(nval) {
        if (this.inputType === "number") {
          this.editValue =
            nval.value === undefined ? nval.value : Number(this.value.value);
        } else if (this.inputType === "percent-number") {
          if (nval.value && nval.value.toString().split("%")[0] !== null) {
            this.editValue = Number(
              // eslint-disable-next-line no-useless-escape
              this.value.value.toString().split("%")[0].replace(/\,/g, "")
            );
          } else {
            this.editValue = 0;
          }
        } else if (this.inputType === "checkbox") {
          if (nval.value === "true" || nval.value === true) {
            this.editValue = true;
          } else {
            this.editValue = false;
          }
        } else {
          this.editValue = nval?.value;
        }
      },
      deep: true,
    },
    editValue(nval) {
      this.$emit(
        "editValueChange",
        nval,
        this.lineIndex,
        this.fieldName,
        this.subordinateIndex
      );
    },
    inputType() {
      this.setInputValue();
    },
    /*
    编辑时折扣数据回显
    */
    radioDataP: {
      handler(newVal) {
        if (newVal && newVal.cutValue != undefined && newVal != {}) {
          newVal.cutValue = Number(newVal.cutValue);
          this.radioData = newVal;
        }
      },
      deep: true,
      immediate: true,
    },
    addmanualData(e) {
      this.addData = e;
    },
  },
};
</script>

<style lang="scss" scoped>
// 主从录入表格input和percent-number类型字段文字居右显示
::v-deep .text-right-input .el-input .el-input__inner {
  text-align: right;
}

::v-deep .el-input__prefix {
  color: #606266;
  left: 0px;
  transition: all 0.3s;
  // top: 5px;
  margin-left: 5px;
}
.xsjg {
  cursor: pointer;
}
.cellInput {
  position: relative;
}
.cellCard {
  position: fixed;
  z-index: 999;
  width: 260px;
  .discountCardInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 150px;
    p {
      display: inline-block;
      width: 60px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 14px;
      white-space: nowrap;
    }
    ::v-deep .el-input__inner {
      height: 25px;
      width: 100%;
    }
  }
  .btBox {
    margin-top: 20px;
  }
}
// 评分字段显示
.stars {
  width: 100%;
  height: 100%;
  overflow: auto;
  list-style: none;
  margin: 0;
  line-height: 25px;
  color: #ccc;
  // margin-left: 30px;
  float: left;
}

.stars span {
  font-size: 25px;
  margin-left: 5px;
}

.stars span:after {
  content: "☆";
}

.stars .show:after,
.stars .show2:after {
  content: "★";
}

.show2 {
  color: #f7ba2a;
}

// 查找下拉框不显示下拉图标
::v-deep .no-suffix .el-input__suffix {
  right: 16px;
  .el-icon-arrow-up {
    display: none;
  }
}

::v-deep .el-input--prefix .el-input__inner {
  padding-left: 35px !important;
}

::v-deep .no-suffix {
  .el-input__inner {
    cursor: text;
  }
}
::v-deep .el-input__suffix-inner:hover {
  background-color: #ffffff;
}
// 会影响图标展示错位，暂时错位
// ::v-deep .el-input__suffix-inner .el-input__icon {
//   line-height: 40px;
// }
.remoteBtn {
  height: 30px;
  line-height: 30px;
  cursor: pointer;
  position: absolute;
  right: 2%;
  margin-top: 1px;
  color: #c0c4cc;
  z-index: 100;
}

.remoteBtn1 {
  cursor: pointer;
  position: absolute;
  right: 7px;
  color: #c0c4cc;
  z-index: 100;
  // top: 5px;
  font-size: 14px;
}

.searchTipBox {
  height: 34px;
  line-height: 34px;
  cursor: pointer;
  padding: 0 20px;
  margin-bottom: 0;

  &:hover {
    background: #f5f7fa;
  }
}

.editableCell {
  text-align: left;
  position: relative;
}

.errTip {
  color: red;
  height: 20px;
  text-align: left;
}
::v-deep .editableCell >>> .el-input-number {
  width: 100%;
  line-height: 30px;
  .el-input__inner {
    text-align: left;
  }
}

.el-select {
  width: 100%;
}

::v-deep .el-date-editor.el-input {
  width: 100%;
}

.location-box {
  label {
    width: 50px;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
  }

  .el-input {
    width: calc(50% - 50px);
  }
}

.address-box {
  text-align: left;

  label {
    width: 80px;
    text-align: right;
    display: inline-block;
    padding-right: 10px;
  }

  .el-input {
    width: calc(50% - 80px);
  }

  .el-select {
    width: calc(50% - 80px);
  }
}
::v-deep .location {
  display: flex;
  .el-input {
    margin-right: 5px;
  }
}

::v-deep .filtetClass,
::v-deep .rowheight {
  .el-input {
    .el-input__inner {
      height: 30px !important;
      overflow-x: auto;
    }
  }
}
.taxCheckBox {
  max-height: 140px;
  overflow: auto;
  .unLink {
    height: 50px;
    display: flex;
    justify-content: left;
    align-items: center;
  }
  .taxList {
    height: 35px;
    .taxName {
      width: 90px;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 13px;
      font-size: 13px;
      white-space: nowrap;
      margin-left: 5px;
    }
  }
}
::v-deep .el-select__tags {
  flex-wrap: nowrap;
  overflow-x: scroll;
  max-width: 142.797px;
  &::-webkit-scrollbar {
    display: none;
  }
}
::v-deep .el-scrollbar__wrap {
  height: 200px;
}
::v-deep .el-select-dropdown__wrap {
  max-height: 200px;
}
</style>
