import axios from '@/config/httpConfig'
//获取对象列表
export function getobjectlist() {
    return axios.post('/object/getAllObjectList', {
        searchKeyWord: ''
    }, {

    })
}
// 获取CCChat后台设置信息
export function getSetupInfo() {
    return axios.post('/ccchat/person/getSetupInfo')
}
// 获取所仪表板列表
export function getDashboardList(data) {
    return axios.post('/dashboard/getDashboardList', data)
}
// 获取应用程序选项卡
export function getApplicationTab(data) {
    return axios.post('/application/getApplicationTab', data)
}
// 获取应用程序列表
export function getApplicationList(data) {
    return axios.post('/application/getApplicationList', data)
}
//获取伙伴云用户菜单
export function getPartnerTab() {
    return axios.post('partner/getTabList')
}
//获取伙伴云logo
export function getLogoid() {
    return axios.post('partnerSetup/getLogoid')
}
// 新版服务控制台保存tab接口
export function consoleSaveTab(data) {
    return axios.post('UserTab/save', data)
}
// 新版服务控制台获取tab接口
export function getConsoleTab(data) {
    return axios.post('UserTab/query', data)
}
// 根据应用程序id查询页脚工具列表
export function getFootToolByApp(data) {
    return axios.post('/footerTool/userQueFootToolByApp', data)
}
