// 对象列表页跳转至详情页需保存的数据

const objectList = {
  state: {
    objectApi: '',//对象id
    objId: '',//视图下拉框中的id
    prefix: '',//对象前缀
    isPdfViewStatus: '',//是否跳转到PDF视图页面
    viewId: '', // 视图id
    pageSize: 25, // 每页条数
    skippageNum: 1, // 当前页数
    recordStatus: '',//记录的状态
    objectNumber: '',//对象编号
    tableListConfig: {
      viewId: '', // 视图id
      pageSize: 25, // 每页条数
      skippageNum: 1, // 当前页数
      applicationId: '', // 应用id
      sortField: '', // 当前排序字段
      sortDir: '', // 当前排序顺序
      prefix: '', // 当前对象前缀
    }
  },
  mutations: {
    // 设置视图id
    setViewId(state, data) {
      state.viewId = data;
    },
    // 记录的状态
    setRecordStatus(state, data) {
      state.recordStatus = data;
    },
    // 对象编号
    setObjectNumber(state, data) {
      state.objectNumber = data;
    },
    // 设置对象id
    setObjectApi(state, data) {
      state.objectApi = data;
    },
    // 设置详情页是否为PDF视图详情页
    setIsPdfViewStatus(state, data) {
      state.isPdfViewStatus = data;
    },
    // 设置视图下拉框中的id
    setObjId(state, data) {
      state.objId = data;
    },
    // 设置对象前缀
    setPrefix(state, data) {
      state.prefix = data;
    },
    // 设置每页条数
    setPageSize(state, data) {
      state.pageSize = data;
    },
    // 设置当前页数
    setSkippageNum(state, data) {
      state.skippageNum = data;
    },
    // 赋值表格缓存数据
    setTableListConfig(state, data) {
      state.tableListConfig = data;
    },

  },
  getters: {
    // 获取视图id
    getViewId(state) {
      return state.viewId;
    },
    getIsPdfViewStatus(state) {
      return state.isPdfViewStatus;
    },
    // 记录状态
    getRecordStatus(state) {
      return state.recordStatus;
    },
    // 对象编号
    getObjectNumber(state) {
      return state.objectNumber;
    },
    // 获取对象id
    getObjectApi(state) {
      return state.objectApi;
    },
    // 视图下拉框中视图的id
    getObjId(state) {
      return state.objId;
    },// 视图下拉框中视图的id
    getPrefix(state) {
      return state.prefix;
    },
    // 获取视图id
    getPageSize(state) {
      return state.pageSize;
    },
    // 获取视图id
    getSkippageNum(state) {
      return state.skippageNum;
    },

  },
  actions: {}
}
export default objectList;