/**
 * 邮件对象路由文件
 */
const emailobject = [
    {
        path: "emailobject",
        redirect: () => "/relation",
    },
    {
        path: "relation",
        component: () => import("@/views/Email/relation.vue"),
        name: "关联页",
    },
    {
        path: "emailhome",
        component: () => import("@/views/Email/Emailhome.vue"),
        name: "邮件首页",
        children: [
            {
                path: "/writeEmail",
                component: () => import("@/views/Email/writeEmail.vue"),
                name: "写邮件",
            },
            {
                path: "/MailMain",
                component: () => import("@/views/Email/emailMain.vue"),
                name: "邮件中心",
            },
            {
                path: "/MailDetail",
                component: () => import("@/views/Email/emailDetail.vue"),
                name: "邮件详情",
            },
        ],
    },
    {
        path: 'list',
        name: 'EmailList', // 邮箱列表
        component: () => import("@/views/Email/emailList.vue"),
    }, {
        path: 'add',
        name: 'AddEmail', // 增加邮箱
        component: () => import("@/views/Email/addEmail.vue"),
    }, {
        path: 'edit',
        name: 'EditEmail', // 编辑邮箱
        component: () => import("@/views/Email/editEmail.vue"),
    }, {
        path: 'personalEmailBox',
        name: 'personalEmailBo', // 添加个人邮箱
        component: () => import("@/views/Email/personalEmailBox.vue"),
    }
];

export default emailobject;
