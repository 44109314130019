import axios from "@/config/httpConfig";
/**
 * 请求获取用户信息
 * @param {data} 请求参数
 * @param {enforce} 是否强制请求最新用户信息
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get("/user/getUserInfo", data);
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    });
  }
}
/**
 * 获取用户配置信息
 * @returns 用户配置信息
 */
export function getUserSetup() {
  return axios.get("/user/getUserSetup");
}

//获取域名
export function geturl() {
  return axios.post("/report/getExportReportURL", {});
}
// 加载自定义标签多语言
export function queryAllTag(data) {
  return axios.post(window.Glod["ccex-setup"] + "/api/customtag/queryAllTag", data);
}
