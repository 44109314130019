const scheduleappointment = [
    {
        name: "Schedule an appointment",
        path: "scheduleappointment/scheduleappointment",
        component: () => import("@/views/scheduleappointment/index.vue"),
      },
      {
        name: "Bookingdetails",
        path: "scheduleappointment/bookingdetails",
        component: () => import("@/views/scheduleappointment/bookingdetails.vue"),
      },
  ];
  
  export default scheduleappointment;
  