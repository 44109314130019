/**
 * 预测页面路由文件
 */
const forecast = [
  {
    path: "forecast",
    name: "预测主页",
    redirect: () => "/forecast/forecast",
  },
  {
    path: "forecast/forecast",
    component: () => import("@/views/forecast/index.vue"),
    name: "预测",
    meta: {
      title: "预测",
      keepAlive: true
    },
  },

];

export default forecast
