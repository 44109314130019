// 白名单hash 列表
export default [
    "promptlyPayment",//邮件支付页面
    "mailEvaluation",//邮件评价
    "login",// 项目中的登录页
    "loginAbb",// 项目中的登录页
    'changePassword',// 修改密码页
    /*以下路由是第三方登录授权页面*/
    "thirdPartyLoginAuth",
    "thirdLogged",
    "thirdLogin",
    "thirdAuthorization",
    "errorPageAuth",
    /******************/
    /* 以下路由是验证提供商授权页面*/
    "singleLogoutAuthorization",
    "singleLogoutLogged",
    "singleLogoutLogin",
    "singleLogout",
    "singleLogoutErrorPage",
    /******************/
    "jumpPage"//跳转页
]
