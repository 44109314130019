import Vue from "vue"
window.ccBus = new Vue;

import Page from "./page"
import Menu from './menu'

function install(vue) {
    Page.install(vue);
    Menu.install(vue);
}

export {
    install
}