<template>
  <cc-renderer
    :key="count"
    v-loading="loading"
    :componentData="componentData"
    :canvasStyleData="canvasStyleData"
    :pageApi="pageApi"
    :echoData="echoData"
  />
</template>

<script>
/**
 * 预览组件
 */
export default {
  name: "rewrite-cc-render",
  props: {
    componentAttrObj: {
      type: Object,
      default: ()=>({}),
    },
  },

  data() {
    return {
      componentData: [],
      canvasStyleData: {},
      echoData: {}, // 渲染器回显的数据
      pageApi: "",
      loading: false,
      count: 0,
    };
  },
  created() {
    if (this.componentAttrObj && this.componentAttrObj.pageApi) {
      this.getDialog(this.componentAttrObj.pageApi);
    }
  },
  methods: {
    getDialog(customPageApi) {
      if (customPageApi) {
        this.loading = true;
        let parameter = {
          pageApi: customPageApi,
        };
        this.$devHttp
          .postFormat(
            `${window.Glod['ccex-dev']}/custom/pc/1.0/post/detailCustomPage`,
            parameter
          )
          .then((res) => {
            if (res.returnCode == 200) {
              this.componentData = JSON.parse(res.data.pageContent);
              this.canvasStyleData = JSON.parse(res.data.canvasStyleData);
              this.pageApi = res.data.pageApi;
              this.echoData = this.componentAttrObj.data;
              this.count++;
            }
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
