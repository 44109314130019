/*
 * @Author: your name
 * @Date: 2022-01-19 14:47:52
 * @LastEditTime: 2022-03-11 10:17:28
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\views\marketForm\marketForm.js
 */
/**
 * 市场云表单
 */
const marketForm = [
  {
    path: "marketForm/marketForm",
    component: () => import("@/views/marketForm/index.vue"),
    name: "Form",
    meta: { title: "Form | Cloudcc" },
  },
  {
    path: "marketForm/createFrom",
    component: () => import("@/views/marketForm/createFrom/index.vue"),
    name: "createFrom",
    meta: { title: "createFrom | Cloudcc" }
  },
  {
    path: "marketForm/preview",
    component: () => import("@/views/marketForm/createFrom/Previews/index.vue"),
    name: "preview",
    meta: { title: "preview | Cloudcc" },
  },
  {
    path: "marketForm/detailForm",
    component: () => import("@/views/marketForm/detailForm/index.vue"),
    name: "Marketdetail",
    meta: { title: "detail | Cloudcc" }
  },
  {
    path: 'marketForm/selectTemplate',
    component: () => import("@/views/marketForm/createFrom/fromType"),
    name: "selectTemplate",
    meta: { title: "selectTemplate | Cloudcc" }
  },
  {
    path: 'marketForm/TablePanel',
    component: () => import("@/views/marketForm/components/TablePanel/index.vue"),
    name: "TablePanel",
    meta: { title: "selectTemplate | Cloudcc" }
  },
  {
    path: "marketForm/mailchimpList/:type",
    component: () => import("@/views/marketForm/mailchimp/index.vue"),
    name: "mailchimpList",
    meta: { title: "mailchimpList || Cloudcc" }
  },
];

export default marketForm;
