import Vue from "vue";
import editObjEdit from "@/components/CCPage/edit-obj.vue"
/**
 * 以dialog新建记录，仅支持_blank方式打开弹窗
 * @param {*} vue vue实例
 * @param {*} obj 新建对象信息
 * @param {*} options 新建对象方式信息
 */
 function dialog_blank(vue, pageId, obj, id, options) {
    // 打开新建弹窗
    Vue.component(`edit-obj-edit`, editObjEdit)
    vue.$rendererDialog({
        comName: 'edit-obj-edit',
        // dialog属性
        dialogAttrObj: {
          title: obj.objectName,
          width: "80%",
          height: options.height || "",
          top: '10vh',

        },
        pageId,
        // 组件所需的数据 比如 pageApi
        componentAttr: {pageId, id, ...obj, ...options}
      });
        // 添加页面关闭方法
    let page = {}
    page.close = ()=>{
      vue.$bus.$emit('closeRendererDialog',pageId)
    }
    window.$CCDK.CCPage.addPage(pageId,page)
}
/**
 * 以dialog新建记录，仅支持_blank方式打开弹窗
 * @param {*} vue 新建对象信息
 * @param {*} obj 新建对象信息
 * @param {*} options 新建对象方式信息
 */
function dialog_self(vue, pageId, obj, id, options) {
 // 打开新建弹窗
 Vue.component(`edit-obj-edit`, editObjEdit)
 vue.$rendererDialog({
     comName: 'edit-obj-edit',
     // dialog属性
     dialogAttrObj: {
       title: obj.objectName,
       width: "80%",
       height: options.height || "",
       top: '10vh',

     },
     pageId,
     // 组件所需的数据 比如 pageApi
     componentAttr: {pageId, id, ...obj, ...options}
   });
     // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    vue.$bus.$emit('closeRendererDialog',pageId)
  }
  window.$CCDK.CCPage.addPage(pageId,page)
}
function menu1_self(vue, pageId, obj={}, id, options) {
  // 菜单数据
  let menuObj = {
    name: obj.objectName,
    id: pageId,
    routerName: 'consoleMenu1Edit',
    params: {
        page: pageId,
        id,
        ...obj,
        ...options
    }
}
// window.$CCDK.CCMenu.addMenu1(menuObj)
window.$CCDK.CCMenu.replaceMenu1(menuObj)
// 添加页面关闭方法
let page = {}
page.close = ()=>{
  window.$CCDK.CCMenu.deleteMenu1({id:pageId})
}
window.$CCDK.CCPage.addPage(pageId,page)
vue.$router.push({
  name: 'consoleMenu1Edit',
  params: {
    page: pageId,
    id,
    ...obj,
    ...options,
  }
});
}
function menu1_blank(vue, pageId, obj={}, id, options) {
// 菜单数据
let menuObj = {
  name: obj.objectName,
  id: pageId,
  routerName: 'consoleMenu1Edit',
  params: {
      page: pageId,
      id,
      ...obj,
      ...options,
  }
}
window.$CCDK.CCMenu.addMenu1(menuObj)
// 添加页面关闭方法
let page = {}
page.close = ()=>{
  window.$CCDK.CCMenu.deleteMenu1({id:pageId})
}
window.$CCDK.CCPage.addPage(pageId,page)
vue.$router.push({
  name: 'consoleMenu1Edit',
  params: {
    page: pageId,
    id,
    ...obj,
    ...options,
  }
});
}
/**
* 以二级菜单新建记录，只有tab模式才支持_blank
*/
function menu2_self(vue, pageId, obj={}, id, options) {
    // 菜单数据
    let menuObj = {
        name: obj.objectName,
        id: pageId,
        routerName: 'consoleMenu2Edit',
        params: {
            page: pageId,
            id,
            ...obj,
            ...options,
        }
    }
    // window.$CCDK.CCMenu.addMenu2(menuObj)
    window.$CCDK.CCMenu.replaceMenu2(menuObj)
    // 添加页面关闭方法
    let page = {}
    page.close = ()=>{
      window.$CCDK.CCMenu.deleteMenu2({id:pageId})
    }
    window.$CCDK.CCPage.addPage(pageId,page)
    vue.$router.push({
      name: 'consoleMenu2Edit',
      params: {
        page: pageId,
        id,
        ...obj,
        ...options,
      }
    });
}
function menu2_blank(vue, pageId, obj={}, id, options) {
// 菜单数据
let menuObj = {
    name: obj.objectName,
    id: pageId,
    routerName: 'consoleMenu2Edit',
    params: {
        page: pageId,
        id,
        ...obj,
        ...options,
    }
}
window.$CCDK.CCMenu.addMenu2(menuObj)
// 添加页面关闭方法
let page = {}
page.close = ()=>{
  window.$CCDK.CCMenu.deleteMenu2({id:pageId})
}
window.$CCDK.CCPage.addPage(pageId,page)
vue.$router.push({
  name: 'consoleMenu2Edit',
  params: {
    page: pageId,
    id,
    ...obj,
    ...options,
  }
});
}
/**
* 以tab新建记录
*/
function tab_self(vue, pageId, obj={}, id, options) {
  tab_blank(vue, pageId, obj, id, options)
}
function tab_blank(vue, pageId, obj={}, id, options) {
    // 将记录ID recordId 以及defalutData存储到localStorage，设置时间，24小时有效期
    if(obj.recordId || obj.defalutData){
      let storeObj = {
        data: obj,
        expire: Date.now() + 1000 * 60 * 60 * 24
      }
      localStorage.setItem(pageId, JSON.stringify(storeObj));
    }
  let routeData = vue.$router.resolve({
    name: 'consoleTabEdit',
    params: {
      page: pageId,
      ...obj,
      id,
      ...options,
    }
  });
  let oNewWindow = window.open(routeData.href, options.openMode);
  // 添加页面关闭方法
  let page = {}
  page.close=()=>{
      oNewWindow.close()
  }
  window.$CCDK.CCPage.addPage(pageId,page)
}
export {tab_self, tab_blank, menu1_self,menu1_blank, menu2_self, menu2_blank, dialog_blank, dialog_self }
