<template>
  <el-upload
    class="upload-myself"
    :class="{
      'upload-myself-info':
        formUploadFileList.length && formUploadFileList[0].status === 'success',
    }"
    ref="upload"
    :action="action"
    :before-upload="beforeUpload"
    :http-request="uploadFile"
    :multiple="multiple"
    :limit="limit"
    :auto-upload="true"
    :disabled="disabled"
    :file-list="formUploadFileList"
    :before-remove="beforeRemove"
    :on-preview="preview"
    :on-success="success"
    :on-exceed="handleExceed"
    :on-change="imagechang"
    :on-error="error"
  >
    <el-button slot="trigger" :disabled="disabled" size="small" type="primary">
      <!-- 点击上传 -->
      {{ $t("label.file.uploadfiles") }}
    </el-button>
    <div class="el-upload__tip" slot="tip">
      <p>
        <!-- 只能上传{{fileType && fileType.join('/')}}文件，且不超过{{(Number(size) / 1024).toFixed(2)}}Mb -->
        {{
          $t("vue_label_file_upload_type_size", {
            fileType: fileType && fileType.join("/"),
            size: (Number(size) / 1024).toFixed(2),
          })
        }}
      </p>
      <p :v-show="showMsg" style="color: red">{{ msg }}</p>
    </div>
  </el-upload>
</template>

<script type="text/ecmascript-6">
export default {
  props: {
    prop: {
      type: String,
      default: "file",
    },
    fileName: {
      type: String,
      default: "",
    },
    fileType: {
      type: Array,
    },
    size: {
      // 上传的大小
      type: Number,
      default: 10240,
    },
    showMsg: {
      // 是否显示提示语
      type: Boolean,
      default: false,
    },
    msg: {
      type: String,
      default: "",
    },
    callback: {
      type: [Function, undefined],
      default: undefined,
    },
    fileApi: {
      type: Object,
    },
    action: {
      // 请求地址
      type: String,
      default: "test",
    },
    multiple: {
      type: Boolean, // 是否可多选
      default: false,
    },
    data: {
      type: Object, // 额外参数
      default: () => {
        return {};
      },
    },
    fileList: {
      type: Array, // 上传的文件列表
      default: () => [],
    },
    disabled: {
      type: Boolean, // 是否禁用
      default: false,
    },
    limit: {
      type: Number,
      default: 1,
    },
    fileAttr: {
      type: String,
    },
    beforeUploadFile: {
      type: Function,
    },
    recordId: {
      type: String,
    },
  },
  data() {
    return {
      formUploadFileList: [], // 上传列表合集
    };
  },
  created() {
    this.formUploadFileList = this.fileList;
  },
  methods: {
    imagechang(e) {},
    // 移除文件
    beforeRemove(file, fileList) {
      // 禁用状态下不允许删除文件
      if (this.disabled) {
        return false;
      }
      // 重置dialog删除方法 防止删除失败后依然删除
      // 如果需要到服务端删除 则执行成功后再从前端列表中移除附件
      if (file.status === "success") {
        this.$confirm(
          "此操作将永久删除该文件？, 是否继续?",
          this.$i18n.t("label.prompt"),
          {
            confirmButtonText: this.$i18n.t("label.confirm"),
            cancelButtonText: this.$i18n.t("label.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            this.formUploadFileList = fileList;
            this.$emit("remove", this.fileAttr, ...arguments);
          })
          .catch(() => {});
        return false;
      } else {
        this.formUploadFileList = fileList;
      }
    },
    // 查看文件
    preview(file) {
      if (file.status === "success") {
        // fileApi.getFileByAttachmentKey(file.attachmentKey, file.name)
        this.$emit("preview", this.prop, ...arguments);
      }
    },
    success() {
      this.$emit("success", arguments);
    },
    error() {
      this.$emit("error", arguments);
    },
    handleExceed(files, fileList) {
      this.$message.warning(this.$i18n.t("vue_label_file_singel_upload"));
    },
    beforeUpload(file) {
      let size = file.size / 1024;
      let type = file.name.slice(file.name.lastIndexOf(".") + 1);
      if (size > this.size) {
        this.$message.error(
          file.name + this.$i18n.t("vue_label_file_over_file_size")
        );
        return false;
      }
      if (this.fileType.indexOf(type) === -1) {
        this.$message.error(
          this.$i18n.t("vue_label_file_wrong_type", { type: type })
        );
        return false;
      }

      if (this.beforeUploadFile && this.beforeUploadFile instanceof Function) {
        return this.beforeUploadFile(file);
      }
    },
    uploadFile({ action, data, file, fileName }) {
      this.formUploadFileList.push({
        name: file.name,
        status: "ready",
        uid: file.uid,
        url: "",
        file: file,
      });
      // this.$emit('uploadFile', this.formUploadFileList)
      // let form = new FormData() // 创建form对象
      // form.append(this.prop, file) // 通过append向form对象添加数据
      // this.callback && this.callback(form, file)
    },
    // 获取上传文件列表
    getUploadFileList() {
      let formData = new FormData();
      let fileList = [];
      let prop = this.prop;
      if (!this.formUploadFileList) {
        this.formUploadFileList = [];
      }
      this.formUploadFileList.forEach((item, index) => {
        // 如果文件没有上传 返回列表
        if (item.status === "ready") {
          // 用数组表示是因为需要批量上传的缘故
          formData.append(this.prop + "[" + index + "]", item.file);
          fileList.push(item);
        }
      });
      return { prop, formData, fileList };
    },
  },
  watch: {
    fileList() {
      this.formUploadFileList = this.fileList;
    },
    // 文件发生改变时候派发的事件
    formUploadFileList() {
      let _fileList = this.getUploadFileList();
      if (_fileList.fileList.length > 0) {
        this.$emit("fileChange", _fileList);
      }
    },
    recordId(data) {},
    fileType() {},
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.upload-myself-info {
  position: relative;
  padding-bottom: 48px;
  .upload-text-info {
    position: absolute;
    bottom: 0;
    font-size: 12px;
    height: 48px;
    line-height: 24px;
    color: #e43420;
  }
}
</style>
