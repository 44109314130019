/*
 * @Author: your name
 * @Date: 2021-03-31 10:45:48
 * @LastEditTime: 2021-04-23 15:51:14
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\utils\resetMessage.js
 */

import { Message } from 'element-ui'

let messageInstance = null
const resetMessage = (options={}) => {
  // 传入的如果不是个对象需要转化对象,
  if(options && options.constructor!==Object){
    options = {
      message: options,
      type: 'info'
    }
  }
  // 默认开启关闭按钮(如果手动传关闭使用字符串“true”)
  options.showClose=options.showClose!=='true'
  options.offset=options.offset?options.offset:40
  if(messageInstance) {
      messageInstance.close()
  }
  messageInstance = Message(options)
}
['error','success','info','warning'].forEach(type => {
  resetMessage[type] = options => {
      if(typeof options === 'string') {
          options = {
              message:options
          }
      }
      options.type = type
      return resetMessage(options)
  }
})
export const message = resetMessage