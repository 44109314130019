/*
 * @Author: your name
 * @Date: 2021-11-10 13:45:40
 * @LastEditTime: 2022-03-25 17:08:16
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\store\modules\creatform.js
 */
const creatform = {
  state: {
    itemDataId: '', // 点击列表中的编辑按钮，每条数据的id
    actionType: 'new', // 新建、编辑
    leadDefaultValues: [],//潜在客户的字段的默认字段
    selectTempFlg: '',//模板类型
    channel: '',//form 名字
    emails: '',//form 名字
    Daterange: '',//form 列表的时间范围
    totaldaterange: '',//form 总的分析时间范围
    perdaterange: '',//form 业绩页面时间范围
    subdaterange: '',//form 提交页面时间范围
    analydaterange: "",//form 分析页面时间范围
    objectid:"",//表单对象id
    ownerobjectid:"",//表单子对象id
  },
  mutations: {
    formid(state, length) {
      state.roiLength = length
    },
    changeActionType(state, actionType) {
      state.actionType = actionType
    },
    leadvalue(state, leadDefaultValues) {
      state.leadDefaultValues = leadDefaultValues
    },
    selectemp(state, selectTempFlg) {
      state.selectTempFlg = selectTempFlg
    },
    formname(state, channel) {
      state.channel = channel
    },
    emailst(state, emails) {
      state.emails = emails
    },
    Daterange(state, Daterange) {
      state.Daterange = Daterange
    },
    totaldaterange(state, totaldaterange) {
      state.totaldaterange = totaldaterange
    },
    perdaterange(state, perdaterange) {
      state.perdaterange = perdaterange
    },
    subdaterange(state, subdaterange) {
      state.subdaterange = subdaterange
    },
    analydaterange(state, analydaterange) {
      state.analydaterange = analydaterange
    },
  },
  actions: {

  }
}
export default creatform;