<template>
  <div class="table">
    <!-- 表格 -->

    <el-table
      ref="tablePanel"
      :data="tableDataProp"
      style="width: 100%, max-height: 420px"
      :default-sort="{ prop: 'date', order: 'descending' }"
      border
      :cell-style="{ padding: '0' }"
      :row-style="{ height: '64px' }"
      v-loading="loading"
      :header-cell-style="{ background: '#FAFAF9', height: '40px' }"
      @cell-mouse-enter="cellMouseEnter"
      @cell-mouse-leave="cellMouseLeave"
      @selection-change="handleSelectionChange"
      :class="deleteform ? 'batchtable' : ''"
    >
      <el-table-column
        type="selection"
        width="55"
        :selectable="checkDis"
        v-if="selectionFlg"
      >
      </el-table-column>
      <template v-for="(tit, titindex) in tableAttr">
        <slot :item="titindex">
          <el-table-column v-if="tit.ee == 'B'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.cc"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <span>{{ scope.row[tit.bb] }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'C'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.cc"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <el-checkbox
                  v-model="scope.row[tit.bb]"
                  :checked="
                    scope.row[tit.bb] === '0' || scope.row[tit.bb] === 'true'
                  "
                  @change="swicthCheck(scope.row)"
                  :disabled="
                    scope.row.ownerid !== $store.state.userInfoObj.userId
                  "
                ></el-checkbox>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'D'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.cc"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div class="account">
                <div class="accountImg">
                  <img
                    :src="`data:image/png;base64,${scope.row.socialmediapicture}`"
                    v-if="scope.row.socialmediapicture"
                    class="mainImg"
                  />
                  <svg aria-hidden="true" v-else class="mainImg">
                    <use href="#icon-contacts_def"></use>
                  </svg>
                  <!-- 平台图片 -->
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'facebook'"
                  >
                    <use href="#icon-facebook_white"></use>
                  </svg>
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'twitter'"
                  >
                    <use href="#icon-twitter_white"></use>
                  </svg>
                  <svg
                    class="inimg"
                    aria-hidden="true"
                    v-show="scope.row.socialtype === 'linkedIn'"
                  >
                    <use href="#icon-linkedIn_white"></use>
                  </svg>
                </div>
                <div class="account_box">
                  <div class="accountName">
                    {{ scope.row[tit.bb] }}
                  </div>
                  <div
                    class="optionTips"
                    v-show="scope.row.isauthorization === 'false'"
                  >
                    <div class="round_img" style="background: #df2020"></div>
                    <el-tooltip
                      content="您的账户需要重新连接。"
                      placement="top"
                    >
                      <span class="ml-10">一个问题待解决</span>
                    </el-tooltip>
                  </div>
                  <a
                    @click="relink(scope.row)"
                    style="color: #0771ce; cursor: pointer"
                    v-show="scope.row.isauthorization === 'false'"
                    >重新链接</a
                  >
                </div>
                <p class="deleteTips" @click="disconnect(scope.row)">
                  <!-- Disconnect -->
                  {{$t('c968')}}
                </p>
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'E'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.cc"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <span
                  @click="clickTax(scope.row)"
                  style="color: #107dcb; cursor: pointer"
                  >{{ scope.row[tit.bb] }}</span
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column v-if="tit.ee == 'F'" :width="tit.width">
            <template slot="header">
              <div class="tableHeadField" v-show="!deleteform">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  popper-class="my-tooltip table-head-tooltip"
                  :content="tit.aa"
                >
                  <span slot="reference">
                    {{ tit.aa }}
                  </span>
                </el-popover>
                <svg
                  class="icon"
                  aria-hidden="true"
                  :style="{
                    height: '12px',
                    'vertical-align': '',
                    transform: tit.sort ? 'rotate(180deg)' : '',
                  }"
                  v-show="tit.cc"
                >
                  <use href="#icon-icon_sort_top"></use>
                </svg>
                <el-popover
                  placement="bottom"
                  width="100"
                  trigger="hover"
                  :visible-arrow="false"
                  class="popover-span"
                  :ref="`${tit.aa}-popover`"
                  v-if="showLock"
                >
                  <el-checkbox>{{
                    $t("vue_label_commonobjects_detail_locking")
                  }}</el-checkbox>
                  <span slot="reference" @click="titimg(tit)">
                    <svg
                      class="icon sendImg"
                      aria-hidden="true"
                      style="width: 11px"
                    >
                      <use href="#icon-xiajiantou"></use>
                    </svg>
                  </span>
                </el-popover>
              </div>
              <div class="batchremove" v-show="deleteform"></div>
            </template>
            <template slot-scope="scope">
              <div>
                <input
                  type="checkbox"
                  :checked="
                    scope.row[tit.bb] === 'true' || scope.row[tit.bb] === true
                  "
                  :disabled="true"
                />
              </div>
            </template>
          </el-table-column>
        </slot>
      </template>
      <template slot="empty">
        <div class="isnot-box">
          <svg class="icon" aria-hidden="true">
            <use href="#icon-empty-search"></use>
          </svg>
          <div class="not-title">No list cleanup needed.</div>
          <div class="not-text">You don`t have any unused lists right now.</div>
        </div>
      </template>
    </el-table>
  </div>
</template>
<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    // 是否拥有选择框
    selectionFlg: {
      type: Boolean,
      default() {
        return true;
      },
    },
    checkDisable: {
      // 表头
      type: Boolean,
      default() {
        return false;
      },
    },
    tableAttr: {
      // 表头
      type: Array,
      default() {
        return [];
      },
    },
    tableData: {
      // 内容
      type: Array,
      default() {
        return [];
      },
    },
    // 表格行内button
    
    buttonlist: {
      type: Array,
      default:()=> []
    },
  },
  components: {},
  data() {
    return {
      tableDataProp: this.tableData,
      //表格
      showLock: true,
      deleteform: false, //控制表头成一个格
      selectnumber: null, //表格选中个数
      multipleSelection: [], //多选选中的
    };
  },
  methods: {
    clickTax(row) {
      this.$emit("clickTax", row);
    },
    disconnect(row) {
      this.$emit("disconnect", row);
    },
    swicthCheck(row) {
      this.$emit("swicthCheck", row);
    },
    
    changeTableAttr() {
      this.$nextTick(() => {
        this.$refs.tablePanel && this.$refs.tablePanel.doLayout();
        this.$forceUpdate();
      });
    },
    relink(row) {
      this.$emit("relink", row);
    },
    
    toparticulars(item) {
      this.$router.push({
        name: "particulars",
        params: {
          id: item,
          title: "Active list",
          acsta: false,
        },
      });
    },
    checkDis() {
      if (this.checkDisable) {
        return false;
      } else {
        return true;
      }
    },
    //表格划入
    cellMouseEnter(row) {
      row.vshow = true;
    },
    //表格滑出
    cellMouseLeave(row) {
      row.vshow = false;
      for (let i = 0; i < this.tableDataProp.length; i++) {
        this.tableDataProp[i].optionshow = false;
      }
    },
    //多选
    handleSelectionChange(val) {
      this.multipleSelection = val;
      this.selectnumber = val.length;
      if (val.length > 1) {
        this.deleteform = true;
      } else {
        this.deleteform = false;
      }
    },
    //表头排序图片翻转
    titimg(tit) {
      tit.cc = true;
      tit.dd = !tit.dd;
    },
    
    action(key, butnum, but) {
      if (butnum === this.buttonlist.length - 1) {
        key.optionshow = true;
      }
      if (but == "Details") {
        this.$emit("showDrawer");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.account {
  display: flex;
  justify-content: space-around;
  align-items: center;
  img {
    margin-top: 10px;
    width: 80px;
    height: 50px;
  }
  .account_box {
    margin-left: -40px;
    .accountName {
      width: 200px;
    }
    .optionTips {
      display: flex;
      align-items: center;
      height: 30px;
      .round_img {
        border: 1px solid #dddbda;
        height: 10px;
        width: 10px;
        border-radius: 50%;
      }
      span {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #6b6969;
        cursor: pointer;
      }
      a {
        font-family: PingFangSC-Regular;
        font-size: 10px;
        color: #0771ce;
      }
    }
  }
  .accountImg {
    display: inline-block;
    position: relative;
    .mainImg {
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
    .inimg {
      width: 30px;
      height: 30px;
      position: absolute;
      bottom: 5px;
      right: 0px;
    }
  }
  .deleteTips {
    color: rgb(163, 18, 18);
    cursor: pointer;
  }
}

.isnot-box {
  .not-title {
    font-family: MicrosoftYaHei;
    font-size: 14px;
    color: #080707;
  }
  .not-text {
    font-family: MicrosoftYaHei;
    font-size: 12px;
    color: #888888;
  }
}
button {
  outline: none;
}
::v-deep .el-table th.is-leaf {
  color: #333;
  border-bottom: 1px solid #dedcda;
}

::v-deep .el-table--border th {
  border-right: 1px solid #dedcda;
}
::v-deep .batchtable {
  th {
    border-right: none;
  }
}
::v-deep .el-table__header tr th {
  padding: 8px;
  padding-left: 0;
  padding-right: 0;
}
::v-deep .el-table td {
  position: relative;
  padding: 9px 0;
  color: #080707;
  border-bottom: 1px solid #dedcda;
  word-break: break-all;
}

::v-deep .el-table--border td {
  border-right: none;
}

.line-two-btn {
  .btn {
    padding: 0;
    height: 29px;
    padding: 0 4px;
    border: 1px solid #dedcda;
  }

  img {
    width: 18px;
    height: 18px;
  }

  .group-btn {
    // margin-top: -20px;
    padding: 0 4px;
    width: 30px;
    background-size: auto auto;
    background-repeat: no-repeat;
    background-position: 4px;
  }
}
.el-button--primary.is-plain {
  color: #409eff;
  background: #fff;
  border-color: #ccc;
}
.el-button--primary.is-plain:hover {
  color: #ffffff;
  background: #006dcc;
}
.ivu-table-wrapper {
  overflow: visible;
}
.marketform {
  width: 100%;
  height: 100%;
  overflow: hidden;

  .Form {
    width: 99%;
    height: 98%;
    margin-left: 10px;
    background: #fff;
  }
}
::v-deep .el-table {
  overflow: auto;
  overflow-x: hidden;
  width: 100%;
}
::v-deep .el-table__body-wrapper {
  overflow: auto;
  width: 100%;
  overflow-x: hidden;
}
.table {
  width: 100%;
}
.tablename {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  .tablenamefirst {
    color: #006dcc;
    font-size: 14px;
    cursor: pointer;
    .secol {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #006dcc;
      display: inline-block;
    }
    .tablenamebloder {
      font-weight: bolder;
      font-family: MicrosoftYaHei-Bold;
    }
    .tablesecol {
      font-family: MicrosoftYaHei;
      font-size: 12px;
      color: #888888;
    }
  }
  .tablenamesecond {
    button {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      margin-right: 10px;
      height: 30px;
      cursor: pointer;
    }
    .actionselect {
      background: #ffffff;
      border: 1px solid #dedcda;
      border-radius: 3px;
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
      height: 30px;
      .borderli {
        width: 100%;
        height: 1px;
        background: #dedcda;
        margin-top: 3px;
        margin-bottom: 3px;
      }
      .actionoption {
        position: absolute;
        right: -90px;
        top: 50px;
        background: #fff;
        z-index: 999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #006dcc;
        }
      }
      .actiontop {
        position: absolute;
        right: -50px;
        bottom: 50px;
        background: #fff;
        z-index: 9999;
        width: 190px;
        background: #ffffff;
        border: 1px solid #dddbda;
        box-shadow: 1px 2px 8px 0 rgba(0, 0, 0, 0.46);
        li {
          line-height: 32px;
          text-align: left;
          padding-left: 8px;
        }
        li:hover {
          background: #ececec;
          color: #006dcc;
        }
      }
    }
  }
}
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 100%;
  background: #ffff;
}
.texttf {
  width: 100%;
  height: 30px;
  font-weight: bolder;
  font-size: 14px;
  span {
    display: inline-block;
    width: 16px;
    height: 16px;
    text-align: center;
    line-height: 16px;
    background: #ccc;
    margin-left: 10px;
  }
  i {
    padding-right: 10px;
    color: #006dcc;
  }
}
::v-deep .dialog {
  .dialogdiv {
    width: 100%;
    .el-select {
      width: 100%;
    }
    input {
      width: 100%;
      border: 1px solid #dedcda;
      border-radius: 3px;
      height: 40px;
    }
    p {
      font-family: MicrosoftYaHei;
      font-size: 14px;
      color: #080707;
      letter-spacing: 0;
    }
    div {
      margin-bottom: 20px;
      p {
        font-family: MicrosoftYaHei;
        font-size: 14px;
        color: #666666;
        letter-spacing: 0;
      }
      b {
        color: #006dcc;
      }
    }
  }
}

.appearstyle {
  cursor: pointer;
}
.batchremove {
  padding-left: 5px;
  img {
    margin-left: 20px;
    margin-right: 8px;
  }
  span {
    color: #0277b5;
    cursor: pointer;
  }
}
.tableHeadField {
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  overflow: hidden;
  padding-right: 20px;
  text-overflow: ellipsis;

  .popover-span {
    position: absolute;
    right: 0;
  }
}
/* 筛选器位置 */
.screen {
  // height: 88%;
  height: calc(100% - 116px);
  position: absolute;
  right: 11px;
  top: 105px;
  z-index: 99;
}
</style>
