import {
    consoleSaveTab
} from "@/views/home/api.js";
/**
 * 打开menu1
 * @param {*} vue vue实例
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function add_menu1(vue, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    // 新打开菜单之前，关闭所有打开的dialog
    // vue.$bus.$emit('closeDialog')
    // 要添加的一级菜单Item
    let Level1TabItem = {
        ...options
    };
    // 设置当前选中tab的样式
    vue.$store.state.consoleData.data.level1Id = options.id;
    vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
    // 判断是否存在此点击项
    let arr = vue.$store.state.consoleData.data.list;
    let res = arr.some((item) => {
        if (item.id == options.id) {
            return true;
        } else {
            return false;
        }
    });
    if (!res) {
        // 如果不存在添加
        vue.$store.state.consoleData.data.list.push(Level1TabItem);
        vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
        consoleSaveTab({
            appid: vue.$store.state.home.homeApplicationId,
            opentab: JSON.stringify(vue.$store.state.consoleData),
        });
    }
}


/**
 * 替换menu1
 * @param {*} vue vue实例
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function replace_menu1(vue, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    // 要替换的的一级菜单Item
    let Level1TabItem = {
        ...options
    };
    // 过滤出当前选中的一级菜单所在的index下标，并替换
    let arr = vue.$store.state.consoleData.data.list;
    let tabIndex = arr.findIndex((item) => {
        return (item.id == vue.$store.state.consoleData.data.level1Id)
    })
    if(tabIndex == -1) {
        // vue.$message.warning('您当前没有可以替换的一级菜单，所以为您生成了一个新的一级菜单')
        // 没有找到当前选中的一级菜单
        add_menu1(vue, options)
    } else {
        // 设置当前选中tab的样式
        vue.$store.state.consoleData.data.level1Id = options.id;
        // 替换当前选中的一级菜单
        vue.$store.state.consoleData.data.list[tabIndex] = Level1TabItem
    }
    vue.$store.commit("setConsoleData", vue.$store.state.consoleData);
    consoleSaveTab({
        appid: vue.$store.state.home.homeApplicationId,
        opentab: JSON.stringify(vue.$store.state.consoleData),
    });
}

/**
 * 定位到已经打开的一级菜单
 * @param {*} vue vue实例
 * @param {*} options 菜单信息  id
 */
function reopen_menu1(vue, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    vue.$store.state.consoleData.data.list.forEach((item) => {
        if (item.id == options.id) {
            // 通过id过滤出item
            vue.$bus.$emit('clickTab1',item)
        }
    })
}


/**
 * 关闭menu1
 * @param {*} vue vue实例
 * @param {*} options 菜单信息  id
 */
function delete_menu1(vue, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    localStorage.removeItem(options.id)
    // 要关闭的菜单信息
    let tabItem = options
    // 要关闭的菜单当前所在的下标
    let tabIndex = vue.$store.state.consoleData.data.list.findIndex((item) => {
        return (item.id == tabItem.id)
    })
    if (tabIndex == -1) {
        // vue.$message.warning('没有找到你要关闭的一级菜单，请检查传的id是否正确')
    } else {
        vue.$bus.$emit('closeTab1',tabItem, tabIndex)
    }
    // 关闭的是当前选中的 关闭之后需要重新设置一个当前选中的
    // if (tabItem.id == vue.$store.state.consoleData.data.level1Id) {
    //     // 关闭的是最后一位
    //     if (tabIndex == vue.$store.state.consoleData.data.list.length - 1) {
    //         vue.$store.state.consoleData.data.list.splice(tabIndex, 1);
    //         // 设置当前默认选中的一级菜单
    //         vue.$store.state.consoleData.data.level1Id =
    //             vue.$store.state.consoleData.data.list[vue.$store.state.consoleData.data.list.length - 1]?.id || "";
    //     } else {
    //         // 关闭的不是最后一位
    //         // 删除
    //         vue.$store.state.consoleData.data.list.splice(tabIndex, 1);
    //         // 设置当前默认选中的一级菜单
    //         vue.$store.state.consoleData.data.level1Id =
    //             vue.$store.state.consoleData.data.list[tabIndex]?.id || "";
    //     }
    //     // 将数据保存到后端
    //     consoleSaveTab({
    //         appid: vue.$store.state.home.homeApplicationId,
    //         opentab: JSON.stringify(vue.$store.state.consoleData),
    //     });
    //     if (vue.$store.state.consoleData.data.level1Id) {
    //         // 判断当前选中的一级菜单下是否有二级  如果有二级  还需判断当前选中的二级是否是自定义页面  跳转不同的路由   如果没有二级  跳转当前默认的一级即可
    //         // 当前选中的一级tab
    //         let level1Id = vue.$store.state.consoleData.data.level1Id;
    //         // 过滤出当前一级菜单下的二级菜单列表
    //         vue.$store.state.consoleData.data.list.forEach((item) => {
    //             if (item.id == level1Id) {
    //                 // 有children说明有二级tab 那么跳转到当前二级tab
    //                 if (item.children && item.children.length > 0) {
    //                     // 过滤出当前选中的二级item
    //                     let level2Item = item.children.find((citem) => {
    //                         return citem.id == item.level2Id;
    //                     });
    //                     // 有routerName说明是调用ccsdk生成的菜单
    //                     if (level2Item.routerName) {
    //                         vue.$router.push({
    //                             name: level2Item.routerName,
    //                             params: level2Item.params || ""
    //                         })
    //                     } else {
    //                         // 判断当前的二级是自定义页面 还是  标准（自定义）对象
    //                         if (level2Item.customPage == "1") {
    //                             // 新版自定义页面
    //                             vue.$router.push({
    //                                 path: `/commonObjects/console-multi-screen/console-injection-component/${level2Item.pageApi}/${level2Item.id}`,
    //                             });
    //                         } else {
    //                             vue.$router.push({
    //                                 path: `/commonObjects/console-multi-screen/console-detail/${item.level2Id}/DETAIL`,
    //                                 query: {
    //                                     dataId: item.level2Id,
    //                                 },
    //                             });
    //                         }
    //                     }
    //                 } else {
    //                     // 没有二级Tab 那么跳转到当前一级tab
    //                     // 判断一级是否是通过ccsdk生成的
    //                     if (item.routerName) {
    //                         vue.$router.push({
    //                             name: item.routerName,
    //                             params: item.params || ""
    //                         })
    //                     } else {
    //                         vue.$router.push({
    //                             path: `/commonObjects/console-multi-screen/console-detail/${level1Id}/DETAIL`,
    //                             query: {
    //                                 dataId: level1Id,
    //                                 t: new Date(),
    //                             },
    //                         });
    //                     }
    //                 }
    //             }
    //         });
    //     } else {
    //         // 没有一级tab了 选中当前菜单 并跳转到列表页
    //         // 设置选中样式
    //         vue.$store.state.consoleData.data.level1Id = "menu";
    //         // 将数据保存到后端
    //         consoleSaveTab({
    //             appid: vue.$store.state.home.homeApplicationId,
    //             opentab: JSON.stringify(vue.$store.state.consoleData),
    //         });
    //         vue.$router.push({
    //             path: `/commonObjects/views/table/${vue.$store.state.consoleData.objectApi}/${vue.$store.state.consoleData.objId}/${vue.$store.state.consoleData.prefix}`,
    //         });
    //     }
    // } else {
    //     // 关闭的不是当前选中的直接关闭就好了
    //     vue.$store.state.consoleData.data.list.splice(tabIndex, 1);
    //     // 将数据保存到后端
    //     consoleSaveTab({
    //         appid: vue.$store.state.home.homeApplicationId,
    //         opentab: JSON.stringify(vue.$store.state.consoleData),
    //     });
    // }
}
/**
 * 刷新menu1
 * @param {*} vue vue实例
 * @param {*} options 菜单信息 id
 */
function refresh_menu1(vue, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    let tabItem;
    // 判断当前是选中状态再刷新
    if (vue.$store.state.consoleData.data.level1Id == options.id) {
        // 刷新
        // 过滤出刷新的id所在项
        vue.$store.state.consoleData.data.list.forEach((item) => {
            if (item.id == options.id) {
                tabItem = item;
                vue.$bus.$emit('refreshTab1',tabItem)
            }
        })
    }
}
export {
    add_menu1,
    replace_menu1,
    reopen_menu1,
    delete_menu1,
    refresh_menu1
}
