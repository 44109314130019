
//导入页面路由文件
const importmodule = [
  {
    path: "importmodule",
    component: () => import("@/views/importModule/importModule.vue"),
    name: "导入页面首页",
    children: [
      {
        path: "/",
        component: () => import("@/views/importModule/index.vue"),
        name: "导入页面首页",
        meta: {
          title: "导入",
        }
      },
      {
        path: "step1",
        component: () => import("@/views/importModule/step1.vue"),
        name: "导入页面1",
        meta: { title: "选择对象与文件 | 导入" },
      },
      {
        path: "step2",
        component: () => import("@/views/importModule/step2.vue"),
        name: "导入页面2",
        meta: {
          keepalive: true,
          title: "编辑字段映射 | 导入"
        }
      },
      {
        path: "step3",
        component: () => import("@/views/importModule/step3.vue"),
        name: "导入页面3",
        meta: {
          keepalive: true,
          title: "开始导入 | 导入",
        },
      },
    ]
  },
];

export default importmodule;
