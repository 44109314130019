<template>
  <div id="app" ref="directrecordwp">
    <probationTips v-if="isShowProbationTips"></probationTips>
    <router-view v-if="showRouterView || isShowWhitePage" />
    <!-- sse组件 -->
    <sseComponents v-if="useSSE" :sseMsgServiceAddress="sseMsgServiceAddress" />
    <!-- 内存提醒提示窗 -->
    <div class="memery-reminder" v-if="showReminder">
      <div class="memery-reminder-title">
        <i class="el-notification__icon el-icon-warning"></i>
        <span>温馨提示</span>
      </div>
      <div class="memery-reminder-tip">
        检测到运行卡顿，
        <span class="memery-reminder-tip-sec">{{ countdownNum }}</span>
        秒后，将为您自动刷新浏览器！
        <div>注意：请勿在通话时刷新浏览器！</div>
      </div>
      <div class="memery-reminder-footer">
        <el-button type="primary" size="mini" @click="hanldeReminder(0)"
          >现在刷新</el-button
        >
        <el-button size="mini" @click="hanldeReminder(1)">取消刷新</el-button>
      </div>
    </div>
  </div>
</template>
<script>
import { loadLanguageAsync } from "@/utils/i18n"; //多语言
import { getUserSetup, getUserInfo } from "./api";
// 获取域名
import { geturl } from "./api.js";
import * as api from "./api.js";
import { SET_UESR_INFO } from "@/store/mutations-types";
import whiteAuthList from "@/utils/whiteAuthList.js";
import probationTips from "@/views/header/probationTips.vue";
// 初始化平台功能，因为需要vue的router，所以在app中初始化了
import * as ccdk from "@/utils/ccdk";
export default {
  components: {
    probationTips,
    sseComponents: () => import("@/components/SseNotify/index.vue"),
  },
  data() {
    return {
      // 是否断网
      isNoNetWork: false,
      // 此变量控制页面内容是否显示
      showRouterView: false,
      // 是否显示白名单的路由页面
      isShowWhitePage: false,
      isShowProbationTips: false,
      isWhitePage: [],
      // 是否使用sse
      useSSE: false,
      // sse地址
      sseMsgServiceAddress: "",
      // 定时器 提醒清理内存
      timer: null,
      // 倒计时定时器 提醒清理内存
      countdownTimer: null,
      // 展示提醒窗口
      showReminder: false,
      // 倒计时
      countdownNum: 30,
    };
  },
  created() {
    ccdk.install(this);
    // 清空渲染器存储的数据
    localStorage.removeItem("coldLevel");
    localStorage.removeItem("hotLevel");
    localStorage.removeItem("userInformation");
    localStorage.removeItem("detailRecordId");
    localStorage.removeItem("connectPopupManager::connect::loginPopup");
    // 确定是否登录的白名单路由
    let websiteRouterToPageHash = document.location.hash;
    this.isWhitePage = whiteAuthList.find((item) => {
      if (websiteRouterToPageHash.indexOf(item) !== -1) {
        return item;
      }
    });
  },
  mounted() {
    // 设置项目icon
    const s = document.createElement("link");
    s.href = window.Glod.FAVICON_ICON || "./favicon.ico";
    s.rel = "icon";
    document.head.appendChild(s);

    // 移出网络监听事件
    window.addEventListener("offline", this.watchNetwork);
    // 注册网络监听事件
    window.addEventListener("online", this.watchNetwork);

    // 自定义组件二级dialog
    this.$bus.$on("openDialog", (obj) => {
      localStorage.setItem("customPageDialogParams", JSON.stringify(obj));
      this.$rendererDialog({
        pageId: obj.pageId,
        comName: obj.comName || "rewrite-cc-render",
        // dialog属性
        dialogAttrObj: {
          title: obj.title || obj.label || "详情",
          width: obj.width || "50%",
          height: obj.height || "",
          data: obj,
        },
        // 组件所需的数据 比如 pageApi
        componentAttr: { ...obj },
      });
    });
    this.$bus.$on("closeDialog", (pageId = "") => {
      this.$bus.$emit("closeRendererDialog", pageId);
    });

    // 如果不是白名单，那么进入
    if (!this.isWhitePage) {
      // 将binding放入cookies中
      this.$utils.getBinding();
      this.getUserInfo();
      this.getComponents();
      this.getWaterMark();
      window.addEventListener("resize", this.windowResizeFn);

      // 设置监听返回网站，需要请求，查看是否需要刷新页面，当设置多语言需要刷新页面。
      document.addEventListener("visibilitychange", this.visibilitychangeFn);
      window.addEventListener("storage", this.storageChangeFn);
      // 将binding放入cookies中
      this.$utils.getBinding(this.$cookies, "binding");
      setTimeout(() => {
        this.isShowProbationTips = true;
      }, 1000);
    } else {
      // 白名单，为了保证有多语言显示，读取浏览器的语言
      this.loadLanguage(navigator.language === "zh-CN" ? "zh" : "en");
    }
    // 内存监测提醒
    this.timer = setInterval(this.memeryReminderFn, 180 * 1000);
  },
  beforeDestroy() {
    // 移出网络监听事件
    window.removeEventListener("offline", this.watchNetwork);
    window.removeEventListener("online", this.watchNetwork);
    // 存储当前选中的应用程序id
    let applicationId = this.$store.state.home.homeApplicationId;
    localStorage.setItem("application", applicationId);
    window.removeEventListener("resize", this.windowResizeFn);
    document.removeEventListener("visibilitychange", this.visibilitychangeFn);
    window.removeEventListener("storage", this.storageChangeFn);

    // 清理定时器
    if (this.timer) {
      clearInterval(this.timer);
    }
    if (this.countdownTimer) {
      clearInterval(this.countdownTimer);
    }
  },
  methods: {
    /**
     * 监听storage中的用户信息变化回调
     */
    storageChangeFn(e) {
      if (e.key === "changeUserInfo") {
        // 获得用户信息
        const userInfo = this.$store.state.userInfoObj;
        // 用户信息不为空时进行请求
        if (userInfo) {
          getUserInfo(null, true).then((res) => {
            if (res && res.data) {
              // 如果多语言或国家或货币或时区不一致，那么重新加载页面
              if (
                res.data.countryCode != userInfo.countryCode ||
                res.data.currencyCode != userInfo.currencyCode ||
                res.data.language != userInfo.language ||
                res.data.timeZone != userInfo.timeZone
              ) {
                location.reload();
              }
            }
          });
        }
      }
    },
    // 事件监听
    visibilitychangeFn() {
      // 回显
      if (document.visibilityState == "visible") {
        // 解决同时打开两个detail窗口，localstrorage中的第一个页面的detailRecordId被第二个覆盖的问题
        this.$bus.$emit("updateDetailRecordId");
      }
    },
    // 事件监听
    windowResizeFn() {
      let that = this;
      if (that.$route.path.split("/")[1] !== "print-detail") {
        this.$bus.$emit(
          "windowResize",
          document.body.offsetHeight,
          document.body.offsetWidth
        );
      }
    },
    /**
     * 加载自定义标签多语言
     * 1 检测是否有缓存时间
     * 2 没有缓存时间,则传空时间返回所有label context
     * 3 有缓存时间,拿缓存时间增量查询变动的label context
     * 4 存入local混村时间和label context 永久缓存
     */
    async loadCustomLabel(local) {
      // 检测本地是否有缓存上次接口查询的时间
      let queryTime = localStorage.getItem("customLabelQueryTime") || "";
      let customLabelOrigin = JSON.parse(
        localStorage.getItem("customLabelOrigin")
      );
      // 没有缓存则一直加载所有
      if (!customLabelOrigin) {
        queryTime = "";
      }
      let res = await api.queryAllTag({
        queryTime,
      });
      let data = {};
      if (Object.keys(res?.data?.list).length < 1) return;
      data = res.data.list;
      data = { ...customLabelOrigin, ...data };
      this.$i18n.mergeLocaleMessage(local, data);
      localStorage.setItem("customLabelOrigin", JSON.stringify(data));
      localStorage.setItem("customLabelQueryTime", res?.data?.queryTime || "");
    },
    /**
     * 网络状态监听
     * @param {Object} event:事件
     */
    watchNetwork(event) {
      // 关闭当前this.$notify
      this.$notify.closeAll();
      const type = event.type === "offline" ? "error" : "success";
      //  网络已断开，请检查网络连接
      let offlineTitle = this.$i18n.t("c985"),
        //  网络已连接
        onlineTitle = this.$i18n.t("c984"),
        duration = event.type === "offline" ? 0 : 3000;
      this.$notify[type]({
        title: type === "error" ? offlineTitle : onlineTitle,
        position: "bottom-right",
        duration: duration,
      });
      setTimeout(() => {
        // 更新网络状态（是否断网）
        this.isNoNetWork = event.type === "offline";
      }, 1500);
    },
    /**
     * 加载多语言
     */
    async loadLanguage() {
      this.isShowWhitePage = await loadLanguageAsync(
        this.getQueryLanguage("language")
      );
    },
    /**
     * 查询多语言类型
     */
    getQueryLanguage(name) {
      var reg = new RegExp(name + "=([^&]*)(&|$)");
      var r = window.location.href.match(reg);
      if (r != null) return r[1].trim();
      // 默认中文
      return "zh";
    },
    // 获取全局用户相关信息
    getUserInfo() {
      geturl().then((urlRes) => {
        if (urlRes && urlRes.result == true && urlRes.data) {
          this.$cookies.set("domainName", urlRes.data, { expires: 7 }, { sameSite: 'Strict' });
        }
        getUserInfo(null, true).then(async (res) => {
          // res.data.loginUrl
          //   ? localStorage.setItem("loginUrl", res.data.loginUrl)
          //   : null;
          // 存储重新登录地址
          localStorage.setItem("loginUrl", window.Glod.LOGIN_URL);
          localStorage.setItem("userInformation", JSON.stringify(res));
          if (
            res.data &&
            res.data.orgId != null &&
            res.data.orgId != undefined
          ) {
            localStorage.setItem("orgId", res.data.orgId);
            localStorage.setItem("username", res.data.userName);
            localStorage.setItem("userId", res.data.userId);
          }

          // 判断是否是私有云，若是私有云，禁用邮箱相关功能
          localStorage.setItem("private", res.data.private);
          this.$cookies.set("countryCode", res.data.countryCode, { sameSite: 'Strict' });
          this.$store.commit(SET_UESR_INFO, res.data);
          // this.$i18n.locale = res.data.language // 根据接口返回的语言确定当前登录用户所使用的语言

          /**
           * 1、保存用户档案设置的语言
           * 2、在多语言的i18n的 loadLanguageAsync 中判断档案没有设置语言就用默认的浏览器语言
           */
          window.sessionStorage.setItem("ProfileLanguage", res.data.language);

          let setupRes = await loadLanguageAsync(res.data.language);
          // 需本地存储下用户设置的语言，用于当token失效时，前端要提示重新登录，可以直接取本地存储的语言进行赋值即可。
          localStorage.setItem("locale", setupRes);
          this.showRouterView = true;

          //如果后台没有返回sse的地址，就不链接sse了
          if (!res.data.sseMsgServiceAddress) return;
          this.sseMsgServiceAddress = res.data.sseMsgServiceAddress;
          this.useSSE = true;
        });
      });
    },
    /**
     *请求确认用户是否开启水印
     */
    async getWaterMark() {
      let result = await getUserSetup();
      if (result) {
        if (result.data) {
          localStorage.setItem("openWater", result.data.isEnableWaterMark);
          localStorage.setItem("inlineedit", result.data.inlineedit === "true");
          localStorage.setItem(
            "relatedList",
            result.data.relatedList === "true"
          );
        } else {
          this.$message.error(result.returnInfo);
        }
      }
    },
    /**
     * 查询组件，并注入到项目
     */
    getComponents() {
      this.$devHttp
        .postFormat(
          `${window.Glod["ccex-dev"]}/custom/pc/1.0/post/listCustomCompAddr`
        )
        .then((res) => {
          if (res.returnCode == 200) {
            // 注入官方组件
            res.data.cloudccAddrList.map((item) => {
              this.appendScript(item);
            });

            // 注入自定义组件
            res.data.customAddrList.map((item) => {
              this.appendScript(item + "?t=" + new Date().getTime());
            });
            this.$bus.$emit("appendOK");
          }
        });
    },
    /**
     * 追加JS对象
     */
    appendScript(item) {
      let scripts = document.body.getElementsByTagName("script");
      for (let i = 0; i < scripts.length; i++) {
        if (item == scripts[i].src) return;
      }
      const s = document.createElement("script");
      s.type = "text/javascript";
      s.src = item;
      document.body.appendChild(s);
    },
    /**
     * 定时提醒
     */
    memeryReminderFn() {
      // 有些浏览器没有
      if (performance.memory) {
        // 系统分配内存
        let totalMemery = performance.memory.jsHeapSizeLimit / 1024 / 1024;
        // 设置内存阈值 80%
        let limitMemery = (totalMemery * 0.8).toFixed(2) * 1;
        // 获取当前占用内存
        let usedMemery =
          (performance.memory.usedJSHeapSize / 1024 / 1024).toFixed(2) * 1;
        if (usedMemery > limitMemery) {
          this.showReminder = true;
          this.countdownNum = 30;
          this.countdownTimer = setInterval(this.countdownFn, 1000);
        }
      }
    },
    /**
     * 内存刷新倒计时
     */
    countdownFn() {
      if (this.countdownNum > 0) {
        this.countdownNum--;
      } else {
        clearInterval(this.countdownTimer);
        location.reload();
      }
    },
    /**
     * 内存刷新提醒操作按钮
     * 0 立即刷新、 1 取消刷新
     */
    hanldeReminder(flag) {
      if (flag == 0) {
        clearInterval(this.countdownTimer);
        location.reload();
      } else if (flag == 1) {
        clearInterval(this.countdownTimer);
        this.showReminder = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import "./style/index.scss";
@font-face {
  font-family: "element-icons";
  src: url(./assets/element_icons/element-icons.ttf);
}
@font-face {
  font-family: "element-icons";
  src: url(./assets/element_icons/element-icons.woff);
}
/**
表格组件强制在一行滚动
 */
body .el-table--scrollable-y {
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 9px !important;
    height: 9px !important;
  }
  .gutter {
    width: 10px !important;
  }
}
/* 修改element表头错位的问题 */
.el-table--border th.gutter:last-of-type {
  width: 17px !important;
}

@media screen and (min-width: 320px) and (max-width: 768px) {
  #app {
    min-width: auto;
  }
}
.memery-reminder {
  position: fixed;
  bottom: 16px;
  right: 16px;
  z-index: 3000;
  display: flex;
  flex-wrap: wrap;
  width: 330px;
  padding: 14px 26px 14px 13px;
  border-radius: 8px;
  box-sizing: border-box;
  border: 1px solid #e1e1e1;
  position: fixed;
  background-color: #ffffff;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  transition: opacity 0.3s, transform 0.3s, left 0.3s, right 0.3s, top 0.4s,
    bottom 0.3s;
  overflow: hidden;
  &-title {
    font-weight: bold;
    font-size: 16px;
    display: flex;
    align-items: center;
    color: #fe8a14;
    span {
      margin-left: 8px;
      color: #080707;
    }
  }
  &-tip {
    font-size: 14px;
    line-height: 21px;
    margin: 6px 0 0 0;
    color: #393939;
    padding-left: 32px;
    .memery-reminder-tip-sec {
      color: red;
      font-size: 16px;
    }
  }
  &-footer {
    width: 100%;
    padding-top: 12px;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
