// 控制面板对象路由文件
const controlPanel = [
  {
    path: "controlPanel/control",
    component: () => import("@/views/controlPanel/index.vue"),
    name: "控制台",
  }
];

export default controlPanel;
