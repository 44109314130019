/**
 * 知识文章路由
 */
const knowledgearticles = [
    {
        name: "Knowledgearticles",
        path: "Knowledgearticles/:objectApi/:objId/:prefix",
        component: () => import("@/views/Knowledgearticles"),
        // name: " 知识文章",
    },
];

export default knowledgearticles;

