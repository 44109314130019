/**
 * 实际工作清单对象路由
 */
const actualworklist = [
  {
    path: "/actualworklist/listviews",
    component: () => import("@/views/actualworklist/index.vue"),
    name: "列表视图",
    meta: {
      title: "列表视图",
      // keepAlive: true
    },
  },
  {
    path: "/actualworklist/batchAdd/CreatProObjChild",
    component: () => import("@/views/actualworklist/batchAdd/CreatProObjChild.vue"),
    name: "批量新增",
    meta: {
      //keepAlive:true //需要缓存
    }
  },
];

export default actualworklist;
