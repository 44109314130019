import dayjs from "./dayjs.js";
export default (Vue) => {
  // 时间戳
  Vue.filter("formatTime", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs
      .tz(dayjs.unix(Number(value / 1000)), timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  });

  Vue.filter("deadline", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs
      .tz(dayjs.unix(Number(value / 1000)), timeZone)
      .format("YYYY-MM-DD");
  });
  Vue.filter("startTime", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs.tz(dayjs.unix(Number(value / 1000)), timeZone).format("HH:mm");
  });
  Vue.filter("monthDay", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    // 转化为时间毫秒值
    value = new Date(value).getTime();
    return dayjs.tz(dayjs.unix(Number(value / 1000)), timeZone).format("MM-DD");
  });
  Vue.filter("formatTimes", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs
      .tz(dayjs.unix(Number(value / 1000)), timeZone)
      .format("YYYY-MM-DD HH:mm:ss");
  });
  Vue.filter("formatTimess", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs
      .tz(dayjs.unix(Number(value / 1000)), timeZone)
      .format("YYYY-MM-DD HH:mm");
  });
  Vue.filter("yearMonthDayTime", function(value, countryCode) {
    let timeZone = countryCode ? countryCode : "Asia/Shanghai";
    return dayjs
      .tz(dayjs.unix(Number(value / 1000)), timeZone)
      .format("YYYY-MM-DD HH:mm");
  });

  // 字符串类型
  Vue.filter("dateFormat", function(value) {
    if (value !== null && value !== undefined && value !== "") {
      return dayjs(value).format("YYYY-MM-DD");
    } else {
      return value;
    }
  });
  Vue.filter("datetimeFormat", function(value) {
    if (value !== null && value !== undefined && value !== "") {
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    } else {
      return value;
    }
  });
  Vue.filter("timeFormat", function(value) {
    if (value !== null && value !== undefined && value !== "") {
      return value;
    } else {
      return value;
    }
  });

  // 数字显示千分位格式
  Vue.filter("ThousandSeparator", function(value) {
    let n = Number(value);
    if (!isNaN(n)) {
      return (
        value &&
        value
          .toString()
          .replace(/(^|\s)\d+/g, (m) => m.replace(/(?=(?!\b)(\d{3})+$)/g, ","))
      );
    } else {
      return value;
    }
  });

  /**
   * ! 注意：这是国际化时区格式化，其他地方看清楚是否需要国际化时区再使用（需要两个参数必选）
   * TODO : 主页的headercard的国际化时区格式化
   */
  Vue.prototype.$formatZoneTime = (value, timeZone) => {
    return dayjs(value)
      .tz(timeZone ?? "Asia/Shanghai")
      .format("YYYY/MM/DD/HH:mm:ss");
  };
};
