export default [{
    // 以tab方式打开新建
    path: "/console-tab-create/:prefix/:objId/:objectApi/:page",
    component: () => import("@/components/CCPage/create-obj.vue"),
    name: "consoleTabCreate",
    props: true,
},{
    // 以tab方式打开编辑
    path: "/console-tab-edit/:id/:prefix/:objId/:objectApi/:page",
    component: () => import("@/components/CCPage/edit-obj.vue"),
    name: "consoleTabEdit",
    props: true,
},{
    // 以tab方式打开标准详情页
    path: "/console-tab-detail/:id/:oprateType",
    component: () => import("@/views/commonObjects/detail.vue"),
    name: "consoleTabDetail",
    props: true,
},{
    // 以tab方式打开项目详情页
    path: "/console-tab-detail/project-detail/:id/:oprateType",
    component: () => import("@/views/commonObjects/project-detail/index.vue"),
    name: "consoleTabDetailProject",
    props: true,
}];
