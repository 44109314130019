/*
 * @Author: your name
 * @Date: 2021-07-05 16:14:38
 * @LastEditTime: 2021-08-30 11:38:46
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\views\ccFileCenter\config\fileIconLibrary.js
 */
const FILEICON = {
  // 操作
  'dropdown': "#icon-xialakuang",
  'download': "#icon-xiazai",
  'publicLink': "#icon-gonggonglianjie",
  'viewinfo': "#icon-xiangxixinxi",
  'share': "#icon-fenxiang",
  'delete': "#icon-shanchu",
  'editinfo': "#icon-edit-big",
  // 详情
  'historyList': "#icon-lishibanben",
  'shareList': "#icon-gongxiang",
  'followList': "#icon-guanzhuzhe",
  'group': "#icon-group",
  // 默认图标
  'default': "#icon-default",
  'lib': "#icon-folder",
  'folder': "#icon-folder",
  // 小写
  'avi': "#icon-avi",
  'csv': "#icon-csv",
  'doc': "#icon-doc",
  'docx': "#icon-docx",
  'jpg': "#icon-JPG",
  'jpeg': "#icon-JPG",
  'mp3': "#icon-mp3",
  'mp4': "#icon-mp4",
  'pdf': "#icon-pdf",
  'png': "#icon-png",
  'ppt': "#icon-ppt",
  'pptx': "#icon-pptx",
  'rar': "#icon-rar",
  'txt': "#icon-txt",
  'xls': "#icon-xls",
  'xlsx': "#icon-xlsx",
  'zip': "#icon-zip",
  // 大写
  'AVI': "#icon-avi",
  'CSV': "#icon-csv",
  'DOC': "#icon-doc",
  'DOCX': "#icon-docx",
  'JPG': "#icon-JPG",
  'MP3': "#icon-mp3",
  'MP4': "#icon-mp4",
  'PDF': "#icon-pdf",
  'PNG': "#icon-png",
  'PPT': "#icon-ppt",
  'PPTX': "#icon-pptx",
  'RAR': "#icon-rar",
  'TXT': "#icon-txt",
  'XLS': "#icon-xls",
  'XLSX': "#icon-xlsx",
  'ZIP': "#icon-zip",
}


export default FILEICON
