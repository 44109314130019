const printRouter = [
    // 打印列表页
    {
        path: "/print-table/:objId/:prefix/:objectApi/:viewId/:pageNum/:pageSize/:searchKeyWord/:tagIdsArr/:exactSearchFlag",
        name: "打印标准列表页",
        component: () => import("@/views/Print/index.vue"),
    },
    // 打印详情页
    {
        path: "/print-detail/:id/:prefix",
        name: "打印详情页",
        component: () => import("@/views/Print/detail.vue"),
    },
]

export default printRouter