import Vue from 'vue'
import router from "@/router/index.js";
import store from "@/store/index.js";
import RendererPopup from './customDialog.vue'
import i18n from '@/utils/i18n'
const RendererPopupBox = Vue.extend(RendererPopup)

RendererPopup.install = function (data) {
    // todo:需要修改全局dialog实现方案...
    let instance = new RendererPopupBox({
        data,
        router,
        store,
        i18n
    }).$mount()
    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
        // dialogVisible 和弹窗组件里的dialogVisible对应，用于控制显隐
        instance.dialogVisible = true
    })
}
export default RendererPopup
