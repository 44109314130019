/**
 * 公海池对象路由
 */
const clientPool = [
    {
        name: "clientPool",
        path: "clientPool/:objectApi/:objId/:prefix",
        component: () => import("@/views/clientPool"),
    },
]
export default clientPool;