/**
 *订货平台路由文件
 */
const ordering = [
  {
    path: "ordering",
    redirect: () => "/orderingPlatform",
    name: "订货平台",
  },
  {
    path: "orderingPlatform",
    component: () => import("@/views/orderingPlatform/orderingPlatform.vue"),
    name: "订货平台",
  },
  {
    path: "orderingShoppingCar",
    component: () => import("@/views/orderingPlatform/orderShoppingCar.vue"),
    name: "购物车",
  },
  {
    path: "createOrder",
    component: () => import("@/views/orderingPlatform/createOrder.vue"),
    name: "创建订单",
  },
  {
    path: "orderPay/:totalPrice/:orderId",
    component: () => import("@/views/orderingPlatform/newOrderPay.vue"),
    name: "订单付款",
  },

]

export default ordering;
