const gantt = [
    {
      path: "gantt",
      name: "甘特图",
      redirect: () => "/gantt/gantt",
    },
    {
      path: "gantt/gantt",
      component: () => import("@/views/gantt/index.vue"),
      name: "甘特图",
      meta: { title: "甘特图" },
    },
    
  ];
  
  export default gantt;
  