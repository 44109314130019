//文本、长文本、富文本、电话、邮件、地址、URL、币种类型、自动编号、加密文本、记录类型、查找、主祥、查找多选、图片、选项列表、选项列表多选
// ['e','n','l','g','m','h','c','k','s','i'];
import i18n from '@/utils/i18n'; //多语言

var screeningOpeTypes1 = {
  getScreeningOpeTypes() {
    return [
      {
        // 等于
        id: "e",
        name: i18n.t('label_tabpage_equalz'),
      },
      {
        // 不等于
        id: "n",
        name: i18n.t('noequal'),
      },
      {
        // 小于
        id: "l",
        name: i18n.t('less'),
      },
      {
        // 大于
        id: "g",
        name: i18n.t('label.tabpage.greaterz'),
      },
      {
        // 小于或等于
        id: "m",
        name: i18n.t('label.tabpage.lessequalz'),
      },
      {
        // 大于或等于
        id: "h",
        name: i18n.t('noless'),
      },
      {
        // 包含
        id: "c",
        name: i18n.t('contain'),
      },
      {
        // 不包含
        id: "k",
        name: i18n.t('label_tabpage_exclusivez'),
      },
      {
        // 起始字符
        id: "s",
        name: i18n.t('startwith'),
      },

    ]
  }
}

//数字、币种、百分比、日期、日期时间、时间、评分=['e','n','l','g','m','h'];
const screeningOpeTypes2 = {
  getScreeningOpeTypes() {
    return [
      {
        // 等于
        id: "e",
        name: i18n.t('label_tabpage_equalz'),
      },
      {
        // 不等于
        id: "n",
        name: i18n.t('noequal'),
      },
      {
        // 小于
        id: "l",
        name: i18n.t('less'),
      },
      {
        // 大于
        id: "g",
        name: i18n.t('label.tabpage.greaterz'),
      },
      {
        // 小于或等于
        id: "m",
        name: i18n.t('label.tabpage.lessequalz'),
      },
      {
        // 大于或等于
        id: "h",
        name: i18n.t('noless'),
      },
    ]
  }
}

//复选框=['e','n'];
const screeningOpeTypes3 = {
  getScreeningOpeTypes() {
    return [
      {
        // 等于
        id: "e",
        name: i18n.t('label_tabpage_equalz'),
      },
      {
        // 不等于
        id: "n",
        name: i18n.t('noequal'),
      },
    ]
  }
}

//地理定位=['o'];
const screeningOpeTypes4 = {
  getScreeningOpeTypes() {
    return [
      {
        // 在范围中
        id: "o",
        name: i18n.t('label_in_scope'),
      }
    ]
  }
}

let TYPE = {
  U: screeningOpeTypes1,
  P: screeningOpeTypes2,
  c: screeningOpeTypes2,
  C: screeningOpeTypes1,
  ct: screeningOpeTypes1,
  Y: screeningOpeTypes1,
  MR: screeningOpeTypes1,
  LT: screeningOpeTypes4,
  AD: screeningOpeTypes1,
  H: screeningOpeTypes1,
  E: screeningOpeTypes1,
  B: screeningOpeTypes3,
  R: screeningOpeTypes1,
  encd: screeningOpeTypes1,
  SCORE: screeningOpeTypes2,
  D: screeningOpeTypes2,
  F: screeningOpeTypes2,
  T: screeningOpeTypes2,
  N: screeningOpeTypes2,
  S: screeningOpeTypes1,
  X: screeningOpeTypes1,
  A: screeningOpeTypes1,
  J: screeningOpeTypes1,
  L: screeningOpeTypes1,
  Q: screeningOpeTypes1,
  M: screeningOpeTypes1,
  V: screeningOpeTypes1,
}

let OPERATIOR = {
  TYPE: TYPE,
  screeningOpeTypes1: screeningOpeTypes1
}

export default OPERATIOR
// module.exports = OPERATIOR