import Vue from "vue";
import Vuex from "vuex";
import Home from "./modules/home"; // home模块
import Login from "./modules/login"; // 登录模块
import Chat from "./modules/chat"; // 群策模块
import List from "./modules/list"; //
import report from "./modules/report"; // 报表模块
import dashboard from "./modules/dashboard"; // 仪表板模块
import channel from "./modules/channel"; // 市场营销渠道二维码模块
import projectManage from "./modules/projectManage"; // 项目管理系统模块
import objectList from './modules/objectList'; // 对象列表模块
import createPersistedState from "vuex-persistedstate";
import mutations from "./mutations"; // 主模块的mutations
import actions from "./actions"; // 主模块的actions
import getters from "./getters"; // 主模块的getters
import i18n from "@/utils/i18n"; //多语言
import creatform from './modules/creatform'
import menu from './modules/menu'
Vue.use(Vuex);
// 新版控制台初始化数据
const consoleDataObj = {
  currentObject: "", // 当前菜单名字
  currentObjectId:"", // 当前菜单id
  iscustom: "", // 菜单是 标准 还是自定义(目前没用)
  type: '', // 菜单类型(目前没用)
  objectApi: "",
  objId: "",
  prefix: "",
  viewId: "",
  data: {
    level1Id: "", // 一级菜单当前的选中的菜单
    list: [
      // 一级列表
      // {
      //   level2Id:"111", // 二级菜单当前的选中的菜单
      //   name: "aaa", // tab名称
      //   id: "12312wfsdf",// tab id
      //   isfixed: true, // 是否固定选项卡 true固定 false不固定
      //   // tab的下拉操作项  tabAction是统一处理的  没有在添加一二级菜单时的业务里处理
      //   tabAction: [
      //     {
      //       name: "刷新选项卡",
      //       id: "refresh",
      //     },
      //     {
      //       // isfixed 为true时 展示取消固定选项卡
      //       name: "取消固定选项卡",
      //       id: "cancelFixed",
      //       // isfixed 为false 时展示固定选项卡
      //       // name: "固定选项卡",
      //       // id: "fixed",
      //     },
      //     {
      //       name: "自定义选项卡",
      //       id: "custom",
      //     },
      //     {
      //       name: "关闭选项卡",
      //       id: "close",
      //     },
      //   ],
      //   isShowClose: true, // 控制每个tab外层是否展示关闭按钮  true展示  false不展示
      //   // 一级菜单下的二级菜单
      //   childer: [
      //     {
      //       name: "aaa",
      //       id: "111",
      //       isShowClose: true, // 控制每个tab外层是否展示关闭按钮  true展示  false不展示
      //       // 二级菜单没有固定选项卡
      //       tabAction:[
      //         {
      //           name: "刷新选项卡",
      //           id: "refresh",
      //         },
      //         {
      //           name: "自定义选项卡",
      //           id: "custom",
      //         },
      //         {
      //           name: "设置为工作区选项卡",
      //           id: "setToLevel1",
      //         },
      //         {
      //           name: "关闭选项卡",
      //           id: "close",
      //         },
      //       ]
      //     },
      //     {
      //       name: "bbb",
      //       id: "222",
      //       customPage:'1', // 说明是自定义页面
      //       isShowClose: true, // 控制每个tab外层是否展示关闭按钮  true展示  false不展示
      //       tabAction:[
      //         {
      //           name: "刷新选项卡",
      //           id: "refresh",
      //         },
      //         {
      //           name: "自定义选项卡",
      //           id: "custom",
      //         },
      //         {
      //           name: "设置为工作区选项卡",
      //           id: "setToLevel1",
      //         },
      //         {
      //           name: "关闭选项卡",
      //           id: "close",
      //         },
      //       ]
      //     },
      //   ],
      // },
    ],
  },
}
export default new Vuex.Store({
  state: {
    // 判断是否是控制台样式 true是 false否
    navigationStyle: sessionStorage.getItem("navigationStyle") || "",
    // 服务控制台数据
    consoleData: JSON.parse(sessionStorage.getItem("consoleData")) || consoleDataObj,
    remaining: false,
    // 是否显示剩余时间用来控制样式
    countnum: "",
    //选中数量
    checkedNum: [],
    //盒子显示状态
    checkboxshow: true,
    // 当前选中ID
    currentSeletedOptionId: "",
    // 右边盒子不显示
    boxrightnullshow: "",
    // 右边盒子显示
    boxrightfullshow: "",
    // 中间盒子不显示
    boxcenternullshow: "",
    // 中间盒子显示
    boxcenterfullshow: "",
    // 选择客户方式
    customer: "",
    // 选择联系人方式
    contact: "",
    // 选中的li
    isactive: "",
    // 点击的tab
    swicthtablebal: "",
    // 选中的label名字
    labelName: "",
    // 选中的字段的id
    filelistobjid: "",
    List: [], //当前点击数据
    //触发器
    checked1: "",
    //验证规则
    checked2: "",
    //查重规则
    checked3: "",
    //共享规则
    checked4: "",
    //点击的tab
    defaultselect: "",
    //已选中字段的数组的长度
    selectlength: "",
    file: "",
    //“导入接口”的filelist对象包含的数据
    commitdata: [],
    //上传文件表头的长度
    csvfiledlength: "",
    //文件解析后的数据
    curdata: "",
    //选择对象的id
    id: "",
    //后台返回的编辑字段的列表
    cloudccoption: "",
    //用户上传文件数量
    numlimit: "",
    //上传文件的名
    filename: "",
    // label:'',
    dropdownData: [], //这是下拉选择框的数据
    reportInfos: JSON.parse(sessionStorage.getItem("reportInfos")) || {},
    runpageAndEditpage: false, //是否是运行页跳转到编辑页（在编辑页做数据传输判断）
    chooseRowFileds: JSON.parse(sessionStorage.getItem("chooseRowFileds")) || [], //编辑页选择的行
    choosedColumnFileds: JSON.parse(sessionStorage.getItem("choosedColumnFileds")) || [], //编辑页选择的列
    token: "", // 用户登录标识
    runReportABCD: JSON.parse(sessionStorage.getItem("runReportABCD")) || {}, //从列表页到报表页携带id请求时返回的数据
    editPageFirstEnterId: sessionStorage.getItem("editPageFirstEnterId") || "", // 保存编辑页首次从列表页进来时的ID
    keepOpenKuang: true, //是否保持开启（（点击dom时让页面的框消失，所有页面通用）
    choosedTimeRanges: {}, //保存当前选择的筛选信息到strore(运行和编辑页使用)
    tempReporttypecustomid: sessionStorage.getItem("tempReporttypecustomid") || "", //保存临时的toid，用于在保存报表时使用，给接口传递该ID才能正常保存报表
    reportName: sessionStorage.getItem("reportName") || "", //报表名字（编辑页和运行页的报表名称）
    selectTitleName: "标准主页", //当前选中的标题名称

    tabName: sessionStorage.getItem("tabName"), // 当前选择的菜单名称
    reportOperation: {
      // 用户有无报表相关的权限
      run: false, // 运行报表权限
      export: false, // 导出报表权限
      new: false, // 新建报表权限
    },
    currencyCode: "", // 个人币种
    dashboardCondition: {
      dashboardConditionTimeArr: [],
      dashboardConditionPersonArr: [],
    }, // 仪表板筛选项配置的筛选条件
    userInfoObj: {}, //当前登录的用户的信息（APP中保存）
    // 在点击关系网之前，保存当前的url，然后跳转到关系网，在关系网的面包屑中做返回到改对象使用
    structureBeforeClickSavePath: sessionStorage.getItem("structureBeforeClickSavePath") || "",
    VueDropboxPicker: false, //判断VueDropboxPicker是否加载完成
    lightningDomainStr: "", //lightning设置页 右侧 开始设置跳转地址路径 保存当前环境域名
  },
  mutations,
  actions,
  getters,
  modules: {
    home: Home,
    login: Login,
    chat: Chat,
    list: List,
    report: report,
    dashboard: dashboard,
    channel: channel,
    projectManage: projectManage,
    i18n,
    creatform,
    objectList,
    menu
  },
  plugins: [createPersistedState()],
});
