import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
 export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
      return axios.get('/user/getUserInfo', data)
    } else {
      return new Promise(function (resolve, ) {
        resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
      })
    }
  }

// 获获取选项列表值
export function GetViewGetSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}
// 获取币种信息
export function GetCurrencyInfo(data) {
    return axios.post('/currency/getCurrencyInfo', data)
}
// 联系人列表
export function queryContactList(data) {
    return axios.post('/contactRole/queryContactList', data)
}
//更改报价单价格手册
export function GetSavePricebook(data) {
    return axios.post('/quotePdt/savePricebook', data)
}
//保存新添加的报价单
export function GetSaveProducts(data) {
    return axios.post('/quotePdt/saveProducts', data)
}
//选择产品后的添加产品页
export function GetSelectAddProducts(data) {
    return axios.post('/quotePdt/selectAddProducts', data)
}
//报价单查询价格手册
export function GetQuotePdtPricebooks(data) {
    return axios.post('/quotePdt/getPricebooks', data)
}
// 保存业务机会小组
export function Save(data) {
    return axios.post('/opportunityTeam/save', data)
}
// 保存客户小组
export function saveAccountTeams(data) {
    return axios.post('/opportunityTeam/saveAccountTeams', data)
}
// 业务机会小组设置页
export function Setuppop(data) {
    return axios.post('/opportunityTeam/setup', data)
}
//更改业务机会产品价格手册
export function SavePricebook(data) {
    return axios.post('/opportunityPdt/savePricebook', data)
}
//保存批量编辑的业务机会产品
export function SaveEditProducts(data) {
    return axios.post('/opportunityPdt/saveEditProducts', data)
}
//保存新添加的业务机会产品
export function SaveProducts(data) {
    return axios.post('/opportunityPdt/saveProducts', data)
}
//选择产品后的添加产品页
export function SelectAddProducts(data) {
    return axios.post('/opportunityPdt/selectAddProducts', data)
}
//设置联系人角色
export function Setup(data) {
    return axios.post('/contactRole/setup', data)
}
//保存联系人角色
export function SaveContacts(data) {
    return axios.post('/contactRole/save', data)
}
//业务机会产品查询价格手册
export function GetPricebooks(data) {
    return axios.post('/opportunityPdt/getPricebooks', data)
}
//获取添加业务机会产品搜素产品
export function SearchProducts(data) {
    return axios.post('/opportunityPdt/searchProducts', data)
}
//获取添加业务机会产品页信息
export function GetAddOpportunityPdtInfo(data) {
    return axios.post('/opportunityPdt/getAddOpportunityPdtInfo', data)
}
//查询当前业务机会下的业务机会产品
export function EditProducts(data) {
    return axios.post('/opportunityPdt/editProducts', data)
}
// 联系人特定字段查询
export function queryContactGivenField(data) {
    return axios.post('/contactRole/queryContactGivenField', data)
}

//联系人角色 删除
export function deleteContact(data) {
    return axios.post('/contactRole/delete', data)
}
//获取添加报价单搜素产品
export function GetSearchProducts(data) {
    return axios.post('/quotePdt/searchProducts', data)
}
//获取添加报价单页信息
export function getAddQuotePdtInfo(data) {
    return axios.post('/quotePdt/getAddQuotePdtInfo', data)
}

// 根据名称搜索对象记录接口--查找字段自动搜索
export function queryMoreDataByName(data) {
    return axios.post('objectdetail/queryMoreDataByName', data)
}

//获取对象记录权限
export function getPermissionById(data) {
    return axios.post('/objectdetail/getPermissionById', data)
}

// 删除记录
export function del(data) {
    return axios.post('/objectdetail/delete', data)
}

// 获取记录类型
export function getRecordType(data) {
    return axios.post('/batch/getRecordType', data)
}
// 获取已启用的价格手册
export function getEnablePriceBook(data) {
    return axios.get('/transaction/getEnablePriceBook', data)
}
// 展示产品列表
export function showProductList(data) {
    return axios.post('/cpq/productconfig/showProductList', data)
}
// 根据价格手册搜索产品
export function searchProducts(data) {
    return axios.post('/master/searchProducts', data)
}
//获取对象字段列表
export function getFieldList(data) {
    return axios.post('/field/getFieldList', data)
}
export function masterRecordDetailInfo(data) {
    return axios.post('/master/masterRecordDetailInfo', data)
}
// 删除业务机会关联的子产品记录
export function deleteRelatedProductInfo(data) {
    return axios.post('/master/deleteRelatedProductInfo', data)
}