/**
 * 目标模型对象路由
 */
const targetModel = [
      {
        path: "targetModel/index",
        component: () => import("@/views/targetModel/index.vue"),
        name: "targetModel",
        meta: { title: "targetModel" },
      },
      {
        path: "targetModel/detail",
        component: () => import("@/views/targetModel/newTarget.vue"),
        name: "detail",
        meta: { title: "detail" },
      },
];

export default targetModel;
