import * as types from "./mutations-types"
export default {
  getremaining(state, remaining) {
    state.remaining = remaining
  },
  getcountnum(state, countnum) {
    state.countnum = countnum
  },
  getcurrentSeletedOptionId(state, currentSeletedOptionId) {
    state.currentSeletedOptionId = currentSeletedOptionId
  },
  getboxrightnullshow(state, boxrightnullshow) {
    state.boxrightnullshow = boxrightnullshow
  },
  getboxrightfullshow(state, boxrightfullshow) {
    state.boxrightfullshow = boxrightfullshow
  },
  getboxcenternullshow(state, boxcenternullshow) {
    state.boxcenternullshow = boxcenternullshow
  },
  getboxcenterfullshow(state, boxcenterfullshow) {
    state.boxcenterfullshow = boxcenterfullshow
  },
  getcustomer(state, customer) {
    state.customer = customer
  },
  getcontact(state, contact) {
    state.contact = contact
  },
  getisactive(state, isactive) {
    state.isactive = isactive
  },
  getswicthtablebal(state, swicthtablebal) {
    state.swicthtablebal = swicthtablebal
  },
  getlabelName(state, labelName) {
    state.labelName = labelName
  },
  getfilelistobjid(state, filelistobjid) {
    state.filelistobjid = filelistobjid
  },
  getchecked1(state, checked1) {
    state.checked1 = checked1
  },
  getchecked2(state, checked2) {
    state.checked2 = checked2
  },
  getchecked3(state, checked3) {
    state.checked3 = checked3
  },
  getchecked4(state, checked4) {
    state.checked4 = checked4
  },
  getdefaultselect(state, defaultselect) {
    state.defaultselect = defaultselect
  },
  getselectlength(state, selectlength) {
    state.selectlength = selectlength
  },
  getupfile(state, file) {
    state.file = file
  },
  getcommitdata(state, commitdata) {
    state.commitdata = commitdata
  },
  getmatcharrlength(state, matcharrlength) {
    state.matcharrlength = matcharrlength
  },
  getcsvfiledlength(state, csvfiledlength) {
    state.csvfiledlength = csvfiledlength
  },
  getid(state, id) {
    state.id = id
  },
  getcurdata(state, curdata) {
    state.curdata = curdata
  },
  getcloudccoption(state, cloudccoption) {
    state.cloudccoption = cloudccoption
  },
  getnumlimit(state, numlimit) {
    state.numlimit = numlimit
  },
  getfileObj(state, filename) {
    state.filename = filename
  },

  setDropdownData(state, data) {
    state.dropdownData = data;
  },
  // 保存到vuex中的请求参数(运行到编辑,编辑到运行)
  [types.SET_REPORTINFOS](state, data) {
    state.reportInfos = data;
    sessionStorage.setItem("reportInfos", JSON.stringify(data))
  },
  setToken(state, data) {
    sessionStorage.setItem('token', data);
    state.token = data;
  },
  [types.IS_RUNPAGE_TO_EDITPAGE](state, status) {
    state.isRunpageToEditpage = status
  },
  //从列表页到报表页携带id请求时返回的数据
  [types.SET_RUNREPORTABCD](state, data) {
    state.runReportABCD = data
    sessionStorage.setItem('runReportABCD', JSON.stringify(data));

  },
  // 保存编辑页首次从列表页进来时的ID(保存时判断用)
  [types.SET_EDITPAGE_FIRST_ID](state, id) {
    state.editPageFirstEnterId = id
    sessionStorage.setItem("editPageFirstEnterId", id)
  },
  // 点击dom时，关闭页面的所有框
  [types.SET_KEEPOPENKUANG](state, status) {
    state.keepOpenKuang = status
  },
  // 保存当前选择的筛选信息到strore(运行和编辑页使用)
  [types.SAVE_CHOOSEDTIMERANGES](state, data) {
    state.choosedTimeRanges = data
  },
  //保存临时的toid，用于在保存报表时使用，给接口传递该ID才能正常保存报表
  [types.SAVE_TEMP_REPORTTYPECUSTOID](state, id) {
    state.tempReporttypecustomid = id
    sessionStorage.setItem("tempReporttypecustomid", id)
  },
  //报表名字（编辑页和运行页的报表名称）
  [types.SET_REPORT_NAME](state, reportName) {
    state.reportName = reportName
    sessionStorage.setItem("reportName", reportName)
  },
  //存储菜单名称
  [types.SET_TAB_NAME](state, tabName) {
    state.tabName = tabName;
    sessionStorage.setItem("tabName", tabName)

  },
  // 存储报表权限信息
  setReportOperation(state, data) {
    state.reportOperation = data;
  },
  // 存储个人币种字段
  setCurrencyCode(state, data) {
    state.currencyCode = data;
  },
  // 存储仪表板筛选项配置的筛选条件
  setDashboardCondition(state, data) {
    // 清空数据
    state.dashboardCondition = {
      dashboardConditionTimeArr: [],
      dashboardConditionPersonArr: [],
    }
    // 将返回的数据user和time类型的区分开
    data.map((item) => {
      if (item.type === "user") {
        // 人员
        state.dashboardCondition.dashboardConditionPersonArr.push(item);
      } else if (item.type === "time") {
        // 时间
        state.dashboardCondition.dashboardConditionTimeArr.push(item);
      }
    })
  },
  //保存当前登录的用户信息（APP中请求）
  [types.SET_UESR_INFO](state, payload) {
    state.userInfoObj = payload
    sessionStorage.setItem(types.SET_UESR_INFO, payload)

    // 存储到cookies中全局共享
    window.$CCDK.CCUser.setUserInfo(payload)
  },
  // 在点击关系网之前，保存当前的url，然后跳转到关系网，在关系网的面包屑中做返回到改对象使用
  [types.SET_STRUCTURE_BEFORE_CLICK_SAVE_PATH](state, payload) {
    state.structureBeforeClickSavePath = payload
    sessionStorage.setItem("structureBeforeClickSavePath", payload)
  },
  // VueDropboxPicker组件是否加载
  setDropbox(state, data) {
    state.VueDropboxPicker = data
  },
  //lightning设置页 右侧 开始设置跳转地址路径 保存当前环境域名
  setLightningDomainStr(state, domainStr) {
    state.lightningDomainStr = domainStr
  },
  // 存储当前应用名称
  setNavigationStyle(state, data) {
    // 等于0为控制台样式 true
    state.navigationStyle = data == '0'
    sessionStorage.setItem("navigationStyle", data)
  },
  // 存储新版控制台数据
  setConsoleData(state, data) {
    // 创建一个新的对象，将data,state.consoleData对象复制到新对象中
    let resData = Object.assign({}, data);
    // 一级菜单集合
    let level1TabList = resData.data.list
    level1TabList.length > 0 &&
      level1TabList.forEach((item) => {
        // 给一级菜单天价tabAction
        // 如果没有tabAction这个字段  那么初始化加上原有的4项操作项
        if (!item.tabAction) {
          if (item.isfixed) {
            // 固定选项卡
            item.tabAction = [
              {
                name: "刷新选项卡",
                id: "refresh",
              },
              {
                name: "取消固定选项卡",
                id: "cancelFixed",
              },
              {
                name: "自定义选项卡",
                id: "custom",
              },
              {
                name: "关闭选项卡",
                id: "close",
              },
            ];
          } else {
            // 非固定选项卡
            item.tabAction = [
              {
                name: "刷新选项卡",
                id: "refresh",
              },
              {
                name: "固定选项卡",
                id: "fixed",
              },
              {
                name: "自定义选项卡",
                id: "custom",
              },
              {
                name: "关闭选项卡",
                id: "close",
              },
            ];
          }
        } else {
          // 如果已经有tabAction操作项了
          // 如果tabAction有固定或者取消固定选项卡  点击之后操作项要相应改变成取消固定或固定选项卡
          if (item.tabAction.length > 0) {
            let idx = item.tabAction.findIndex(actionItem => {
              return actionItem.id == 'fixed' || actionItem.id == 'cancelFixed'
            })
            if (idx != -1) {
              if (item.isfixed) {
                item.tabAction[idx] = {
                  name: "取消固定选项卡",
                  id: "cancelFixed"
                }
              } else {
                item.tabAction[idx] = {
                  name: "固定选项卡",
                  id: "fixed"
                }
              }
            }
          }
        }
        // 给二级菜单添加tabAction
        if (item.children && item.children.length > 0) {
          item.children.forEach((i) => {
            // 如果没有tabAction ] 那么默认以下四项
            if (!i.tabAction) {
              // 处理二级tab下的操作tab的下拉
              i.tabAction = [
                {
                  name: "刷新选项卡",
                  id: "refresh",
                },
                {
                  name: "自定义选项卡",
                  id: "custom",
                },
                {
                  name: "设置为工作区选项卡",
                  id: "setToLevel1",
                },
                {
                  name: "关闭选项卡",
                  id: "close",
                },
              ];
            }
          });
        }
      });
    // 将state.consoleData指向新对象的引用地址 解决consoleData变了 但是没有触发computed中的mapState（原因是state.consoleData取的data的引用）
    resData.data.list.forEach(menuItem => {
      if (menuItem.id == resData.data.level1Id) {
        localStorage.setItem('current_page', JSON.stringify(menuItem))
      }
    })
    state.consoleData = resData
    // sessionStorage.setItem("consoleData",JSON.stringify(resData))
  }
}
