// 原生js复制到粘贴板
export function copyText(data) {
    navigator.clipboard.writeText(data)
        .then(() => {
            // 复制成功
            this.$message.success(this.$i18n.t('label.copy.success'))
        })
        .catch(err => {
            // 不支持复制
            this.$message.warning(this.$i18n.t('front-dialog-module-v1-nosupport-copy'), err)
        });
}