<!-- 查找/查找多选弹框 -->
<template>
  <div class="seachTableBox">
    <!-- 查找条件 -->
    <base-form
      ref="baseForm"
      :form-attr="formAttr"
      :label-width="labelWidth"
      v-if="formAttr && formAttr.length > 0"
    >
    </base-form>
    <!-- 无搜索条件时展示模糊搜索框 -->
    <el-input v-else v-model="searchKeyWord" class="searchKeyWord-input">
    </el-input>
    <!-- 按钮 -->
    <div style="margin-bottom: 10px">
      <el-button size="mini" type="primary" @click="search">
        <!-- 搜索 -->
        {{ $t("label.searchs") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        @click="clearSelect"
        v-if="isShowClearBtn"
      >
        <!-- 清空 -->
        {{ $t("label_emailtocloudcc_clear") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-if="checked"
        @click="selectChecked"
      >
        <!-- 选择 -->
        {{ $t("label.select") }}
      </el-button>
      <el-button
        size="mini"
        type="primary"
        v-if="
          relevantObjid !== 'user' &&
          relevantObjid !== 'marketsea' &&
          isquickcreated
        "
        @click="create"
      >
        <!-- 新建 -->
        {{ $t("label.new") }}
      </el-button>
    </div>
    <!-- 列表 -->
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :checked="checked"
      :pict-loading="pictLoading"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :use-hyperlinks="false"
      :backchecked="checkedArr"
      :isClickCell="false"
      @append="append"
      @selectData="select"
      @allRow="allRow"
    >
    </table-panel>
  </div>
</template>

<script>
import { TablePanel } from "@/components/index";
import BaseForm from "./base-form";
import * as CommonObjApi from "./api";
import INPUTTYPE from "@/config/enumCode/searchTableInputType";

export default {
  name: "SearchTable",
  components: { TablePanel, BaseForm },
  props: {
    // 查找字段数据
    relevantObj: {
      type: Object,
      default: () => ({}),
    },
    dialogBodyHeight: {
      type: String,
      default: "500px",
    },
    // 新建发票时，对应的信息
    newInvoiceInfo: {
      type: Object,
      default: () => {},
    },
    // 查找字段的id
    fieldId: {
      type: String,
      default: "",
    },
    // 用户类型
    ownerType: {
      type: String,
      default: "",
    },
    // 查找对象的id
    relevantObjid: {
      type: String,
      default: "",
    },
    // 查找对象的api
    relevantObjapi: {
      type: String,
      default: "",
    },
    // 查找对象的前缀
    relevantPrefix: {
      type: String,
      default: "",
    },
    // 查找的数据是否支持多选
    checked: {
      type: Boolean,
      default: false,
    },
    // 主从录入时 记录当前数据所在行数用
    ind: {
      type: Number,
      default: 0,
    },
    projectId: {
      type: String,
      default: "",
    },
    milestone: {
      type: String,
      default: "",
    },
    relevantmodelid: {
      type: String,
      default: "",
    },
    //是否显示清空按钮
    isShowClearBtn: {
      type: Boolean,
      default: true,
    },
    objconid: {
      type: String,
      default: "",
    },
    isKnowledge: {
      type: String,
      default: "",
    },
    // 是否来自视图新建、编辑
    fromTableView: {
      type: Boolean,
      default: false,
    },
    //存储回显选中的数据
    optionCheckedArr: {
      type: Array,
      default: () => [],
    },
    //表单类型
    remoType: {
      type: String,
      default: "",
    },
    taskOrquestion: {
      type: String,
      default: "",
    },
    vendorid: {
      type: String,
      default: "",
    },
    //潜客转换获取选中客户下的联系人与业务机会数据
    customerId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      prefix: "",
      objectApi: "",
      tableHeight: 400,
      objId: this.$route?.params.objId,
      tableAttr: null,
      pageObj: {
        dataList: [],
      },
      pictLoading: false,
      noDataTip: false,
      isSLookup: null,
      formAttr: null,
      labelWidth: 150,
      page: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      searchKeyWord: "",
      selectValue: "",
      searchConditions: "",
      filterConditionVals: [""],
      recordType: "",
      recordOptions: [],
      level: 0,
      isquickcreated: false, // 是否支持快速新建
      checkedArr: [], // 表格分页/搜索前选中数据.缓存数据用
    };
  },
  created() {
    // 需要在捕获阶段响应
    window.addEventListener("keyup", this.keyupSearch, true);
  },
  destroyed() {
    window.removeEventListener("keyup", this.keyupSearch);
  },
  beforeDestroy() {
    window.removeEventListener("storage", this.listenStorageFn);
  },
  methods: {
    /**
     *
     * 重置tableHeight高度
     */
    resetTableHeight() {
      // 获取baseForm的高度，如果没有默认为40
      const tableHeight =
        this.$refs.baseForm?.$el.clientHeight > 40
          ? this.$refs.baseForm?.$el.clientHeight
          : 40;
      // 搜索弹窗整体高度为this.dialogBodyHeight，按钮高度为28
      const tabHeight = parseFloat(this.dialogBodyHeight) - tableHeight - 28;
      this.tableHeight = tabHeight > 400 ? tabHeight : 400;
    },
    keyupSearch(code = {}) {
      // 需要阻止事件向上传播，防止触发列表组件中内联编辑中的keyup监听事件
      code.stopPropagation();
      if (code.keyCode === 13) {
        this.search();
      }
    },
    handleInvoice(rows, array) {
      if (!this.newInvoiceInfo.isOrderPage) {
        CommonObjApi.isSameCustomer({ orderid: array.join(",") }).then(
          (res) => {
            if (res.result) {
              CommonObjApi.isSameCurencyAndPriceBook({
                orderid: array.join(","),
              }).then((result) => {
                if (result.result) {
                  rows.relaInfo = result.data;
                  this.$emit("changeSelect", rows);
                }
              });
            }
          }
        );
      } else {
        CommonObjApi.isSameCurencyAndPriceBook({
          orderid: array.join(","),
        }).then((res) => {
          if (res.result) {
            this.$emit("changeSelect", rows);
          }
        });
      }
    },
    allRow(val) {
      this.checkedArr = val;
    },
    /**
     *
     * @param {*} searchValueText :快速新建搜索参数
     */
    init(searchValueText) {
      this.page = 1;
      this.formAttr = [];
      this.searchKeyWord = "";
      this.pageObj.dataList = [];

      // 重置level
      this.level =
        this.$route?.params.relevantObjectLevel === undefined
          ? 0
          : Number(this.$route?.params.relevantObjectLevel) + 1;
      this.listenStorage();

      CommonObjApi.getLookupInfo({
        fieldId: this.fieldId,
        objId: this.relevantObjid,
        prefix: this.relevantPrefix,
      }).then((res) => {
        this.objectApi = res.data.objectApi;
        this.prefix = res.data.prefix;
        // 更改快速新建的权限字段
        this.isquickcreated = res.data.isquickcreated === "true";
        // window.Glod.DDE_CREATE为1，新建权限不用再判断是否设置了筛选条件
        // 设置了筛选条件的字段不能新建
        if (
          this.relevantObj?.isenable_filter === "true" &&
          window.Glod.DDE_CREATE !== "1"
        ) {
          this.isquickcreated = false;
        }

        // 开启查找筛选
        if (res.data.isEnableFilter === "true") {
          this.filterConditionVals = [""];
          this.$emit("setFieldReltaion", res.data.filterFieldIds);
        }
        this.isSLookup = res.data.isSLookup;
        if (res.data.Fields) {
          this.getFormAttr(res.data.Fields, searchValueText);
        } else {
          this.formAttr = [];
          // 单条件搜索赋值
          if (searchValueText) {
            this.searchKeyWord = searchValueText;
          }
        }
        // 重置表格高度
        this.$nextTick(() => {
          this.resetTableHeight();
        });
        this.getLookupData();
      });
    },
    // 搜索
    search() {
      this.page = 1;
      this.pageObj.dataList = [];
      let obj = {};
      this.formAttr &&
        this.formAttr.length > 0 &&
        this.formAttr.forEach((item) => {
          obj[item.prop] = this.$refs.baseForm?.formData[item.prop];
        });
      /**
       * 只有一个查询条件嘞情况下
       * 接口需要传 searchKeyWord 值为当前搜索的 keyword
       * 其他情况下，还是用原来的字段传递
       */
      if (Object.keys(obj)?.length === 1) {
        Object.keys(obj).forEach((key) => {
          this.searchKeyWord = obj[key];
        });
      }
      this.searchConditions = JSON.stringify(obj);
      this.getLookupData();
    },
    // 滚动加载数据
    append() {
      if (!this.noDataTip) {
        this.getLookupData();
      }
    },
    getCookie(name) {
      var prefix = name + "=";
      var start = document.cookie.indexOf(prefix);
      if (start == -1) {
        return null;
      }
      var end = document.cookie.indexOf(";", start + prefix.length);
      if (end == -1) {
        end = document.cookie.length;
      }
      var value = document.cookie.substring(start + prefix.length, end);
      return unescape(value);
    },
    // 获取查找数据
    getLookupData(item) {
      if (item && item == "search") {
        this.page = 1;
      }
      if (this.page === 1) {
        this.tableAttr = null;
      }
      this.pictLoading = true;

      /**
       * fieldId	查找字段Id
       * prefix 对象prefix
       * objId 对象id
       * isSLookup 查询方式，true为多条件搜索，false为单条件搜索
       * searchKeyWord 单条件搜索时搜索关键字
       * page 页数
       * pageSize 每页显示条数
       * selectValue 更改所有人时区分用户与伙伴云用户，用户为user,伙伴云用户为partneruser
       * searchConditions 多条件搜索时搜索参数集合,数据格式为{“字段id1”=”value1”,”字段id2”=”value2”}
       * filterConditionVals 查找筛选器参数集合，数据格式为：[“001wdwsjjisss22”,”张三”,”李四”],第一个参数如果有值就传，没值的话传””,后面参数依次按照filterFields字段顺序获取
       * customerId 潜客转换获取选中客户下的联系人与业务机会数据
       */
      let params = {
        fieldId: this.fieldId,
        prefix: this.prefix || this.relevantPrefix,
        objId: this.relevantObjid,
        modelid: this.relevantmodelid,
        objconid: this.objconid,
        isSLookup: this.isSLookup,
        searchKeyWord: this.searchKeyWord,
        page: this.page,
        pageSize: this.pageSize,
        selectedValue: this.ownerType,
        searchConditions: this.searchConditions,
        filterConditionVals:
          this.filterConditionVals === ""
            ? this.filterConditionVals
            : JSON.stringify(this.filterConditionVals),
        isKnowledge: this.isKnowledge,
        parentId: this.taskOrquestion ? this.taskOrquestion : "",
        customerId: this.customerId,
      };
      //在项目管理系统下对负责人所属项目的判断
      if (
        ((window.location.href.substring(window.location.href.length - 3) ==
          "p03" ||
          this.getCookie("parentObjectApi") == "cloudccTask") &&
          this.milestone == "their_milestone") ||
        (this.getCookie("parentObjectApi") == "cloudccTask" &&
          this.milestone == "their_task")
      ) {
        params.projectId = "";
      } else if (
        (window.location.href.substring(window.location.href.length - 3) ==
          "p05" ||
          this.getCookie("parentObjectApi") == "cloudccSubtask") &&
        this.milestone == "their_task"
      ) {
        params.projectId = "";
      } else {
        params.projectId = this.projectId;
      }
      params.filterConditionVals =
        params.filterConditionVals === "[null]"
          ? '[""]'
          : params.filterConditionVals;

      // 从视图新建编辑页面进来，且为用户时，添加过滤条件
      if (this.fromTableView) {
        if (this.prefix === "005" || this.relevantPrefix === "005") {
          params.isAdvSearch = "true";
        }
      }
      if (this.vendorid) {
        params.vendorid = this.vendorid;
      }
      // 如果是新建发票页面，并且选择了客户 进行订单筛选
      if (
        this.newInvoiceInfo &&
        this.newInvoiceInfo.prefix === "021" &&
        this.fieldId === "ffe2021theinvoices01"
      ) {
        params.isFilterOrder = "true";
        params.orderFilterCondition = "invoiceStatus";
      }
      CommonObjApi.getLookupData(params).then((res) => {
        if (res.result && res.returnCode === "1") {
          // 页码为1时重新请求表头
          if (this.page === 1) {
            this.tableAttr = res.data.headLabel;
            this.tableAttr.forEach((item) => {
              // 名称、查找、主详、查找多选均可跳转
              if (
                item.schemefieldName === "name" ||
                item.schemefieldType === "Y" ||
                item.schemefieldType === "M" ||
                item.schemefieldType === "MR"
              ) {
                item.click = "selectData";
              }
            });
            this.$refs.tablePanel?.changeTableAttr(this.tableAttr);
          }

          this.totalSize = res.data.totalCount;
          if (res.data.dataList.length === 0 && params.page === 1) {
            this.pageObj.dataList = [];
          } else if (this.pageObj.dataList.length === 0 || params.page === 1) {
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = res.data.dataList.slice(
                0,
                res.data.dataList.length - 1
              );
            } else {
              this.pageObj.dataList = res.data.dataList;
            }
            if (this.optionCheckedArr.length > 0) {
              this.pageObj.dataList.forEach((element) => {
                this.checkedArr.forEach((item) => {
                  if (item.id === element.id) {
                    element.checked = true;
                  }
                });
              });
              // }
              if (this.pageObj !== undefined) {
                this.$refs.tablePanel.dataList = this.pageObj.dataList;
              }
            } else {
              this.pageObj.dataList.forEach((element) => {
                this.checkedArr.forEach((item) => {
                  if (item.id === element.id) {
                    element.checked = true;
                  }
                });
              });
              if (this.$refs.tablePanel && this.$refs.tablePanel.dataList) {
                this.$refs.tablePanel.dataList = this.pageObj.dataList;
              }
            }
            this.page++;
          } else {
            // 记忆选中数据
            let checkedList = this.checkedArr;
            if (res.data.dataList.length > this.pageSize) {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList.slice(0, res.data.dataList.length - 1),
              ];
            } else {
              this.pageObj.dataList = [
                ...this.pageObj.dataList,
                ...res.data.dataList,
              ];
            }
            this.pageObj.dataList.forEach((element) => {
              checkedList.forEach((item) => {
                if (item.id === element.id) {
                  element.checked = true;
                }
              });
            });
            this.page++;
            this.$refs.tablePanel.dataList = this.pageObj.dataList;
          }
          // 判断是否有更多数据
          if (Number(res.data.dataList.length) === this.pageSize) {
            this.noDataTip = false;
          } else {
            this.noDataTip = true;
          }
          this.pictLoading = false;
          this.pageObj.totalSize = res.data.totalCount;
        } else {
          this.pictLoading = false;
          this.tableAttr = [];
          this.pageObj.totalSize = 0;
          this.pageObj.dataList = [];
          this.$message.error(res.stackTrace);
        }
      });
    },
    getFormAttr(attrs, searchValueText) {
      this.formAttr = [];
      attrs.forEach((item) => {
        item.prop = item.id;
        this.$set(item, "width", "50%");
        this.$set(item, "readonly", false);
        if (item.type === "Y" || item.type === "MR") {
          this.$set(item, "type", "input");
        } else {
          this.$set(item, "type", INPUTTYPE[item.type]);
        }
        // 百分数字段字数长度限制
        if (item.type === "number") {
          // item.precision = Number(item.decimalPlaces);
          item.valueInterval = {
            min: 0 - Math.pow(10, 18) + 1,
            max: Math.pow(10, 18) - 1,
          };
        }
        if (item.type === "select" || item.type === "multi-select") {
          item.options = [];
          item.selectOptions &&
            item.selectOptions.forEach((select) => {
              item.options.push({
                key: select.label,
                val: select.value,
              });
            });
        }
        // 如果是报价单明细，特殊处理
        if (item.name === "bjdmc") {
          item.type = "input";
        }
        // name字段赋值父组件传进来的搜索值
        if (item.name === "name" && searchValueText) {
          item.value = searchValueText;
        }
        this.formAttr.push(item);
      });
    },
    // 选中数据(单选情况)
    select(row) {
      // 只有单选才走select方法，多选走selectChecked方法
      if (this.checked) return;
      // 主详字段需要判断是否是锁定状态
      if (this.remoType === "M") {
        let params = {
          id: row.data.id,
          isProject: "false",
        };
        if (
          row.field.schemetableId == "cloudcc_project" ||
          row.field.schemetableId == "cloudccMilestone" ||
          row.field.schemetableId == "cloudccTask"
        ) {
          params.isProject = "true";
        }
        CommonObjApi.getPermissionById(params).then((res) => {
          if (res.data.isEdit) {
            this.$emit("changeSelect", row);
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("label.no.edit.permission"), //无所选记录编辑权限，不可选择
            });
          }
        });
      } else {
        if (
          this.newInvoiceInfo &&
          this.newInvoiceInfo.prefix === "021" &&
          this.fieldId === "ffe2021theinvoices01"
        ) {
          let array = [];
          array.push(row.data.id);
          this.handleInvoice(row, array);
        } else {
          this.$emit("changeSelect", row);
        }
      }
    },
    // 多行数据
    selectChecked() {
      let rows = this.checkedArr;
      if (rows.length > 0) {
        if (
          this.newInvoiceInfo &&
          this.newInvoiceInfo.prefix === "021" &&
          this.fieldId === "ffe2021theinvoices01"
        ) {
          let array = [];
          rows.forEach((list) => {
            array.push(list.id);
          });
          this.handleInvoice(rows, array);
        } else {
          this.$emit("changeSelect", rows);
        }
      } else {
        // 请选择要添加的数据
        this.$message.warning(
          this.$i18n.t("vue_label_commonobject_view_notice_selectadd")
        );
      }
    },
    // 新建数据
    create() {
      // 传参：对象前缀、对象api
      let objApi = this.relevantObjapi ? this.relevantObjapi : this.objectApi;
      // window.open(
      //   `#/add-relevant-obj/${this.relevantPrefix}/${objApi}/${this.level}`
      // );
      // 使用ccdk弹窗方式打开快速新建
      this.$CCDK.CCPage.openCreatePage(
        {
          objectName: this.$i18n.t("label.new"),
          objId: this.relevantObjid,
          objectApi: objApi,
          prefix: this.relevantPrefix,
          isTableEdit: true,
        },
        {
          openPlace: "dialog",
          openMode: "_blank",
        }
      );
    },
    // 清空选中数据
    clearSelect() {
      if (this.$refs.tablePanel && this.$refs.tablePanel.checkedList) {
        this.$refs.tablePanel.checkedList = [];
      }
      // 需求更改，搜索弹窗的清空不清空查找字段的值，改为清空搜索条件（产品：呼富军，bug：2933）
      // this.$emit("changeSelect", []);
      // 清空单条件
      this.searchKeyWord = "";
      // 清空多条件
      if (Array.isArray(this.formAttr)) {
        this.formAttr.forEach((item) => {
          item.value = "";
        });
        this.formAttr = [...this.formAttr];
      }
    },
    cancel() {
      this.$emit("cancel");
    },
    listenStorage() {
      window.addEventListener("storage", this.listenStorageFn);
    },
    // 监听relevantObjectLevel变化
    listenStorageFn(event) {
      if (event.key === "relevantObjectLevel") {
        let objectApi = localStorage.getItem("relevantObjectApi");
        let level = localStorage.getItem("relevantObjectLevel");
        if (level - 1 === this.level && objectApi === this.objectApi) {
          this.page = 1;
          this.pageObj.dataList = [];
          this.getLookupData();
          localStorage.setItem(
            "relevantObjectLevel",
            Number(localStorage.getItem("relevantObjectLevel")) - 1
          );
        }
      }
    },
  },
  watch: {
    /**
     * 监听浏览器窗口高度变化，改变表格高度
     * @param {*} nval
     * @param {*} oval
     */
    dialogBodyHeight: {
      handler: function () {
        this.resetTableHeight();
      },
      immediate: true,
    },
    recordType(nval, oval) {
      this.$nextTick(() => {
        this.pageObj.dataList = [];
        this.getLookupInfo();
      });
    },
    optionCheckedArr: {
      handler() {
        this.checkedArr = this.optionCheckedArr;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.seachTableBox {
  height: 100%;
}
.searchKeyWord-input {
  width: 50%;
  margin-bottom: 10px;
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
