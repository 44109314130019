/**
 * 项目任务路由
 */
 const projectTasks = [
    {
        name: "projectTasks",
        path: "/projectTasks",
        component: () => import("@/views/projectTasks"),
        // name: " 项目任务首页",
      },
  ]
  
  export default projectTasks;
  