/**
 * 文件对象路由文件
 */
const file = [
  {
    path: "ccFileCenter",
    redirect: () => "/ccFileCenter/ccFileList",
    name: "文件",
  },
  {
    path: "ccFileCenter/ccFileList",
    component: () => import("@/views/ccFileCenter/ccFileList.vue"),
    name: "文件列表页",
  },
  {
    path: "ccFileCenter/fileDetail",
    component: () => import("@/views/ccFileCenter/fileDetail.vue"),
    name: "文件详情",
  },
  {
    path: "ccFileCenter/detailList",
    component: () => import("@/views/ccFileCenter/detailList.vue"),
    name: "文件详情列表",
  },
];

export default file;
