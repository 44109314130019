

const projectManage = {
  state: {
    relationResource:null, //资源使用新建任务带值
  },
  mutations: {
    updateRelationResource(state, relationResource) {
        state.relationResource = relationResource;
    }
  },
  getters: {},
  actions: {}
}
export default projectManage;