import Vue from "vue";
import store from "./store";
import App from "./App.vue";
import router from "./router";
import WebIM from "./utils/WebIM";
import i18n from '@/utils/i18n'; //多语言
import utils from "@/utils/utils";
import SearchTable from "@/components/Form/search-table.vue";// 全局引入查找弹窗组件，解决组件循环引用问题
Vue.component("SearchTable", SearchTable)
import rewriteCCRender from './components/RewriteCCRender/index.js' // 二次封装cc-render
import CcCheckbox from '@/components/CcCheckbox/index.js' //   list视图列表表格
import CcTableColumn from '@/components/OldTable/table-column/index.js' //   list视图列表表格
import CcTable from '@/components/OldTable/table/index.js' //   list视图列表表格所用的checkbox
import devHttp from "@/config/httpConfig"; // 注册devconsole的http配置
import { preventReClick } from '@/config/preventReClick.js'

import { message } from "@/utils/resetMessage";
import '@/style/cloudccStyle.scss';
import ElementUI from 'element-ui';
import packageManage from "@/utils/packageManage";//第三方包管理
import "./assets/images/active/font/iconfont.css";
import "./assets/font/iconfont.css";
if (Vue && !window.Vue) {
  window.Vue = Vue
}
// 共用库
import "cloudcc-ccdk/lib/ccdk.min.js";

Vue.prototype.$devHttp = devHttp
Vue.prototype.$utils = utils
Vue.prototype.$Components = new Map();
Vue.prototype.$Bus = new Vue();
Vue.prototype.$WebIM = WebIM;
Vue.prototype.$store = store;
// 解决ccdk打开新建保存之后 跳转路由$router为undefined的问题
Vue.prototype.$router$router = router;
Vue.prototype.$imconn = WebIM.conn;
Vue.prototype.$bus = new Vue();//全局事件总线对象
Vue.prototype.$baseConfig = window.Glod;
Vue.prototype.$baseConfig.baseURL = window.Glod['ccex-apitsf'] + "/api"
Vue.use(rewriteCCRender)
Vue.use(ElementUI, {
  zIndex: 3000,
  i18n: ((key, value) => i18n.t(key, value))
});

Vue.use(CcCheckbox)
Vue.use(CcTableColumn)
Vue.use(CcTable)
Vue.prototype.$message = message; //重写message弹窗口，防止多次提示  必须放在Vue.use(ElementUI)后才能覆盖默认的
Vue.use(preventReClick)
Vue.use(packageManage)
Vue.config.productionTip = false;
import ErrorDialog from '@/views/ErrorDialog/errorDialog.js' // 全局报错弹框
Vue.prototype.$ErrorDialog = ErrorDialog.install
// 监听性能缓存池是否满了，如果满了，那么清空
if (window.performance) {
  performance.addEventListener(
    "resourcetimingbufferfull", () => {
      performance.clearResourceTimings()
    }
  );
}

// 提供cct多语言转换方法
Vue.prototype.$cct = (value) => {
  return i18n.t(value);
};
new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#lightningapp");


