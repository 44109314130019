/**
 * 伙伴账户对象路由文件
 */
const partnerAccount = [
  {
    path: "partnerAccount/detail",
    component: () => import("@/views/partnerAccount/index.vue"),
    name: "伙伴账户详情页",
  }
];

export default partnerAccount;
