var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.response &&
    _vm.response.data &&
    _vm.response.data.returnInfo &&
    // info中包含这个消息得都不弹，接口超时
    _vm.response.data.returnInfo.indexOf('Cannot get a connection') === -1 &&
    // 服务重启，不弹
    _vm.response.data.returnCode != "11-300-APICallFailed"
  )?_c('el-dialog',{staticClass:"error-dialog",attrs:{"title":_vm.$t('label_popup_infoz'),"visible":_vm.errorDialogVisible,"width":"30%","close-on-click-modal":false},on:{"update:visible":function($event){_vm.errorDialogVisible=$event}}},[_c('div',{staticClass:"errorInfo",staticStyle:{"font-size":"14px","color":"#080707"}},[_c('p',{staticClass:"auto-wrap"},[_vm._v(_vm._s(_vm.$t(_vm.handleInfo(_vm.response))))]),_c('div',{staticClass:"error-detail"},[_c('span',[_vm._v(_vm._s(_vm.$t("front-dialog-module-v1-error-detail")))]),_c('span',{class:_vm.isOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down',staticStyle:{"cursor":"pointer"},on:{"click":function($event){_vm.isOpen = !_vm.isOpen}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.isOpen),expression:"isOpen"}],staticStyle:{"color":"#666666"}},[_c('div',[_c('span',[_vm._v(_vm._s(_vm.$t("front-dialog-module-v1-error-code")))]),_vm._v(" "+_vm._s(_vm.handleCode(_vm.response))+" ")]),_c('div',{staticClass:"labelId"},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("front-dialog-module-v1-requestid")))]),_c('span',{staticClass:"detail"},[_vm._v(" "+_vm._s(_vm.handleRequeseId(_vm.response))+" ")]),_c('span',{staticClass:"el-icon-copy-document copy-btn",on:{"click":function($event){_vm.copyText(_vm.handleRequeseId(_vm.response))}}})])])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.errorDialogVisible = false}}},[_vm._v(" "+_vm._s(_vm.$t("label.cancel"))+" ")])],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }