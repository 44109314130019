// import instance from "./http"
import axios from "axios"
const HOSTNAME = 'https://testdev.cloudcc.com/'
import instance from '@/config/httpConfig'

//所有的请求
const job = {
    //获取群策开关状态
    getQunceState(){
        return instance.post('easemobim/getGroupSwitch')
    },
    //获取环信token
    getToken(){
        return instance.post('easemobim/getImToken')
    },
    //获取环信登录账号和密码
    login() {
        return instance.post('easemobim/getImUser')
    },
    //搜索用户群组或群策
    searchUser(searchKey, type, pageNum, pageSize,objId) {
        return instance.post('easemobim/searchUserAndImGroupList', { searchKey: searchKey, type: type, pageNum: pageNum, pageSize: pageSize,objid:objId })
    },
    //用户头像   
    seachHeaderImg(id) {
        return axios.get(`${HOSTNAME}distributor.action?serviceName=showChatterImage&type=user&id=${id}&binding=${localStorage.getItem('binding')}`)
    },
    //新增群组
    setNewGroup(id, name, timestamp, members) {
        return instance.post(`easemobim/group/addEasemobImGroup`, { id: id, name: name, isPublic: false, allowinvites: false, membersonly: false, timestamp: timestamp, members: members })

    },
     //新增群策群组
    setNewGroupSta(id, name, timestamp, members,recordId) {
        return instance.post(`easemobim/group/addEasemobImGroup`, { id: id, name: name, isPublic: true, allowinvites: false, membersonly: false, timestamp: timestamp, members: members,recordId:recordId })
    },
    //获取环信对应用户信息
    getUserInformation(userId) {
        return instance.post(`easemobim/getUserInfoByImIds`, { ids: userId })
    },
    //邀请成员加入群聊
    addgroupmember(groupId, userIds, memberType, timestamp) {
        return instance.post(`easemobim/group/member/addMemberToEasemobImGroup`, { groupId: groupId, userIds: userIds, memberType: memberType, timestamp: timestamp })
    },

    //获取群组信息
    getgroupmessage(id) {
        return instance.post(`easemobim/group/getEasemobImGroupInfo`, { id: id })
    },
    //获取多个群组信息
    getgroupmessages(id) {
        return instance.post(`easemobim/group/getEasemobImGroupInfos`, { id: id })
    },
    //更新群组信息
    updateGroupDate(groupId, groupName, timestamp) {
        return instance.post(`easemobim/group/updateEasemobImGroup`, { id: groupId, name: groupName, description: '', allowinvites: false, membersonly: false, timestamp: timestamp })
    },
    //获取群策用户ID
    getStrategyMember(groupId) {
        return instance.post(`easemobim/group/getEasemobImGroupType`, { ids: groupId })
    },
    //判断客户是否存在群策
    judgeIsHasStrategy(recordId){
        return instance.post(`easemobim/group/getEasemobImStrategyGroup`,{recordId:recordId})
    },
    //删除群组成员
    deleteGroupMember(groupId,userIds){
        return instance.post(`easemobim/group/member/deleteMemberToEasemobImGroup`, {groupId: groupId,userIds:userIds })
    },
     //开通群策用户
     createImUser(ids){
        return instance.post(`easemobim/createImUser`, {ids:ids})
    },
    //更改是否开通群策用户状态
    changeUserIsteam(ids,isteam){
        return instance.post(`easemobim/changeUserIsteam`, {ids:ids,isteam:isteam})
    },
    //更改是否开通群策用户状态
    getUserInfoList(option){
        return instance.post(`easemobim/getUserInfoList`, option)
    },
    //获取域名
    geturl(data){
        return instance.post('report/getExportReportURL',data)
    }
}

export default job;