// 第三方登录授权
const singleLogout = [
    {
        path: "/singleLogoutLogged",
        component: () => import("@/views/singleLogout/logeed/index.vue"),
        name: "已登录",
    },
    {
        path: "/singleLogoutLogin",
        component: () => import("@/views/singleLogout/login/index.vue"),
        name: "登录页",
    },
    {
        path:"/singleLogoutErrorPage",
        component: () => import("@/views/singleLogout/errorPage/index.vue"),
        name: "错误页面",
    },
    {
        path:"/singleLogout",
        component: () => import("@/views/singleLogout/index.vue"),
        name: "注销页",
    },
    {
        path: "/singleLogoutAuthorization",
        component: () => import("@/views/singleLogout/authorization/index.vue"),
        name: "授权",
    },
]

export default singleLogout
