import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import * as types from "@/store/mutations-types";
// 查：Create
import Create from "@/views/Create/router";
// 改：Update
import Update from "@/views/Update/router";
// 查：list
import List from "@/views/List/router";
// 老版视图列表
// import commonObjectsRouter from '@/views/commonObjects/commonObjectsRouter'
// button按钮涉及的路由切换
import buttonWrapperRouter from "@/components/ButtonWrapper/router";
// 点击标签跳转
import tagRouter from "@/views/TagList/router";
// 外部用户
import externalUsersRouter from "@/views/ExternalUsers/router";
// 报价单相关路由
import quotation from "@/views/quotation/quotation";
// 报表
import reportObject from "@/views/report/reportRouter";
// 主页
import homePageObject from "@/views/homePage/home-page-object";
// 仪表板
import dashboardObject from "@/views/dashboardObject/dashboardObjectRouter";
// 审批
import approval from "@/views/approval/approvalRouter";
// 客服工作台
import workbench from "@/views/customerWorkbench/workbench";
// 客服控制台
import controlPanel from "@/views/controlPanel/controlPanel";
// 文件
import file from "@/views/ccFileCenter/ccFileCenterRouter";
// 短信
import noteSms from "@/views/noteSms/noteSmsRouter";
//邮件对象
import email from "@/views/Email/emailRouter";
//标情发现
import moodFound from "@/views/moodFound/moodFoundRouter";
//项目管理主页
import projectManagement from "@/views/projectManagement/projectManagement";
//甘特图
import gantt from "@/views/gantt/ganttRouter";
//人员资源日历
import resoursecalendar from "@/views/resoursecalendar/resourseCalendarRouter";
//同事圈
import colleaguecircle from "@/views/colleagueCircle/colleagueCircleRouter";
//实际工作清单
import actualworklist from "@/views/actualworklist/actualworklistRouter";
//小组
import groupObject from "@/views/group/groupRouter";
//目标模型
import targetModel from "@/views/targetModel/targetModelRouter";
//目标
import target from "@/views/target/targetRouter";
//预测
import forecast from "@/views/forecast/forecastRouter";
//预测
import importmodule from "@/views/importModule/importmodule";
// 回收站
import recycle from "@/views/recycle/router";
//form 页面
import marketForm from "@/views/marketForm/marketForm";
//社交媒体页面
import social from "@/views/social/social";
//伙伴账户页面
import partnerAccount from "@/views/partnerAccount/partnerAccount";
//注入组件
import injectionComponent from "@/views/injectionComponent/injectionComponent";
//个人设置
import profileSetting from "@/views/profileSetting/profileSettingRouter";
//系统设置
import systemSettings from "@/views/systemSettings/systemSettings";
//lists页面
import lists from "@/views/lists/lists";
//landing Page页面
import landingPage from "@/views/landingPage/landingPage";
//订货平台
import orderingPlatform from "@/views/orderingPlatform/orderingPlatformRouter";
//派工派台
import dispatchingUnitsRouter from "@/views/dispatchingUnits/dispatchingUnitsRouter";
//日历
import calendarRouter from "@/views/calendar/calendarRouter";
//价格手册
import priceManual from "@/views/priceManual/priceManualRouter";
//打印
import printRouter from "@/views/Print/printRouter";
// 客户公海池
import clientPool from "@/views/clientPool/clientPoolRouter";
//知识库
import knowledgearticles from "@/views/Knowledgearticles/knowledgearticlesRouter";
//项目任务
import projectTasks from "@/views/projectTasks/projectTasksRouter";
//实际工作清单详情
import workListDetails from "@/views/workListDetails/workListRouter";
//组织架构图
import structureChart from "@/views/structureChart/structureChartRouter";
//日程安排
import scheduleappointment from "@/views/scheduleappointment/scheduleappointmentRouter";
//股权结构图
import equityPenetrationChart from "@/views/equityPenetrationChart/equityPenetrationChartRouter";
//市场云特殊相关列表
import marketListRouter from "@/views/marketList/marketListRouter";
// PDF视图详情页
import pdfviewrouter from "@/views/commonObjects/pdfViewDetail/pdfViewRouter";
import thirdPartyAuth from "@/views/thirdPartyAuth/router";
import singleLogout from "@/views/singleLogout/router";

// liveChat
import liveChatRouter from "@/views/liveChat/liveChatRouter";
//ccdk路由
import ccdkRouter from "@/router/tab.js";
//跳转页
import jumpPage from "@/views/jumpPage/jumpPageRouter"


Vue.use(VueRouter)
const routes = [
  {
    path: "/login",
    component: window.Glod.LOGIN_PAGE_PATH ? () => import(`@/views/account/views/${window.Glod.LOGIN_PAGE_PATH}/index`) : () => import('@/views/account/views/login/index'),
  },
  {
    path: "/changePassword",
    component: () => import("@/views/account/views/changePassword/index"),
  },
  // 第三方授权登录
  ...thirdPartyAuth,
  //单点注销相关
  ...singleLogout,
  ...jumpPage,//跳转页
  // home页
  {
    path: "/",
    component: () => import("@/views/home/index.vue"),
    // redirect: () => "/homePageObject/standardPage",
    name: "home页",
    children: [
      ...Create,
      ...Update,
      ...List,
      // ...commonObjectsRouter,
      ...homePageObject,
      ...workbench,
      ...controlPanel,
      ...quotation,
      ...profileSetting,
      ...reportObject,
      ...dashboardObject,
      ...target,
      ...targetModel,
      ...forecast,
      ...actualworklist,
      ...gantt,
      ...resoursecalendar,
      ...noteSms,
      ...orderingPlatform,
      ...dispatchingUnitsRouter,
      ...calendarRouter,
      ...colleaguecircle,
      ...groupObject,
      ...approval,
      ...file,
      ...partnerAccount,
      ...lists,
      ...landingPage,
      ...social,
      ...projectManagement,
      ...moodFound,
      ...importmodule,
      ...recycle,
      ...marketForm,
      ...injectionComponent,
      ...priceManual,
      ...clientPool,
      ...knowledgearticles,
      ...projectTasks,
      ...workListDetails,
      ...structureChart,
      ...email,
      ...scheduleappointment,
      ...equityPenetrationChart,
      ...marketListRouter,
      ...buttonWrapperRouter,
      ...tagRouter,
      ...externalUsersRouter,
      ...pdfviewrouter,
      ...liveChatRouter,
      // 自定义对象
      {
        path: "custome/customePage",
        component: () => import("@/views/commonObjects/custome.vue"),
        name: "自定义对象",
      },
      // 自定义sql
      {
        path: "customsql/:reportId/:m",
        component: () =>
          import("@/views/report/editReport/CustomSQL/index.vue"),
        name: "自定义SQL",
      },
      //全局搜索页
      {
        path: "globalsearch/:searchObjId/:userInput?",
        component: () => import("@/views/GlobalSearch/index.vue"),
        name: "全局搜索",
        meta: {
          keepAlive: true, //需要缓存
        },
      },
      // 打印活动成员列表页
      {
        path: "/print-activeTable/:channel/:campaignId",
        name: "打印列表页",
        component: () =>
          import(
            "@/views/marketList/marketingChannel/component/print/index.vue"
          ),
      },
      //报价单打印
      {
        path: "/print-quotation/:title/:objId/:id/:templateId/:type",
        name: "打印报价单",
        component: () =>
          import("@/views/quotationEmail/components/printContent.vue"),
      },
      // 控制台自定义iframe页面  此路由实现 缓存一级菜单iframe自定义页面使用
      {
        path: "console--iframe-custompage/:page/:pageId",
        // component: () => import("@/views/injectionComponent/index.vue"),
        name: "consoleIframeCustomPage",
      },
    ],
  },
  // ntt
  {
    path: "/ntt",
    component: () => import("@/views/nttEdit/index.vue"),
    name: "ntt",
  },
  // 新建查找数据
  {
    path: "/add-relevant-obj/:prefix/:objectApi/:relevantObjectLevel",
    component: () => import("@/views/quickCreate/add-relevant-obj.vue"),
    name: "新增相关对象",
  },
  // 帮助手册
  {
    path: "/help",
    component: () => import("@/views/helpHandbook"),
    name: "帮助中心",
  },
  // 系统设置
  ...systemSettings,
  // 打印
  ...printRouter,
  // ccdk所需路由
  ...ccdkRouter,
  // //注入组件，打开新tab
  {
    path: "/injectionComponent1",
    component: () => import("@/views/injectionComponent/index.vue"),
    name: "注入组件",
  },
  {
    path: "/cacheRefresh",
    component: () => import("@/views/operationSetting/index.vue"),
    name: "运维设置",
  },
  {
    path: "/viewRefresh",
    component: () => import("@/views/operationSetting/viewRefresh.vue"),
    name: "刷新对象视图",
  },
  {
    path: "/appReport/:type/:reportId?/:folderId?/:isOptimization?",
    // type：页面类型，是运行（run）还是编辑（edit）；reportId：报表id；folderId：文件夹id;isOptimization:是否优化报表
    name: "移动端报表",
  },
  // 底部bar左侧自定义页面window方式弹出
  {
    path: "/customPageWindowPopup",
    component: () => import("@/views/customPageWindowPopup/index.vue"),
    name: "自定义页面弹出",
  },
];

const router = new VueRouter({
  routes,
});

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

//所有的正在请求cancel方法
// window._axiosPromiseArr = []
router.beforeEach((to, from, next) => {
  /**
   *
   * 如果进入发页面不是编辑
   * 页不是运行页
   * 即 清除保存到编辑或运行页中的数据
   * 即（store中的数据）
   */
  // 根据路由元信息设置文档标题
  window.document.title = to.meta.title ?
    `${to.meta.title} | ${window.Glod.BASE_TITLE || "CloudCC"}` :
    window.Glod.BASE_TITLE || "CloudCC";

  if (to.path !== "/reportObject/editReport") {
    store.commit(types.SET_REPORTINFOS, {});
    store.commit(types.SET_RUNREPORTABCD, {});
    store.commit(types.SET_EDITPAGE_FIRST_ID, "");
    store.commit(types.SAVE_CHOOSEDTIMERANGES, {});
    store.commit(types.SAVE_TEMP_REPORTTYPECUSTOID, "");
    store.commit(types.SET_REPORT_NAME, "");
  }
  // 如果是项目详情页跳转到新的详情页（包括通用详情页和项目详情页），浏览器新窗口打开
  if (
    from.path.indexOf("/commonObjects/project-detail") !== -1 &&
    (to.path.indexOf("/commonObjects/detail") !== -1 ||
      to.path.indexOf("/workListDetails") !== -1)
  ) {
    window.location.hash = `${from.path}`;
    // 实际工作清单详情页
    if (to.path.indexOf("/workListDetails") !== -1) {
      window.open(
        `#${to.path}?row=${JSON.stringify(to.query.row)}&state=${to.query.state
        }&startDate=${to.query.startDate}&endDate=${to.query.endDate}`
      );
    } else {
      window.open(`#${to.path}`);
    }
  } else if (
    to.path.indexOf("/commonObjects/detail") !== -1 &&
    to.params.id.slice(0, 3) === "p01"
  ) {
    // 如果是项目详情页，路由重定向
    router.replace({
      path: `/commonObjects/project-detail/${to.params.id}/${to.params.oprateType}`,
      query: to.query ? to.query : "",
    });
  } else if (
    to.path.indexOf("/commonObjects/detail") !== -1 &&
    (to.params.id.slice(0, 3) === "092" ||
      to.params.id.slice(0, 3) === "032" ||
      to.params.id.slice(0, 3) === "021" ||
      to.params.id.slice(0, 3) === "011") &&
    store.getters.getIsPdfViewStatus === "true"
  ) {
    // 1、跳转到detail详情页时，根据getIsPdfViewStatus判断是否打开PDF视图详情页
    // router.replace({
    //   path: `/commonObjects/pdfViewDetail/${store.getters.getObjectApi}/${store.getters.getObjId}/${to.params.id}/${store.getters.getPrefix}/${store.getters.getViewId}`,
    //   query: to.query ? to.query : ''
    // })
    router.replace({
      path: `/commonObjects/pdfViewDetail/${to.params.id}`,
      // query: to.query ? to.query : ''
      query: {
        objectApi: store.getters.getObjectApi,
        objId: store.getters.getObjId,
        prefix: store.getters.getPrefix,
        viewId: store.getters.getViewId,
      },
    });
  } else {
    //跳转路由前取消所有在pending中的请求
    // if (sessionStorage.getItem('applicationTab')) {
    //   window._axiosPromiseArr.forEach((ele, index) => {
    //     ele.cancel()
    //     delete window._axiosPromiseArr[index]
    //   })
    // }
    next();
  }
});
export default router;
