/**
 * 个人设置路由
 */
export default [
    {
        path: "profileSetting",
        name: "profileSetting",
        component: () => import("@/views/profileSetting"),
        meta: { title: "个人设置首页" },
        redirect: {
            path: "profileSetting/profileInfo",
            query: {
                id: "profileInfo"
            }
        },
        children: [
            // 个人信息
            {
                path: "profileInfo",
                component: () => import("@/views/profileSetting/components/profileInfo.vue"),
            },
            // 修改密码
            {
                path: "modifyPassword",
                component: () => import("@/views/profileSetting/components/modifyPassword.vue"),
            },
            // 授予权限
            {
                path: "authVisit",
                component: () => import("@/views/profileSetting/components/authVisit.vue"),
            },
            // 登录历史
            {
                path: "loginHistory",
                component: () => import("@/views/profileSetting/components/loginHistory.vue"),
            },
            // 跳转到个人邮箱页
            {
                path: 'personalEmailBox',
                component: () => import("@/views/Email/personalEmailBox.vue"),
            }
        ]
    },
];
