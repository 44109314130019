import axios from '@/config/httpConfig'

/**
 * 请求获取用户信息
 * @param {data} 请求参数
 * @param {enforce} 是否强制请求最新用户信息
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve, reject) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

// 获取用户有无报表相关的权限的结果
export function getReportEnableList(data) {
  return axios.post('/report/getReportEnableList', data)
}
