export function transformTime(dates){
    let date = new Date(dates);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    if (minutes <= 9) {
        minutes = "0" + minutes;
      }
    let time = `${year}-${month}-${day}/${hours}:${minutes}`;
    return time
}

export function transformTimeDay (dates) {
  let date = new Date(dates);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  let day = date.getDate();
  let time = `${year}-${month}-${day}`;
  return time
}
export function transformTimeDayTwo (dates) {
  let date = new Date(dates);
  let year = date.getFullYear();
  let month = date.getMonth() + 1;
  month=month>9?month:`0${month}`
  let day = date.getDate();
  day=day>9?day:`0${day}`
  let time = `${year}-${month}-${day}`;
  return time
}

export function dateFormat(countryCode){
  // if(countryCode === 'USA'){
  //   return 'MM/dd/yyyy'
  // } else {
  //   return 'yyyy-MM-dd'
  // }
  return 'yyyy-MM-dd'
}

export function datetimeFormat(countryCode){
  // if(countryCode === 'USA'){
  //   return 'MM/dd/yyyy hh:mm:ss A'
  // } else {
  //   return 'yyyy-MM-dd HH:mm:ss'
  // }
  return 'yyyy-MM-dd HH:mm:ss'
}

export function timeFormat(countryCode){
  // if(countryCode === 'USA'){
  //   return 'hh:mm:ss A'
  // } else {
  //   return 'HH:mm:ss'
  // }
  return 'HH:mm:ss'
}

