import Vue from 'vue'
import ErrorDialog from './index.vue'
import i18n from "@/utils/i18n.js"
const DialogBox = Vue.extend(ErrorDialog)

ErrorDialog.install = function (data) {
    let instance = new DialogBox({
        data,
        i18n
    }).$mount()

    document.body.appendChild(instance.$el)

    Vue.nextTick(() => {
        // errorDialogVisible 和弹窗组件里的errorDialogVisible对应，用于控制显隐
        // instance.errorDialogVisible = true
    })
}
export default ErrorDialog