import axios from '@/config/httpConfig';

// 邮箱是否验证
export function getMailboxVerification(data) {
    return axios.postFormat(window.Glod['ccex-login'] + "/verifyOpenAccount/validFirstLogin", data)
}

// 获取剩余天数
export function getDaysRemaining(data) {
    return axios.postFormat(window.Glod['ccex-appstore'] + "/web/trial/remainDays", data)
}
// 验证是否是管理员
export function getAdministrators(data) {
    return axios.postFormat(window.Glod['ccex-appstore'] + "/web/trial/validAdmin", data)
}
// 获取套餐剩余天数
export function getPackageDays(data) {
    return axios.postFormat(window.Glod['ccex-appstore'] + "/web/list/loadAppList", data);
}

// 获取消息接口
export function queryPushMessage(data) {
    return axios.post("/messageCenter/queryPushMessage", data);
}
// 查询消息接口
export function queryPushMessageCount(data) {
    return axios.post("/messageCenter/queryPushMessageCount", data);
}
// 更改消息状态为已读接口
export function changeReadStatus(data) {
    return axios.post("/messageCenter/changeReadStatus", data);
}
// 获取CCChat后台设置信息
export function getSetupInfo() {
    return axios.post('/ccchat/person/getSetupInfo')
}
// 获取CCChat追随人员列表
export function queryFollows(data) {
    return axios.post("/ccchat/person/queryFollows", data);
}
// 获取CCChat小组列表
export function queryUserGroups(data) {
    return axios.post("/ccchat/usergroup/queryUserGroups", data);
}
// 更新用户上传头像
export function updatePersonContactInfo(data) {
    return axios.post("/ccchat/person/updatePersonContactInfo", data);
}

// 查看任务事件未读通知
export function queryTaskUnread(data) {
    return axios.post("/messageCenter/queryTaskUnread", data);
}


// 获取对象记录权限
export function getPermissionById(data) {
    return axios.post("/objectdetail/getPermissionById", data);
}

// 获取所仪表板列表
export function getDashboardList(data) {
    return axios.post('/dashboard/getDashboardList', data)
}
//邮件发送页
export function getEmailPageInfo(data) {
    return axios.post('emailObject/getEmailPageInfo', data)
}
// 获取数据导入权限
export function getImportPermission(accessToken) {
    return axios.post("importData/getImportPermission", accessToken);
}
// 查询伙伴账户与流水
export function queryPartnerFund(data) {
    return axios.post('partnerFund/queryPartnerFund', data)
}
//查询伙伴账户启用设置
export function queryPartnerFundSetup(data) {
    return axios.get('/partnerFund/queryPartnerFundSetup', data)
}
// 立即订购剩余时间
export function getUserVersionInfo(data) {
    return axios.post('/user/getUserVersionInfo', data)
}
// 退出登录接口
export function logout(data) {
    return axios.post("/user/logout", data);
}
// 今天到期或者逾期的任务消息推送
export function todayTaskPushMessage(data) {
    return axios.post("/messageCenter/todayTaskPushMessage", data);
}

//判断快速新建权限
export function getNewAuth(data) {
    return axios.post('/objectdetail/getObjectPermission', data)
}
/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
    if (enforce || localStorage.getItem("userInformation") === null) {
        return axios.get('/user/getUserInfo', data)
    } else {
        return new Promise(function (resolve) {
            resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
        })
    }
}

//保存个人菜单设置（自定义菜单）
export function savemymenusetting(data) {
    return axios.post('application/saveMyTabSetting', data)
}

//获取个人菜单设置（自定义菜单)
export function getmymenusetting(data) {
    return axios.post('application/getMyTabSetting', data)
}
// 获取应用程序选项卡
export function getApplicationTab(data) {
    return axios.post('/application/getApplicationTab', data)
}
//应用程序选项卡搜索
export function seachApplicationTab(data) {
    return axios.post('/application/showAllTabs', data)
}
//伙伴钱包是否展示
export function ispartnerwallet(data) {
    return axios.get('/partnerFund/ispartnerwallet', data)
}
// 帮助中心请求接口
export function findConditionHelpCenter(data) {
    return axios.post('/helpcenter/findConditionHelpCenter', data)
}

// 获取组织机构的创建时间
export function getOrgCreatetimeByOrgId() {
    return axios.post('/user/getOrgCreatetimeByOrgId', {}, "public")
}