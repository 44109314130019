<template>
  <!-- 顶部试用日期模块 新-->
  <div class="trialDate" v-if="remaining">
    <div class="occupy">
      <!-- 占位 -->
    </div>
    <div class="mail" v-if="mailbox">
      <svg class="icon" aria-hidden="true">
        <use href="#icon-shiyongdaoqi-email"></use>
      </svg>
      <!-- 查看电子邮件，并验证账户。如您所知，您仅可在确认邮件后重新登 -->
      <div class="text_mail">
        {{ $t("front-vew-email-verification") }}
      </div>
    </div>
    <div class="mailR">
      <div class="days" v-html="$t('c551', { days: days })"></div>
      <el-button
        type="warning"
        class="orderNowBtn"
        @click="changeOrderNow(orderNowData)"
        >{{ $t("vue_label_title_ordernow") }}</el-button
      >
      <!-- 取消掉关闭按钮，试用账号不可关闭 -->
      <!-- <svg class="icon" aria-hidden="true">
        <use href="#icon-close1" @click="remainingShow"></use>
      </svg> -->
    </div>
  </div>
</template>

<script>
import * as appStore from "./api.js";
export default {
  name: "",
  data() {
    return {
      mailbox: false, //顶部邮箱是否验证
      remaining: false, //获取剩余天数
      days: "", //天数
      packageData: [],
      orderNowData: {},
      countryCode: "",
    };
  },
  mounted() {
    this.getUserInfo();
  },
  methods: {
    // 如果countryCode不是CN 才会请求
    getUserInfo() {
      appStore.getUserInfo().then((res) => {
        if (res.data && res.data.countryCode && res.data.countryCode !== "CN") {
          this.administratorPrivileges();
          this.changePackageDays();
        }
      });
    },
    // 验证是否是管理员
    administratorPrivileges() {
      appStore.getAdministrators().then((res) => {
        if (res.data.isAdmin === true) {
          this.mailboxVerification();
          this.getRemainingTime(res.data.isAdmin);
        } else if (res.data.status === false) {
          this.remaining = false;
        }
      });
    },
    // 邮箱是否验证
    mailboxVerification() {
      // 页面顶部显示邮箱是否验证
      appStore.getMailboxVerification().then((res) => {
        if (res.data.status === "1") {
          this.mailbox = false;
        } else if (res.data.status === "0") {
          this.mailbox = true;
        }
      });
    },
    // 获取剩余天数
    getRemainingTime(isAdmin) {
      // res.data.remainDays === -1 顶部试用日期不显示
      appStore.getDaysRemaining().then((res) => {
        if (res.data.remainDays !== -1) {
          this.days = res.data.remainDays;
          if (isAdmin) {
            if (!sessionStorage.getItem("remaining")) {
              this.remaining = true;
            }
          }
        } else if (res.data.remainDays === -1) {
          this.remaining = false;
        }
      });
    },
    // 获取套餐剩余天数
    changePackageDays() {
      appStore.getPackageDays().then((res) => {
        this.packageData = res.data.isLoading;
        this.orderNowData = this.packageData[0];
      });
    },
    // 立即订购
    changeOrderNow(val) {
      // var local = localStorage.getItem("locale");
      let cook = document.cookie.replace(/=/g, ":");
      var cookList = cook.split(";");
      //
      cookList.forEach((item) => {
        let kie = item.split(":");
        //
        if (kie[0] == " binding") {
          this.cookieData = kie[1];
        }
      });
      window.open(
        `${window.Glod.APP_STORE_URL}/#/home/store?product=` +
          val.productCode +
          "&" +
          "v=" +
          val.versionCode +
          "&" +
          "binding=" +
          this.cookieData +
          "&U=" +
          window.Glod['ccex-apitsf']+'/api'
      );
    },
    remainingShow() {
      this.remaining = false;
      sessionStorage.setItem("remaining", this.remaining);
    },
  },
  watch: {
    remaining: {
      immediate: true,
      handler() {
        this.$store.commit("getremaining", this.remaining);
      },
    },
  },
};
</script>

<style lang="scss"  scoped>
.trialDate {
  min-width: 1000px;
  height: 42px;
  background: #272d43;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .icon {
    width: 20px;
    height: 20px;
  }
  .occupy {
    width: 350px;
  }
  @media screen and (max-width: 1500px) {
    .occupy {
      width: 0px;
    }
  }
  .mail {
    display: flex;
    .text_mail {
      font-size: 14px;
      font-weight: 400;
      color: #6c98fd;
      margin-left: 8px;
    }
  }
  .mailR {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    .el-button {
      padding: 0;
    }
    .days {
      font-size: 14px;
      font-weight: bold;
      color: #abc4fe;
      span {
        font-size: 16px;
        font-weight: bold;
        color: #fe8a14;
      }
    }
    .orderNowBtn {
      min-width: 80px;
      height: 25px;
      font-size: 12px;
      background: #fe8a14;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #fe8a14;
      margin: 0 46px 0 55px;
      padding: 0 10px;
    }
  }
}
</style>
