<!--
  仪表板首页顶部中的组件：时间人员筛选
-->
<template>
  <div class="div_content">
    <!-- 时间下拉框 -->
    <el-select
      v-model="timeValue"
      :placeholder="$t('time')"
      filterable
      clearable
      @change="fnchooseTime"
      class="select-style"
      size="mini" 
      id="custom_time"
      @blur="selectBlur"
    >
      <el-option-group
        v-for="item in timeOptions"
        :key="item.id"
        :label="item.name"
      >
        <el-option
          v-for="items in item.options"
          :key="items.id"
          :label="items.name"
          :value="items.id"
        ></el-option>
      </el-option-group>
    <!-- 日期选择器 -->
    <el-date-picker
      v-if="inputType === 'date'"
      v-model="timeValueArr"
      type="date"
      ref="datePickerRef"
      value-format="yyyy-MM-dd"
      :format="dateFormat"
      @change="fnchooseCustomTimes"
      class="date_style"
      :placeholder="$t('label_tabpage_selectdate')"
    >
    </el-date-picker>
    <!-- 日期时间选择器 -->
    <el-date-picker
      v-else-if="inputType === 'datetime'"
      popper-class="editabale-cell-edit-view"
      v-model="timeValueArr"
      type="datetime"
      ref="datePickerRef"
      value-format="yyyy-MM-dd HH:mm:ss"
      :format="datetimeFormat"
      @change="fnchooseCustomTimes"
      class="date_style"
      :placeholder="$t('label_tabpage_selectdate')"
    >
    </el-date-picker>
    </el-select>
  </div>
</template>

<script>
import * as Time from '@/utils/time'
export default {
  props: {
    // 字段类型：日期类型、时间类型
    inputType: {
      type: String,
    },
    editValue: {
      type: String,
    },
  },
  data() {
    return {
      qq: false,
      value: "",
      timeValue: "", // 选择的时间
      timeValueArr: "", // 选择的自定义时间范围
      userValue: "", // 选择的用户value
      userLabel: this.$i18n.t("label_tabpage_rolez"), // 选择的用户label
      defaultProps: {
        children: "childrenList",
        id: "roleId",
        label: "roleName",
      },
      timeOptions: [],
      dateFormat: Time.dateFormat($cookies.get('countryCode')), // 日期的格式化
      datetimeFormat: Time.datetimeFormat($cookies.get('countryCode')), // 日期时间的格式化
    };
  },
  created() {
    if (this.editValue !== "") {
      this.timeValue = this.editValue;
    }
    this.times(); // 获取仪表板条件时间
  },
  methods: {
    times() {
      this.timeOptions.push(
        {
          options: [
            {
              id: "自定义日期",
              name: this.$i18n.t("vue_label_fiter_component_date_customdate"), //"自定义日期",
            },
          ],
        },
        {
          options: [
            {
              id: "昨天",
              name: this.$i18n.t("lable.account.Yesterday"), //"昨天",
            },
            {
              id: "今天",
              name: this.$i18n.t("today"), //"今天",
            },
            {
              id: "明天",
              name: this.$i18n.t("tomorrow"), //"明天",
            },
            {
              id: "上个月",
              name: this.$i18n.t("fliter.lastmonth"), //"上个月",
            },
            {
              id: "本月",
              name: this.$i18n.t("this.month"), //"本月",
            },
            {
              id: "下个月",
              name: this.$i18n.t("fliter.nextmonth"), //"下个月",
            },
            {
              id: "上星期",
              name: this.$i18n.t("lable.account.LastWeek"), //"上星期",
            },
            {
              id: "本星期",
              name: this.$i18n.t("lable.account.ThisWeek"), //"本星期",
            },
            {
              id: "下星期",
              name: this.$i18n.t("lable.account.NextWeek"), //"下星期",
            },
          ],
        }
      );
    },
    // 切换时间
    async fnchooseTime() {
      if (this.timeValue.indexOf("自定义日期") > -1) {
        // 重新选择自定义时间时，若是还是选择的之前的时间，则不会触发切换事件，所以在最开始给他赋上个时间值
        this.timeValue = this.timeValueArr;
        // 属于自定义时间
        this.$refs.datePickerRef.pickerVisible = true; // 手动控制日期时间选择器打开
        return;
      }
      // this.chooseTime();
    },
    // 选择自定义时间范围
    fnchooseCustomTimes(value) {
      this.timeValue = value;

      // this.chooseTime();
    },
    selectBlur(e) {
      let str = e.target.value;
      var regs = new RegExp("[\\u4E00-\\u9FFF]+", "g");
      let reg = /(\d{4})(-|\/)(\d{1,2})(-|\/)(\d{1,2})/g;
      if (str.match(reg) !== null) {
        this.timeValue = str;
        this.$emit("getChartsByCondition", str);
      } else {
        this.timeValue = "";
      }
    },
  },
  watch: {
    editValue: {
      handler(val) {
        this.timeValue = val;
        // 判断回显的是否为自定义时间选项，当选择的为自定义时间时，才需给对应的v-model赋值
        if (val.indexOf("/") > -1 || val.indexOf("-") > -1) {
          this.timeValueArr = val;
        }
      },
      deep: true,
      immediate: true,
    },
    timeValue(val) {
      if (val === "自定义日期") {
        this.timeValue = "";
      } else {
        this.timeValue = val;
        this.$emit("getChartsByCondition", val);
      }
    },
  },
};
</script>


<style lang="scss" scoped>
.div_content {
  .select-style {
    width: 100%;
    margin: 0 5px;
    ::v-deep .el-input {
      position: relative;
      left: -5px;
    }
    ::v-deep .el-input__inner {
      padding: 4px;
      padding-left: 10px;
      padding-right: 30px;
    }
  }
  .date_style {
    margin-left: -100px;
    width: 100px;
    z-index: -1;
  }
  .user_select {
    width: 100px;
    height: 28px;
    padding: 4px;
    margin-right: 5px;
    border-radius: 4px;
    border: 1px solid #dcdfe6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    color: #c0c4cc;
    div {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .userActive {
    border: 1px solid #006dcc;
    div {
      color: #006dcc;
    }
  }
}
::v-deep .el-picker-panel {
  top: 220px !important;
  left: 570px !important;
}
</style>

<style>
.user_popper {
  max-height: 266px;
  overflow-y: auto;
}
.editabale-cell-edit-view{
  z-index:120001 !important;
}
</style>