/**
 *  标情发现对象路由
 */
const moodFound = [
  {
    path: "/moodFound",
    name: "标情发现",
    // eslint-disable-next-line no-unused-vars
    redirect: (to) => "/moodFound/index",
  },
  {
    path: "moodFound/index",
    component: () => import("@/views/moodFound/index.vue"),
    name: "标情发现页",
    meta: { title: "标情发现" },
  },

];

export default moodFound;
