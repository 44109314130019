/**
 * 小组菜单路由文件
 */
const groupObject = [
  {
    path: "groupObject",
    redirect: () => "/groupObject/groupList",
  },
  {
    path: "groupObject/groupList",
    component: () =>
      import("@/views/group/groupList/index.vue"),
    name: "小组列表页",
  },
  {
    path: "groupObject/groupDetails",
    component: () =>
      import("@/views/group/groupDetails/index.vue"),
    name: "小组详情页",
  },

];

export default groupObject;
