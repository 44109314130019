//  判断当前浏览类型
export function BrowserType () {
  let userAgent = navigator.userAgent // 取得浏览器的userAgent字符串
  let isOpera = userAgent.indexOf('Opera') > -1 // 判断是否Opera浏览器
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera // 判断是否IE浏览器
  let isEdge = userAgent.indexOf('Windows NT 6.1; Trident/7.0;') > -1 && !isIE // 判断是否IE的Edge浏览器
  let isFF = userAgent.indexOf('Firefox') > -1 // 判断是否Firefox浏览器
  let isSafari = userAgent.indexOf('Safari') > -1 && userAgent.indexOf('Chrome') === -1 // 判断是否Safari浏览器
  let isChrome = userAgent.indexOf('Chrome') > -1 && userAgent.indexOf('Safari') > -1 // 判断Chrome浏览器

  if (isIE) {
    let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    let fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion === 7) {
      return 'IE7'
    } else if (fIEVersion === 8) {
      return 'IE8'
    } else if (fIEVersion === 9) {
      return 'IE9'
    } else if (fIEVersion === 10) {
      return 'IE10'
    } else if (fIEVersion === 11) {
      return 'IE11'
    } else {
      //  IE版本过低
      return '0'
    }
  }// isIE end

  if (isFF) {
    return 'fireFox'
  }
  if (isOpera) {
    return 'Opera'
  }
  if (isSafari) {
    return 'Safari'
  }
  if (isChrome) {
    return 'Chrome'
  }
  if (isEdge) {
    return 'Edge'
  }
}// myBrowser() end

// 判断是否是IE浏览器
export function isIE () {
  let userAgent = navigator.userAgent //  取得浏览器的userAgent字符串
  let isOpera = userAgent.indexOf('Opera') > -1 //  判断是否Opera浏览器
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera //  判断是否IE浏览器
  if (isIE) {
    return '1'
  } else {
    return '-1'
  }
}

// 判断是否是IE浏览器，包括Edge浏览器
export function IEVersion () {
  let userAgent = navigator.userAgent //  取得浏览器的userAgent字符串
  let isOpera = userAgent.indexOf('Opera') > -1 //  判断是否Opera浏览器
  let isIE = userAgent.indexOf('compatible') > -1 && userAgent.indexOf('MSIE') > -1 && !isOpera //  判断是否IE浏览器
  let isEdge = userAgent.indexOf('Edge') > -1 && !isIE //  判断是否IE的Edge浏览器
  if (isIE) {
    let reIE = new RegExp('MSIE (\\d+\\.\\d+);')
    reIE.test(userAgent)
    let fIEVersion = parseFloat(RegExp['$1'])
    if (fIEVersion === 7) {
      return 'IE7'
    } else if (fIEVersion === 8) {
      return 'IE8'
    } else if (fIEVersion === 9) {
      return 'IE9'
    } else if (fIEVersion === 10) {
      return 'IE10'
    } else if (fIEVersion === 11) {
      return 'IE11'
    } else {
      return '0'
    }// IE版本过低
  } else if (isEdge) {
    return 'edge'
  } else {
    return '-1'// 非IE
  }
}

// 根据name获取cookie
export function getCookie (name) {
  let prefix = name + "="
  let start = document.cookie.indexOf(prefix)

  if (start == -1) {
    return null;
  }

  let end = document.cookie.indexOf(";", start + prefix.length)
  if (end == -1) {
    end = document.cookie.length;
  }

  let value = document.cookie.substring(start + prefix.length, end)
  return unescape(value);
}
