

<script>
/**
 * 新建编辑页查找字段相关功能混入文件
 */
export default {
    methods:{
        /**
         * 查找字段保存时，存在筛选条件不符，页面定位指定字段，并给出提示
         * 1、考虑多个查找字段不匹配情况
         * 2、考虑两个查找字段同时设置一个字段的筛选关系
         * @param {array} fieldList:查找字段信息
         */
        setLookupField(fieldAry){
            if(Array.isArray(fieldAry)){
                let list = []
                let fieldList = {}
                fieldAry.forEach(item => {
                    // 查找字段设置筛选条件字段
                    let reg1=/(?<=\[)(.+?)(?=\])/g
                    let errAry = item.match(reg1)
                    if(errAry && errAry[0]){
                      let ary1 = errAry[0].split(',')
                      ary1.forEach(item=>{
                        fieldList[item] = ''
                        // 值不存在或与筛选条件不匹配
                        let obj = {errorField:item,errormessage:this.$i18n.t("c2420")}
                        list.push(obj)
                      })
                    }
                })
                this.$refs.baseForm.verify(list,true)
                // 处理表单未通过的字段集合
                this.handleValidateAry(fieldList);
                this.$nextTick(()=>{
                    //跳转到第一个验证失败的字段位置
                    this.verifyJumpFn(fieldList, true);
                })
            }
           
        }
    }
}
</script>


