/*
 * @Description: 产品记录类型为组合产品时 组合产品项从对象数据配置
 */
let assortmentItem = {
    fieldList:
      [
        { id: "ffe520comproitems012", name: "product", apiname: "product", entityfiledname: "product", decimalPlaces: null, label: "label.product.name", type: "Y", remote: 'searchAssortmentItem' },
        { id: "ffe520comproitems015", name: "quantity", apiname: "quantity", entityfiledname: "quantity", length: '19', decimalPlaces: "2", label: "label_file_num", type: "N", changeEvents: 'changeAssortmentItem' },
        { id: "ffe520comproitems016", name: "unit", apiname: "unit", entityfiledname: "unit", decimalPlaces: null, label: "label.custom.unit", type: "S", disabled: true },
        { id: "ffe520comproitems013", name: "productcode", apiname: "productcode", entityfiledname: "productcode", decimalPlaces: null, label: "label.product.code", type: "S", disabled: true },
        { id: "ffe520comproitems017", name: "productfamily", apiname: "productfamily", entityfiledname: "productfamily", decimalPlaces: null, label: "label.forecast.customforecast.cpxl", type: "S", disabled: true },
        { id: "ffe520comproitems014", name: "productprice", apiname: "productprice", entityfiledname: "productprice", length: '13', decimalPlaces: 2, label: "lable.product.standp", type: "c", disabled: true },
        { id: "ffe520comproitems019", name: "subtotal", apiname: "subtotal", entityfiledname: "subtotal", length: '13', decimalPlaces: 2, label: "label.subtotal", type: "c", disabled: true, tooltip: 'c1032' }
      ],
    linenums: "1",
    objectApi: "cloudcccomproductitem",
    objectId: "20220520comproduitem",
    relatedId:'aee10componentpdt006',
    relatedApiname: "masterproduct",
    relatedListName: "组合产品项"
  }
  
  export default assortmentItem
  