/**
 * 在当前tab打开新页面（以tab方式打开的没有菜单）
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function tab_self(vue, pageId, obj, options) {
    let routeData = vue.$router.resolve({
        path: `/injectionComponent1?page=${obj.pageApi}&pageId=${pageId}`,
       
    });
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.query.pageId可以拿到key
        localStorage.setItem(pageId, JSON.stringify(obj.data));
    }
    let oNewWindow = window.open(routeData.href, options.openMode);
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        oNewWindow.close()
    }
    window.$CCDK.CCPage.addPage(pageId,page)
}
/**
 * 使用新tab打开新页面 （以tab方式打开的没有菜单）
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function tab_blank(vue, pageId, obj, options) {
    // 因为只有打开方式不一样，所以直接调用tab_self方法即可
    // tab_self(vue, pageId, obj, options)
    let routeData = vue.$router.resolve({
        path: `/injectionComponent1?page=${obj.pageApi}&pageId=${pageId}`,
       
    });
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.query.pageId可以拿到key
        localStorage.setItem(pageId, JSON.stringify(obj.data));
    }
    window.open(routeData.href, pageId);
    // 添加reOpen
    let page = {}
    page.reOpenPage=()=>{
        window.open(routeData.href, pageId);
    }
    window.$CCDK.CCPage.addPage(pageId,page)
}


/**
 * 在当前页打开带有一级菜单的自定义页面(menu1的self是替换掉当前的一级菜单)
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function menu1_self(vue, pageId, obj, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    let pId = pageId || Math.random().toString(16).slice(2)
    let routerName;
    let routeData;
    // 自定义页面渲染的是否是Iframe
    if(options.isIframe) {
        // 自定义页面渲染的是iframe
        routerName = "consoleIframeCustomPage"
        routeData = vue.$router.resolve({
            path: `/console--iframe-custompage/${obj.pageApi}/${pId}`,
        });
    } else {
        // 自定义页面渲染的是普通页面
        routerName = "consoleInjectionComponent"
        routeData = vue.$router.resolve({
            path: `/commonObjects/console-multi-screen/console-injection-component/${obj.pageApi}/${pId}`,
        });
    }
    // 生成菜单所需数据
    let menuObj = {
        // 菜单名字
        name: options.title || '一级菜单',
        // 菜单id
        id: pId,
        // 是否展示tab关闭按钮  false不展示  不写或者写别的都展示
        isShowClose: options.isShowClose == false ? false : true,
        // tab下拉操作项 不传 默认是原来的操作项  传[]没有操作项  其他的传啥是啥
        tabAction: options.tabAction || "",
        routerName: routerName,
        params: {
            page: obj.pageApi,
            pageId : pId
        }
    }
    // 替换当前一级菜单
    window.$CCDK.CCMenu.replaceMenu1(menuObj)
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.params.pageId可以拿到key
        localStorage.setItem(pId, JSON.stringify(obj.data));
    }
    window.open(routeData.href, options.openMode);
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        window.$CCDK.CCMenu.deleteMenu1({id: pId})
    }
    page.reOpenPage=()=>{
        window.$CCDK.CCMenu.reOpenMenu1({id: pId})
    }
    window.$CCDK.CCPage.addPage(pId,page)
}
/**
 * 在新页打开带有一级菜单的自定义页面(menu1的blank是添加一个新的一级菜单)
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function menu1_blank(vue, pageId, obj, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    let pId = pageId || Math.random().toString(16).slice(2)
    let routerName;
    let routeData;
    // 自定义页面渲染的是否是Iframe
    if(options.isIframe) {
        // 自定义页面渲染的是iframe
        routerName = "consoleIframeCustomPage"
        routeData = vue.$router.resolve({
            path: `/console--iframe-custompage/${obj.pageApi}/${pId}`,
        });
    } else {
        // 自定义页面渲染的是普通页面
        routerName = "consoleInjectionComponent"
        routeData = vue.$router.resolve({
            path: `/commonObjects/console-multi-screen/console-injection-component/${obj.pageApi}/${pId}`,
        });
    }
    // 菜单所需数据
    let menuObj = {
        name: options.title || '一级菜单',
        id: pId,
        // 是否展示tab关闭按钮
        isShowClose: options.isShowClose == false ? false : true,
        // tab下拉操作项
        tabAction: options.tabAction || "",
        routerName: routerName,
        params: {
            page: obj.pageApi,
            pageId : pId
        }
    }
    // 生成新的一级菜单
    window.$CCDK.CCMenu.addMenu1(menuObj)
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.params.pageId可以拿到key
        localStorage.setItem(pId, JSON.stringify(obj.data));
    }
    window.open(routeData.href, '_self');
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        window.$CCDK.CCMenu.deleteMenu1({id: pId})
    }
    page.reOpenPage=()=>{
        window.$CCDK.CCMenu.reOpenMenu1({id: pId})
    }
    window.$CCDK.CCPage.addPage(pId,page)
}
/**
 * 在当前页打开带有二级菜单的自定义页面(menu2的self是替换掉当前选中一级菜单下的二级菜单)
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function menu2_self(vue, pageId, obj, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    let pId = pageId || Math.random().toString(16).slice(2)
    // 菜单所需数据
    let menuObj = {
        name: options.title || '二级菜单',
        id: pId,
        // 是否展示tab关闭按钮
        isShowClose: options.isShowClose == false ? false : true,
        // tab下拉操作项
        tabAction: options.tabAction || "",
        routerName: 'consoleInjectionComponent',
        params: {
            page: obj.pageApi,
            pageId : pId
        }
    }
    window.$CCDK.CCMenu.replaceMenu2(menuObj)
    let routeData = vue.$router.resolve({
        path: `/commonObjects/console-multi-screen/console-injection-component/${obj.pageApi}/${pId}`,
    });
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.params.pageId可以拿到key
        localStorage.setItem(pId, JSON.stringify(obj.data));
    }
    window.open(routeData.href, options.openMode);
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        window.$CCDK.CCMenu.deleteMenu2({id: pId})
    }
    page.reOpenPage=()=>{
        window.$CCDK.CCMenu.reOpenMenu2({id: pId})
    }
    window.$CCDK.CCPage.addPage(pId,page)
}
/**
 * 在新页打开带有一级菜单的自定义页面(menu2的blank是在当前选中一级菜单下添加一个新的二级菜单)
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function menu2_blank(vue, pageId, obj, options) {
    if (!vue.$store.state.navigationStyle) {
        vue.$message.warning('请将当前应用程序设置为控制台菜单才能支持此操作')
        return
    }
    let pId = pageId || Math.random().toString(16).slice(2)
    // 菜单所需数据
    let menuObj = {
        name: options.title || '二级菜单',
        id: pId,
        // 是否展示tab关闭按钮
        isShowClose: options.isShowClose == false ? false : true,
        // tab下拉操作项
        tabAction: options.tabAction || "",
        routerName: 'consoleInjectionComponent',
        params: {
            page: obj.pageApi,
            pageId : pId
        }
    }
    window.$CCDK.CCMenu.addMenu2(menuObj)
    let routeData = vue.$router.resolve({
        path: `/commonObjects/console-multi-screen/console-injection-component/${obj.pageApi}/${pId}`,
    });
    if (obj.data) {
        // 自定义页面需要回显的数据  将pageId做为存储的key，页面中通过this.$route.params.pageId可以拿到key
        localStorage.setItem(pId, JSON.stringify(obj.data));
    }
    window.open(routeData.href, '_self');
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        window.$CCDK.CCMenu.deleteMenu2({id: pId})
    }
    page.reOpenPage=()=>{
        window.$CCDK.CCMenu.reOpenMenu2({id: pId})
    }
    window.$CCDK.CCPage.addPage(pId,page)
}
/**
 * dialog方式打开
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function dialog_self(vue, pageId, obj, options) {
    let dialogObj = {
        title: options.title || "详情",
        width: options.width || "50%",
        height: options.height || "",
        pageApi: obj.pageApi,
        data: obj.data || {},
        pageId: pageId
    };
    vue.$bus.$emit("openDialog", dialogObj);
    // 添加页面关闭方法
    let page = {}
    page.close=()=>{
        vue.$bus.$emit("closeDialog",pageId);
    }
    window.$CCDK.CCPage.addPage(pageId,page)
}

/**
 * dialog方式打开
 * @param {Object} vue vue对象实例
 * @param {String} pageId 页面唯一标识
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode、title（此时是menu的name）...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function dialog_blank(vue, pageId, obj, options) {
    dialog_self(vue, pageId, obj, options)
}

export {
    tab_self,
    tab_blank,
    menu1_self,
    menu1_blank,
    menu2_self,
    menu2_blank,
    dialog_self,
    dialog_blank
}
