import WebIM from '@/utils/WebIM'
const Login = {
	state: {
		isRegister: false,
		username: ""
	},
	mutations: {
		setUserName(state, username) {
			state.username = username;
		},
		setRegisterFlag(state, flag) {
			state.isRegister = flag;
		}
	},
	actions: {
		onLogin: function (context, payload) {
			context.commit("setUserName", payload.username);
			var options = {
				apiUrl: WebIM.config.apiURL,
				user: payload.username,
				pwd: payload.password,
				appKey: WebIM.config.appkey
			};
			WebIM.conn.open(options);
			localStorage.setItem("userInfo", JSON.stringify({ userId: payload.username }));
		},
		onLogout: function (context) {
			context.commit("setUserName", "");
			localStorage.setItem("userInfo", "");
			WebIM.conn.close();
		},
	}
}
export default Login;
