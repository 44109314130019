const injectionComponent = [{
    path: "injectionComponent",
    component: () => import("@/views/injectionComponent/index.vue"),
    name: "注入组件",
    meta: {
        title: "注入组件"
    },
}];

export default injectionComponent;
