export const IS_RUNPAGE_TO_EDITPAGE = "isRunpageToEditpage"
export const SET_RUNREPORTABCD = "runReportABCD"
export const SET_EDITPAGE_FIRST_ID="setEditPageFirstId" 
export const SET_KEEPOPENKUANG = "keepOpenKuang"
export const SAVE_CHOOSEDTIMERANGES = "saveChoosedTimeRanges"
export const SAVE_TEMP_REPORTTYPECUSTOID = "tempReporttypecustomid"
export const SET_REPORTINFOS = "setReportInfos"
export const SET_REPORT_NAME = "SetReportName"
export const SET_TAB_NAME = "SetTabName"
export const SET_UESR_INFO="setUserInfo"
export const SET_STRUCTURE_BEFORE_CLICK_SAVE_PATH='setStructureBeforeClickSavePath'




























