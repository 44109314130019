<template>
  <div>
   
  </div>
</template>
<script>
/**
 * 仅用于开发ccpage需要的方法以及提供新建、编辑的基础功能（不涉及业务）
 */
// 客户端脚本
 import {
  listClientScript,
  myOnLoad,
  myOnSave,
} from "@/utils/ccdk/clientScript.js";
export default {
  data(){
    return {
      // 添加文件时文件字段信息
      addFieldInfo: {},
      // 是否正在上传过程中
      isUpload: false,
      // 本地文件上传dialog
      batchAddFilesVisible: false,
      // 通过this.$attrs.defalutData传的数据
      propsDefalutDat: [],
      // 新建/编辑数据弹窗主体高度
      dialogBodyHeight: `${document.body.offsetHeight - 210}px`,
      // 控制表单校验底部提示显隐
      visible: false,
      // 表单校验中必填未通过的字段集合，任务1015
      validateAry: [],
    }
  },
  mounted(){
    // 判断是否是tab打开
    if((this.$route?.name === 'consoleTabCreate' || this.$route?.name === 'consoleTabEdit') && this.$attrs.page){
      // 关闭loading图标
    document.getElementById("lightning-loading").style.display = "none";
      // 获取传的参数
      let storeObj = localStorage.getItem(this.$attrs.page)
      if(storeObj){
        storeObj = JSON.parse(storeObj).data
        if(storeObj.defalutData){
          this.propsDefalutDat = storeObj.defalutData
        }
        if(storeObj.recordId){
          this.recordId = storeObj.recordId
        }
      }
      
    }
    if(this.$attrs.openPlace === 'dialog'){
      this.dialogBodyHeight = `${document.body.offsetHeight - 308}px`
    }
    // 如果不是弹窗，需要存储起来defalutData中的值
    if(this.$attrs.page){
      if(this.$attrs.defalutData){
        window.localStorage.setItem(this.$attrs.page,JSON.stringify(this.$attrs.defalutData))
        this.propsDefalutDat = this.$attrs.defalutData
      }else{
        let data = window.localStorage.getItem(this.$attrs.page)
        if(data){
          this.propsDefalutDat=JSON.parse(data)
        }
      }
    }else if(this.$attrs.defalutData){
      this.propsDefalutDat = this.$attrs.defalutData
    }
      
  },
methods:{
  /**
     * 判断高级条件是否成立
     * @param {Array} booleanList:条件成立结果组成的数组
     * @param {String} label:高级条件的label，表示高级条件的组合关系
     * 
     */
     isJudgment(booleanList,label){
      let isTrue = false
      if(label){
        // 去掉1 and 
        label = label.slice(5)
        // 替换and和or
        label = label.replace(/and/g,'&&')
        label = label.replace(/or/g,'||')
        // 替换每个条件的执行
        booleanList.forEach((item,index) => {
          let labelIndex = new RegExp((index+2).toString())
          label = label.replace(labelIndex,item)
        })
        isTrue = eval(label)
      }
      return isTrue
    },
   /**
     *
     * @param {Object} list ：未通过的字段集合
     * @param {*} type
     */
     handleValidateAry(list = {}) {
      if (Object.prototype.toString.call(list) === "[object Object]") {
        let propsList = Object.keys(list);

        this.validateAry = [];
        this.formAttr.forEach((attr) => {
          if (propsList.includes(attr.prop)) {
            this.validateAry.push({ label: attr.label, field: attr.prop });
          }
        });
        if (this.validateAry.length > 0) {
          this.visible = true;
        }
      }
    },
  // 定位到非必填位置
  verifyJumpFn(object, type) {
      let dom = null;
      if (type) {
        for (let i in object) {
          dom = this.$refs.baseForm.$refs[i];
          if (Object.prototype.toString.call(dom) !== "[object Object]") {
            //这里是针对遍历的情况（多个输入框），取值为数组
            dom = dom[0];
            break;
          }
        }
      } else {
        dom = object;
      }
      dom.$el.scrollIntoView(false);
    },
    /**
     *
     * @param {Object} field :必填字段数据
     */
     linkField(field = {}) {
      if (field.field) {
        let obj = {};
        obj[field.field] = "";
        this.verifyJumpFn(obj, true);
        // 关闭校验弹窗
        this.visible = false;
      }
    },
    /**
       * 打开添加文件弹窗
       * @param {Object} field 当前字段信息
       * @param {String} type 当前文件上传类型：1:本地上传；2:文件库上传
       */
      addFile(field,type) {
        this.fieldProp = field.prop;
        this.fileInfo = JSON.parse(JSON.stringify(field));
        if(type==='1'){
          // 本地上传
          this.batchAddFilesVisible = true
        }else{
          // 文件库上传
          this.$refs.addFile.initPage();
          this.dialogAddVisible = true;
        }
        
      },
    /**
       * 文件上传成功后的回调
       */
      batchaddfilesSave(){
        // 获取上传文件列表
        let fieldList = this.$refs.batchaddfiles?.fileList||[]
        if(fieldList.length>0){
          this.batchAddFilesVisible=false
          // 将上传的文件列表添加到对应字段中
          this.addWorkSubmit(fieldList)
        }
      
      },
      /**
       * 本地上传确定按钮
       */
      localAddFile(){
        this.$refs.batchaddfiles.uploadSave()
      },
      /**
       * 本地上传文件弹窗关闭回调
       */
      closeLocalField(){
        this.isUpload= false
      },
      /**
       * 处理客户端脚本参数
       * @param {String} event:事件
       */
      handleParam(event = "", pageId = "") {
        let param = {
          pageId: "",
          event: event,
          objId: "",
          recordTypeId: "",
        };
        // 判断pageId
        if (pageId) {
          param.pageId = pageId;
        } else {
          if (this.dialogAttr.type === "EDIT") {
            param.pageId = "edit";
          } else if (this.dialogAttr.type === "NEW") {
            param.pageId = "add";
          }
        }

        // 判断objId
        param.objId = this.$route?.params.objId || this.objId || this.realObjId;
        // 判断recordTypeId
        param.recordTypeId = this.recordType ? this.recordType : "";

        return param;
      },
    /**
       * 客户端脚本执行
       */
      clientOnLoad() {
        // 页面类型
        let pageId = null;
        if (this.dialogAttr.type === "EDIT") {
          pageId = "edit";
        } else if (this.dialogAttr.type === "NEW") {
          pageId = "add";
        }
        if (pageId) {
          // 客户端脚本onLoad
          listClientScript(this.handleParam("onLoad", pageId), myOnLoad);
          // 客户端脚本onSave
          listClientScript(this.handleParam("onSave", pageId), (res) => {
            this.onSaveAry = res?.data && Array.isArray(res.data) ? res.data : [];
          });
        }
      },
      /**
        * ccpage功能
        * setDefalutData: 回显defalut中的参数
        * @param {Object} fieldObj: 需要替换的字段值
        */
        setDefalutData(fieldObj = {}){
          if(Array.isArray(this.propsDefalutDat)&&this.$attrs.defalutData){
            this.propsDefalutDat.forEach(item=>{
              if(item[fieldObj.apiname]){
                // 当前字段需要更改为this.$attrs.defalutData的值
                fieldObj.value=item[fieldObj.apiname]
                delete item[fieldObj.apiname]
                fieldObj=Object.assign(fieldObj,item)
              }
            })
          }
          return fieldObj
        },
  }
}
</script>

<style lang="scss" scoped>
// 校验弹窗
.iconBox {
  margin-right: 10px;
}
.valTitle {
  font-size: 14px;
  padding-bottom: 10px;
  color: #333333;
}
.liBox {
  font-size: 12px;
  color: #006dcc;
  line-height: 16px;
  padding-left: 6px;
  cursor: pointer;
}
</style>