/**
 * 业务机会对象路由
 */
const structureChart = [
    {
        name: "structureChart",
        path: "structureChart/:opportunityId/:bigTitle/:objId",
        component: () => import("@/views/structureChart/structureChart"),
    },
];

export default structureChart;
