import getTimeStringAutoShort from '@/utils/changeTime.js'
import WebIM from '@/utils/WebIM'

const List = {
  state: {
    //当前正在聊天用户或者群聊IMID以及聊天类型
    isChatID: {
      imId: '',
      imgUrl: '',
      isgroup: false,
      name: ''
    },
    //初始列表通讯录
    chatList: [],
    //获取群邀请创建群时对话框显示邀请人员
    groupInvite: {
      invite: '',
      isInvite: '',
      isNowGroup: true
    },
    //获取聊天记录后时间更改
    timeWindow:''
  },
  getters: {
    //当前聊天被管理员移除群聊信息
    removeGroupMessage (state) {
      let findIndex = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      return findIndex >= 0 && state.chatList[findIndex].delete ? state.chatList[findIndex].delete : '';
    },
    //总未读条数
    totalNoRead (state) {
      if (state.chatList.length > 0) {
        let count = state.chatList.map(res => res.noRead)
        return eval(count.join("+"));
      } else {
        return 0
      }
    },
    getIndex (state) {
      let index = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      return index

    },
    //每个用户的聊天记录
    getIsSelectList (state) {
      let index = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      return index >= 0 ? state.chatList[index].msg : []

    }
  },
  mutations: {
    //更改timeWindow时间
    changeTimeWindow(state,payload){
      state.timeWindow = payload
    },
    //更改是否获取远程聊天记录
    changeGetLiatStatus(state,payload){
      let index = state.chatList.findIndex(res=>res.imId == payload.id);
      if(index >= 0){
        state.chatList[index].getChatList = true
      }
    },
    //更改列表数据
    getChatList(state,payload){
      state.chatList = payload
    },
    //更改群名称
    changeGroupNameCmd(state,payload){
      let index = state.chatList.findIndex(res=>res.imId == payload.id);
      if(index >= 0){
        state.chatList[index].name = payload.name
      }
      if(state.isChatID.imId == payload.id){
        state.isChatID.name = payload.name
      }
    },
    //更改成员列表
    changeListMember(state,payload){
      state.chatList[payload.index].members = payload.members;
    },
    //更改音频的已读与未读状态
    changeListenType (state, payload) {
      let findIndex = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      state.chatList[findIndex].msg[payload].isListen = true;
    },
    //群信息增加或删除被移出群聊的消息
    removedFromGroup (state, payload) {
      let findIndex = state.chatList.findIndex(res => res.imId == payload.gid);
      if (findIndex >= 0) {
        state.chatList[findIndex].delete = payload.message;
      }
    },
    //收到消息未读数加1
    getMessageNoRead (state, payload) {
      state.chatList[payload].noRead = state.chatList[payload].noRead + 1;
    },
    //更改当前的用户发消息的时间时间戳等
    changeTimestampWord (state, payload) {
      state.chatList[payload.index].time = payload.time;
      state.chatList[payload.index].timestamp = payload.date.getTime();
      state.chatList[payload.index].lastWord = payload.message;
    },
    //更改某个群的名字
    changeGroupName (state, payload) {
      state.chatList[payload.index].name = payload.inputName;
    },
    //删除聊天记录中空白文件和图片
    deleteEmptyFile (state, payload) {
      let listIndex = state.chatList.findIndex(
        res => res.imId == state.isChatID.imId
      );
      let indexFindex = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      let getIsSelectList = indexFindex >= 0 ? state.chatList[indexFindex].msg : []
      let index = getIsSelectList.findIndex(
        res =>
          (res.type == "image" && res.data == "") ||
          (res.type == "file" && res.data.url == "")
      );
      if (index >= 0) {
      state.chatList[listIndex].msg.splice(index, 1);
      }
    },
    //清空与某个用户聊天的消息
    clearChatMsg (state, payload) {
      state.chatList[payload].msg = [];
      state.chatList[payload].lastWord = "";
    },
    //当前聊天列表清空
    clearChatList (state) {
      state.chatList = [];
    },
    //更改当前选中用户的未读数
    changeIsSelectNoRead (state, payload) {
      state.chatList[payload].noRead = 0;
    },
    //删除左侧列表某对话
    deleteChatListLeft (state, payload) {
      state.chatList.splice(payload, 1);
    },
    //将chatlist列表最后面一个聊天替换成新值
    chatListEndPush (state, payload) {
      state.chatList[0] = payload
    },
    //更改再次登录时的头像binding
    changeImgBinding (state) {
      if (state.chatList.length > 0) {
        state.chatList.forEach(res => {
          if (res.imgUrl && res.imgUrl.indexOf('binding=') != -1) {
            res.imgUrl = res.imgUrl.replace(res.imgUrl.substring(res.imgUrl.indexOf('binding='), res.imgUrl.length), `binding=${localStorage.getItem('binding')}`)
          }
          if (res.msg.length > 0) {
            res.msg.forEach(val => {
              if (typeof (val.img) == 'string') {
                if (val.img.indexOf('binding=') != -1) {
                  val.img = val.img.replace(val.img.substring(val.img.indexOf('binding='), val.img.length), `binding=${localStorage.getItem('binding')}`)
                }
              }
            })

          }
        })
      }
    },
    //删除当前选中的数据
    deleteIschatId (state) {
      state.isChatID = {
        imId: '',
        imgUrl: '',
        isgroup: false,
        name: ''
      }
    },
    //改变列表时间
    changeListDate (state) {
      state.chatList.forEach(res => {
        res.time = getTimeStringAutoShort(res.timestamp, false)
      })
    },
    updateChatID (state, payload) {
      state.isChatID = payload;
    },
    updateChatList (state, payload) {
      state.chatList.unshift(payload);

    },
    updateChatListLastWord (state, payload) {
      let index = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      if (index >= 0) {
        state.chatList[index].lastWord = payload;
      }
    },
    updateChatListNoRead (state, payload) {
      let index = state.chatList.findIndex(res => res.imId == state.isChatID.imId);
      if (index >= 0) {
        state.chatList[index].noRead = payload;
      }
    },
    changeListPosition (state, payload) {
      state.chatList.unshift(state.chatList.splice(payload, 1)[0]);
    },
    //改变邀请人员名单
    changeInvite (state, payload) {
      state.groupInvite = payload;
    },
    //完全替换当前聊天的记录
    updateMsgCurrent (state, payload) {
      state.chatList[payload.index].msg = payload.content
    },
    //更改当前聊天的聊天记录
    updateMsg (state, payload) {
      state.chatList[payload.index].msg.push(payload.obj)
    },
    //发送消息改变当前时间
    changeSendMessageTime (state, payload) {
      let date = new Date();
      let hour = date.getHours();
      let minute = date.getMinutes();
      if (minute <= 9) {
        minute = "0" + minute;
      }
      let time = `${hour}:${minute}`;
      state.chatList[payload].time = time;
      state.chatList[payload].timestamp = date.getTime();

    }
  },
  actions: {
    //同步请求
    async request (context, payload) {
      await WebIM.utils.download.call(WebIM.conn, payload);
    },
    //转化再次登录时的本地音频
    changeInitialMedia (context) {
      let state = context.state;
      let findIndex = state.chatList.findIndex(res=> res.imId == state.isChatID.imId);
      // if (state.chatList.length > 0) {
        // state.chatList.forEach((res) => {
          if (state.chatList[findIndex].msg.length > 0) {
            state.chatList[findIndex].msg.forEach((val) => {
              if (typeof (val.data) == 'object' && val.data.initialUrl) {
                var options = { url: val.data.initialUrl };
                options.headers = {
                  Accept: "audio/mp3",
                };
                options.onFileDownloadComplete = async (response) => {
                  let objectURL = await WebIM.utils.parseDownloadResponse.call(
                    WebIM.conn,
                    response
                  );
                  val.data.url = objectURL;
                };
                options.onFileDownloadError = (res) => {
                }
                context.dispatch('request', options)
                //   WebIM.utils.download.call(WebIM.conn, options);
              }
            })

          }
        // })
      // }
    },
  }
}
export default List;