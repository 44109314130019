 /**
  * 同事圈对象路由
  */
  const circleobject = [
      {
          path: "colleagueCircle",
          redirect: () => "/colleagueCircle/dynamic",
          name: "同事圈",
      },
      {
        path: "colleagueCircle/dynamic",
        component: () => import("@/views/colleagueCircle/main.vue"),
        name: "同事圈动态",
      },
      {
        path: "colleagueCircle/details",
        component: () => import("@/views/colleagueCircle/details.vue"),
        name: "用户详情页",
      },
   
  ]
export default circleobject;