const create = [
  {
    path: "home/create/:type/:objectApi/:objId/:prefix",
    component: () => import("./index.vue"),
    name: "创建数据",
  },
]


export default create
