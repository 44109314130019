// 通用对象使用组件
const TablePanel = () => import('./TablePanel/index.vue')
const ButtonWrapper = () => import('./ButtonWrapper/index.vue')
const EditableCell = () => import('./TablePanel/EditableCell.vue')
const Statistics = () => import('./TablePanel/statistics.vue')
const ViewButtons = () => import('./ButtonWrapper/viewButtons.vue')
const BaseForm = () => import('./Form/index.vue')
const InfoForm = () => import('./Form/info-form.vue')
const TransferOwnerDialog = () => import('./TransferOwnerDialog/index.vue')
const Upload = () => import('./Upload/index.vue')
const ChooseView = () => import('./ChooseView/index.vue')
const SearchWrapper = () => import('./SearchWrapper/index.vue')
const FilterPanel = () => import('./Filter/index.vue')
const LabelView = () => import('./LabelView/index.vue')
const Share = () => import('./Share/index.vue')
const Progress = () => import('./Progress/index.vue')
const wangEditor = () => import('./wangEditor/index.vue')
const PdfViewer = () => import('./PdfViewer/dialog-viewer.vue')
const AddFiles = () => import('./AddFiles/AddFiles.vue')
const BatchAddFiles = () => import('./AddFiles/batchAddFiles.vue')
const Distribution = () => import('./Distribution/index.vue')
const Submittranslation = () => import('./Submittranslation/index.vue')
const knowledgeindextablePanel = () => import('./TablePanel/knowledgeindex.vue')
const Articleversion = () => import('./Articleversion/index.vue')
const Release = () => import('./Release/index.vue')
const Dialog = () => import('./Dialog/index.vue')

export { TablePanel, EditableCell, Statistics, ButtonWrapper, ViewButtons, BaseForm, InfoForm, TransferOwnerDialog, Upload, ChooseView, SearchWrapper, FilterPanel, LabelView, Share, Progress, wangEditor, PdfViewer, AddFiles, knowledgeindextablePanel, Distribution, Submittranslation, Articleversion, Release, Dialog, BatchAddFiles}
