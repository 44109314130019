import CryptoJS from "crypto-js";

const keyCode = "646576636f6e736f6c652d7376633132"
// const keyCode = "6c6b6a3233346a6b6c6173646877636b"

export default {
    en(word, keyStr = keyCode) {
        let enc = CryptoJS.AES.encrypt(word, CryptoJS.enc.Hex.parse(keyStr), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return enc.ciphertext.toString()
    },

    de(word, keyStr = keyCode) {
        let dec = CryptoJS.AES.decrypt(CryptoJS.format.Hex.parse(word), CryptoJS.enc.Hex.parse(keyStr), {
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7
        })
        return CryptoJS.enc.Utf8.stringify(dec)
    }
};
