// 报表菜单使用的store

const report = {
  state: {
    lineCountButt: true, // 是否显示行计数
    lineCountButtDisabled: false, // 是否禁用行计数按钮
    reportPageIndex:1,//报表列表页当前页数
    reportPageSize:10,//报表列表页每页条数
    reportChooseFolder:'',//报表列表页当前选中的文件夹：最近查看，我创建的，所有报表或文件夹id
    searchContent:{},//搜索框内容,搜索类型：文件夹名称，报表名
  },
  mutations: {
    // 保存报表搜索框内容
    setreportSearchContent(state, data){
      state.searchContent = data;
    },
    // 保存报表当前文件夹
    setreportChooseFolder(state, data){
      state.reportChooseFolder = data;
    },
    // 保存报表列表页每页条数
    setReportPageSize(state, data){
      state.reportPageSize = data;
    },
    // 保存报表列表页当前页数
    setreportPageIndex(state, data){
      state.reportPageIndex = data;
    },
    // 设置行计数的值
    setLineCountButt(state, data) {
      state.lineCountButt = data;
    },
    // 设置禁用行计数的值
    setLineCountButtDisabled(state, e) {
      // 判断是否除了记录数没有进行其他字段的统计，设置为禁用
      if (e.gatherfieldname.length === 0 &&
        e.avgfield.length === 0 &&
        e.maxfield.length === 0 &&
        e.minfield.length === 0) {
        state.lineCountButtDisabled = true;
      } else {
        // 解除禁用
        state.lineCountButtDisabled = false;
      }
    },
  },
  getters: {
    // 获取报表搜索框内容
    getreportSearchContent(state){
      return state.searchContent;
    },
    // 获取报表当前文件夹
    getreportChooseFolder(state){
      return state.reportChooseFolder;
    },
    // 获取报表列表页每页条数
    getReportPageSize(state){
      return state.reportPageSize;
    },
    // 获取报表列表页当前页数
    getreportPageIndex(state) {
      return state.reportPageIndex;
    },
    // 获取行计数的值
    getLineCountButt(state) {
      return state.lineCountButt;
    },
    // 获取是否禁用行计数按钮
    getLineCountButtDisabled(state) {
      return state.lineCountButtDisabled;
    },
  },
  actions: {}
}
export default report;