//注入组件
const list = [
  {
    path: "commonObjects/views/:type/:objectApi/:objId/:prefix",
    component: () => import("@/views/List/index.vue"),
    name: "对象列表布局",
    // meta: {
    //   keepAlive: true, //需要缓存
    // },
  },
  {
    path: "commonObjects/multi-screen/:objectApi/:objId/:prefix",
    name: "分屏页",
    component: () => import("@/views/commonObjects/multi-screen.vue"),
  },
  {
    path: "commonObjects/console-multi-screen",
    name: "服务控制台分屏页",
    component: () => import("@/views/commonObjects/console-multi-screen.vue"),
    children: [
      // 控制台详情页
      {
        path: "console-detail/:id/:oprateType",
        name: "consoleDetail",
        component: () => import("@/views/commonObjects/detail.vue"),
        meta: {
          // keepAlive: true, //需要缓存
        },
      },
      // 控制台项目详情页
      {
        path: "console-project-detail/:id/:oprateType",
        name: "consoleProjectDetail",
        component: () => import("@/views/commonObjects/project-detail/index.vue"),
        meta: {
          // keepAlive: true, //需要缓存
        },
      },
      // 控制台自定义页面
      {
        path: "console-injection-component/:page/:pageId",
        component: () => import("@/views/injectionComponent/index.vue"),
        name: "consoleInjectionComponent",
        meta: {
          keepAlive: true, //需要缓存
          title: "注入组件",
        },
      },
      {
        // menu1新建
        path: "console-menu1-create/:objectApi/:objId/:prefix/:page",
        component: () => import("@/components/CCPage/create-obj.vue"),
        name: "consoleMenu1Create",
        props: true,
        meta: {
          keepAlive: true, //需要缓存
        },
      },
      {
        // menu2新建
        path: "console-menu2-create/:objectApi/:objId/:prefix/:page",
        component: () => import("@/components/CCPage/create-obj.vue"),
        name: "consoleMenu2Create",
        props: true,
        meta: {
          keepAlive: true, //需要缓存
        },
      },
      {
        // menu1编辑
        path: "console-menu1-edit/:objectApi/:objId/:prefix/:page",
        component: () => import("@/components/CCPage/edit-obj.vue"),
        name: "consoleMenu1Edit",
        props: true,
        meta: {
          // keepAlive: true, //需要缓存
        },
      },
      {
        // menu2新建
        path: "console-menu2-edit/:objectApi/:objId/:prefix/:page",
        component: () => import("@/components/CCPage/edit-obj.vue"),
        name: "consoleMenu2Edit",
        props: true,
        meta: {
          // keepAlive: true, //需要缓存
        },
      },
      // 详情页相关列表查看更多页面
      {
        path: "console-commonObjects/suspension-list/:id/:type",
        component: () => import("@/views/commonObjects/suspensionList.vue"),
        name: "consolecommonObjects",
        meta: {
          // keepAlive: true, //需要缓存
        },
      },
      //  派工平台
      {
        path: "console-dispatchingUnits",
        component: () => import("@/views/dispatchingUnits/dispatchingUnits.vue"),
        name: "consoleDispatchingUnits",
        meta: {
          title:'派工平台',
          // 是否展示左侧分屏视图列表 比如特殊页面无需展示，可以配此字段  如派工平台
          isShowLeftList: false,
          keepAlive: true, //需要缓存
        }
      },
    ]
  },
  {
    path: "commonObjects/detail/:id/:oprateType",
    name: "详情页",
    component: () => import("@/views/commonObjects/detail.vue"),
  },
  {
    path: "commonObjects/project-detail/:id/:oprateType",
    name: "项目详情页",
    component: () => import("@/views/commonObjects/project-detail/index.vue"),
  },
  {
    path: "commonObjects/tagList",
    component: () => import("@/views/TagList/index.vue"),
    name: "标签列表",
  },
  {
    path: "commonObjects/combination",
    component: () => import("@/components/ButtonWrapper/combination.vue"),
    name: "合并",
  },
  {
    path: "commonObjects/cnki",
    component: () => import("@/components/ButtonWrapper/cnki.vue"),
    meta: {
      keepAlive: true // 需要缓存
    },
    name: "查重合并",
  },
  {
    path: "commonObjects/cnkiResult/:objApi",
    component: () => import("@/components/ButtonWrapper/cnkiResult.vue"),
    name: "重复列表",
  },
  {
    path: "commonObjects/handleRepeat",
    component: () => import("@/components/ButtonWrapper/handleRepeat.vue"),
    name: "详细重复数据",
  },
  {
    path: "commonObjects/combinationRepeat",
    component: () => import("@/components/ButtonWrapper/combinationRepeat.vue"),
    name: "合并重复数据",
  },
  {
    path: "commonObjects/customer",
    component: () => import("@/views/commonObjects/customer.vue"),
    name: "客户层级",
  },
  {
    path: "commonObjects/suspension-list/:id/:type",
    component: () => import("@/views/commonObjects/suspensionList.vue"),
    name: "悬浮列表",
  },
  {
    path: "commonObjects/hopper",
    component: () => import("@/views/commonObjects/opportunity/sellHopper.vue"),
    name: "销售漏斗",
  },
  {
    path: "commonObjects/upgrade-hopper",
    component: () => import("@/views/commonObjects/opportunity/upgradeHopper.vue"),
    name: "销售阶段推进",
  },
  {
    path: "commonObjects/externalUsers/:id/:prefix/:objectApi",
    component: () => import("@/views/ExternalUsers/index.vue"),
    name: "外部用户",
  },
  {
    path: "commonObjects/maketingChannelListShow",
    component: () => import("@/views/marketList/marketingChannel/marketingChannelListShow.vue"),
    name: "营销渠道列表展示",
  },
  {
    path: "commonObjects/activeMember/:id",
    name: "活动成员",
    component: () => import("@/views/marketList/marketingChannel/activeMember.vue"),
  },
  {
    path: "commonObjects/invitation/:type/:objectApi/:objId/:prefix",
    component: () => import("@/views/marketList/marketingChannel/invitation.vue"),
    name: "invitation",
  },
  {
    path: "commonObjects/sendMail/:id/:userIds",
    name: "sendMail",
    component: () => import("@/views/marketList/marketingChannel/sendMail.vue"),
  },
  {
    path: 'marketingPostersListShow',
    name: 'marketingPostersListShow',
    component: () => import("@/views/marketList/marketingPosters/marketingPostersListShow.vue"),
  },
  {
    path: 'maketingSocialListShow',
    name: 'maketingSocialListShow',
    component: () => import("@/views/marketList/marketingSocial/marketingSocialListShow.vue"),
  },
  {
    path: 'maketingInvitationShow',
    name: 'maketingInvitationShow',
    component: () => import("@/views/marketList/marketingInvitation/marketingInvitationShow.vue"),
  },
  {
    path: 'marketingLandPageListShow',
    name: 'marketingLandPageListShow',
    component: () => import("@/views/marketList/marketingLandPage/marketingLandPageListShow.vue"),
  },
  {
    path: 'marketingFlowListShow',
    name: 'marketingFlowListShow',
    component: () => import("@/views/marketList/marketingFlow/marketingFlowListShow.vue"),
  },
]


export default list
