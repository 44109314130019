import normalizeWheel from 'normalize-wheel';

const isFirefox = typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('firefox') > -1;


let eventListener

// const mousewheel = function(element, callback) {
//   if (element && element.addEventListener) {
//     eventListener = function(event) {
//       const normalized = normalizeWheel(event);
//       callback && callback.apply(this, [event, normalized]);
//     }
//     element.addEventListener(isFirefox ? 'DOMMouseScroll' : 'mousewheel', eventListener);
//   }
// };

export default {
  bind(el, binding) {
    // mousewheel(el, binding.value);
    if (el && el.addEventListener) {
      eventListener = function(event) {
        const normalized = normalizeWheel(event);
        binding.value &&binding.value.apply(this, [event, normalized]);
      }
      el.addEventListener(isFirefox ? 'DOMMouseScroll' : 'mousewheel', eventListener);
    }
  },
  unbind(el){
    if(eventListener && el && el.addEventListener){
      el.removeEventListener(isFirefox ? 'DOMMouseScroll' : 'mousewheel', eventListener);
      eventListener = null
    }
  }
};
