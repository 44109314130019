import * as CreatePage from "./create"
import * as EditPage from "./edit"
import * as DetailPage from "./detail"
import * as ListPage from "./list"
import * as CustomPage from "./custom"

/**
 * 打开对象视图页面
 */
function openListPage(vue) {
    window.$CCDK.CCBus.$on('openListPage', (pageId, objectType, options) => {
        ListPage[options.openPlace + options.openMode](vue, pageId, objectType, options);
    })
}

/**
 * 打开数据详情页
 */
function openDetailPage(vue) {
    window.$CCDK.CCBus.$on('openDetailPage', (pageId, objectType, id, options) => {
        objectType.oprateType = 'DETAIL'
        let openMode = options.openPlace + options.openMode
        // if(options.openPlace !== 'tab'){
        //     openMode = options.openPlace + '_self'
        // }
        DetailPage[openMode](vue, pageId, objectType, id, options);
    })
}

/**
 * 打开创建页面
 */
function openCreatePage(vue) {
    window.$CCDK.CCBus.$on('openCreatePage', (pageId, obj = {}, options) => {
        let openMode = options.openPlace + options.openMode
        // if(options.openPlace !== 'tab'){
        //     openMode = options.openPlace + '_self'
        // }
        CreatePage[openMode](vue, pageId, obj, options);
    })
}

/**
 * 打开修改页面
 */
function openEditPage(vue) {
    window.$CCDK.CCBus.$on('openEditPage', (pageId, objectType, id, options) => {
        let openMode = options.openPlace + options.openMode
        // if(options.openPlace !== 'tab'){
        //     openMode = options.openPlace + '_self'
        // }
        EditPage[openMode](vue, pageId, objectType, id, options);
    })
}

/**
 * 打开自定义页面
 * @param {Object} vue vue对象实例
 * @param {Object} obj {pageApi:", data:""}
 * @param {Object} options包括 openPlace、openMode...等具体以文档为准 https://ccui.cloudcc.com/#/zh-CN/tool/ccpage
 */
function openCustomPage(vue) {
    window.$CCDK.CCBus.$on('openCustomPage', (pageId, obj, options) => {
        CustomPage[options.openPlace + options.openMode](vue, pageId, obj, options);
    })
}

/**
 * 安装工具
 */
function install(vue) {
    openListPage(vue)
    openEditPage(vue)
    openCreatePage(vue)
    openDetailPage(vue)
    openCustomPage(vue)
}

/**
 * 卸载工具
 */
function uninstall() {
    window.$CCDK.CCBus.$off('openListPage')
    window.$CCDK.CCBus.$off('openEditPage')
    window.$CCDK.CCBus.$off('openCreatePage')
    window.$CCDK.CCBus.$off('openDetailPage')
    window.$CCDK.CCBus.$off('openCustomPage')
}

export default { install, uninstall }
