/**
 * 在当前tab打开新页面
 * @param {*} vue vue实例
 * @param {*} pageId 自定义页面id
 */
function tab_self(vue, pageId, options) {
    vue.$router.push({
        path: `/injectionComponent?page=${pageId}&button=Home`,
    });
}
/**
 * 使用新tab打开新页面
 * @param {*} vue vue实例
 * @param {*} pageId 自定义页面id
 */
function tab_blank(vue, pageId, options) {
    vue.$router.push({
        path: `/injectionComponent1?page=${pageId}`,
    });

}
export { tab_self, tab_blank }