const INPUTTYPE = {
  U: 'input', // URL
  P: 'percent-number',// 百分比
  c: 'number',// 币种
  ct: 'select', // 币种类型
  TAG: 'tag',// 标签
  Y: 'remote-select',// 查找
  MR: 'remote-multi-select', // 查找多选
  LT: 'location',// 地理定位
  AD: 'address',// 地址
  H: 'input', // 电话
  E: 'input',// 电子邮件
  B: 'checkbox',// 复选框
  Z: 'input', // 公式
  R: 'select',// 记录类型
  enc: 'input',// 加密文本(存储加密)
  encd: 'input', // 加密文本(显示加密)
  C: 'number',// 累计汇总
  sum: 'number',// 累计汇总合计
  count: 'number', // 累计汇总记录数
  max: 'number',// 累计汇总最大
  min: 'number',// 累计汇总最小
  SCORE: 'score', // 评分
  D: 'date',// 日期
  F: 'datetime',// 日期/时间
  T: 'time', // 时间
  N: 'number',// 数字
  IMG: 'file',// 图片 -- Upload组件
  S: 'input',// 文本
  X: 'textarea',// 文本区
  A: 'kindeditor', // 文本区（富文本）-- wangEditor组件
  J: 'textarea',// 文本区（长）
  // L: 'file',// 文件 -- Upload组件
  FL: 'file',
  L: 'select',// 选项列表
  Q: 'multi-select', // 选项列表（多选）
  M: 'remote-select',// 主详
  V: 'input'// 自动编号
}

export default INPUTTYPE