/*
 * @Author: your name
 * @Date: 2021-09-08 20:14:11
 * @LastEditTime: 2021-09-10 12:23:32
 * @LastEditors: your name
 * @Description: In User Settings Edit
 * @FilePath: \lightning-web\src\utils\WebIM.js
 */
import config from "./WebIMConfig";

//创建连接
var WebIM = {};
WebIM = window.WebIM;
WebIM.config = config;
WebIM.conn = new WebIM.connection({
    appKey: WebIM.config.appkey,
    isHttpDNS: WebIM.config.isHttpDNS,
    isMultiLoginSessions: WebIM.config.isMultiLoginSessions,
    https: WebIM.config.https,
    url: WebIM.config.socketServer,
    apiUrl: WebIM.config.restServer,
    isAutoLogin: true,
    autoReconnectNumMax: WebIM.config.autoReconnectNumMax,
    autoReconnectInterval: 3,
    delivery: WebIM.config.delivery,
    useOwnUploadFun: WebIM.config.useOwnUploadFun
})
export default WebIM;

