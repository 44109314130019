import fetch from '@/config/fetch'
import utils from "@/utils/utils";
// 客户端脚本-查询当前组织已启用的脚本
const url = window.Glod['ccex-dev'] + '/script/listClientScript';
// const url='http://192.168.1.41:9000/devconsole/script/listClientScript'
/**
 * 展示右侧活动组件
 */
export function showDom() {
    var text = document.getElementById('activityAndDynamicPublics')
    if (text.style.display === 'none') {
        text.style.cssText = "display: block"
        var text1 = document.getElementById('listChild')
        text1.style.cssText = "width: calc(66.7% - 10px)"
        // text1.style.cssText = "width: 100%"

        var div2 = document.getElementsByClassName('layoutSwitchBox')
        for (let i = 0; i < div2.length; i++) {
            div2[i].style.cssText = "display: block"
        }
    }

}
// 所有特殊页面客户端脚本的pageId
export const specialPageIdList = {
    // 审批页面pageId
    approval: 'approvalRequest1671156348948',
}
/**
     * 调用客户端脚本
     * @param {Object} param: 获取客户端脚本接口返回的数据
     * 1、pageId：页面 add添加，list列表、edit编辑页detail详情页
     * 2、event：事件 onLoad、onSave
     * 3、objId：对象id commonObjects/views/:type/:objectApi/:objId/:prefix (注意业务机会新建报价单这种特殊调用)
     * 4、recordTypeId：记录类型id(视图列表接口要一直返回记录类型字段、新建信息的时候可能没有记录类型)
     */

export function listClientScript(param = {}, callback) {
    const clientParam = {
        head: {
            accessToken: utils.getBinding()
        },
        body: param
    }

    return fetch(url, clientParam, callback)
}
/**
 * onLoad
 * @param {Object} res: onLoad返回的客户端脚本
 */
export async function myOnLoad(res = {}) {
    if (res?.data && Array.isArray(res.data) && window.$CCDK) {
        for (let i = 0; i < res.data.length; i++) {
            if (res.data[i].scriptContent) {
                let newFun = Function('$CCDK', res.data[i].scriptContent)
                await newFun(window.$CCDK)
            }

        }

    }
}
/**
* onSave
* @param {Object} data: onLoad返回的客户端脚本
* 1、方法中需要传当前保存记录的id
* todo:一个保存前，这个方法返回ture，才会继续保存。一个是保存后，请求成功会调用
*/
export async function myOnSave(data = [], obj = {}) {
    if (obj.id, data?.length && window.$CCDK) {
        for (let i = 0; i < data.length; i++) {
            if (data[i].scriptContent) {
                let newFun = Function('$CCDK', 'obj', data[i].scriptContent)
                await newFun(window.$CCDK, obj)
            }

        }
    }
}