import router from "../router";
/**
 * 错误日志上报
 * @param {object} error 响应体
 */
function reportError(error) {
    if (process.env.NODE_ENV == 'production' && "1" === window.Glod['CC_LOG_LEVEL'] || "2" === window.Glod['CC_LOG_LEVEL']) {
        if (error.config.data) {
            try {
                let data = JSON.parse(error.config.data)
                if (data.head && data.head.accessToken) {
                    data.head.accessToken = "******"
                    error.config.data = JSON.stringify(data)
                }
                // eslint-disable-next-line no-empty
            } catch (e) { }
        }
        window.$CCDK.CCLog.reportLog(window.Glod['ccex-log'] + "/ccerrorlog", error, "error", {
            serviceName: "lightning-main", remark: error ? JSON.stringify(error) : "未知捕获异常"
        });
    }
}
/**
 * 常规日志上报
 * @param {object} response 响应体
 */
function reportInfo(response) {
    if (process.env.NODE_ENV == 'production' && "2" === window.Glod['CC_LOG_LEVEL']) {
        if (response.config.data) {
            try {
                let data = JSON.parse(response.config.data)
                if (data.head && data.head.accessToken) {
                    data.head.accessToken = "******"
                    response.config.data = JSON.stringify(data)
                }
                // eslint-disable-next-line no-empty
            } catch (e) { }
        }
        window.$CCDK.CCLog.reportLog(window.Glod['ccex-log'] + "/systeminfolog", response, "info", {
            serviceName: "lightning-main", busiType: router.app._route.name
        });
    }
}

export { reportError, reportInfo }