/**
 * liveChat 会话路由
 */
const liveChatRouter = [
    {
        path:'liveChat',
        component:()=> import("@/views/liveChat/index.vue"),
    },
    {
        path:'sendCode',
        component:()=> import("@/views/liveChat/sendCode/index.vue")
    },
    {
        path:'liveChatLogin',
        component:()=> import("@/views/liveChat/login/index.vue")
    }
  
]

export default liveChatRouter