<!-- 查找/查找多选弹框 -->
<template>
  <div>
    <!-- 无搜索条件时展示模糊搜索框 -->
    <div style="display:flex;">
      <!-- 在全部联系人中搜索 -->
      <span style="margin-right: 20px;height: 34px;
    line-height: 32px;">{{$t('vue_label_opportunity_contactrole_searchallcontacts')}}</span>
      <el-input v-model="searchKeyWord" class="searchKeyWord-input" style="flex:1;"> </el-input>
    </div>
    <!-- 列表 -->
    <table-panel
      class="text-center"
      ref="tablePanel"
      :border="true"
      :show-lock="false"
      :table-height="tableHeight"
      :show-index="false"
      :checked="checked"
      :pict-loading="pictLoading"
      :table-attr="tableAttr"
      :page-obj="pageObj"
      :noDataTip="noDataTip"
      :use-hyperlinks="false"
      @append="append"
      @onchange="onchange"
    >
    </table-panel>
  </div>
</template>

<script>
import { TablePanel } from '@/components/index'
import * as OperationalTeamApi from './api'

export default {
  name: 'SearchTable',
  components: { TablePanel },
  props: {
    fieldId: {
      type: String,
      default: ''
    },
    // 用户类型
    ownerType: {
      type: String,
      default: ''
    },
    relevantObjid: {
      type: String,
      default: ''
    },
    relevantObjapi: {
      type: String,
      default: ''
    },
    relevantPrefix: {
      type: String,
      default: ''
    },
    checked: {
      type: Boolean,
      default: true
    },
    ind: {
      type: Number,
      default: 0
    },
  },
  data () {
    return {
      prefix: '',
      objectApi: '',
      tableHeight: 400,
      objId: this.$route.params.objId,
      tableAttr: null,
      pageObj: {
        dataList: []
      },
      pictLoading: false,
      noDataTip: false,
      isSLookup: null,
      formAttr: {
        label: '123'
      },
      labelWidth: 100,
      page: 1, //页码默认为1
      pageSize: 20, // 每页展示数据条数
      searchKeyWord: '',
      selectValue: '',
      searchConditions: '',
      filterConditionVals: [''],
      recordType: '',
      recordOptions: [],
      level: 0,
      isquickcreated: false // 是否支持快速新建
    }
  },
  created () {
    var _this = this;
    document.onkeydown = function () {   //按下回车提交
      let key = window.event.keyCode
      //事件中keycode=13为回车事件
      if (key == 13) {
        _this.search()
      }
    }
    // this.init()
  },
  methods: {
    init () {
      this.page = 1
      // this.formAttr = []
      this.searchKeyWord = ''
      this.pageObj.dataList = []
      this.getLookupData()
    },
    // 搜索
    search () {
      this.page = 1
      this.pageObj.dataList = []
      this.getLookupData()
    },
    // 滚动加载数据
    append () {
      if (!this.noDataTip) {
        this.getLookupData()
      }
    },
    // 获取查找数据
    getLookupData () {
      let data = {
        opportunityId: this.$route.params.id,
        searchKeyWord: this.searchKeyWord,
        page: this.page,
        pageSize: this.pageSize,
      }
      OperationalTeamApi.queryContactList(data).then(res => {
        if (res.result && res.returnCode === '1') {
          this.$emit('isDisabledNext')
          // 页码为1时重新请求表头
          if (this.page === 1) {
            res.data.fieldlist.forEach(val => {
              this.$set(val, 'nameLabel', val.labelname)
              this.$set(val,'schemefieldName',val.apiname)
              this.$set(val,'schemefieldType',val.fieldtype)
              delete val.labelname
              delete val.fieldtype

              // 名称、查找、主详、查找多选均可跳转
              // 事件及任务下的主题、名称、相关项可跳转
              if (
                val.schemefieldName === "name" ||
                val.schemefieldType === "Y" ||
                val.schemefieldType === "M" ||
                val.schemefieldType === "MR" ||
                val.schemefieldName === "subject" ||
                val.lookupObj === "user" ||
                val.schemefieldName === "whoid" ||
                val.schemefieldName === "relateid"
              ) {
                this.$set(val, "click", "detail");
              }
            })
              this.tableAttr = res.data.fieldlist
              this.$refs.tablePanel.changeTableAttr(this.tableAttr)
            }
          this.totalSize = res.data.total
          // if (res.data.datalist) {
            if (res.data.datalist && res.data.datalist.length === 0 && data.page === 1) {
              this.pageObj.dataList = []
            } else if (this.pageObj.dataList.length === 0 || data.page === 1) {
              if (res.data.datalist.length > this.pageSize) {
                this.pageObj.dataList = res.data.datalist.slice(0, res.data.datalist.length - 1)
              } else {
                this.pageObj.dataList = res.data.datalist
              }
              this.page++
            } else {
              if (res.data.datalist.length > this.pageSize) {
                this.pageObj.dataList = [
                  ...this.pageObj.dataList,
                  ...res.data.datalist.slice(0, res.data.datalist.length - 1)
                ]
              } else {
                this.pageObj.dataList = [
                  ...this.pageObj.dataList,
                  ...res.data.datalist
                ]
              }

              this.page++
              
            // }
              this.$refs.tablePanel.dataList = this.pageObj.dataList
            if (Number(res.data.datalist.length) === this.pageSize) {
              this.noDataTip = false
            } else {
              this.noDataTip = true
            }
            this.pictLoading = false
            this.pageObj.total = res.data.total
          }
        } else {
          this.pictLoading = false
          this.tableAttr = []
          this.pageObj.total = 0
          this.pageObj.dataList = []
          this.$message.error(res.stackTrace)
        }
      })
    },
 
    onchange(rows){
      this.$emit('addContactChange',rows)
    },
 
    // 新建数据
    // create () {
    //   // 传参：对象前缀、对象api
    //   let objApi = this.relevantObjapi ? this.relevantObjapi : this.objectApi
    //   window.open(`#/add-relevant-obj/${this.relevantPrefix}/${objApi}/${this.level}`)
    // },
    // 清空选中数据
    clearSelect () {
      this.$emit('changeSelect', [])
    },
    cancel () {
      this.$emit('cancel')
    },
    // listenStorage () {
    //   window.addEventListener('storage', (event) => {
    //     // 监听relevantObjectLevel变化
    //     if (event.key === 'relevantObjectLevel') {
    //       let objectApi = localStorage.getItem('relevantObjectApi')
    //       let level = localStorage.getItem('relevantObjectLevel')
    //       if (level - 1 === this.level && objectApi === this.objectApi) {
    //         this.page = 1
    //         this.pageObj.dataList = []
    //         this.getLookupData()
    //         localStorage.setItem('relevantObjectLevel', Number(localStorage.getItem('relevantObjectLevel')) - 1)
    //       }
    //     }
    //   })
    // }
  },
  watch: {
    recordType () {
      this.$nextTick(() => {
        this.pageObj.dataList = []
        this.getLookupInfo()
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.searchKeyWord-input {
  width: 50%;
  margin-bottom: 10px;
}

::v-deep .el-input__inner {
  height: 34px;
}

::v-deep .el-form-item {
  margin-bottom: 10px;
}
</style>
