// home使用的store,处理全局使用的公共状态

const home = {
    state: {
        // 全局使用的弹窗层级
        // 主要是home文件夹中应用程序菜单和电话条使用
      zIndex: 2900,
      // 当前应用程序的id
      homeApplicationId:sessionStorage.getItem("applicationId") || localStorage.getItem("application") || '',
    },
    mutations: {
      // 全局使用的弹窗层级
      setzIndex(state, data) {
        state.zIndex = data;
      },
      // 更改当前应用程序id
      setHomeApplicationId(state, data){
        sessionStorage.setItem("applicationId",data)
        state.homeApplicationId = data;
      },
    },
    getters: {

    },
    actions: {}
  }
  export default home;
