
//回收站页面路由文件
const recycle = [
  {
    path: "recycle",
    component: () => import("./index.vue"),
    name: "回收站",
  },
];

export default recycle;
