<template>
  <!-- 布局切换按钮 -->
  <ul class="changeColumnBtnGroup">
    <li
      @click="changeColumns('twoColumns')"
      style="border-right: 1px solid #dedcda"
    >
      <svg
        v-if="detailMode === 'twoColumns'"
        class="icon setColumnsIcon"
        aria-hidden="true"
      >
        <use href="#icon-twoColumns-active"></use>
      </svg>
      <svg v-else class="icon setColumnsIcon" aria-hidden="true">
        <use href="#icon-twoColumns"></use>
      </svg>
    </li>
    <li @click="changeColumns('responsiveLayout')">
      <svg
        v-if="detailMode === 'responsiveLayout'"
        class="icon setColumnsIcon autoColumnsIcon"
        aria-hidden="true"
      >
        <use href="#icon-autoColumns-active"></use>
      </svg>
      <svg
        v-else
        class="icon setColumnsIcon autoColumnsIcon"
        aria-hidden="true"
      >
        <use href="#icon-autoColumns"></use>
      </svg>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    detailMode: {
      type: String,
      default: ''
    }
  },
    methods:{
        changeColumns(param){
            this.$emit('changeColumns',param)
        }
    }
};
</script>

<style lang="scss" scoped>
  .changeColumnBtnGroup {
    display: flex;
    height: 30px;
    line-height: 30px;
    border: 1px solid #dedcda;
    border-radius: 3px;
  
    li {
        display: flex;
        justify-content: center;
        align-items: center;
      width: 30px;
      height: 30px;

  
      
    }
    .setColumnsIcon {
    width: 12px;
    height: 28px;
  }
  
  .autoColumnsIcon {
    width: 16px;
    // margin-left: 7px;
  }
  }
</style>