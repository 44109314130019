
/**
 * 使用菜单打开标准详情
 * @param {*} vue vue实例
 * @param {*} pageId 自定义页面id
 */
function menu2_self(vue, pageId, obj={}, id, options) {
    // 菜单数据
    let menuObj = {
      name: obj.objectName,
      id: pageId,
      routerName: 'consoleDetail',
      params: {
          page: pageId,
          ...obj,
          id,
          ...options,
      }
  }
  // window.$CCDK.CCMenu.addMenu2(menuObj)
  window.$CCDK.CCMenu.replaceMenu2(menuObj)
  // let routeData = vue.$router.resolve({
  //   name: 'consoleDetail',
  //   params: {
  //     page: pageId,
  //     ...obj,
  //     id,
  //     ...options,
  //   }
  // });
  // window.open(routeData.href, options.openMode);
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    window.$CCDK.CCMenu.deleteMenu2({id:pageId})
  }
  window.$CCDK.CCPage.addPage(pageId,page)
  vue.$router.push({
    name: 'consoleDetail',
    params: {
      page: pageId,
      ...obj,
      id,
      ...options,
    }
  });
}
function menu2_blank(vue, pageId, obj={}, id, options) {
  // 菜单数据
  let menuObj = {
    name: obj.objectName,
    id: pageId,
    routerName: 'consoleDetail',
    // 一级菜单id
    menu1PageId: options.menu1PageId,
    params: {
        page: pageId,
        ...obj,
        id,
        ...options,
    }
  }
  window.$CCDK.CCMenu.addMenu2(menuObj)
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    window.$CCDK.CCMenu.deleteMenu2({id:pageId})
  }
  window.$CCDK.CCPage.addPage(pageId,page)
  // 只有在当前选中的一级菜单中打开二级菜单才需要跳转
  // 两种情况  1、没有传menu1PageId 没有传则默认在当前选中的一级菜单中打开 则需要跳转
  //          2、传了menu1PageId 但是menu1PageId与当前选中的一级菜单id一样  则需要跳转
  if (!options.menu1PageId || (options.menu1PageId && options.menu1PageId == vue.$store.state.consoleData.data.level1Id)) {
      // 传了父级id指定了在哪个一级菜单中打开  说明不是在当前选中的一级菜单中打开 如果不是在当前选中的一级菜单中打开二级菜单那么就不跳转
      vue.$router.push({
          name: 'consoleDetail',
          params: {
              page: pageId,
              ...obj,
              id,
              ...options,
          }
      })
  }
}
/**
 * 使用菜单打开标准详情
 * @param {*} vue vue实例
 * @param {*} pageId 自定义页面id
 */
 function menu1_self(vue, pageId, obj={}, id, options) {
    // 菜单数据
    let menuObj = {
      name: obj.objectName,
      id: pageId,
      routerName: 'consoleDetail',
      params: {
          page: pageId,
          ...obj,
          id,
          ...options,
      }
  }
  // window.$CCDK.CCMenu.addMenu1(menuObj)
  window.$CCDK.CCMenu.replaceMenu1(menuObj)
  // let routeData = vue.$router.resolve({
  //   name: 'consoleDetail',
  //   params: {
  //     page: pageId,
  //     ...obj,
  //     id,
  //     ...options,
  //   }
  // });
  // window.open(routeData.href, options.openMode);
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    window.$CCDK.CCMenu.deleteMenu1({id:pageId})
  }
  window.$CCDK.CCPage.addPage(pageId,page)
  vue.$router.push({
    name: 'consoleDetail',
    params: {
      page: pageId,
      ...obj,
      id,
      ...options,
    }
  });
}
function menu1_blank(vue, pageId, obj={}, id, options) {
  // 菜单数据
  let menuObj = {
    name: obj.objectName,
    id: pageId,
    routerName: 'consoleDetail',
    params: {
        page: pageId,
        ...obj,
        id,
        ...options,
    }
}
window.$CCDK.CCMenu.addMenu1(menuObj)
// 添加页面关闭方法
let page = {}
page.close = ()=>{
  window.$CCDK.CCMenu.deleteMenu1({id:pageId})
}
window.$CCDK.CCPage.addPage(pageId,page)
vue.$router.push({
    name: 'consoleDetail',
    params: {
      page: pageId,
      ...obj,
      id,
      ...options,
    }
  });
}
/**
 * 以tab打开详情
 * @param {*} vue this
 * @param {*} obj 对象信息
 * @param {*} options 对象方式信息
 */
 function tab_self(vue, pageId, obj, id, options) {
  //
  tab_blank(vue, pageId, obj,id, options)
}
function tab_blank(vue, pageId, obj, id,options) {
  let routerName = 'consoleTabDetail'
  // 项目详情页
  if(obj.type === 'project'){
    routerName = 'consoleTabDetailProject'
  }
  let routeData = vue.$router.resolve({
    name: routerName,
    params: {
      page: pageId,
      ...obj,
      id,
      ...options,
    }
  });
  let oNewWindow = window.open(routeData.href, options.openMode);
  // 添加页面关闭方法
  let page = {}
  page.close=()=>{
      oNewWindow.close()
  }
  window.$CCDK.CCPage.addPage(pageId,page)
}
export {tab_blank, tab_self, menu2_self, menu2_blank, menu1_self, menu1_blank }
