/**
 * 仪表板菜单路由文件
 */
const dashboardObject = [
  {
    path: "dashboardObject",
    redirect: () => "/dashboardObject/dashBoardList",
    name: "仪表板菜单",
  },
  {
    path: "dashboardObject/dashboardIndex/:dashboardid",
    // dashboardid：仪表板id
    component: () =>
      import("@/views/dashboardObject/dashboardIndex/index.vue"),
    name: "仪表板首页",
  },
  {
    path: "dashboardObject/dashBoardList",
    component: () =>
      import("@/views/dashboardObject/dashBoardList/index.vue"),
    name: "仪表板列表",
  },
];

export default dashboardObject;
