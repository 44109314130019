<template>
  <!-- 报错弹框 -->
  <el-dialog
    v-if="
      response &&
      response.data &&
      response.data.returnInfo &&
      // info中包含这个消息得都不弹，接口超时
      response.data.returnInfo.indexOf('Cannot get a connection') === -1 &&
      // 服务重启，不弹
      response.data.returnCode != `11-300-APICallFailed`
    "
    :title="$t('label_popup_infoz')"
    :visible.sync="errorDialogVisible"
    width="30%"
    :close-on-click-modal="false"
    class="error-dialog"
  >
    <div class="errorInfo" style="font-size: 14px; color: #080707">
      <!-- <p class="auto-wrap">{{ response.data.returnInfo | handleErrMessage }}</p> -->
      <!-- 错误描述信息 -->
      <p class="auto-wrap">{{ $t(handleInfo(response)) }}</p>
      <div class="error-detail">
        <!-- 错误详情 -->
        <span>{{ $t("front-dialog-module-v1-error-detail") }}</span>
        <span
          @click="isOpen = !isOpen"
          style="cursor: pointer"
          :class="isOpen ? 'el-icon-arrow-up' : 'el-icon-arrow-down'"
        ></span>
      </div>
      <div style="color: #666666" v-show="isOpen">
        <div>
          <!-- 错误码 -->
          <span>{{ $t("front-dialog-module-v1-error-code") }}</span>
          {{ handleCode(response) }}
        </div>
        <div class="labelId">
          <!-- 错误请求id -->
          <span class="title">{{
            $t("front-dialog-module-v1-requestid")
          }}</span>
          <span class="detail">
            {{ handleRequeseId(response) }}
          </span>
          <span
            class="el-icon-copy-document copy-btn"
            @click="copyText(handleRequeseId(response))"
          ></span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button type="primary" @click="errorDialogVisible = false">
        {{ $t("label.cancel") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import { copyText } from "@/utils/copyInfo";
export default {
  data() {
    return {
      // 控制dialog显隐
      errorDialogVisible: true,
      // 详细信息开关，默认收起
      isOpen: false,
      // 错误信息返回体
      response: {},
    };
  },
  filters: {
    /**
     * 处理异常提示，弹窗只展示returnInfo中的reason
     * @param {String} returnInfo:错误提示
     * @return {String} errMessage:处理后的错误提示
     */
    handleErrMessage(returnInfo = "") {
      if (!returnInfo) {
        returnInfo = "";
      }
      let errMessage = returnInfo;
      let errAry = returnInfo.split(",");
      if (Array.isArray(errAry)) {
        errAry.forEach((item) => {
          let err = item.split(":");
          if (Array.isArray(err) && err[0] === "reason") {
            errMessage = err[1] || returnInfo;
          }
        });
      }
      return errMessage;
    },
  },
  methods: {
    copyText,
    /**
     * 处理描述信息
     */
    handleInfo(response) {
      // 接口404异常处理
      if (404 === response.status) {
        // 服务地址404，请联系管理员
        return "c1953";
      }
      // 接口访问失败处理
      if ("11-300-APICallFailed" == response.data.returnCode) {
        return "c1954";
      }
      return response.data.returnInfo;
    },
    /**
     * 处理返回码
     */
    handleCode(response) {
      if (404 === response.status) {
        return "404";
      }
      return response.data.returnCode;
    },
    /**
     * 处理请求id
     */
    handleRequeseId(response) {
      if (404 === response.status) {
        return response.config.url;
      }
      return response.data.requestId;
    },
    /**
     * 通过启动在线支持请求节约时间，与CloudCC服务专家零距离沟通
     */
    zeroDistanceCommunication() {
      window.open(
        `${window.Glod['ccex-online']}/#/index/mainLogin?accessToken=${this.$CCDK.CCToken.getToken()}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
// 全局弹框
.errorInfo {
  .auto-wrap {
    word-wrap: break-word;
    word-break: normal;
    word-break: break-all;
    margin-bottom: 8px;
  }
  .error-detail {
    padding: 5px 0;
  }
  .labelId {
    display: flex;
    .detail {
      flex: 1;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  span {
    margin-right: 10px;
  }
  .copy-btn {
    cursor: pointer;
    float: right;
    margin-top: 3px;
    color: #080707;
  }
}
</style>