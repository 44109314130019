<template>
  <!-- 开发人员：王雪茹 -->
  <div class="pop_box">
    <!-- 管理小组 -->
    <el-dialog
      :title="$t('vue_label_commonobjects_detail_team')"
      :visible="popTableVisible"
      width="75%"
      :before-close="closePopTableDialog"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      class="products"
    >
      <!-- 客户小组 -->
      <template
        v-if="
          relatedListObj && relatedListObj.relatedlistType === 'accountteam'
        "
      >
        <el-form
          :model="popForm"
          ref="popForm"
          :rules="popFormRules"
          class="demo-ruleForm mar-t-20"
          size="mini"
          label-position="left"
        >
          <el-table
            :data="popForm.etyyStr"
            :key="tableKeyPop"
            style="width: 100%"
            :header-cell-style="{
              background: '#DDDBDA',
              color: '#333333',
              fontWeight: 400,
            }"
          >
            <!-- 用户 -->
            <el-table-column :label="$t('label.document.history.user')">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.useridccname'"
                  :rules="popFormRules.useridccname"
                >
                  <el-select
                    v-model="scope.row.useridccname"
                    filterable
                    clearable
                    class="no-suffix"
                    style="width: 100%"
                    remote
                    :remote-method="(query) => remoteMethod(query)"
                    @change="changeEvent(scope, arguments[0])"
                  >
                    <p style="margin: 5px 0px">
                      <span
                        @click="remoteSearch(scope.$index)"
                        style="display: inline-block; width: 100%"
                      >
                        <i class="el-icon-search" style="margin-left: 10px"></i>
                        <span style="padding-left: 10px; cursor: pointer"
                          ><!-- 前往精准搜索 -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_to_precise_search"
                            )
                          }}</span
                        >
                      </span>
                    </p>
                    <el-option
                      v-for="item in optionsArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                    <el-option
                      v-show="false"
                      key="new"
                      :label="$t('label.new')"
                      value="新建"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="remoteBtn"
                    @click="remoteSearch(scope.$index)"
                    style="display: block; top: 1px; right: 9px"
                  >
                    <!-- [查找] -->
                    <i class="el-icon-search"></i>
                  </span>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 小组角色 -->
            <el-table-column :label="$t('label.salescloud.teamrole')">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.teammemberrole'"
                  :rules="popFormRules.teammemberrole"
                >
                  <!-- 请选择 -->
                  <el-select
                    v-model="scope.row.teammemberrole"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    clearable
                    size="small"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in rolesArr"
                      :key="item.codetext"
                      :label="item.codetext"
                      :value="item.codevalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 业务机会访问权限 -->
            <el-table-column :label="$t('lable.customer.access')">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.opportunityaccesslevel'"
                  :rules="popFormRules.opportunityaccesslevel"
                >
                  <el-select
                    v-model="scope.row.opportunityaccesslevel"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    clearable
                    size="small"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in levelsArr"
                      :key="item.codevalue"
                      :label="item.codetext"
                      :value="item.codevalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 相关对象权限 -->
            <el-table-column label="相关对象权限">
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.xglbqx'"
                >
                  <el-select
                    v-model="scope.row.xglbqx"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    clearable
                    size="small"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in objArr"
                      :key="item.codevalue"
                      :label="item.codetext"
                      :value="item.codevalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 相关对象权限 -->
            <el-table-column
              v-for="item in accountTableHeader"
              :key="item.name"
              :label="item.label"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.' + item.name"
                >
                  <!-- <el-input v-model="scope.row[item.name]"></el-input> -->
                  <EditableCell
                    ref="editableCell1"
                    :inputType="item.inputType"
                    :fieldId="item.id"
                    :field="item.name"
                    :fieldName="item.name"
                    :name="item.nameLabel"
                    :lineIndex="scope.$index"
                    :options="
                      item.type === 'L'? item.options:
                      (item.type === 'Y' || 'R'
                        ? scope.row[item.name]
                          ? [
                              {
                                value: scope.row[item.name],
                                label: scope.row[`${item.name}ccname`],
                              },
                            ]
                          : []
                        : [])
                    "
                    :disabled="item.modify === 'false' ? true : false"
                    :value="{ value: scope.row[item.name] }"
                    @remoteSearch="remoteSearchAccountTeam(item, scope)"
                    @editValueChange="editValueChange"
                  ></EditableCell>
                </el-form-item>
              </template>
            </el-table-column>

            <!-- 操作 -->
            <el-table-column
              v-if="!showType"
              :label="$t('label_partner_all_operate')"
              width="100"
              class="cell_btn"
            >
              <template slot-scope="scope">
                <div class="btn_all">
                  <el-button
                    @click="delShopList(scope.$index, scope)"
                    v-if="popForm.etyyStr.length !== 1"
                    size="mini"
                    class="btn_s"
                    icon="el-icon-minus"
                    v-preventReClick
                  ></el-button>
                  <el-button
                    @click="addShopList()"
                    size="mini"
                    v-if="popForm.etyyStr.length - 1 === scope.$index"
                    class="btn_s"
                    icon="el-icon-plus"
                    v-preventReClick
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
      <!-- 业务机会小组 -->
      <template
        v-if="
          relatedListObj && relatedListObj.relatedlistType === 'opportunityteam'
        "
      >
        <el-form
          :model="popForm"
          ref="popForm"
          :rules="popFormRules"
          class="demo-ruleForm mar-t-20"
          size="mini"
          label-position="left"
        >
          <el-table
            :data="popForm.etyyStr"
            :key="tableKeyPop"
            style="width: 100%"
            :header-cell-style="{
              background: '#DDDBDA',
              color: '#333333',
              fontWeight: 400,
            }"
          >
            <!-- 用户 -->
            <el-table-column
              :label="$t('label.document.history.user')"
              min-width="130"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.useridccname'"
                  :rules="popFormRules.useridccname"
                >
                  <el-select
                    v-model="scope.row.useridccname"
                    filterable
                    clearable
                    class="no-suffix"
                    style="width: 100%"
                    remote
                    :remote-method="(query) => remoteMethod(query)"
                    @change="changeEvent(scope, arguments[0])"
                  >
                    <p style="margin: 5px 0px">
                      <span
                        @click="remoteSearch(scope.$index)"
                        style="display: inline-block; width: 100%"
                      >
                        <i class="el-icon-search" style="margin-left: 10px"></i>
                        <span style="padding-left: 10px; cursor: pointer"
                          ><!-- 前往精准搜索 -->
                          {{
                            $t(
                              "vue_label_commonobjects_detail_to_precise_search"
                            )
                          }}</span
                        >
                      </span>
                    </p>
                    <el-option
                      v-for="item in optionsArr"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                    <el-option
                      v-show="false"
                      key="new"
                      :label="$t('label.new')"
                      value="新建"
                    >
                    </el-option>
                  </el-select>
                  <span
                    class="remoteBtn"
                    @click="remoteSearch(scope.$index)"
                    style="display: block; top: 1px; right: 9px"
                  >
                    <!-- [查找] -->
                    <i class="el-icon-search"></i>
                  </span>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 小组角色 -->
            <el-table-column
              :label="$t('label.salescloud.teamrole')"
              min-width="130"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.teammemberrole'"
                  :rules="popFormRules.teammemberrole"
                >
                  <!-- 请选择 -->
                  <el-select
                    v-model="scope.row.teammemberrole"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    clearable
                    size="small"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in rolesArr"
                      :key="item.codetext"
                      :label="item.codevalue"
                      :value="item.codetext"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 业务机会访问权限 -->
            <el-table-column
              :label="$t('label.salescloud.opportunity.access')"
              min-width="90"
            >
              <template slot-scope="scope">
                <el-form-item
                  :prop="'etyyStr.' + scope.$index + '.opportunityaccesslevel'"
                  :rules="popFormRules.opportunityaccesslevel"
                >
                  <el-select
                    v-model="scope.row.opportunityaccesslevel"
                    :placeholder="$t('label_tabpage_pleaseSelectz')"
                    clearable
                    size="small"
                    style="width: 100%"
                  >
                    <el-option
                      v-for="item in levelsArr"
                      :key="item.codevalue"
                      :label="item.codetext"
                      :value="item.codevalue"
                    ></el-option>
                  </el-select>
                </el-form-item>
              </template>
            </el-table-column>
            <!-- 操作 -->
            <el-table-column
              v-if="!showType"
              :label="$t('label_partner_all_operate')"
              width="100"
              class="cell_btn"
            >
              <template slot-scope="scope">
                <div class="btn_all">
                  <el-button
                    @click="delShopList(scope.$index, scope)"
                    v-if="popForm.etyyStr.length !== 1"
                    size="mini"
                    class="btn_s"
                    icon="el-icon-minus"
                    v-preventReClick
                  ></el-button>
                  <el-button
                    @click="addShopList()"
                    size="mini"
                    v-if="popForm.etyyStr.length - 1 === scope.$index"
                    class="btn_s"
                    icon="el-icon-plus"
                    v-preventReClick
                  ></el-button>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
      </template>
      <span slot="footer" class="dialog-footer">
        <el-button @click="popTableClose('popForm')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}
        </el-button>
        <el-button
          type="primary"
          @click="popSubmit('popForm')"
          size="small"
          v-preventReClick
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 选择价格手册 -->
    <el-dialog
      :title="$t('lable.opppdt.selPbk.title')"
      :visible="manualVisible"
      width="28%"
      :before-close="closeManualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
      class="manual"
    >
      <el-form
        :model="manualForm"
        :rules="manualRules"
        ref="manualForm"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <div class="prompt">
          <!-- 您只能从单个价格手册添加产品，更改已选取价格手册将从该业务机会中删除全部现有的产品。 -->
          {{ $t("lable.opppdt.selPbk.description") }}
        </div>
        <el-form-item :label="$t('lable.opppdt.selPbk.title')" prop="region">
          <el-select v-model="manualForm.region" @change="changeListPriceBook">
            <el-option
              v-for="val in pricebooks"
              :key="val.id"
              :label="val.name"
              :value="val.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="manualClose('manualForm')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="manualNext('manualForm')"
          size="small"
        >
          <!-- 下一步 -->
          {{ $t("UG_Forcast_09") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 业务机会添加产品弹框 -->
    <ProductFilter
      ref="productFilter"
      v-if="productFilterShow"
      :addmanualVisible.sync="productFilterShow"
      :isOpportunity="true"
      :addmanualForm="addmanualForm"
      :prefix="prefix"
      :pricSelect="manualForm.region"
      :currencyVal="currencyMain"
      searchProductsId="ffe201100006408jOiKp"
      :optionst_one="optionst_one"
      @remoteSearchs="remoteSearchsPric"
      @confirmAddProduct="confirmAddProduct"
    ></ProductFilter>
    <!-- 添加产品 -->
    <el-dialog
      :title="$t('vue_label_opportunity_product_addproduct')"
      :visible="addmanualVisible"
      width="75%"
      :before-close="closeAddmanualDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <!-- 当前价格手册： -->
      <!-- <div class="pop_tit">
        {{ $t("vue_label_pricebook_book") }}：{{ defaultManual }}
      </div> -->
      <div class="btn_box">
        <span @click="closeFilter">{{ filtername }}</span>
        <!-- 进行筛选 -->
        <span @click="filterBtn">{{ $t("label.mobile.phone.screen") }}</span>
        <!-- 清除条件 -->
        <span @click="cleanFilter">{{ $t("label.delete.condition") }}</span>
      </div>
      <el-form
        :model="addmanualForm"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
        v-show="filterFlag"
      >
        <!-- 筛选器 -->
        <div
          class="screen_s"
          v-for="(ev, index) in addmanualForm.list"
          :key="index"
        >
          <el-form-item>
            <span style="width: 20px">{{ index + 1 }}</span>
            <el-select
              v-model="ev.fieldId"
              :placeholder="$t('vue_label_commonobjects_detail_field')"
              class="select_one"
              filterable
              @change="selectChange($event, index)"
            >
              <el-option
                v-for="item in optionst_one"
                :key="item.id"
                :label="item.labelName"
                :value="item.id"
              ></el-option>
            </el-select>
            <!-- 运算符 -->
            <el-select
              v-model="ev.op"
              :placeholder="$t('label.operator')"
              class="select_two"
              filterable
            >
              <el-option
                v-for="item in ev.optionst_two"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
            <EditableCell
              ref="editableCell"
              :inputType="ev.inputType"
              :options="selecArr"
              :value="ev.val"
              :max="1000000000000000000"
              @remoteSearch="remoteSearchs(ev, index)"
              style="width: 38%; position: relative"
            ></EditableCell>
            <div style="width: 100px">
              <span
                class="filter_btn"
                @click="dels(index)"
                v-if="addmanualForm.list.length !== 1"
                >-</span
              >
              <span
                class="filter_btn"
                @click="adds"
                v-if="
                  addmanualForm.list.length - 1 === index &&
                  addmanualForm.list.length <= 4
                "
                >+</span
              >
            </div>
          </el-form-item>
        </div>
      </el-form>
      <el-table
        ref="addmanualData"
        :data="addmanualData"
        v-loadmore="loadMore"
        v-loading="tableLoading"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 20px"
        :header-cell-style="{
          background: '#DDDBDA',
          color: '#333333',
          fontWeight: 400,
        }"
        class="add_table"
        :empty-text="$t('label.dashboard.noRecord')"
        height="260px"
        @selection-change="handleSelectionChange"
      >
        <el-table-column
          v-if="!createEdit"
          type="selection"
          width="55"
        ></el-table-column>
        <!-- 产品名称 -->
        <el-table-column prop="name" :label="$t('label.product.name')">
          <template slot-scope="scope">
            <span
              @click="choseProduct(scope.row)"
              style="color: #006dcc; cursor: pointer"
              >{{ scope.row.name || "-"  }}</span
            >
          </template>
        </el-table-column>
        <!-- 产品代码 -->
        <el-table-column :label="$t('label.product.code')">
          <template slot-scope="scope">{{ scope.row.cpdm || "-" }}</template>
        </el-table-column>
        <!-- 产品规格 -->
        <el-table-column :label="$t('c1029')">
          <template slot-scope="scope">
            {{ scope.row.productspecification || "-" }}
          </template>
        </el-table-column>
        <!-- 产品系列 -->
        <el-table-column :label="$t('label.product.series')">
          <template slot-scope="scope">{{ scope.row.cpxl || "-" }}</template>
        </el-table-column>
        <!-- 标准价格 -->
        <el-table-column :label="$t('lable.product.standp')" prop="createdate">
          <template slot-scope="scope">
            <span v-show="scope.row.productprice">{{
              scope.row.productCurrency
            }}</span>
            {{ scope.row.productprice || "-" }}
          </template>
        </el-table-column>
        <!-- 价目表价格 -->
        <el-table-column label="价目表价格" prop="createdate">
          <template slot-scope="scope">
            <span v-show="scope.row.listprice">{{
              scope.row.itemCurrency
            }}</span>
            {{ scope.row.listprice || "-" }}</template
          >
        </el-table-column>
      </el-table>
      <span v-if="!createEdit" slot="footer" class="dialog-footer">
        <el-button @click="addmanualClose" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button type="primary" @click="addmanualNext" size="small"
          ><!-- 下一步 -->
          {{ $t("UG_Forcast_09") }}</el-button
        >
      </span>
      <div v-else style="height: 20px"></div>
    </el-dialog>
    <!-- 编辑现有产品 -->
    <el-dialog
      :title="$t('lable.opppdt.editPdt.title')"
      :visible="productsVisible"
      width="75%"
      :before-close="closeProductsDialog"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
      class="products"
    >
      <el-form
        :model="productsForm"
        ref="productsForm"
        :rules="productsFormRules"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
      >
        <el-table
          :data="productsForm.opppdts"
          :key="tableKeyPro"
          :header-cell-class-name="starAdd"
          v-loading="addProductLoading"
          style="width: 100%"
          :header-cell-style="{
            background: '#DDDBDA',
            color: '#333333',
            fontWeight: 400,
          }"
        >
          <!-- 产品 -->
          <el-table-column
            :label="$t('label.product')"
            min-width="100"
            prop="productname"
          ></el-table-column>
          <!-- 数量 -->
          <el-table-column
            :label="$t('label.forecast.settings.sl')"
            min-width="80"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'opppdts.' + scope.$index + '.quantity'"
                :rules="productsFormRules.quantity"
              >
                <el-input
                  v-model="scope.row.quantity"
                  size="small"
                  @input="
                    opppdtsNumbereCheck(scope, scope.row.quantity, 'quantity')
                  "
                ></el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 销售价格 -->
          <el-table-column :label="$t('label.quote.xsjg')" min-width="80">
            <template slot-scope="scope">
              <el-form-item
                :prop="'opppdts.' + scope.$index + '.unitprice'"
                :rules="productsFormRules.unitprice"
              >
                <el-input
                  v-model="scope.row.unitprice"
                  size="small"
                  @input="
                    unitpriceNumbereCheck(
                      scope,
                      scope.row.unitprice,
                      'unitprice'
                    )
                  "
                >
                </el-input>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 日期 -->
          <el-table-column :label="$t('date')" width="160">
            <template slot-scope="scope">
              <el-date-picker
                v-model="scope.row.date"
                value-format="yyyy-MM-dd"
                type="date"
                style="width: 150px"
                size="small"
              >
              </el-date-picker>
            </template>
          </el-table-column>
          <!-- 行描述 -->
          <el-table-column
            :label="$t('lable.opppdt.addpdt.table.description')"
            min-width="160"
          >
            <template slot-scope="scope">
              <el-input v-model="scope.row.description" size="small"></el-input>
            </template>
          </el-table-column>
          <!-- 操作 -->
          <el-table-column
            fixed="right"
            :label="$t('label.operate')"
            width="100"
          >
            <template slot-scope="scope">
              <span
                @click="delProducts(scope.row.id, scope.row)"
                size="mini"
                style="color: red; cursor: pointer"
              >
                <!-- 删除 -->
                {{ $t("button_partner_delete") }}</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="productsClose('productsForm')" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          :loading="btnLoading"
          @click="productsSumit('productsForm')"
          size="small"
        >
          <!-- v-preventReClick -->
          <!-- 确定 -->
          {{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑业务机会联系人   添加联系人角色-->
    <!-- $t('label.salescloud.opportunity.contactrole') -->
    <el-dialog
      :title="primaryContactTitle"
      :visible="contactsVisible"
      width="1100px"
      :before-close="closeContactsDialog"
      :modal-append-to-body="false"
      :close-on-click-modal="false"
    >
      <el-form
        :model="contactsForm"
        ref="contactsForm"
        :rules="contRules"
        class="demo-ruleForm mar-t-20"
        size="mini"
        label-position="left"
        v-loading="loadingConcatRole"
      >
        <!-- 主要联系人 -->
        <el-form-item
          :label="$t('label.customer.maincontact')"
          class="primaryBox"
          v-if="isShowMaincontact"
        >
          <el-tooltip
            :content="
              $t('vue_label_opportunity_contactrole_primarycontact_notice')
            "
            placement="top-start"
          >
            <svg class="icon tipsBox" aria-hidden="true">
              <use href="#icon-explain"></use>
            </svg>
          </el-tooltip>
          <el-select
            v-model="primaryContact"
            :placeholder="$t('label_tabpage_pleaseSelectz')"
            clearable
            filterable
            size="small"
            style="width: 100%"
            :disabled="primaryContactFlag"
            @change="getTemplateRow"
          >
            <el-option
              v-for="item in primaryContactList"
              :key="item.id"
              :label="item.codevalue"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-table
          :data="contactsForm.etyyStr"
          :key="tableKey"
          style="width: 100%; margin-bottom: 0"
          :header-cell-style="{
            background: '#DDDBDA',
            color: '#333333',
            fontWeight: 400,
          }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column :label="lablename" min-width="170">
            <template slot-scope="scope">
              <el-form-item
                :prop="'etyyStr.' + scope.$index + '.contactName'"
                :rules="contRules.contactName"
              >
                <el-select
                  v-model="scope.row.contactName"
                  filterable
                  clearable
                  class="no-suffix"
                  style="width: 100%"
                  @click.native="contSearch(scope.$index)"
                >
                  <p style="margin: 5px 0px">
                    <span style="display: inline-block; width: 100%">
                      <i class="el-icon-search" style="margin-left: 10px"></i>
                      <span style="padding-left: 10px; cursor: pointer">
                        <!-- 前往精准搜索 -->
                        {{
                          $t("vue_label_commonobjects_detail_to_precise_search")
                        }}
                      </span>
                    </span>
                  </p>
                  <el-option
                    v-show="false"
                    key="new"
                    :label="$t('label.new')"
                    :value="$t('label.ems.create')"
                  >
                  </el-option>
                </el-select>
                <span
                  class="remoteBtn"
                  @click="contSearch(scope.$index)"
                  style="display: block"
                >
                  <!-- [查找] -->
                  <i class="el-icon-search"></i>
                </span>
              </el-form-item>
            </template>
          </el-table-column>
          <!-- 角色 -->
          <el-table-column
            :label="filterLabel(setUpArr.fieldList, 'role')"
            min-width="130"
          >
            <template slot-scope="scope">
              <el-form-item
                :prop="'etyyStr.' + scope.$index + '.role'"
                :rules="contRules.role"
              >
                <el-select
                  v-model="scope.row.role"
                  :placeholder="$t('label_tabpage_pleaseSelectz')"
                  clearable
                  size="small"
                  style="width: 100%"
                >
                  <el-option
                    v-for="item in setUpArr.roles"
                    :key="item.id"
                    :label="item.codetext"
                    :value="item.codevalue"
                  ></el-option>
                </el-select>
              </el-form-item>
            </template>
          </el-table-column>
          <!--对我司态度 - 关系程度 -->
          <el-table-column
            :label="filterLabel(setUpArr.fieldList, 'attitude')"
            min-width="150"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.attitude"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in setUpArr.attitudes"
                  :key="item.id"
                  :label="item.codetext"
                  :value="item.codevalue"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 成熟度 拜候状态-->
          <el-table-column
            :label="filterLabel(setUpArr.fieldList, 'maturity')"
            min-width="130"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.maturity"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in setUpArr.maturitys"
                  :key="item.id"
                  :label="item.codetext"
                  :value="item.codevalue"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 购买风格 -->
          <el-table-column
            :label="filterLabel(setUpArr.fieldList, 'buystyle')"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.buystyle"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in setUpArr.buystyles"
                  :key="item.id"
                  :label="item.codetext"
                  :value="item.codevalue"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <!-- 层级 -->
          <el-table-column
            :label="filterLabel(setUpArr.fieldList, 'hierarchies')"
            min-width="100"
          >
            <template slot-scope="scope">
              <el-select
                v-model="scope.row.hierarchies"
                :placeholder="$t('label_tabpage_pleaseSelectz')"
                clearable
                size="small"
                style="width: 100%"
              >
                <el-option
                  v-for="item in setUpArr.hierarchies"
                  :key="item.id"
                  :label="item.codetext"
                  :value="item.codevalue"
                ></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column
            v-if="!showType"
            :label="$t('label_partner_all_operate')"
            width="100"
            class="cell_btn"
          >
            <template slot-scope="scope">
              <div class="btn_all">
                <el-button
                  @click="delcontacts(scope.$index, scope)"
                  v-if="contactsForm.etyyStr.length !== 1"
                  size="mini"
                  class="btn_s"
                  icon="el-icon-minus"
                  v-preventReClick
                ></el-button>
                <el-button
                  @click="addcontacts()"
                  v-if="contactsForm.etyyStr.length - 1 === scope.$index"
                  size="mini"
                  class="btn_s"
                  icon="el-icon-plus"
                  v-preventReClick
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-checkbox
          v-model="needSycContact"
          style="float: left; margin-left: 20px"
        >
          <!-- 将信息同步到联系人上 -->
          {{ $t("vue_label_opportunity_contactrole_synctocontacts") }}
        </el-checkbox>
        <el-button @click="contactsClose" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="contactsSubmit('contactsForm')"
          size="small"
          v-preventReClick
        >
          <!-- 确定 -->
          {{ $t("label.confirm") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.quickbooks.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      :before-close="beforeClose"
      append-to-body
    >
      <search-table
        ref="searchTable"
        :relevantObjid="relevantObjid"
        :fieldId="fieldId"
        :relevantPrefix="relevantPrefix"
        @changeSelect="changeSelect"
      />
    </el-dialog>
    <!-- 添加/联系人 -->
    <el-dialog
      :title="$t('vue_label_opportunity_contactrole_add')"
      custom-class="dialog-form-wrapper"
      width="80%"
      top="5%"
      :visible="addContact"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :modal-append-to-body="false"
      :before-close="addContactClose"
      append-to-body
    >
      <add-contact
        ref="addContact"
        @addContactChange="addContactChange"
        @isDisabledNext="isDisabledNext"
      />
      <span slot="footer" class="dialog-footer">
        <el-button @click="addContactClose" size="small">
          <!-- 取消 -->
          {{ $t("label.ems.cancel") }}</el-button
        >
        <el-button
          type="primary"
          @click="addContactNext"
          size="small"
          v-preventReClick
          :disabled="nextFlag"
        >
          <!-- 下一步 -->
          {{ $t("UG_Forcast_09") }}</el-button
        >
      </span>
    </el-dialog>
    <!-- 业务机会相关列表选择价格手册切换价格手册提示 -->
    <el-dialog
      :title="$t('label_popup_infoz')"
      width="40%"
      top="5%"
      :visible.sync="pricIntro"
      append-to-body
    >
      <div style="font-size: 16px; font-weight: bold; word-break: break-word">
        <!-- 更改已选取的价格手册，将删除全部现有的产品。是否更改为新的价格手册？ -->
        {{ $t("c731") }}
      </div>
      <span slot="footer">
        <el-button @click="cancelPricChange" size="mini">
          <!-- 取消 -->
          {{ $t("label.cancel") }}
        </el-button>
        <el-button @click="confirmPricChange" type="primary" size="mini">
          <!-- 删除 -->
          {{ $t("label.confirm") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  Setuppop,
  Save,
  saveAccountTeams,
  SavePricebook,
  SaveEditProducts,
  SaveProducts,
  SelectAddProducts,
  Setup,
  SaveContacts,
  GetPricebooks,
  // SearchProducts,
  GetAddOpportunityPdtInfo,
  EditProducts,
  deleteContact,
  queryContactGivenField,
  GetViewGetSelectValue,
  GetCurrencyInfo,
  getEnablePriceBook,
  searchProducts,
  getFieldList,
  masterRecordDetailInfo,
  deleteRelatedProductInfo
} from "./api";
import filterValueInputType from "@/config/enumCode/inputType.js";
import operator from "@/config/enumCode/operator.js";
import EditableCell from "@/components/TablePanel/EditableCell.vue";
import SearchTable from "@/components/Form/search-table.vue";
import AddContact from "./addContact.vue";
import detailSendCard from "@/utils/robotMessage.js";
import * as CommonObjApi from "./api";
import debounce from "lodash.debounce";

export default {
  name: "popUpTeam",
  // 自定义下拉指令
  directives: {
    loadmore: {
      bind(el, binding) {
        const selectWrap = el.querySelector(".el-table__body-wrapper");
        selectWrap.addEventListener("scroll", function () {
          let sign = 100;
          const scrollDistance =
            this.scrollHeight - this.scrollTop - this.clientHeight;
          if (scrollDistance <= sign) {
            binding.value();
          }
        });
      },
    },
  },
  components: { 
    EditableCell, 
    AddContact, 
    SearchTable, 
    ProductFilter: () => import("../../components/productFilter/productFilter.vue"),
   },
  props: {
    // 业务机会产品相关列表数据
    listData: {
      type: Array,
      default: () => [],
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
    // 主记录id
    recordId: {
      type: String,
      default: "",
    },
    // 主记录价格手册id
    pricebook2idMainProp: {
      type: String,
      default: "",
    },
    createEdit: {
      type: Boolean,
      default: false,
    },
    // 新建业务机会筛选产品
    // popUpteamCurrency: {
    //   type: String,
    //   default: "",
    // },
    //管理小组
    popTableVisible: {
      type: Boolean,
      default: false,
    },
    opportunityId: {
      type: String,
      default: "",
    },
    //选择价格手册
    manualVisible: {
      type: Boolean,
      default: false,
    },
    //添加产品
    addmanualVisible: {
      type: Boolean,
      default: false,
    },
    //编辑现有产品
    productsVisible: {
      type: Boolean,
      default: false,
    },
    //编辑业务机会联系人
    contactsVisible: {
      type: Boolean,
      default: false,
    },
    // 是否提示无更多数据
    noDataTip: {
      type: Boolean,
      default: false,
    },
    DeletName: {
      type: String,
      default: "",
    },
    //联系人角色  添加按钮
    addContactRoleDialogVisible: {
      type: Boolean,
      default: false,
    },
    //添加
    addContact: {
      type: Boolean,
      default: false,
    },
    //每个相关列表数据
    relatedListObj: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      // 产品弹窗创建或销毁
      productFilterShow: false,
      btnLoading: false,
      // 表格loading
      tableLoading: false,
      addProductLoading: false,
      // 已经切换主记录的价格手册，保存时删除业务机会相关列表子产品
      canDelListData: false,
      // 主记录价格手册
      pricebook2idMain: "",
      // 主记录价格币种
      currencyMain: "",
      // 业务机会相关列表选择价格手册切换价格手册提示
      pricIntro: false,
      /**
       * 是否显示主要联系人
       * 业务机会下,联系人角色点击管理时不让其显示
       */

      isShowMaincontact: true,
      relevantPrefix: "005",
      relevantObjid: "user",
      //小组
      title: "",
      showType: false,
      popArr: {},
      tableKeyPop: 0,
      tableKeyPro: 0,
      tableKey: 0,
      opportunityname: "",
      popForm: {
        etyyStr: [],
      },
      initpopData: {
        useridccname: "",
        teammemberrole: "",
        opportunityaccesslevel: "",
        userid: "",
        opportunity: "",
        opportunityccname: "",
      },
      popId: 2,
      billType: [],
      levelsArr: [],
      // 对象数据
      objArr: [],
      rolesArr: [],
      popUpteamCurrency: "", //新建业务机会筛选产品
      popFormRules: {
        useridccname: [
          //为必填项
          {
            required: true,
            message: this.$i18n.t("label.mobile.phone.required.short"),
            trigger: "change",
          },
        ],
        teammemberrole: [
          {
            required: true,
            message: this.$i18n.t("label.mobile.phone.required.short"),
            trigger: "change",
          },
        ],
        opportunityaccesslevel: [
          {
            required: true,
            message: this.$i18n.t("label.mobile.phone.required.short"),
            trigger: "change",
          },
        ],
        // xglbqx: [
        //   {
        //     required: true,
        //     message: this.$i18n.t("label.mobile.phone.required.short"),
        //     trigger: "change",
        //   },
        // ],
      },
      //价格手册
      manualForm: {
        region: "",
      },
      manualRules: {
        region: [
          {
            required: true,
            message: this.$i18n.t("message.oppdt.selectpricebook"),
            trigger: "change",
          },
        ],
      },
      pricebooks: [],
      defaultManual: "",
      //添加产品
      addmanualData: [],
      condition: [], //筛选的数组
      popNoDataTip: false, // 是否提示无更多数据
      page: 1,
      pageSize: 10,
      addmanualForm: {
        list: [
          {
            fieldId: "",
            op: "",
            val: {
              value: "",
            },
            type: 1,
            id: 1,
            inputType: "input",
            optionst_two: [],
          },
        ],
      },
      initData: {
        fieldId: "",
        op: "",
        val: {
          value: "",
        },
        type: 1,
        inputType: "input",
        optionst_two: [],
      },
      optionst_one: [],
      dataId: 1,
      filterFlag: true,
      selecArr: [],
      newdefaultManual: "",
      SelectChangeArr: [], //选中的数组
      selectRowArr: [],
      filtername: this.$i18n.t("vue_label_commonobjects_detail_filter"), //收起筛选器
      //编辑现有产品
      isAdd: "",
      productsForm: {
        opppdts: [],
      },
      productsFormRules: {
        quantity: [
          //数量不能为空
          {
            required: true,
            message: this.$i18n.t("label.numberscannotnull"),
            trigger: "blur",
          },
        ],
        unitprice: [
          //销售价格不能为空
          {
            required: true,
            message: this.$i18n.t("vue_label_commonobjects_detail_empty"),
            trigger: "blur",
          },
        ],
      },
      //编辑业务机会联系人
      contactsForm: {
        etyyStr: [
          {
            contactName: "",
            role: "",
            attitude: "",
            maturity: "",
            buystyle: "",
            hierarchies: "",
            isDefault: 0,
          },
        ],
      },
      contactsData: {
        contactName: "",
        role: "",
        attitude: "",
        maturity: "",
        buystyle: "",
        hierarchies: "",
        isDefault: 0,
      },
      priceForm: {
        name: "",
        names: "",
      },
      formLabelWidth: "100px",
      rules: {
        name: [{ required: true, trigger: "blur" }],
      },
      contRules: {
        contactName: [{ required: true, message: "必填项", trigger: "blur" }],
        role: [{ required: true, message: "必填项", trigger: "blur" }],
      },
      contactsId: 2,
      isDefault: "",
      needSycContact: false,
      //单独的弹窗  查找
      showSearchTable: false,
      fieldId: "",
      setUpArr: {},
      manual: "",
      rowIndex: 0,
      primaryContactList: [],
      primaryContact: "",
      //添加
      contactSelectArr: [],
      nextFlag: true,
      //主要联系人
      primaryContactFlag: false,
      primaryContactTitle: "",
      tableName: sessionStorage.getItem("tabName"),
      optionsArr: [],
      deleteConArr: [],
      lablename: "",
      loadingConcatRole: false, // 管理联系人角色弹窗loading
      // 当前用户的币种（新建业务机会时，添加产品，主记录没有币种时，默认当前用户币种）
      userCurrencyCode: "",
      // 客户小组动态表头
      accountTableHeader: [],
      // 要更改客户小组查找字段
      changeCell: "",
      // 要更改的行索引
      lineIndex: 0,
    };
  },

  methods: {
    choseProduct(val) {
      if (!this.createEdit) {
        return;
      } else {
        this.$emit("choseProduct", val);
        this.$emit("addmanualClose");
        this.$emit("manualClose");
      }
    },
    // 设置联系人角色动态表头
    filterLabel(arr, attr) {
      let objLabel = arr?.find((item) => item.name == attr);
      return objLabel?.label || "";
    },
    /**
     * 如果是业务机会并且是联系人角色下的关系网并且点击单个卡片进行编辑
     * 点击时,将会根据当前点击的卡片的contact 将element table change color(focus)
     */
    tableRowClassName({ row }) {
      if (row.foucs) {
        return "table_row_focus";
      } else {
        return "";
      }
    },
    unitpriceNumbereCheck(scope, val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      if (scope.$index) {
        this.productsForm.opppdts[scope.$index].unitprice = val;
      } else {
        this.productsForm.opppdts[0].unitprice = val;
      }
    },
    opppdtsNumbereCheck(scope, val) {
      val = val.replace(/[^\d.]/g, ""); //清除“数字”和“.”以外的字符
      val = val.replace(/\.{2,}/g, "."); //只保留第一个. 清除多余的
      val = val.replace(".", "$#$").replace(/\./g, "").replace("$#$", ".");
      val = val.replace(/^(\\-)*(\d+)\.(\d\d).*$/, "$1$2.$3"); //只能输入两个小数
      if (val.indexOf(".") < 0 && val != "") {
        //以上已经过滤，此处控制的是如果没有小数点，首位不能为类似于 01、02的金额
        val = parseFloat(val);
      }
      if (scope.$index) {
        this.productsForm.opppdts[scope.$index].quantity = val;
      } else {
        this.productsForm.opppdts[0].quantity = val;
      }
    },
    remoteSearchs(item, num) {
      this.rowIndex = num;
      this.fieldId = "ffe100000001265aWyi8";
      this.showSearchTable = true;
      this.popArr.popName = "3";
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    remoteSearchAccountTeam(item, row) {
      this.lineIndex = row.$index;
      this.changeCell = item.name;
      this.fieldId = item.id;
      this.showSearchTable = true;
      this.relevantPrefix = "";
      this.relevantObjid = item.lookupObj;
      this.popArr.popName = "accounteam";
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 编辑值改变
     * @param {val} 变化的值
     * @param {lineIndex} 更改值对应的索引
     */
    editValueChange(val, lineIndex, field) {
      this.popForm.etyyStr[lineIndex][field] = val;
    },
    remoteMethod(query) {
      let params = {
        name: query,
        objId: "005",
        fieldId: "",
        lkdp: "",
        page: 1,
        pageSize: 20,
      };
      CommonObjApi.queryMoreDataByName(params).then((res) => {
        let options = JSON.parse(res.data);
        options.forEach((option) => {
          option.value = option.id;
          option.label = option.queryname;
        });
        this.optionsArr = options;
      });
    },
    //获取查找字段下拉框选中数据
    changeEvent(scope, valId) {
      this.optionsArr.forEach((val) => {
        if (valId === val.id) {
          scope.row.useridccname = val.label;
          scope.row.userid = val.id;
        }
      });
    },
    // 节流函数
    fnThrottle(method, delay, duration) {
      var that = this;
      var timer = this.timer;
      var begin = new Date().getTime();
      return function () {
        var current = new Date().getTime();
        clearTimeout(timer);
        if (current - begin >= duration) {
          method();
          begin = current;
        } else {
          that.timer = setTimeout(function () {
            method();
          }, delay);
        }
      };
    },
    // 滚动事件
    loadMore() {
      let that = this;
      let dom = document.getElementsByClassName("add_table")[0].children[2];
      dom.addEventListener("scroll", function () {
        const scrollDistance =
          dom.scrollHeight - dom.scrollTop - dom.clientHeight;
        if (scrollDistance <= 1) {
          //等于0证明已经到底，可以请求接口
          if (!this.popNoDataTip) {
            that.fnThrottle(that.append, 500, 1000)();
          }
        }
      });
    },
    // 表格滚动懒加载
    append() {
      if (!this.popNoDataTip) {
        this.searchProducts();
      }
    },
    init() {
      //管理小组 下拉框数据
      this.optionsArr = [];
      let data = {
        opportunityId: this.$route.params.id,
      };
      Setuppop(data).then((res) => {
        this.levelsArr = res.data && res.data.levels;
        this.rolesArr = res.data.roles && res.data.roles;
        this.objArr = res.data?.objOptions;
        this.opportunityname = res.data.opportunityname;
        if (res.data.teams.length > 0) {
          this.popForm.etyyStr = res.data.teams;
        } else {
          this.popForm.etyyStr = [
            {
              useridccname: "",
              teammemberrole: "",
              opportunityaccesslevel: "",
              userid: "",
              opportunity: "",
              opportunityccname: "",
            },
          ];
        }
        const fixedField = [
          "userid",
          "teammemberrole",
          "accountaccesslevel",
          // "xglbqx",
        ];
        let otherField = [];
        this.relatedListObj.fieldList.forEach((item) => {
          if (!fixedField.includes(item.name) && item.modify === "true") {
            otherField.push(item);
          }
        });
        otherField.forEach((item) => {
          item.inputType = filterValueInputType[item.type];
        });
        this.accountTableHeader = otherField;
      });
    },
    //管理小组 用户信息
    changeSelect(n) {
      this.optionsArr = [];
      if (this.popArr.popName === "1") {
        this.popForm.etyyStr.forEach((item, index) => {
          if (index === this.popArr.ind) {
            if (n.length != 0 && n.data.id) {
              item.useridccname = n.data.name;
              item.userid = n.data.id;
              this.optionsArr.push({
                label: n.data.name,
                value: n.data.id,
              });
            } else {
              item.useridccname = "";
              item.userid = "";
            }
          }
        });
      } //编辑业务机会联系人
      if (this.popArr.popName === "2") {
        this.primaryContactList = [];
        let addFlag = false;
        this.contactsForm.etyyStr.some((item) => {
          if (n.length != 0 && n.data.id === item.contact) {
            addFlag = true;
            return true;
          }
        });
        if (addFlag) {
          this.$message.warning(
            this.$i18n.t("vue_label_normal_notice_repeated")
          ); //"请勿重复添加"
        } else {
          this.contactsForm.etyyStr.forEach((item, index) => {
            if (n.length != 0 && index === this.popArr.ind) {
              this.$set(item, "contactName", n.data.name);
              this.$set(item, "contact", n.data.id);
              //  联系人特定字段查询
              queryContactGivenField({ id: n.data.id }).then((res) => {
                item.attitude = res.data.attitude;
                item.buystyle = res.data.buystyle;
                item.role = res.data.contactrole;
                item.hierarchies = res.data.hierarchies;
                item.maturity = res.data.maturity;
              });
            } else if (n.length === 0 && index === this.popArr.ind) {
              this.$set(item, "contactName", "");
              this.$set(item, "contact", "");
              item.attitude = "";
              item.buystyle = "";
              item.role = "";
              item.hierarchies = "";
              item.maturity = "";
            }
          });
        }
        this.contactsForm.etyyStr.forEach((val) => {
          this.primaryContactList.push({
            //获取主要联系人数据
            id: val.contact,
            codevalue: val.contactName,
          });
        });
      }
      if (this.popArr.popName == "3") {
        //添加产品
        this.addmanualForm.list.forEach((item, index) => {
          if (this.rowIndex === index) {
            if (n.length !== 0) {
              let options = {
                label: n.data.id,
                value: n.data.name,
              };
              this.$set(this.addmanualForm.list[this.rowIndex], "val", options);
            } else {
              this.$set(this.addmanualForm.list[this.rowIndex], "val", n);
            }
          }
        });
      }
      // 客户小组查找字段
      if (this.popArr.popName == "accounteam") {
        if (n.length != 0 && n.data.id) {
          this.popForm.etyyStr[this.lineIndex][this.changeCell] = n.data.id;
          this.popForm.etyyStr[this.lineIndex][`${this.changeCell}ccname`] =
            n.data.name;
        } else {
          this.popForm.etyyStr[this.lineIndex][this.changeCell] = "";
          this.popForm.etyyStr[this.lineIndex][`${this.changeCell}ccname`] = "";
        }
      }
      this.showSearchTable = false;
    },
    //管理小组 加
    addShopList() {
      this.popId += 1;
      this.popForm.etyyStr.push({
        ...this.initpopData,
        // addId: this.popId,
        id: "",
      });
      this.optionsArr = [];
    },
    //管理小组 减
    delShopList(id, row) {
      this.optionsArr = [];
      if (row.row.id) {
        CommonObjApi.getPermissionById({ id: row.row.id }).then((res) => {
          if (res.data.isDelete) {
            CommonObjApi.del({
              id: row.row.id,
              objectApi: this.DeletName,
            }).then(() => {
              this.dialogVisible = false;
              this.$message({
                showClose: true,
                message: this.$i18n.t("label.ems.delete.success"),
                type: "success",
              }); //删除成功
              this.$emit("popTableClose", true);
            });
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_nopermission_delete"),
              type: "warning",
            });
            //抱歉，您无权删除该条数据，请联系系统管理员。
          }
        });
      } else {
        this.popForm.etyyStr.splice(id, 1);
      }
    },
    //管理小组  保存
    popSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let data = this.popForm.etyyStr;
          data.map((item) => {
            item.opportunityccname = this.opportunityname;
            item.opportunity = this.$route.params.id; //业务机会Id
          });
          if (this.relatedListObj.relatedlistType === "accountteam") {
            let options = [];
            data.forEach((item) => {
              let conf = {
                userid: item.userid,
                teammemberrole: item.teammemberrole,
                opportunityaccesslevel: item.opportunityaccesslevel,
                xglbqx: item.xglbqx,
                opportunity: this.$route.params.id,
              };
              this.accountTableHeader.forEach((obj) => {
                conf[obj.name] = item[obj.name];
              });
              if (conf.account === "") {
                conf.account = this.recordId;
              }
              options.push(conf);
            });
            saveAccountTeams(options).then((res) => {
              if (res.result === true) {
                //群策卡片
                if (res.data) {
                  for (let i = 0; i < res.data.length; i++) {
                    (function (i) {
                      setTimeout(async () => {
                        let fieldsList = [];
                        res.data[i].fieldsList.forEach((item) => {
                          if (
                            fieldsList.length < 5 &&
                            item.fieldValue != null &&
                            item.fieldName != "id"
                          ) {
                            fieldsList.push(item);
                          }
                        });
                        await detailSendCard(res.data[i].groupId, {
                          title: `新建${res.data[i].objName}`,
                          recordId: res.data[i].id,
                          type: "NORMAL_CARD",
                          body: fieldsList,
                          changer: localStorage.getItem("username"),
                        });
                      }, 500 * i);
                    })(i);
                  }
                }
                this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
                this.$emit("popTableClose", true);
              }
            });
          } else {
            Save(data).then((res) => {
              if (res.result === true) {
                //群策卡片

                if (res.data) {
                  for (let i = 0; i < res.data.length; i++) {
                    (function (i) {
                      setTimeout(async () => {
                        let fieldsList = [];
                        res.data[i].fieldsList.forEach((item) => {
                          if (
                            fieldsList.length < 5 &&
                            item.fieldValue != null &&
                            item.fieldName != "id"
                          ) {
                            fieldsList.push(item);
                          }
                        });
                        await detailSendCard(res.data[i].groupId, {
                          title: `新建${res.data[i].objName}`,
                          recordId: res.data[i].id,
                          type: "NORMAL_CARD",
                          body: fieldsList,
                          changer: localStorage.getItem("username"),
                        });
                      }, 500 * i);
                    })(i);
                  }
                }
                this.$message.success(this.$i18n.t("label.search.saveok")); //保存成功
                this.$emit("popTableClose", true);
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    //管理小组  关闭
    popTableClose(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields();
      this.$emit("popTableClose", false);
    },
    closePopTableDialog() {
      this.$emit("popTableClose", false);
    },
    /**
     * "选择价格手册"弹框逻辑处理
     */
    manuFun2() {
      this.cleanFilter();
      if (!this.createEdit) {
        // 获取主记录的价格手册
        masterRecordDetailInfo({
          objId: "opportunity",
          recordId: this.recordId
        }).then((res)=> {
          if(res.data && res.data[0]) {
            this.currencyMain = res.data[0].currency;
          }
          // 获取已启用的价格手册
          getEnablePriceBook().then((result)=> {
            this.pricebooks = result.data;
            // 已启用的价格手册添加"空"选项
            this.pricebooks.unshift({name:"空", id:"null"})
            if(res?.data[0]?.pricebook2id) {
              this.pricebook2idMain = res.data[0].pricebook2id;
              this.pricebooks.forEach((item)=> {
                if(item.id == res.data[0].pricebook2id) {
                  this.manualForm.region = item.id;
                }
              })
            } else {
              this.manualForm.region = result.data[0].id;
              this.pricebook2idMain = "null";
            }
          })
        })
        // let data = {
        //   opportunityId: this.$route.params.id,
        // };
        // GetPricebooks(data).then((res) => {
        //   if (res.data.pricebooks) {
        //     if (res.data.pricebooks.length === 1) {
        //       this.pricebooks = res.data.pricebooks;
        //       this.manualForm.region = res.data.pricebooks[0].id;
        //       let data = {
        //         opportunityId: this.$route.params.id, //业务机会产品id
        //         pricebookId: this.manualForm.region,
        //       };
        //       SavePricebook(data).then((res) => {
        //         if (res.result === true) {
        //           this.$emit("addmanualChange");
        //         } else {
        //           this.$message({
        //             showClose: true,
        //             message: res.returnInfo,
        //             type: "error",
        //           });
        //         }
        //       });
        //     } else {
        //       let hasBasic = false;
        //       res.data.pricebooks.forEach((item) => {
        //         if (item.id === "01s00000000000000001") {
        //           this.manualForm.region = item.id;
        //           this.$parent.manualVisible = true;
        //           hasBasic = true;
        //         }
        //       });
        //       if (!hasBasic) {
        //         this.$parent.manualVisible = true;
        //       }
        //     }
        //     this.pricebooks = res.data.pricebooks;
        //   }
        // });
      } else {
        // 获取当前用户币种信息
        CommonObjApi.getUserInfo({}).then((res) => {
          if (res.data) {
            this.userCurrencyCode = res.data.currencyCode;
          }
        });
        // 新建业务机会时，点击添加产品，获取已启用的价格手册
        getEnablePriceBook().then((res) => {
          if (res.data) {
            let mypriceId = ""; //价格手册是否启用
            // 业务机会中的价格手册，匹配已启用的价格手册
            if (this.$parent.$refs.baseForm.formData["pricebook2id"]) {
              res.data.forEach((item) => {
                if (
                  item.id ===
                  this.$parent.$refs.baseForm.formData["pricebook2id"]
                ) {
                  mypriceId = item.id;
                }
              });
            }
            // 业务机会中的价格手册未启用
            if (
              this.$parent.$refs.baseForm.formData["pricebook2id"] &&
              mypriceId == ""
            ) {
              this.$message.warning(this.$i18n.t("c982")); //"当前价格手册已禁用"
            } else if (
              this.$parent.$refs.baseForm.formData["pricebook2id"] &&
              mypriceId != ""
            ) {
              // 业务机会中的价格手册已启用
              this.manualForm.region = mypriceId;
              this.pricebooks = res.data.pricebooks;
              this.$emit("addmanualChange");
            } else if (!this.$parent.$refs.baseForm.formData["pricebook2id"]) {
              // 没有启用的价格手册时，提示至少启用一个价格手册
              if (res.data.length === 0) {
                this.$message.warning(this.$i18n.t("c970"));
              } else if (res.data.length == 1) {
                // 价格手册启用只有1个时，默认选择该价格手册
                this.manualForm.region = res.data[0].id;
                this.pricebooks = res.data.pricebooks;
                this.$emit("addmanualChange");
              } else if (res.data.length > 1) {
                this.$parent.getPric();
                // 价格手册启用多个时，默认展示 Standard Price Book
                this.$emit("openManualVisible");
                res.data.forEach((item) => {
                  if (item.id === "01s00000000000000001") {
                    this.manualForm.region = item.id;
                  }
                });
              }
            }
            this.pricebooks = res.data;
          }
        });
      }
    },
    /**
     * 选择价格手册
     */
    manualInit() {
      this.manual = "";
      this.condition = [];
      this.manuFun2();
    },
    manuFun() {
      this.cleanFilter();
      let data = {
        opportunityId: this.$route.params.id,
      };
      GetPricebooks(data).then((res) => {
        if (res.data.pricebooks) {
          let pricebookIdAndregion = ""; //业务机会中的价格手册
          // 当业务机会中包含价格手册,匹配已启动的价格手册
          if (res.data.pricebookId) {
            res.data.pricebooks.forEach((val) => {
              if (val.id === res.data.pricebookId) {
                pricebookIdAndregion = val.id;
              }
            });
          }
          if (pricebookIdAndregion == "" && res.data.pricebookId) {
            // 业务机会中的价格手册未启用
            this.$message.warning(this.$i18n.t("c982")); //"当前价格手册已禁用"
          } else if (pricebookIdAndregion != "" && res.data.pricebookId) {
            // 业务机会中的价格手册已启用
            this.manualForm.region = pricebookIdAndregion;
            if (this.manual === this.$i18n.t("label.quote.choseproduct")) {
              //添加产品
              this.$emit("addmanualChange");
            }
          } else {
            // 点击添加产品，当前价格手册没有值时，显示价格手册选择框
            if (res.data.pricebooks.length == 1) {
              // 只有一个价格手册已启用时，默认该价格手册，无需弹框选择价格手册
              this.manualForm.region = res.data.pricebooks[0].id;
              if (this.manual === this.$i18n.t("label.quote.choseproduct")) {
                //添加产品
                this.$emit("addmanualChange");
              }
            } else if (res.data.pricebooks.length > 1) {
              // 有多个价格手册已启用时，默认该第一个价格手册，弹框选择价格手册
              if (this.manual === this.$i18n.t("label.quote.choseproduct")) {
                this.$parent.manualVisible = true;
              }
              // 价格手册启用多个时，默认展示 Standard Price Book
              res.data.pricebooks.forEach((item) => {
                if (item.id === "01s00000000000000001") {
                  this.manualForm.region = item.id;
                }
              });
            }
          }
          this.pricebooks = res.data.pricebooks;
        }
      });
    },
    //选择价格手册 关闭弹窗
    manualClose(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields();
      this.$emit("manualClose");
    },
    closeManualDialog() {
      this.$emit("manualClose");
    },
    /**
     * 添加产品前切换价格手册
     */
    changeListPriceBook(val) {
      if(val !== this.pricebook2idMain) {
        if(this.listData.length > 0) {
          this.pricIntro = true;
        } else {
          this.manualForm.region = val;
          this.pricebook2idMain = val;
        }
      } else {
        this.manualForm.region = val;
        this.pricebook2idMain = val;
      }
    },
    /**
     * 取消切换价格手册
     */
    cancelPricChange() {
      this.manualForm.region = this.pricebook2idMain;
      this.pricIntro = false;
      this.canDelListData = false;
    },
    /**
     * 确定切换价格手册
     */
    confirmPricChange() {
       this.pricIntro = false;
       this.canDelListData = true;
    },
    /**
     * 选择价格手册 下一步
     */
    manualNext() {
      this.$emit("addmanualChange");
      // 啦啦啦啦啦啦啦
      // if (this.pricebooks) {
      //   this.$refs[formName].validate((valid) => {
      //     if (valid) {
      //       // 如果有业务机会产品id  则需要调接口为当前业务机会保存价格手册
      //       if (!this.createEdit) {
      //         let data = {
      //           opportunityId: this.$route.params.id, //业务机会产品id
      //           pricebookId: this.manualForm.region,
      //         };
      //         SavePricebook(data).then((res) => {
      //           if (res.result === true) {
      //             this.$emit("addmanualChange");
      //           } else {
      //             this.$message({
      //               showClose: true,
      //               message: res.returnInfo,
      //               type: "error",
      //             });
      //           }
      //         });
      //       } else {
      //         // 如果没有则不需要调用接口 直接展示下一步的弹窗
      //         this.$emit("addmanualChange");
      //       }
      //     } else {
      //       return false;
      //     }
      //   });
      // } else {
      //   this.$message({
      //     showClose: true,
      //     message: this.$i18n.t("message.oppdt.selectpricebook"),
      //     type: "warning",
      //   });
      // }
    },
    /**
     * 业务机会产品添加产品-筛选字段列表
     */
    addGetFieldList() {
      CommonObjApi.getFieldList({ objId: "201100000006339JMl6w" }).then((res) => {
        if (res.data) {
          this.optionst_one = res.data;
        }
      });
    },
    //业务机会产品添加产品 列表
    addmanualInit() {
      if (!this.createEdit) {
        let data = {
          opportunityId: this.$route.params.id,
        };
        GetAddOpportunityPdtInfo(data).then((res) => {
          if (res.data) {
            this.optionst_one = res.data.fieldList;
            // this.pricebooks.forEach((val) => {
            //   if (val.id === res.data.pricebookId) {
            //     this.defaultManual = val.name;
            //   }
            // });
          }
        });
      } else {
        getEnablePriceBook().then((res) => {
          this.pricebooks = res.data;
          this.pricebooks.forEach((val) => {
            if (val.id === this.manualForm.region) {
              this.defaultManual = val.name;
            }
          });
        });
        getFieldList({ objId: "201100000006339JMl6w" }).then((res) => {
          if (res.data) {
            this.optionst_one = res.data;
            this.optionst_one.forEach((list) => {
              list.schemefieldType = list.type;
            });
            this.pricebooks.forEach((val) => {
              if (val.id === this.manualForm.region) {
                this.defaultManual = val.name;
              }
            });
          }
        });
      }
    },
    /**
     * 添加业务机会产品搜索产品
     */
    async searchProducts() {
      if (!this.createEdit) {
        if(this.manualForm.region == "null") {
          this.manualForm.region = "";
        }
        this.popNoDataTip = false;
        let data = {
          pricebookId: this.manualForm.region || this.pricebook2idMainProp,
          conditonstr:
            this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件, //条件
          keywords: "", //关键字
          page: this.page,
          pageSize: this.pageSize,
          currency: this.currencyMain,
          vendorid: "",
          fieldId: "ffe201300147518BK0yM",
        };
        this.tableLoading = true;
        await searchProducts(data).then((res) => {
          if (this.page === 1) {
            this.addmanualData = [];
          }
          if (res.data.dataList.length === 0 && this.page === 1) {
            this.addmanualData = [];
          } else if (this.addmanualData.length === 0 || this.page === 1) {
            this.addmanualData = res.data.dataList;
            this.page++;
          } else {
            this.addmanualData = [...this.addmanualData, ...res.data.dataList];
            this.page++;
          }
          // 是否提示无更多数据
          if (this.addmanualData.length === res.data.totalCount) {
            this.popNoDataTip = true;
          }
        });
        /** 价格手册为空时，价目表价格全设置为空 */
        if (this.manualForm.region == "") {
          this.addmanualData = this.addmanualData.map((item) => {
            item.listprice = null;
            return item;
          });
        }
        /** 价格手册不为空时，不能匹配到当前价格手册的，价目表价格全设置为空 */
        else {
          this.addmanualData = this.addmanualData.map((item) => {
            if (this.manualForm.region !== item.pricebookid) {
              item.listprice = null;
            }
            return item;
          });
        }
        this.tableLoading = false;
        // let data = {
        //   opportunityId: this.$route.params.id,
        //   conditonstr:
        //     this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件
        //   keywords: "", //关键字
        //   page: this.page,
        //   pageSize: this.pageSize,
        // };
        // SearchProducts(data).then((res) => {
        //   if (this.page === 1) {
        //     this.addmanualData = [];
        //   }
        //   if (res.data.dataList.length === 0 && this.page === 1) {
        //     this.addmanualData = [];
        //   } else if (this.addmanualData.length === 0 || this.page === 1) {
        //     this.addmanualData = res.data.dataList;
        //     this.page++;
        //   } else {
        //     this.addmanualData = [...this.addmanualData, ...res.data.dataList];
        //     this.page++;
        //   }
        //   // 是否提示无更多数据
        //   if (this.addmanualData.length === res.data.totalCount) {
        //     this.popNoDataTip = true;
        //   }
        // });
      } else {
        let data = {
          conditonstr:
            this.condition.length > 0 ? JSON.stringify(this.condition) : "", //条件
          page: this.page,
          pageSize: this.pageSize,
          pricebookId: this.manualForm.region,
          fieldId: "ffe201300147518BK0yM",
          // 新建业务机会时，添加产品，主记录没有币种时，默认当前用户币种
          currency: this.popUpteamCurrency || this.userCurrencyCode,
        };
        searchProducts(data).then((res) => {
          if (this.page === 1) {
            this.addmanualData = [];
          }
          if (res.data.dataList.length === 0 && this.page === 1) {
            this.addmanualData = [];
          } else if (this.addmanualData.length === 0 || this.page === 1) {
            this.addmanualData = res.data.dataList;
            this.page++;
          } else {
            this.addmanualData = [...this.addmanualData, ...res.data.dataList];
            this.page++;
          }
          if (res.data.dataList.length === 0) {
            this.popNoDataTip = true;
          }
        });
      }
    },
    //添加产品 (判断有没有价格手册)
    isManual() {
      this.manual = this.$i18n.t("label.quote.choseproduct");
      this.page = 1;
      this.condition = [];
      this.manuFun();
    },
    //添加产品 新增
    adds() {
      this.dataId = 1;
      if (this.dataId < 5) {
        this.dataId += 1;
        this.addmanualForm.list.push({
          ...this.initData,
          id: this.dataId,
        });
      } else {
        return;
      }
    },
    //添加产品 删除
    dels(n) {
      this.addmanualForm.list.splice(n, 1);
      this.$refs.editableCell.splice(n, 1);
    },
    /**
     * 添加产品 表格多选
     */
    handleSelectionChange(val) {
      this.SelectChangeArr = [];
      this.SelectChangeArr = [];
      val.map((item) => {
        this.SelectChangeArr.push(item.pbkmid);
      });
      this.selectRowArr = val;
    },
    //记录类型
    getRecordTypesFun() {
      let arr = [];
      let params = {
        prefix: this.prefix,
      };
      CommonObjApi.getRecordType(params).then((res) => {
        if (res.result) {
          res.data.recordTypeList.forEach((item) => {
            let obj = {
              label: item.name,
              value: item.name,
            };
            arr.push(obj);
            this.selecArr = arr;
          });
        }
      });
    },
    //添加产品 筛选下拉
    selectChange(val, index) {
      this.optionst_one.map((item) => {
        if (val === item.id) {
          this.addmanualForm.list[index].op = "";
          this.addmanualForm.list[index].optionst_two =
            operator.TYPE[item.schemefieldType].getScreeningOpeTypes();
          this.addmanualForm.list[index].val = {
            value: "",
          };
          this.addmanualForm.list[index].inputType =
            filterValueInputType[item.schemefieldType];
          this.selecArr = [];
          if (item.schemefieldType === "ct") {
            //币种
            GetCurrencyInfo().then((res) => {
              res.data.forEach((item) => {
                (item.label = item.currencyname),
                  (item.value = item.currencycode);
              });
              this.selecArr = res.data;
            });
          } else if (item.schemefieldType === "R") {
            //记录类型
            this.getRecordTypesFun();
          } else {
            GetViewGetSelectValue({
              fieldId: val,
            }).then((res) => {
              res.data.forEach((item) => {
                (item.label = item.codekey), (item.value = item.codevalue);
              });
              this.selecArr = res.data;
            });
          }
        }
      });
    },
    //添加产品 关闭筛选器
    closeFilter() {
      if (this.filterFlag) {
        this.filtername = this.$i18n.t(
          "vue_label_commonobjects_detail_openfilter"
        ); //展开筛选器;
      } else {
        this.filtername = this.$i18n.t("vue_label_commonobjects_detail_filter"); //收起筛选器
      }
      this.filterFlag = !this.filterFlag;
    },
    //筛选列表
    filterBtn() {
      let copyList = JSON.parse(JSON.stringify(this.addmanualForm.list));
      copyList.forEach((item, i) => {
        delete copyList[i].type;
        delete copyList[i].inputType;
        delete copyList[i].id;
        delete copyList[i].optionst_two;
        if (item.seq) {
          delete copyList[i].seq;
        }
      });
      if (this.$refs.editableCell) {
        this.$refs.editableCell.forEach((item, index) => {
          copyList[index].val = item.editValue;
        });
      } else {
        copyList.forEach((item) => {
          item.val = item.val.value;
        });
      }
      this.condition = copyList;
      this.page = 1;
      this.searchProducts();
    },
    //添加产品 清除筛选器
    cleanFilter() {
      this.addmanualForm.list = [
        {
          fieldId: "",
          op: "",
          val: {
            value: "",
          },
          type: 1,
          id: 1,
          inputType: "input",
          optionst_two: [],
        },
      ];
    },
    emptyFun() {
      this.page = 1;
      this.addmanualData = [];
    },
    /**
     * 添加产品弹窗中筛选时查找/查找多选字段查找弹窗
     */
    remoteSearchsPric(item, num) {
      this.rowIndex = num;
      this.fieldId = "ffe100000001265aWyi8";
      this.showSearchTable = true;
      this.$nextTick(() => {
        this.$refs.searchTable.page = 1;
        this.$refs.searchTable.pageObj.dataList = [];
        this.$refs.searchTable.searchConditions = "";
        this.$refs.searchTable.init();
      });
    },
    /**
     * 添加产品 (多选)
     * @param {Array} data:查找选择的产品数据
     */
    confirmAddProduct(data) {
      let dataArr= [];
      dataArr = data.map((item)=> {
        let obj = {};
        obj.pricebookitem2id = item.pricebookid;
        obj.product2 = item.id;
        obj.quantity = null;
        obj.unitprice = item.listprice?item.listprice:item.productprice;
        obj.description = null;
        obj.date = null;
        obj.productname = item.name;
        obj.id = null;
        return obj;
      })
      this.productsForm.opppdts = dataArr;
      this.isAdd = this.$i18n.t("label.add");
      this.$emit("confirmAddProduct")
    },
    //添加产品 关闭弹窗
    addmanualClose() {
      this.emptyFun();
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    closeAddmanualDialog() {
      this.emptyFun();
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    /**
     * 添加产品--下一步
     */
    async addmanualNext() {
      if (this.SelectChangeArr.length === 0) {
        this.$message({
          showClose: true,
          message: this.$i18n.t("vue_label_opportunity_related_chooseproduct"), //至少选择一个产品
          type: "warning",
        });
      } else {
        let data = {
          opportunityId: this.$route.params.id,
          selectedIds: this.SelectChangeArr.join(","),
        };
        this.$emit("productsChange");
        this.isAdd = this.$i18n.t("label.add"); //添加
        this.addProductLoading = true;
        await SelectAddProducts(data).then((res) => {
          if(res.result == true) {
            this.productsForm.opppdts = [];
            // this.selectRowArr.map((item)=> {
            //   res.data.productsList.map((item1)=>{
            //     if(item.listprice) {
            //       item1.unitprice = item.listprice;
            //     } else {
            //       item1.unitprice = item.productprice;
            //     }
            //   })
            // })
            this.productsForm.opppdts = res.data.productsList;
          }
        });
        this.addProductLoading = false;
      }
    },
    //编辑现有产品
    async productsInit() {
      this.addProductLoading = true;
      this.isAdd = this.$i18n.t("label_emailtocloudcc_edit"); //"编辑";
      let data = {
        opportunityId: this.$route.params.id,
      };
      await EditProducts(data).then((res) => {
        this.productsForm.opppdts = res.data.opppdts;
      });
      this.addProductLoading = false;
    },
    /**
     * @param {Object} isShowMaincontact 
     * 参数指定是否 显示主要联系人选项
     */
    getInit(transfer) {
      this.loadingConcatRole = true;
      this.isShowMaincontact = true;
      this.contactsForm.etyyStr = [];
      this.primaryContactTitle = this.$i18n.t(
        "vue_label_opportunity_contactrole_edit"
      );
      /**
       *  业务机会下,联系人角色点击管理时不让其显示是否显示主要联系人
       */
      let currentClickItemObj;
      if (transfer) {
        this.isShowMaincontact = transfer.isShowMaincontact;
        currentClickItemObj = transfer.currentClickItemObj;
      }
      this.primaryContactFlag = false;
      this.primaryContactList = [];
      this.primaryContact = "";
      Setup({ opportunityId: this.$route.params.id }).then((res) => {
        res.data.contactRoles.forEach((val) => {
          this.primaryContactList.push({
            id: val.contact,
            codevalue: val.contactName,
          });
        });
        this.setUpArr = res.data;
        this.lablename = res.data.contactName.lablename;
        if (res.data.contactRoles.length > 0) {
          this.contactsForm.etyyStr = this.setUpArr.contactRoles;
          this.contactsForm.etyyStr.forEach((val) => {
            /**
             * 增加逻辑
             *  如果是业务机会并且是联系人角色下的关系网并且点击单个卡片进行编辑
             * 点击时,将会根据当前点击的卡片的contact 将element table change color(focus)
             * 否则走原来逻辑,不进行任何修改
             */
            if (currentClickItemObj) {
              if (val.contact === currentClickItemObj.contact) {
                val.foucs = true;
              }
            }

            if (val.isDefault === 1) {
              this.primaryContact = val.contact;
            } else {
              return false;
            }
          });
        } else {
          this.contactsForm.etyyStr = [
            {
              contact: "",
              role: "",
              attitude: "",
              maturity: "",
              buystyle: "",
              hierarchies: "",
              isDefault: 0,
            },
          ];
        }
        let newArr = JSON.parse(JSON.stringify(this.contactsForm.etyyStr));
        for (var i = 0; i < newArr.length; i++) {
          if (newArr[i].isDefault === 1) {
            this.isDefault = i;
            break;
          } else {
            this.isDefault = "";
            this.allRadio = "";
          }
        }

        this.loadingConcatRole = false;
      });
    },
    //编辑现有产品  删除单条
    delProducts(id) {
      //'编辑'
      if (this.isAdd === this.$i18n.t("label.weixin.detail.edit")) {
        CommonObjApi.getPermissionById({ id: id }).then((res) => {
          if (res.data.isDelete) {
            CommonObjApi.del({ id: id, objectApi: this.DeletName })
              .then(() => {
                this.$message.success(this.$i18n.t("label.ems.delete.success")); //删除成功
                this.$emit("successFun", true);
                this.$emit("manualClose");
                this.$emit("addmanualClose");
              })
              .catch((err) => {
                this.$message.error(err.returnInfo);
              });
          } else {
            this.$message({
              showClose: true,
              message: this.$i18n.t("vue_label_notice_nopermission_delete"),
              type: "warning",
            });
            //抱歉，您无权删除该条数据，请联系系统管理员。
          }
        });
      } else {
        this.productsForm.opppdts.splice(id, 1);
      }
    },
    //编辑业务机会联系人 加
    addcontacts() {
      this.contactsId += 1;
      this.contactsForm.etyyStr.push({
        ...this.contactsData,
        addId: this.contactsId,
      });
    },
    //编辑业务机会联系人 减
    delcontacts(id, row) {
      if (row.row.id) {
        this.deleteConArr.push(row.row.id);
      }
      this.contactsForm.etyyStr.splice(id, 1);
      this.primaryContactList.splice(id, 1); //获取主要联系人数据
      this.primaryContact = "";
    },
    //编辑业务机会联系人 单选
    getTemplateRow(e) {
      this.contactsForm.etyyStr.forEach((item) => {
        if (item.contact === e) {
          item.isDefault = "1";
        } else {
          item.isDefault = "0";
        }
      });
    },
    //编辑现有产品 给头部添加星星
    starAdd(obj) {
      if (obj.columnIndex === 1 || obj.columnIndex === 2) {
        return "star";
      }
    },
    /**
     * 保存价格手册
     * @param {function} fn1 方法1
     * @param {function} fn2 方法2
     * @param {Array} data 方法2需要的入参
     */
    async SavePricebook(fn1, fn2, data) {
      this.btnLoading = true;
      await SavePricebook({
        opportunityId: this.$route.params.id, //业务机会产品id
        pricebookId: this.manualForm.region || this.pricebook2idMainProp,
      }).then(() => {
        fn1 && fn1();
        fn2 && fn2(data);
      })
      this.btnLoading = true;
    },
    // 删除业务机会子产品
    async deleteRelatedProductInfo() {
      await deleteRelatedProductInfo({
        objId: "opportunity",
        recordId: this.recordId,
      }).then(()=>{})
    },
    /**
     * 保存产品
     * @param {Array} data
     */
    SaveProducts(data){
      SaveProducts(data).then((res) => {
        if (res.result === true) {
          // 群策卡片
          if (res.data) {
            for (let i = 0; i < res.data.length; i++) {
              (function (i) {
                setTimeout(async () => {
                  let fieldsList = [];
                  res.data[i].fieldsList.forEach((item) => {
                    if (
                      fieldsList.length < 5 &&
                      item.fieldValue != null &&
                      item.fieldName != "id"
                    ) {
                      fieldsList.push(item);
                    }
                  });
                  await detailSendCard(res.data[i].groupId, {
                    title: `新建${res.data[i].objName}`,
                    recordId: res.data[i].id,
                    type: "NORMAL_CARD",
                    body: fieldsList,
                    changer: localStorage.getItem("username"),
                  });
                }, 500 * i);
              })(i);
            }
          }
          this.$message({
            showClose: true,
            type: "success",
            message: this.$i18n.t("label.search.saveok"), //保存成功
          });
          this.$emit("successFun", true);
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.$i18n.t("savefail"), //"保存失败"
          });
        }
      });
    },
    //编辑现有产品 保存
    productsSumit: debounce(
      function(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            if (this.isAdd === this.$i18n.t("label.add")) {
              //添加
              let data = [];
              this.productsForm.opppdts.map((item) => {
                let newItem = {};
                newItem.oppid = this.$route.params.id;
                newItem.pricebookitem2 = item.pricebookitem2;
                newItem.product2 = item.product2;
                newItem.unitprice = item.unitprice;
                newItem.quantity = item.quantity;
                newItem.date = item.date;
                newItem.description = item.description;
                newItem.currency = "";
                data.push(newItem);
              });
              // 确定切换价格手册
              if(this.canDelListData === true) {
                this.SavePricebook(this.deleteRelatedProductInfo, this.SaveProducts, data);
              } else {
                this.SavePricebook("", this.SaveProducts, data);
              }
            } else {
              let data = [];
              this.productsForm.opppdts.map((item) => {
                let newItem = {};
                newItem.oppid = this.$route.params.id;
                newItem.id = item.id ? item.id : "";
                newItem.unitprice = item.unitprice;
                newItem.quantity = item.quantity;
                newItem.date = item.date;
                newItem.description = item.description;
                data.push(newItem);
              });
              this.btnLoading = true;
              SaveEditProducts(JSON.stringify(data)).then((res) => {
                this.btnLoading = false;
                if (res.result === true) {
                  this.$message({
                    showClose: true,
                    type: "success",
                    message: this.$i18n.t("label.search.saveok"), //保存成功
                  });
                  this.$emit("successFun", true);
                } else {
                  this.$message({
                    showClose: true,
                    type: "error",
                    message: this.$i18n.t("savefail"), //"保存失败"
                  });
                }
              }).catch(()=>{
                this.btnLoading = false;
              });
            }
            this.$emit("manualClose");
            this.$emit("addmanualClose");
            // 添加产品弹框关闭
            this.productFilterShow = false;
            // this.$refs.productFilter.addmanualVisible = false;
          } else {
            return false;
          }
        });
      },
      3000,
      { leading: true, trailing: false }
    ),
    //编辑现有产品 关闭
    productsClose(formName) {
      this.$refs[formName] && this.$refs[formName].resetFields();
      this.$emit("successFun", false);
      this.$emit("manualClose");
      this.$emit("addmanualClose");
    },
    closeProductsDialog() {
      this.$emit("manualClose");
      this.$emit("addmanualClose");
      this.$emit("successFun", false);
    },
    //编辑业务机会联系人 关闭
    contactsClose() {
      this.deleteConArr = [];
      this.$emit("contactsClose", false);
    },
    closeContactsDialog() {
      this.$emit("contactsClose", false);
    },
    //编辑业务机会联系人 保存
    contactsSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.contactsForm.etyyStr.forEach((item) => {
            item.opportunity = this.$route.params.id;
            delete item.addId;
            delete item.contactName;
          });
          let data = {
            needSycContact: this.needSycContact ? "true" : "false",
            contactRoles: this.contactsForm.etyyStr,
          };
          SaveContacts(data).then((res) => {
            if (res.result === true) {
              //群策卡片
              if (res.data) {
                for (let i = 0; i < res.data.length; i++) {
                  (function (i) {
                    setTimeout(async () => {
                      let fieldsList = [];
                      res.data[i].fieldsList.forEach((item) => {
                        if (
                          fieldsList.length < 5 &&
                          item.fieldValue != null &&
                          item.fieldName != "id"
                        ) {
                          fieldsList.push(item);
                        }
                      });
                      await detailSendCard(res.data[i].groupId, {
                        title: `新建${res.data[i].objName}`,
                        recordId: res.data[i].id,
                        type: "NORMAL_CARD",
                        body: fieldsList,
                        changer: localStorage.getItem("username"),
                      });
                    }, 500 * i);
                  })(i);
                }
              }
              if (this.deleteConArr.length !== 0) {
                deleteContact({
                  id: this.deleteConArr.join(","),
                  opportunityId: this.$route.params.id,
                })
                  .then(() => {
                    this.deleteConArr = [];
                    this.$emit("contactsClose", true);
                  })
                  .catch((err) => {
                    this.$message.error(err.returnInfo);
                  });
              }
              this.$message({
                showClose: true,
                type: "success",
                message: this.$i18n.t("label.search.saveok"), //保存成功
              });
              this.$emit("contactsClose", true);
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.$i18n.t("savefail"), //"保存失败"
              });
              this.$emit("contactsClose", false);
            }
          });
        } else {
          return false;
        }
      });
    },
    // 单独的弹窗    查找/查找多选
    remoteSearch(index) {
      this.showSearchTable = true;
      this.popArr.popName = "1";
      this.relevantPrefix = "005";
      this.relevantObjid = "user";
      this.popArr.ind = index;
      this.$nextTick(() => {
        this.$refs.searchTable && this.$refs.searchTable.init();
      });
    },
    contSearch(index) {
      this.showSearchTable = true;
      this.relevantPrefix = "003";
      this.relevantObjid = "contact";
      this.popArr.popName = "2";
      this.popArr.ind = index;
      this.$nextTick(() => {
        this.$refs.searchTable && this.$refs.searchTable.init();
      });
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    addContactClose() {
      this.$emit("addContactClose");
    },
    //打开添加弹窗
    addContactOpen() {
      this.$nextTick(() => {
        this.$refs.addContact && this.$refs.addContact.init();
      });
    },
    //从添加弹窗选中的数据
    addContactChange(rows) {
      if (rows.length > 0) {
        this.nextFlag = false;
        this.contactSelectArr = rows;
      } else {
        this.nextFlag = true;
        this.contactSelectArr = [];
      }
    },
    //点击添加按钮(下一步按钮禁止)
    isDisabledNext() {
      this.nextFlag = true;
    },
    async queryContactGivenField(arr) {
      queryContactGivenField({ id: arr.id }).then((res) => {
        this.contactsForm.etyyStr.push({
          contactName: arr.name,
          contact: arr.id,
          attitude: res.data.attitude,
          buystyle: res.data.buystyle,
          role: res.data.contactrole,
          hierarchies: res.data.hierarchies,
          maturity: res.data.maturity,
        });
        //获取主要联系人数据
        this.primaryContactList.push({
          id: arr.id,
          codevalue: arr.name,
        });
      });
    },
    //添加弹窗下一步
    async addContactNext() {
      //获取添加联系人下拉框数据
      Setup({ opportunityId: this.$route.params.id }).then((res) => {
        this.setUpArr = res.data;
      });
      this.primaryContactTitle = this.$i18n.t(
        "vue_label_opportunity_contactrole_add"
      );
      this.primaryContact = "";
      this.contactsForm.etyyStr = [];
      this.primaryContactList = [];
      this.$emit("contactsVisibleOpen");
      for (let i = 0; i < this.contactSelectArr.length; i++) {
        await this.queryContactGivenField(this.contactSelectArr[i]);
      }
      //获取相关列表的主要联系人
      this.relatedListObj.data.forEach((val) => {
        if (val.isdefault === 1) {
          this.primaryContact = val.contactccname;
          this.primaryContactFlag = true;
        } else {
          this.primaryContactFlag = false;
          return false;
        }
      });
    },
  },
  watch: {
    addmanualVisible: {
      handler: function(newVal){
        this.productFilterShow = newVal
      },
      immediate: true
    },
    defaultManual(val) {
      if (val) {
        this.$emit("changePriceBook", {
          val: this.manualForm.region,
          name: val,
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.pop_box {
  //     .el-table td, .el-table th.is-leaf {
  //      border-bottom: 1px solid #fff!important;
  // }
  ::v-deep .el-input--small .el-input__inner {
    height: 30px;
    line-height: 30px;
  }
  ::v-deep .el-table {
    table th.star div::before {
      content: "*";
      color: red;
    }
    .el-table__body-wrapper {
      overflow-y: auto;
      max-height: 400px;
      // 提示语
      .noDataTip {
        height: 35px;
        line-height: 35px;
        text-align: center;
        font-size: 14px;
        background: #fff;
      }
    }
  }
  ::v-deep .el-dialog__header {
    border-bottom: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .el-dialog__footer {
    border-top: 1px solid #ccc;
    padding: 20px;
  }
  ::v-deep .add_table {
    ::v-deep .el-table__body-wrapper {
      height: 140px;
    }
  }
  ::v-deep .primaryBox {
    .el-form-item__label {
      padding: 0 !important;
      font-size: 14px !important;
    }
    .el-form-item__content {
      margin-bottom: 20px;
    }
  }
  .btn_all {
    display: flex;
    // 按钮中icon本身就是居中的，可调整padding，font-size
    .btn_s {
      width: 30px;
      height: 30px;
      padding: 0px;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // float: left;
      font-size: 24px;
      // ::v-deep span {
      //   font-size: 30px;
      // }
    }
  }

  .pop_tit {
    margin-bottom: 20px;
    font-size: 12px;
  }
  .btn_box {
    margin-bottom: 16px;
    span {
      color: #006dcc;
      font-size: 12px;
      margin-right: 20px;
      cursor: pointer;
    }
  }
  .screen_s > div {
    margin-bottom: 0 !important;
  }

  .screen_s ::v-deep .el-form-item__content {
    display: flex;
    align-items: center;
  }
  .el-form-item {
    margin-bottom: 0;
  }

  .select_one {
    width: 180px;
    margin: 6px;
  }

  .select_two {
    width: 140px;
    margin: 6px;
  }

  .select_three {
    width: 72px;
    margin: 6px;
  }
  .filter_btn {
    font-size: 24px;
    margin: 0 8px;
    cursor: pointer;
  }
  .icon_s {
    cursor: pointer;
    position: absolute;
    top: 1px;
    right: 7px;
  }
  .products {
    ::v-deep .el-form-item__content {
      margin: 14px 0;
    }
  }
  .manual {
    ::v-deep .el-form-item__error {
      left: 108px;
    }
    ::v-deep .el-form-item__label {
      min-width: 108px;
      word-break: break-word;
      height: auto;
      padding: 0;
      margin-right: 10px;
    }
    .prompt {
      margin-bottom: 20px;
      word-break: break-word;
    }
  }
  .radio_box {
    ::v-deep .el-radio__label {
      display: none;
    }
  }
  ::v-deep .searchTipBox {
    height: 34px;
    line-height: 34px;
    cursor: pointer;
    padding: 5px 10px !important;
    margin: 10px 10px 5px 10px !important;
    &:hover {
      background: #f5f7fa;
    }
  }
  .remoteBtn {
    cursor: pointer;
    position: absolute;
    right: 9px;
    top: 1px;
    color: #c0c4cc;
    z-index: 100;
  }
  // 查找下拉框不显示下拉图标
  ::v-deep .no-suffix .el-input__suffix {
    right: 25px;
    .el-icon-arrow-up {
      display: none;
    }
  }

  .tipsBox {
    width: 10px;
    margin: -4px 0 0 5px;
    cursor: pointer;
  }
}
</style>

<style>
.el-table .table_row_focus {
  background: #f5f7fa;
}
</style>