// 报表中日期分组依据和统计类型的数据关系
import i18n from '@/utils/i18n'; //多语言
import dayjs from "@/utils/dayjs";

// 日期分组依据
function dateTypes() {
  let dateTypes = [
    { label: i18n.t('label.schedulejob.setup.schedule.day'), value: "day" },
    { label: i18n.t('month'), value: "month" },
    { label: i18n.t('label.login.access.year'), value: "year" },
    { label: i18n.t('label.forecast.edit.kjjd'), value: "FQ" },
    { label: i18n.t('label.forecast.edit.kjnd'), value: "FY" },
    { label: i18n.t('label.custom.CQ'), value: "CQ" },
    { label: i18n.t('lable.custom.year'), value: "CY" },
  ]
  return dateTypes
}

// 统计类型
function statisticsTypes() {
  let statisticsTypes = [
    { label: i18n.t('label.summation'), value: 'sum' },
    { label: i18n.t('label.average'), value: 'avg' },
    { label: i18n.t('label.maximum'), value: 'max' },
    { label: i18n.t('label.minimun'), value: 'min' },
  ]
  return statisticsTypes
}

// 单位
function units() {
  let units = [
    {
      value: "integral",
      label: i18n.t('label.dashboardSetup.showdw.d5'),
    },
    {
      value: "ten thousand",
      label: i18n.t('label.dashboardSetup.showdw.d4'),
    },
    {
      value: "million",
      label: i18n.t('label.dashboardSetup.showdw.d3'),
    },
    {
      value: "ten million",
      label: i18n.t('label.dashboardSetup.showdw.d2'),
    },
    {
      value: "billion",
      label: i18n.t('label.dashboardSetup.showdw.d1'),
    }
  ]
  return units
}

// 日历年
function calendarYear() {
  let calendarYear = [
    {
      id: 0,
      name: i18n.t('today'), // 今天
      startdate: dayjs().startOf('day').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 1,
      name: i18n.t('lable.account.Yesterday'), // 昨天
      startdate: dayjs().startOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().endOf('day').subtract(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 2,
      name: i18n.t('this.month'), // 本月
      startdate: dayjs().startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 3,
      name: i18n.t('label.event.lastmonth'), // 上月
      startdate: dayjs().add(-1, 'month').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().add(-1, 'month').endOf('month').format('YYYY-MM-DD HH:mm:ss'),
    },
  ]
  // 本季度、上一个日历季度
  let curMonth = dayjs().month()
  if (curMonth < 3) {
    // 1/2/3
    calendarYear.push(
      {
        id: 4,
        name: i18n.t('this.season'), // 本季度,
        startdate: dayjs().month(0).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(2).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        id: 5,
        name: i18n.t('lable.account.PreviousCQ'), // 上一个日历季度
        startdate: dayjs().add(-1, 'year').startOf('month').month(9).format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().add(-1, 'year').month(11).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
    )
  } else if (curMonth < 6) {
    // 4/5/6
    calendarYear.push(
      {
        id: 4,
        name: i18n.t('this.season'), // 本季度,
        startdate: dayjs().month(3).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(5).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        id: 5,
        name: i18n.t('lable.account.PreviousCQ'), // 上一个日历季度
        startdate: dayjs().month(0).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(2).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
    )
  } else if (curMonth < 9) {
    // 7/8/9
    calendarYear.push(
      {
        id: 4,
        name: i18n.t('this.season'), // 本季度,
        startdate: dayjs().month(6).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(8).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        id: 5,
        name: i18n.t('lable.account.PreviousCQ'), // 上一个日历季度
        startdate: dayjs().month(3).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(5).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
    )
  } else if (curMonth < 12) {
    // 10/11/12
    calendarYear.push(
      {
        id: 4,
        name: i18n.t('this.season'), // 本季度,
        startdate: dayjs().month(9).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(11).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
      {
        id: 5,
        name: i18n.t('lable.account.PreviousCQ'), // 上一个日历季度
        startdate: dayjs().month(6).startOf('month').format('YYYY-MM-DD HH:mm:ss'),
        enddate: dayjs().month(8).endOf('month').format('YYYY-MM-DD HH:mm:ss'),
      },
    )
  }
  // 本年、上一个日历年度
  calendarYear.push(
    {
      id: 6,
      name: i18n.t('this.year'), // 本年 
      startdate: dayjs().startOf('year').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().endOf('year').format('YYYY-MM-DD HH:mm:ss'),
    },
    {
      id: 7,
      name: i18n.t('lable.account.previous.CY'), // 上一个日历年度
      startdate: dayjs().add(-1, 'year').startOf('year').format('YYYY-MM-DD HH:mm:ss'),
      enddate: dayjs().add(-1, 'year').endOf('year').format('YYYY-MM-DD HH:mm:ss'),
    })
  return calendarYear
}

// 获取上一个月的今天
function getPreMonth() {
  var lastDate = new Date(new Date() - 1000 * 60 * 60 * 24 * 30);
  var lastY = lastDate.getFullYear();
  var lastM = lastDate.getMonth() + 1;
  var lastD = lastDate.getDate();
  var lastdate = lastY + "-" + (lastM < 10 ? "0" + lastM : lastM) + "-" + (lastD < 10 ? "0" + lastD : lastD);
  return lastdate;
}

export { dateTypes, statisticsTypes, units, calendarYear, getPreMonth }