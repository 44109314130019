import robotJob from '@/views/chat/js/chat.js'
import job from '@/views/chat/js/request.js'
/*
robotToken的格式
robotToken={
    startTime:new Date().getTime(),
    expires:12372489239749834,
    token:YWMtb1ONsB9NEeuRCM-B2rE0VQAAAAAAAAAAAAAAAAAAAAFf3RpgjMER5LJre0Qvj_wNAgMAAAF1l9fAkgBPGgCRvzhvOXg2bWBSNLCAcdHJUDkFUzBDdtRMsi7lR4tRMQ
}
*/
async function sendMessage(groupId,msg){
    let token = await robotJob.getToken();
    if(token.data.access_token){
        localStorage.setItem('robotToken',JSON.stringify({startTime:new Date().getTime(),expires:token.data.expires_in * 1000,token:token.data.access_token}));
        await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
    }
}
async function detailSendCard(groupId,msg){
    //  if(localStorage.getItem('robotToken')){
    //      let robotToken = JSON.parse(localStorage.getItem('robotToken'));
    //      if(new Date().getTime() - robotToken.startTime >= robotToken.expires){
    //          let token = await robotJob.getToken();
    //          if(token.data.access_token){
    //              localStorage.setItem('robotToken',JSON.stringify({startTime:new Date().getTime(),expires:token.data.expires_in * 1000,token:token.data.access_token}));
    //              let messageResult = await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
    //              if(messageResult.status == 401){
    //                  sendMessage(groupId,msg);
    //              }else if(messageResult.status != 200 && messageResult.status != 400 && messageResult.status != 401){
    //                  setTimeout(async ()=>{
    //               await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
    //                  },1000)
    //              }
    //          }
    //      }else{
    //         let messageResult = await robotJob.sendMessage(groupId,msg,`Bearer ${ JSON.parse(localStorage.getItem('robotToken')).token}`)
    //         if(messageResult.status == 401){
    //             sendMessage(groupId,msg);
    //         }else if(messageResult.status != 200 && messageResult.status != 400 && messageResult.status != 401){
    //             setTimeout(async ()=>{
    //          await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
    //             },1000)
    //         }
    //      }

    //  }else{
    //     let token = await robotJob.getToken();
    //     if(token.data.access_token){
    //         localStorage.setItem('robotToken',JSON.stringify({startTime:new Date().getTime(),expires:token.data.expires_in * 1000,token:token.data.access_token}));
    //         let messageResult = await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`);
    //         if(messageResult.status == 401){
    //             sendMessage(groupId,msg);
    //         }else if(messageResult.status != 200 && messageResult.status != 400 && messageResult.status != 401){
    //             setTimeout(async ()=>{
    //          await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
    //             },1000)
    //         }
    //     }
    //  }
     let token = await job.getToken();
     if(token.result == true && token.data.token){
        let messageResult = await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.token}`);
        if(messageResult.status == 401){
            sendMessage(groupId,msg);
        }else if(messageResult.status != 200 && messageResult.status != 400 && messageResult.status != 401){
            setTimeout(async ()=>{
         await robotJob.sendMessage(groupId,msg,`Bearer ${token.data.access_token}`)
            },1000)
        }
     }
}
export default detailSendCard