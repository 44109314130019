<template>
  <el-dialog
    v-if="dialogVisible"
    :visible.sync="dialogVisible"
    :title="dialogAttrObj.title || ' '"
    :width="dialogAttrObj.width || '50%'"
    :fullscreen="dialogAttrObj.fullscreen || false"
    :top="dialogAttrObj.top || '15vh'"
    :modal="dialogAttrObj.modal || true"
    :modal-append-to-body="dialogAttrObj['modal-append-to-body'] || true"
    :append-to-body="dialogAttrObj['append-to-body'] || false"
    :lock-scroll="dialogAttrObj['lock-scroll'] || true"
    :close-on-click-modal="dialogAttrObj['close-on-click-modal'] || false"
    :close-on-press-escape="dialogAttrObj['close-on-press-escape'] || true"
    :show-close="dialogAttrObj['show-close'] || true"
    :center="dialogAttrObj.center || false"
    :destroy-on-close="dialogAttrObj['destroy-on-close'] || false"
    @close="closeRendererDialogCallback"
  >
    <component
      :style="dialogHeigt"
      :is="comName"
      :componentAttrObj="componentAttr"
      v-bind="componentAttr"
    ></component>
  </el-dialog>
</template>

<script>
export default {
  name: "Dialog",
  data() {
    return {
      pageId:"", // ccdk关闭指定dialog用的
      comName: "", // 组件名称
      dialogVisible: false,
      componentAttr: {}, // 给业务组件里传参
      dialogAttrObj: {
        title: "",
        width: "50%",
        // 默认不设置高  自定义页面自动撑开  如果自定义页面的高度是百分比   必须设置高度
        height: "",
        fullscreen: false,
        top: "15vh",
        modal: true,
        "modal-append-to-body": true,
        "append-to-body": false,
        "lock-scroll": true,
        "close-on-click-modal": false,
        "close-on-press-escape": true,
        "show-close": true,
        center: false,
        "destroy-on-close": false,
      }, // dialog属性
    };
  },
  computed:{
    // 控制高度
    dialogHeigt() {
      return this.dialogAttrObj.height ? `height:${this.dialogAttrObj.height}` : ''
    }
  },
  mounted() {
    // 关闭弹框的事件
    this.$bus.$on("closeRendererDialog", this.closeRendererDialogCallback);
  },
  methods:{
    closeRendererDialogCallback(id){
      if(this.pageId == id || !this.pageId){
        this.dialogVisible = false;
      }
    }
  },
  beforeDestroy() {
    // 关闭弹框的事件
    this.$bus.$off("closeRendererDialog", this.closeRendererDialogCallback);
  },
};
</script>
<style lang='scss' scoped>
// .el-dialog__wrapper {
//   z-index: 99999 !important;
// }
</style>
