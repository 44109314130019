/**
 * 审批中心路由文件
 */
const approval = [
  {
    path: "approvalCenter",
    redirect: () => "approvalCenter/approvalList",
    name: "审批中心",
  },
  {
    path: "approvalCenter/approvalList",
    component: () => import("@/views/approval/approval.vue"),
    name: "审批列表",
    meta: {
      keepAlive: true //需要缓存
    }
  },
  {
    path: "approvalCenter/lastApprovalPeo",
    component: () => import("@/views/approval/components/last-approval-person.vue"),
    name: "下一个审批人",
  },
  {
    path: "approvalCenter/nextApproval",
    component: () => import("@/views/approval/components/return-approval-step.vue"),
    name: "下一个审批人单人",
  },
  {
    path: "approvalCenter/approvalRequest",
    component: () => import("@/views/approval/components/approval-request.vue"),
    name: "批准请求",
    // meta:{
    //   keepAlive:true //需要缓存
    // }
  },
  {
    path: "approvalCenter/approvalRecall",
    component: () => import("@/views/approval/components/approval-recall.vue"),
    name: "调回批准请求",
  },
  {
    path: "approvalCenter/distributionAagain",
    component: () => import("@/views/approval/components/distribution-again.vue"),
    name: "重新分配",
  },
  {
    path: "approvalCenter/batchApproval",
    component: () => import("@/views/approval/components/batch-approval.vue"),
    name: "批量批准拒绝请求",
  },
  {
    path: "approvalCenter/batchDistributionAagain",
    component: () => import("@/views/approval/components/batch-distribution.vue"),
    name: "重新批量分配",
  },
]

export default approval;
