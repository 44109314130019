<template>
  <!-- 查找弹窗表单 -- 组件不能循环引用 -->
  <div class="base-form" :style="{ width: width }">
    <el-form
      v-loading="formData.length"
      :status-icon="statusIcon"
      ref="elForm"
      :model="formData"
      class="clearfix"
      :rules="rules"
      :label-position="labelPosition"
      :disabled="disabled"
    >
      <!-- item.fieldType === undefined 时为地址和地理定位child字段 -->
      <div
        class="pull-left"
        :style="{
          width:
            item.type === 'groupTitle'
              ? '100%'
              : item.colNum === 1
              ? oneColumnWidth
              : twoColumnWidth,
          clear:
            item.position === 'left' && item.attrIndex % columnNum === 0
              ? 'both'
              : '',
        }"
        v-for="(item, itemIndex) in formAttr"
        v-if="formData && !item.visible"
        :key="item.prop"
        :devid="item.fieldId ? item.fieldId : item.selectionId"
      >
        <p class="groupTitle" v-if="item.type === 'groupTitle'">
          {{ item.title }}
        </p>
        <p v-else-if="item.type === 'noData'"></p>
        <el-form-item
          v-else
          :prop="item.prop"
          :class="[
            item.className,
            {
              'no-label': item.noLabel,
              remind: item.remind,
            },
          ]"
          :style="item.labelWidth ? 'min-height: 38px;' : 'min-height: 38px;'"
          :label="item.label"
          :label-width="
            item.labelWidth ? item.labelWidth + 'px' : labelWidth + 'px'
          "
        >
          <el-input
            v-if="
              (item.type === 'input' ||
                item.type === 'text' ||
                item.type === 'password') &&
              !item.query &&
              !item.filterable
            "
            :ref="item.refKey"
            :disabled="item.readonly"
            :type="item.type"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            auto-complete="off"
            @change="changeEvent(item.changeEvent, arguments[0], item)"
            @blur="
              handleBlurInChild(
                item,
                formData[item.prop],
                formData,
                ...arguments
              )
            "
            v-model="formData[item.prop]"
          >
            <el-button
              v-if="item.button"
              type="primary"
              slot="append"
              @click.prevent.stop="buttonClick(item, index)"
            >
              {{ item.button.label }}
            </el-button>
          </el-input>
          <el-input-number
            v-if="
              (item.type === 'number' || item.type === 'percent-number') &&
              !item.query
            "
            :ref="item.refKey"
            :disabled="item.readonly"
            :type="item.type"
            :controls="false"
            :placeholder="item.placeholder"
            :min="item.valueInterval.min"
            :max="item.valueInterval.max"
            :precision="item.precision"
            controls-position="right"
            @blur="
              handleBlurInChild(
                item,
                formData[item.prop],
                formData,
                ...arguments
              )
            "
            v-model="formData[item.prop]"
          >
          </el-input-number>
          <!--  动态检索项 -->
          <el-autocomplete
            class="el-input"
            v-if="item.query"
            v-model="formData[item.prop]"
            :fetch-suggestions="item.query"
            :placeholder="item.placeholder"
            @select="selecter(item.selecter, ...arguments)"
          >
          </el-autocomplete>
          <!-- select远程搜索 -->
          <!-- <el-select v-if="item.type === 'remote-select' || item.type === 'remote-multi-select'"
                     v-model="formData[item.prop]"
                     filterable
                     :multiple='item.type === "remote-multi-select" ? true : false'
                     remote
                     clearable
                     :disabled="item.readonly"
                     :remote-method="remoteMethod"
                     :loading="loading"
                     style="width:90%"
                     @blur="blurEvent"
                     @change="clearFilterOptions">
            <el-option v-for="item in filterOptions"
                       :key="item.value"
                       :label="item.label"
                       :value="item.value">
            </el-option>
          </el-select> -->
          <!-- 查找/查找多选 -->
          <el-input
            v-if="
              item.type === 'remote-select' ||
              item.type === 'remote-multi-select'
            "
            :ref="item.refKey"
            :disabled="item.readonly"
            :type="item.type"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            auto-complete="off"
            style="width: 90%"
            @change="changeEvent(item.changeEvent, arguments[0], item)"
            @blur="
              handleBlurInChild(
                item,
                formData[item.prop],
                formData,
                ...arguments
              )
            "
            v-model="formData[item.prop]"
          >
          </el-input>
          <!-- <span v-if="(item.type === 'remote-select' || item.type === 'remote-multi-select') && item.name !== 'createbyid' && item.name !== 'lastmodifybyid'"
                class="remoteBtn"
                @click="remoteSearch(item)">
            [{{$t('label.conditiontag.lookup')}}]
          </span> -->
          <!-- 当为非会员时显示input -->
          <el-input
            v-if="item.filterable && formData.isAssociator !== '1'"
            :ref="item.refKey"
            :disabled="item.readonly"
            :type="item.type"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            auto-complete="off"
            @blur="
              handleBlurInChild(
                item,
                formData[item.prop],
                formData,
                ...arguments
              )
            "
            v-model="formData[item.prop]"
          >
            <el-button
              v-if="item.button"
              type="primary"
              slot="append"
              @click.prevent.stop="buttonClick(item, index)"
            >
              {{ item.button.label }}
            </el-button>
          </el-input>
          <form-upload
            v-if="item.type === 'file'"
            :disabled="item.readonly"
            :ref="item.refKey"
            :prop="item.prop"
            :size="item.size"
            :showMsg="item.showMsg"
            :msg="item.msg"
            :file-type="item.fileType"
            :file-list="item.fileList"
            :beforeUploadFile="item.beforeUploadFile"
            :fileAttr="item.fileAttr"
            @fileChange="fileChange"
            @remove="remove"
            @success="success"
            v-model="formData[item.prop]"
          >
            {{ item.refKey }}
          </form-upload>
          <el-input
            v-if="item.type === 'textarea'"
            :disabled="item.readonly"
            :type="item.type"
            :placeholder="item.placeholder"
            :maxlength="item.maxlength"
            :autosize="{
              minRows: item.minRows || 3,
              maxRows: item.maxRows || 6,
            }"
            v-model="formData[item.prop]"
          >
          </el-input>
          <el-radio-group
            v-model="formData[item.prop]"
            v-if="item.type === 'radio'"
            :disabled="item.readonly"
          >
            <span
              v-for="(child, index) in item.options"
              class="plr-10"
              :key="index"
            >
              <el-radio :label="child.val">{{ child.key }}</el-radio>
            </span>
          </el-radio-group>
          <!-- 多选框  用checkedLabel和 checkedKey来区分取值 -->
          <el-checkbox-group
            v-model="formData[item.prop]"
            v-if="item.type === 'checked'"
            :disabled="item.readonly"
          >
            <template v-for="(checkedItem, index) in item.checkedList">
              <el-checkbox
                :label="checkedItem[item.checkedKey]"
                :name="item.prop"
                @change="changeEvent(item.changeEvent, arguments[0], item)"
                :key="index"
              >
                {{ checkedItem[item.checkedLabel] }}
              </el-checkbox>
            </template>
          </el-checkbox-group>
          <el-select
            v-if="item.type === 'select' || item.type === 'multi-select'"
            v-model="formData[item.prop]"
            :disabled="item.readonly"
            :multiple="item.type === 'multi-select' ? true : false"
            :filterable="filterable"
            :placeholder="item.placeholder"
            @visible-change="visibleChange"
            @change="changeEvent(item.changeEvent, arguments[0], item)"
            style="width: 100%"
            class="elIconStyle"
          >
            <template v-for="(opt, index) in item.options">
              <el-option
                :key="`${opt.key}-${index}`"
                :label="opt.key"
                :value="opt.val"
              >
              </el-option>
            </template>
          </el-select>
          <el-checkbox
            v-if="item.type === 'checkbox'"
            v-model="formData[item.prop]"
          >
            <!-- {{ item.label }} -->
          </el-checkbox>
          <el-cascader
            v-if="item.type === 'cascader'"
            :disabled="item.readonly"
            v-model="formData[item.prop]"
            :options="item.options"
            style="width: 100%"
            filterable
            :show-all-levels="item.showAllLevels || false"
          >
          </el-cascader>
          <!-- 日期 -->
          <el-date-picker
            v-if="item.type === 'date'"
            :disabled="item.readonly"
            :editable="false"
            v-model="formData[item.prop]"
            type="date"
            :default-time="item.picker && item.picker.defaultTime"
            value-format="yyyy-MM-dd"
            :format="dateFormat"
            :picker-options="item.picker"
            :placeholder="item.placeholder"
          >
          </el-date-picker>
          <!-- 日期时间 -->
          <el-date-picker
            v-if="item.type === 'datetime'"
            :disabled="item.readonly"
            :editable="false"
            :default-time="item.picker && item.picker.defaultTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            :format="datetimeFormat"
            v-model="formData[item.prop]"
            type="datetime"
            :picker-options="item.picker"
            :placeholder="item.placeholder"
          >
          </el-date-picker>
          <!-- 年份 -->
          <el-date-picker
            v-if="item.type === 'dateYear'"
            :disabled="item.readonly"
            :editable="false"
            :default-time="item.picker && item.picker.defaultTime"
            :value-format="item.picker && item.picker.valueFormat"
            v-model="formData[item.prop]"
            type="year"
            :picker-options="item.picker"
            :placeholder="item.placeholder"
          >
          </el-date-picker>
          <!-- 提醒 -->
          <span class="remind-text" v-if="item.remind">
            {{ item.remind }}
          </span>
        </el-form-item>
      </div>
    </el-form>

    <!-- 查找/查找多选 -->
    <!-- 搜索 -->
    <el-dialog
      :title="$t('label.searchs')"
      custom-class="dialog-form-wrapper"
      width="60%"
      top="5%"
      :visible.sync="showSearchTable"
      :lock-scroll="true"
      :close-on-click-modal="false"
      :modal="true"
      :before-close="beforeClose"
      append-to-body
    >
      <search-table ref="searchTable" :fieldId="fieldId" />
    </el-dialog>
  </div>
</template>

<script>
import * as Time from "@/utils/time";
import FormUpload from "@/components/Upload/formUpload_Central";
import { IEVersion } from "@/utils/brower";
import * as CommonObjApi from "./api";

export default {
  name: "base-form",
  props: {
    labelPosition: {
      type: String,
      default: "right",
    },
    width: {
      type: String,
    },
    // 几列布局
    columnNum: {
      type: Number,
      default: 2,
    },
    // 一列占宽百分比
    oneColumnWidth: {
      type: String,
      default: "100%",
    },
    // 两列占宽百分比
    twoColumnWidth: {
      type: String,
      default: "50%",
    },
    statusIcon: {
      // 是否显示验证
      type: Boolean,
      default: true,
    },
    formAttr: {
      type: Array,
    },
    // 表单label宽度
    labelWidth: {
      type: [Number, String],
      default: "150px",
      // default: "264px",
    },
    handleBlur: {
      // 处理移出事件
      type: [Function, undefined],
      default: undefined,
    },
    autoRefresh: {
      // 值发生变化时是否自动刷新
      type: Boolean,
      default: true,
    },
    disabled: {
      // 是否
      type: Boolean,
      default: false,
    },
    // 对象前缀
    prefix: {
      type: String,
      default: "",
    },
  },
  components: {
    FormUpload,
  },
  data() {
    return {
      dateFormat: Time.dateFormat($cookies.get("countryCode")),
      datetimeFormat: Time.datetimeFormat($cookies.get("countryCode")),
      possibilityList: [],
      showSearchTable: false,
      fieldId: "",
      props: {
        checkStrictly: true,
        emitPath: false,
      },
      formData: {}, // 当前表格数据,
      rules: {}, // 验证规则
      filterable: true, // 是否可检索
      loading: false, // 远程搜索loading
      filterOptions: [], // 远程搜索用于显示的数组
      _timeOut: null, // 当远程搜索频繁改变时用setTimeout减少发送
    };
  },
  created() {
    this.filterFormData();
    // 如果是edge浏览器 将select可搜索改为false
    if (IEVersion() === "edge") {
      this.filterable = false;
    }
  },
  methods: {
    /* selectTime(val){
    },*/
    // 双向绑定 form name
    filterFormData() {
      this.rules = {};
      this.formData = {};
      this.formAttr &&
        this.formAttr.forEach((v, k) => {
          // 业务机会对象可能性随阶段变化显示对应的值
          if (this.prefix === "002" && v.apiname === "jieduan") {
            this.getKNXSelectValue();
          }

          // 百分比字段显示特殊处理
          if (v.fieldType === "P" && v.value === "") {
            this.$set(this.formData, v.prop, undefined);
          } else if (v.prop === "recordtype") {
            this.$set(this.formData, v.prop, v.id);
          } else {
            this.$set(this.formData, v.prop, v.value);
          }

          // 如果有验证规则
          // 字段必填且不只读时加必填规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val, key) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            //启用外部用户判断用户值是否是邮箱格式
            if (this.contactes === "contactsNew" && v.apiname === "loginname") {
              this.rules[name].push({ validator: this.isEmail });
            }

            // if (v.apiname === 'twitter') {
            //   this.rules[name].push({ validator: this.twitter })
            // } else if (v.apiname === 'linkedin') {
            //   this.rules[name].push({ validator: this.linkedin })
            // } else if (v.apiname === 'facebook') {
            //   this.rules[name].push({ validator: this.facebook })
            // }
            // 邮件、手机添加校验规则
            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          } else if (
            this.contactes === "contactsNew" &&
            v.apiname === "loginname"
          ) {
            this.$set(this.rules, v.prop, arr);
          }

          // 必填项不为空字符串校验 v.required ,自动编号字段除外
          if (v.required && v.fieldType !== "V" && v.apiname !== "principal") {
            if (this.rules[v.prop] !== undefined) {
              this.rules[v.prop].push({ validator: this.isEmpty });
            }
          }
        });

      // 获取任务事件下拉框默认选项
      this.formAttr && this.getDefaultOption();
      // 事件类型 计算时间差
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        this.timeDiff = this.timeFn(
          this.formData.begintime,
          this.formData.endtime
        );
      }
    },
    getDefaultOption() {
      if (
        this.specialObjectapi === "Event" ||
        this.specialObjectapi === "Task"
      ) {
        CommonObjApi.getDialigItem({ type: this.objId }).then((res) => {
          this.defaultPrefix = {
            relate: res.data.relateobjList[0].prefix,
            name: res.data.whoobjInfoList[0].prefix,
          };
          this.formAttr.forEach((item) => {
            if (item.prop === "whoid") {
              if (item.value) {
                this.eventValueA = item.child.whoobj; // 名称
                this.eventCheckedObjA = {
                  id: item.value,
                  name: item.child.whoname,
                };
              } else {
                this.eventValueA = this.defaultPrefix.name; //  名称
              }
            }
            if (item.prop === "relateid") {
              if (item.value) {
                this.eventValueB = item.child.relateobj; // 相关项
                this.eventCheckedObjB = {
                  id: item.value,
                  name: item.child.relatename,
                };
              } else {
                this.eventValueB = this.defaultPrefix.relate; // 相关项
              }
            }
          });
        });
      }
    },
    resetRules() {
      this.rules = {};
      this.formAttr &&
        this.formAttr.forEach((v, k) => {
          // 如果有验证规则
          if (v.rules && v.rules.length && v.edit) {
            let name = v.prop;
            v.rules.forEach((val, key) => {
              if (this.rules[name]) {
                this.rules[name].push(val);
              } else {
                let arr = [];
                arr.push(val);
                this.$set(this.rules, name, arr);
              }
            });

            //启用外部用户判断用户值是否是邮箱格式
            if (this.contactes === "contactsNew" && v.apiname === "loginname") {
              this.rules[name].push({ validator: this.isEmail });
            }

            if (v.fieldType === "E") {
              this.rules[name].push({ validator: this.isEmail });
            } else if (v.fieldType === "P") {
              this.rules[name].push({ validator: this.isNumber });
            }

            // twitter,linkedin,facebook有验证规则
            if (v.apiname === "twitter") {
              this.rules[name].push({ validator: this.twitter });
            } else if (v.apiname === "linkedin") {
              this.rules[name].push({ validator: this.linkedin });
            } else if (v.apiname === "facebook") {
              this.rules[name].push({ validator: this.facebook });
            }
          } else if (v.fieldType === "E") {
            let arr = [{ validator: this.isEmail }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.fieldType === "P") {
            let arr = [{ validator: this.isNumber }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.apiname === "twitter") {
            // twitter,linkedin,facebook无验证规则
            let arr = [{ validator: this.twitter }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.apiname === "linkedin") {
            let arr = [{ validator: this.linkedin }];
            this.$set(this.rules, v.prop, arr);
          } else if (v.apiname === "facebook") {
            let arr = [{ validator: this.facebook }];
            this.$set(this.rules, v.prop, arr);
          }

          // 必填项不为空字符串校验 v.required ,自动编号字段除外
          if (v.required && v.fieldType !== "V") {
            this.rules[v.prop].push({ validator: this.isEmpty });
          }
        });
    },
    // 获取 阶段-可能性 对应关系
    getKNXSelectValue(filedName) {
      CommonObjApi.getSelectValue({ fieldId: "ffe201100003943eFUc8" }).then(
        (res) => {
          if (res.result && res.returnCode === "1") {
            let options = [];
            res.data.forEach((item) => {
              options.push({
                val: item.codevalue,
                key: item.codekey,
                knx: Number(item.knx),
                forecasttype: item.forecasttype,
              });
            });
            this.possibilityList = options;
          } else {
            this.$message.error(res.returnInfo);
          }
        }
      );
    },
    // 清除验证规则
    resetFiled() {
      this.$refs.elForm && this.$refs.elForm.resetFields();
    },
    // 查找/查找多选
    remoteSearch(item) {
      this.fieldId = item.fieldId;
      this.showSearchTable = true;
    },
    beforeClose() {
      this.showSearchTable = false;
    },
    // 远程搜索方法
    remoteMethod(query) {
      if (this.formData.isAssociator === "1") {
        if (query !== "") {
          clearTimeout(this._timeOut);
          this.loading = true;
          this._timeOut = setTimeout(() => {});
        } else {
          this.filterOptions = [];
        }
      }
    },
    // 远程搜索完成选择后清空搜索数组
    clearFilterOptions() {
      this.filterOptions.splice(0);
    },
    // 远程搜索框失去焦点后清空查询列表
    blurEvent() {
      // this.filterOptions = []
    },
    // 选项框发生变化
    selectChange(val, item) {
      this.$emit("selectChange", arguments);
    },
    fileChange(params) {
      this.$emit("fileChange", params);
    },
    remove() {
      this.$emit("remove", ...arguments);
    },
    success() {},
    // 内部组件处理鼠标移出事件
    handleBlurInChild(item, val, formData, params) {
      this.handleBlur && this.handleBlur(...arguments);
    },
    // 选项框发生改变时候执行的事件
    changeEvent(callback, selectVal, selectItem, fieldType) {
      //项目管理弹窗上添加提示
      if (
        (selectItem.apiname === "inform_principal" ||
          selectItem.apiname == "notice_principal") &&
        selectVal == this.$i18n.t("label.tabpage.noz") //否
      ) {
        //当您选择否时，将无法通知负责人！
        this.$alert(
          this.$i18n.t("label.projectManagement.the.person.not.be.informed")
        );
      }
      if (
        selectItem.apiname === "remind_principal" &&
        selectVal == this.$i18n.t("label.tabpage.noz") //"否"
      ) {
        //当您选择“否”时，提醒频率将无法生效
        this.$alert(
          this.$i18n.t("label.projectManagement.alert.frequency.will.not.work")
        );
      }
      if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "0%"
      ) {
        this.formData.status = this.$i18n.t("label.nostart"); //"未开始"
        this.formData.task_status = this.$i18n.t("label.nostart"); //"未开始"
      } else if (
        (selectItem.apiname === "complete_percentage" ||
          selectItem.apiname === "fulfill_percent") &&
        selectVal == "100%"
      ) {
        this.formData.status = this.$i18n.t(
          "label.emailtocloudcc.button.complete"
        ); //完成
        this.formData.task_status = this.$i18n.t(
          "label.emailtocloudcc.button.complete"
        ); //完成
      } else if (
        selectItem.apiname === "complete_percentage" ||
        selectItem.apiname === "fulfill_percent"
      ) {
        this.formData.status = this.$i18n.t("label.starting"); //"进行中"
        this.formData.task_status = this.$i18n.t("label.starting"); //"进行中"
      }
      // 业务机会对象可能性、预测类别随阶段变化显示对应的值
      if (this.prefix === "002" && selectItem.apiname === "jieduan") {
        let knx = this.possibilityList.find((item) => {
          return item.val === selectVal;
        });
        if (knx !== undefined) {
          // 强制改可能性和预测类别的值 --- 接口处理
          // this.$set(this.formData, 'knx', knx.knx)
          // this.$set(this.formData, 'forecasttype', knx.forecasttype)
          if ("knx" in this.formData) {
            this.formData["knx"] = knx.knx;
          }
          if ("forecasttype" in this.formData) {
            this.formData["forecasttype"] = knx.forecasttype;
          }
        }
      }

      // 日期时间字段处理
      if (fieldType === "datetime") {
        if (
          this.formData[`${selectItem.prop}Date`] &&
          this.formData[`${selectItem.prop}Time`]
        ) {
          this.$set(
            this.formData,
            selectItem.prop,
            `${this.formData[`${selectItem.prop}Date`]} ${
              this.formData[`${selectItem.prop}Time`]
            }`
          );
        } else {
          this.$set(this.formData, selectItem.prop, "");
        }
      }
      // callback && callback(selectVal, selectItem, this.formData)
      // 同一字段变化可能绑定多事件，如查找带值，依赖关系，动态布局规则
      let changeEvent = callback !== undefined ? callback.split(",") : [];
      if (selectItem.prop !== "recordtype") {
        changeEvent.forEach((eventItem) => {
          if (eventItem === "control") {
            eventItem &&
              this.$emit(
                eventItem,
                selectVal,
                selectItem.fieldId,
                this.formData
              );
          } else {
            eventItem &&
              this.$emit(eventItem, selectVal, selectItem, this.formData);
          }
        });
      }
      // 事件类型自动更改结束时间
      if (
        this.specialObjectapi === "Event" &&
        this.formData.endtime &&
        this.formData.begintime
      ) {
        // 如果更改项为开始时间自动更改结束时间
        if (selectItem.prop === "begintime") {
          this.autoEventDate(this.formData.begintime);
        }
        // 如果更改项为结束时间重新计算时间差
        if (selectItem.prop === "endtime") {
          if (
            this.timeFn(this.formData.begintime, this.formData.endtime) >= 0
          ) {
            this.timeDiff = this.timeFn(
              this.formData.begintime,
              this.formData.endtime
            );
          } else {
            this.formData.endtime = "";
            this.$message({
              showClose: true,
              type: "warning",
              // '结束时间不应早于开始时间'
              message: this.$i18n.t("label.appointment.wizard.notif1"),
            });
          }
        }
      }
    },
    // 获取焦点时触发事件
    focusEvent() {},
    // 富文本内容变更
    onContentChange(callback, content, selectItem) {
      this.formData[selectItem.prop] = content;
    },
    selecter(cb, data) {
      cb(data);
    },
    // 下拉选项框出现时候触发
    visibleChange(flag) {
      // 如果显示  获取所有下拉选项框 并改变他们的最大宽度
      if (flag) {
        let elSelectDropdown =
          document.getElementsByClassName("el-select-dropdown");
        for (let i = 0; i < elSelectDropdown.length; i++) {
          let el = elSelectDropdown[i];
          el.style.maxWidth = el.style.minWidth;
        }
      }
    },
    // 修改某个属性的键值
    changeProps(props, key, val) {
      for (let i = 0; i < this.formAttr.length; i++) {
        let item = this.formAttr[i];
        if (item.prop === props) {
          item[key] = val;
          break;
        }
      }
    },
  },
  watch: {
    formAttr: {
      handler: function (newValue, oldValue) {
        if (this.autoRefresh) {
          this.filterFormData();
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" rel="stylesheet/scss" scoped>
.remoteBtn {
  cursor: pointer;

  // &:hover{
  //   color:
  // }
}

.groupTitle {
  // height: 40px;
  line-height: 40px;
  font-size: 16px;
  padding-left: 20px;
  background: #f0f0f0;
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

::v-deep .el-input-number {
  width: 100%;
  line-height: 40px;
}

::v-deep .el-form-item__label {
  text-align: left;
  word-break: break-word;
  padding-left: 50px;
}
.elIconStyle {
  ::v-deep .el-input__icon {
    // line-height: 30px !important;   //wj
    height: 40px;
  }
}
.clearfix {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-start;
}
</style>
