import * as menu1 from "./menu1"
import * as menu2 from "./menu2"
/******************************************一级菜单************************************************/
/**
 * 添加menu1
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function addMenu1(vue) {
    window.$CCDK.CCBus.$on('addMenu1', (options) => {
        menu1.add_menu1(vue, options)
    })
}
/**
 * 替换menu1
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function replaceMenu1(vue) {
    window.$CCDK.CCBus.$on('replaceMenu1', (options) => {
        menu1.replace_menu1(vue, options)
    })
}
/**
 * reopen_menu1
 * @param {*} options 菜单信息  id
 */
function reOpenMenu1(vue) {
    window.$CCDK.CCBus.$on('reOpenMenu1', (options) => {
        menu1.reopen_menu1(vue, options)
    })
}
/**
 * 删除menu1
 * @param {*} options 菜单信息  id
 */
function deleteMenu1(vue) {
    window.$CCDK.CCBus.$on('deleteMenu1', (options) => {
        menu1.delete_menu1(vue, options)
    })
}
/**
 * 刷新menu1
 * @param {*} options 菜单信息 id
 */
function refreshMenu1(vue) {
    window.$CCDK.CCBus.$on('refreshMenu1', (options) => {
        menu1.refresh_menu1(vue, options)
    })
}

/******************************************二级菜单************************************************/
/**
 * 添加menu2
 *  * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function addMenu2(vue) {
    window.$CCDK.CCBus.$on('addMenu2', (options) => {
        menu2.add_menu2(vue, options)
    })
}
/**
 * 替换menu2
 * @param {*} options 菜单信息
 * {
        name: "新建联系人", // tab名称
        id: "asdasfd2323",// tab id，唯一标识tab的
        routerName:"" ,//路由的名字
        params:"",// 页面需要的参数
    }
 */
function replaceMenu2(vue) {
    window.$CCDK.CCBus.$on('replaceMenu2', (options) => {
        menu2.replace_menu2(vue, options)
    })
}
/**
 * reopen_menu2
 * @param {*} options 菜单信息  id
 */
function reOpenMenu2(vue) {
    window.$CCDK.CCBus.$on('reOpenMenu2', (options) => {
        menu2.reopen_menu2(vue, options)
    })
}
/**
 * 删除menu2
 * @param {*} options 菜单信息  id
 */
function deleteMenu2(vue) {
    window.$CCDK.CCBus.$on('deleteMenu2', (options) => {
        menu2.delete_menu2(vue, options)
    })
}
/**
 * 刷新menu2
 * @param {*} options 菜单信息
 */
function refreshMenu2(vue) {
    window.$CCDK.CCBus.$on('refreshMenu2', (options) => {
        menu2.refresh_menu2(vue, options)
    })
}

/**
 * 安装工具
 */
function install(vue) {
    addMenu1(vue)
    replaceMenu1(vue)
    reOpenMenu1(vue)
    deleteMenu1(vue)
    refreshMenu1(vue)
    addMenu2(vue)
    replaceMenu2(vue)
    reOpenMenu2(vue)
    deleteMenu2(vue)
    refreshMenu2(vue)
}

/**
 * 卸载工具
 */
function uninstall() {
    window.$CCDK.CCBus.$off('addMenu1')
    window.$CCDK.CCBus.$off('replaceMenu1')
    window.$CCDK.CCBus.$off('reOpenMenu1')
    window.$CCDK.CCBus.$off('deleteMenu1')
    window.$CCDK.CCBus.$off('refreshMenu1')
    window.$CCDK.CCBus.$off('addMenu2')
    window.$CCDK.CCBus.$off('replaceMenu2')
    window.$CCDK.CCBus.$off('reOpenMenu2')
    window.$CCDK.CCBus.$off('deleteMenu2')
    window.$CCDK.CCBus.$off('refreshMenu2')
}

export default {
    install,
    uninstall
}
