
 const equityPenetrationChart = [
    {
        name: "EquityPenetrationRelation",
        path: "/EquityPenetrationRelation/:id",
        component: () => import("@/views/equityPenetrationChart/EquityPenetrationChart.vue"),
      },
  
  ];
  
  export default equityPenetrationChart
  