/*
 * @Author: zhangyutong
 * @Date: 2021-09-14 12:36:59
 * @LastEditTime: 2021-10-20 11:30:02
 * @LastEditors: zhangyutong
 * @FilePath: \lightning-web\src\store\modules\channel.js
 */
// 市场营销渠道二维码模块
const channel = {
  state: {
    itemDataId: '', // 点击列表中的编辑按钮，每条数据的id
    actionType: 'new', // 新建、编辑
    stepFourUnuseSelectList: [], // 步骤四备选列表数据
    bigTitle: '', //
    tabName: '', //
    objIds: '', // 对象id
    marketingListLength: 0, // 营销渠道相关列表的长度
    campaignId: '',// 市场活动记录id
    invitationLength: 0,
    posterLength: 0,
    socialLength: 0,
    mailLength: 0,
    landPageLength: 0,
    flowLength: 0,
    roiLength: 0,
    languge:'',
    proMenu: []
  },
  mutations: {
    programMenu(state, value) {
      state.proMenu = value
    },
    setLanguge(state, value){
      state.languge = value
    },
    setInvitationLength(state, length) {
      state.invitationLength = length;
    },
    setRoiLength(state, length) {
      state.roiLength = length
    },
    setFlowlength(state, length) {
      state.flowLength = length
    },
    setLandPageLength(state, length) {
      state.landPageLength = length
    },
    setMailLength(state, length) {
      state.mailLength = length
    },
    setSocialLength(state, length) {
      state.socialLength = length
    },
    setPosterLength(state, length) {
      state.posterLength = length
    },
    setObjIds(state, id) {
      state.objIds = id
    },
    changeItemDataId(state, id) {
      state.itemDataId = id
    },
    changeActionType(state, actionType) {
      state.actionType = actionType
    },
    getStepFourUnuseSelectList(state, list) {
      state.stepFourUnuseSelectList = list
    },
    changeBigTitle(state, title) {
      state.bigTitle = title
    },
    changeTabName(state, tabName) {
      state.tabName = tabName
    },
    getMarketingListLength(state, length) {
      state.marketingListLength = length
    },
    getCampaignId(state, campaignRecordId) {
      state.campaignId = campaignRecordId
    }
  },
  actions: {

  }
}
export default channel;