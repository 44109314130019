import Vue from "vue";
import createObjEdit from "@/components/CCPage/create-obj.vue"
/**
 * 以dialog新建记录，仅支持_blank方式打开弹窗
 * @param {*} vue vue实例
 * @param {*} pageId 动态生成的id，页面唯一标识
 * @param {*} obj 新建对象信息
 * @param {*} options 新建对象方式信息
 */

function dialog_blank(vue, pageId, obj, options) {
  // 打开新建弹窗
  Vue.component(`create-obj-edit`, createObjEdit)
  vue.$rendererDialog({
    comName: 'create-obj-edit',
    // dialog属性
    dialogAttrObj: {
      title: obj.objectName,
      width: "80%",
      height: options.height || "",
      top: '10vh',
      'lock-scroll': true,
    },
    pageId,
    // 组件所需的数据 比如 pageApi
    componentAttr: { pageId, ...obj, ...options }
  });
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    vue.$bus.$emit('closeRendererDialog',pageId)
  }
  window.$CCDK.CCPage.addPage(pageId,page)
}


function menu1_self(vue, pageId, obj={}, options) {
    // 菜单数据
    let menuObj = {
      name: obj.objectName,
      id: pageId,
      routerName: 'consoleMenu1Create',
      params: {
          page: pageId,
          ...obj,
          ...options,
      }
  }
  // window.$CCDK.CCMenu.addMenu1(menuObj)
  window.$CCDK.CCMenu.replaceMenu1(menuObj)
    // 添加页面关闭方法
    let page = {}
    page.close = ()=>{
      window.$CCDK.CCMenu.deleteMenu1({id:pageId})
    }
    window.$CCDK.CCPage.addPage(pageId,page)
  vue.$router.push({
    name: 'consoleMenu1Create',
    params: {
      page: pageId,
      ...obj,
      ...options,
    }
  });
}
function menu1_blank(vue, pageId, obj={}, options) {
  // 菜单数据
  let menuObj = {
    name: obj.objectName,
    id: pageId,
    routerName: 'consoleMenu1Create',
    params: {
        page: pageId,
        ...obj,
        ...options,
    }
}
  window.$CCDK.CCMenu.addMenu1(menuObj)
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    window.$CCDK.CCMenu.deleteMenu1({id:pageId})
  }
  window.$CCDK.CCPage.addPage(pageId,page)
    vue.$router.push({
      name: 'consoleMenu1Create',
      params: {
        page: pageId,
        ...obj,
        ...options,
      }
    });
}
/**
 * 以二级菜单新建记录，只有tab模式才支持_blank
 */
 function menu2_self(vue, pageId, obj={}, options) {
      // 菜单数据
      let menuObj = {
          name: obj.objectName,
          id: pageId,
          routerName: 'consoleMenu2Create',
          params: {
              page: pageId,
              ...obj,
              ...options,
          }
      }
      // window.$CCDK.CCMenu.addMenu2(menuObj)
      window.$CCDK.CCMenu.replaceMenu2(menuObj)
      // let routeData = vue.$router.resolve({
      //   name: 'consoleMenu2Create',
      //   params: {
      //     page: pageId,
      //     ...obj,
      //     ...options,
      //   }
      // });
      // window.open(routeData.href, options.openMode);
      // 添加页面关闭方法
    let page = {}
    page.close = ()=>{
      window.$CCDK.CCMenu.deleteMenu2({id:pageId})
    }
    vue.$router.push({
      name: 'consoleMenu2Create',
      params: {
        page: pageId,
        ...obj,
        ...options,
      }
    });

}
function menu2_blank(vue, pageId, obj={}, options) {
  // 菜单数据
  let menuObj = {
      name: obj.objectName,
      id: pageId,
      routerName: 'consoleMenu2Create',
      params: {
          page: pageId,
          ...obj,
          ...options,
      }
  }
  window.$CCDK.CCMenu.addMenu2(menuObj)
  // 添加页面关闭方法
  let page = {}
  page.close = ()=>{
    window.$CCDK.CCMenu.deleteMenu2({id:pageId})
  }
  window.$CCDK.CCPage.addPage(pageId,page)
      vue.$router.push({
        name: 'consoleMenu2Create',
        params: {
          page: pageId,
          ...obj,
          ...options,
        }
      });
}
/**
 * 以dialog新建记录，仅支持_blank方式打开弹窗
 * @param {*} vue 新建对象信息
 * @param {*} obj 新建对象信息
 * @param {*} options 新建对象方式信息
 */
function dialog_self(vue, pageId, obj, options) {
  // 打开新建弹窗
  Vue.component(`create-obj-edit`, createObjEdit)
  vue.$rendererDialog({
    comName: 'create-obj-edit',
    // dialog属性
    dialogAttrObj: {
      title: obj.objectName,
      width: "80%",
      height: options.height || "",
      top: '10vh',
      'lock-scroll': true,
    },
    pageId,
    // 组件所需的数据 比如 pageApi
    componentAttr: {pageId, ...obj,...options }
  });
    // 添加页面关闭方法
    let page = {}
    page.close = ()=>{
      vue.$bus.$emit('closeRendererDialog',pageId)
    }
    window.$CCDK.CCPage.addPage(pageId,page)
}
/**
 * 以tab新建记录
 * @param {*} vue 新建对象信息
 * @param {*} obj 新建对象信息
 * @param {*} options 新建对象方式信息
 */
 function tab_self(vue, pageId, obj, options) {
  //
  tab_blank(vue, pageId, obj, options)
}
function tab_blank(vue, pageId, obj, options) {
  // 将记录ID recordId 以及defalutData存储到localStorage，设置时间，24小时有效期
  if(obj.recordId || obj.defalutData){
    let storeObj = {
      data: obj,
      expire: Date.now() + 1000 * 60 * 60 * 24
    }
    localStorage.setItem(pageId, JSON.stringify(storeObj));
  }
  let routeData = vue.$router.resolve({
    name: 'consoleTabCreate',
    params: {
      page: pageId,
      ...obj,
      ...options,
    }
  });
  let oNewWindow = window.open(routeData.href, options.openMode);
  // 添加页面关闭方法
  let page = {}
  page.close=()=>{
      oNewWindow.close()
  }
  window.$CCDK.CCPage.addPage(pageId,page)
}
export { menu1_self,menu1_blank, menu2_self, menu2_blank, dialog_blank, dialog_self, tab_self, tab_blank }
