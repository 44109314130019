

<script>
/**
 * 此文件作为混入文件，仅处理字段依赖相关业务
 */
import operator from "@/config/enumCode/operator.js";
import INPUTTYPE from "@/config/enumCode/inputType";
import * as createApi from "@/views/commonObjects/components/api.js";
export default {
  data() {
    return {
      // 主记录字段控制字段依赖关系集合
      controlContainer: null,
      // 主记录字段依赖字段集合
      dependContainer: null,
      // 工作规则-资源属性字段
      resourceAttributeFields: [],
    };
  },
  computed: {
    /**
     * 做操作类型
     */
    changTypeContainer() {
      let type = null;
      if (this.operation) {
        type = this.operation;
      }
      if (this.dialogAttr?.type) {
        type = this.dialogAttr.type;
      }
      return type;
    },
  },
  methods: {
    /**
     * 给依赖字段设置表示依赖字段身份的属性和依赖字段禁用属性，清空依赖字段的下拉项数据
     * @param {String} fieldId:依赖字段id（用于字段依赖触发时，直接改变optionList）
     * @param {Boolean} isInit:是否是初始化调用
     */
    setDependField(fieldId, isInit = false) {
      if (Array.isArray(this.formAttr) && Array.isArray(this.dependContainer)) {
        if (fieldId && !isInit) {
          // 如果fieldId有值，说明是字段依赖触发，使用find，不要使用forEach
          // 当前依赖字段的下拉选项
          this.formAttr.find((item) => {
            if (Array.isArray(item.children) && fieldId !== item.fieldId) {
              item.children.find((child) => {
                if (child.fieldId === fieldId) {
                  // 清空依赖字段的下拉项数据
                  this.optionList[child.prop] = [];
                  // 依赖字段处理
                  this.isDisableDpendField(item, false);
                }
                return child.fieldId === fieldId;
              });
            } else {
              if (fieldId === item.fieldId) {
                // 清空依赖字段的下拉项数据
                this.optionList[item.prop] = [];
                // 处理依赖字段
                this.isDisableDpendField(item, false);
              }

              return fieldId === item.fieldId;
            }
          });
        } else {
          // 获取所有依赖字段id组成的集合，用于遍历formAttr数据，添加依赖字段属性
          let dependIdAry = this.dependContainer.map((item) => item.id);
          this.formAttr.forEach((item) => {
            // 判断地址字段子字段作为依赖字段的情况
            if (Array.isArray(item.children)) {
              item.children.forEach((child) => {
                // if(child.fieldId && dependIdAry.includes(child.fieldId)){
                if (child.fieldId) {
                  // 添加表示依赖字段属性和依赖字段禁用属性，背景属性
                  if (isInit) {
                    // 是否是依赖字段
                    this.$set(child, "isDpendFieldid", true);
                    // 处理依赖字段
                    this.isDisableDpendField(child, false);
                  }
                  // 清空依赖字段的下拉项数据
                  this.optionList[child.prop] = [];
                }
              });
            }
            // 初始化非地址子字段情况
            if (item.fieldId && dependIdAry.includes(item.fieldId)) {
              // 添加表示依赖字段属性
              if (isInit) {
                this.$set(item, "isDpendFieldid", true);
                // 处理依赖字段
                this.isDisableDpendField(item, false);
              }
              // 清空依赖字段的下拉项数据
              this.optionList[item.prop] = [];
            }
          });
        }
      }
    },
    /**
     * 匹配控制字段，执行依赖方法
     * @param {Array} formAttr:表单数据
     * @param {Array} controlContainer:控制字段集合
     * @param {Object} formData:this.$refs.baseForm.formData
     * @param {Function} callback:回调方法,依赖函数
     */
    setControlField(
      formAttr = [],
      controlContainer = [],
      formData = {},
      callback
    ) {
      if (Array.isArray(formAttr) && Array.isArray(controlContainer)) {
        // 获取所有控制字段组成的集合，用于遍历formAttr数据，匹配字段
        let controlIdAry = controlContainer.map((item) => item.controlfield);
        formAttr.forEach((item) => {
          // 判断地址字段，地址字段需要进行子集遍历匹配
          if (item.children) {
            item.children.forEach((child) => {
              if (child.fieldId && controlIdAry.includes(child.fieldId)) {
                callback(child.value, child.fieldId, formData, true);
              }
            });
          }
          // 其他字段
          if (item.fieldId && controlIdAry.includes(item.fieldId)) {
            callback(item.value, item.fieldId, formData, true);
          }
        });
      }
      /**
       *  城市初始化时默认值赋值
       * todo:待优化
       */
      if (Array.isArray(this.provincecityContainer)) {
        this.provincecityContainer.forEach((itemx) => {
          this.formAttr.forEach((attr) => {
            //地址字段子级需遍历
            if (attr.children) {
              attr.children.forEach((child) => {
                if (child.fieldId === itemx.controlfield) {
                  this.getCity(
                    child.value,
                    child.fieldId,
                    this.$refs.baseForm.formData
                  );
                }
              });
            }
          });
        });
      }

      /**
       * 县城初始化默认值赋值
       * todo:待优化
       */
      if (Array.isArray(this.citycountyContainer)) {
        this.citycountyContainer.forEach((itemx) => {
          this.formAttr.forEach((attr) => {
            //地址字段子级需遍历
            if (attr.children) {
              attr.children.forEach((child) => {
                if (child.fieldId === itemx.controlfield) {
                  this.getCounty(
                    child.value,
                    child.fieldId,
                    this.$refs.baseForm.formData
                  );
                }
              });
            }
          });
        });
      }
    },
    /**
     * 依赖函数，初始化或者控制字段触发执行
     * 1、initControl为true时是新建初始化，已经将选项值都清空，无需再次清理
     * @param {string} selectVal:控制字段选项列表选中的值（或者复选框选中的值）
     * @param {string} fieldId:控制字段的字段id
     * @param {object} formData:this.$refs.baseForm.formData
     * @param {boolean} initControl:是否是新建、编辑时初始化
     */
    controlFun(selectVal, fieldId, formData = {}, initControl = false) {
      this.workAndServiceGoal(selectVal, fieldId);
      // 处理富文本字段
      if (typeof selectVal === "boolean") {
        selectVal = selectVal.toString();
      }
      if (
        fieldId &&
        Array.isArray(this.controlContainer) &&
        Array.isArray(this.formAttr) &&
        Array.isArray(this.dependContainer)
      ) {
        // 根据fieldId在controlContainer中找到对应的控制字段信息
        let control = this.controlContainer.find((control) => {
          return control.controlfield === fieldId;
        });
        // 布局中存在该控制字段
        if (control) {
          // 查找当前控制字段选择的值是否有控制规则
          let controlCondition = control.controlVals.find((item) => {
            // todo：这里使用的是val，需要判断下是否有多语言问题（开发完后要重新梳理下整个流程）
            return item.val === selectVal;
          });
          // 循环后有对应选项再进行赋值
          // 当前控制字段下所有依赖字段id集合（一个控制字段可以有多个依赖字段）
          if (!controlCondition) {
            // 如果controlCondition不存在(或者selectVal为空)，说明该控制字段选中值没有设置依赖关系，此时应该清空该控制字段所有依赖字段选中值，并禁用
            // 获取该控制字段下所有的依赖字段id
            let dependListId = [];
            control.controlVals.forEach((controlItem) => {
              if (Array.isArray(controlItem.dependFields)) {
                controlItem.dependFields.forEach((dependItem) => {
                  if (!dependListId.includes(dependItem.dependfieldid)) {
                    dependListId.push(dependItem.dependfieldid);
                  }
                });
              }
            });
            // 非初始化触发（即用户使用字段依赖触发）
            if (!initControl) {
              // 先将依赖字段选项option数据清空
              dependListId.forEach((item) => {
                if (item) {
                  this.setDependField(item);
                }
              });
            }
            // 清空所有依赖字段值
            this.formAttr.forEach((attrItem) => {
              // 如果selectVal有值，考虑复选框
              // 增加控制字段背景色
              if (attrItem.fieldId === fieldId) {
                if (
                  typeof selectVal === "string" ||
                  typeof selectVal === "boolean"
                ) {
                  // if (selectVal) {
                  //   this.$set(attrItem, "bgcolor", true);
                  // } else {
                  //   this.$set(attrItem, "bgcolor", false);
                  // }
                  // 没有设置依赖关系或者选的值不在下拉项中，不加背景色
                  this.$set(attrItem, "bgcolor", false);
                }
              }

              if (dependListId.includes(attrItem.fieldId)) {
                // 处理依赖关系
                this.isDisableDpendField(attrItem, false);
                // 编辑初始化不执行一下操作
                if (!(initControl && this.changTypeContainer === "EDIT")) {
                  // 该字段是依赖字段，需要清空并禁用，
                  if (attrItem.fieldType === "Q") {
                    // Q:选项列表多选
                    this.$set(formData, attrItem.apiname, []);
                  } else {
                    this.$set(formData, attrItem.apiname, "");
                  }
                  // 如果该字段也是控制字段，需要递归调用（一个字段的依赖字段可以作为其他字段的控制字段）
                  if (attrItem.isContrField) {
                    this.controlFun(
                      formData[attrItem.apiname],
                      attrItem.fieldId,
                      formData,
                      initControl
                    );
                  }
                }
              }
            });
          } else {
            // 控制字段选中的值有对应的依赖值
            controlCondition.dependFields.forEach((dependField) => {
              this.formAttr.forEach((item) => {
                // 如果selectVal有值，考虑复选框
                // 增加控制字段背景色
                if (item.fieldId === fieldId) {
                  if (
                    typeof selectVal === "string" ||
                    typeof selectVal === "boolean"
                  ) {
                    if (selectVal) {
                      this.$set(item, "bgcolor", true);
                    } else {
                      this.$set(item, "bgcolor", false);
                    }
                  }
                }
                // 如果该字段是依赖字段
                if (item.isDpendFieldid) {
                  this.clearDependFieldOption(
                    item,
                    dependField,
                    formData,
                    initControl
                  );
                }
                // 地址字段含有子级处理逻辑
                if (Array.isArray(item.children)) {
                  item.children.forEach((child) => {
                    // 如果是依赖字段
                    if (child.isDpendFieldid) {
                      this.clearDependFieldOption(
                        child,
                        dependField,
                        formData,
                        initControl
                      );
                    }
                  });
                }
              });
            });
          }
        }
      }
    },
    /**
     * 依赖字段清空，取消背景色
     * @param {string} selectVal:依赖字段的值
     * @param {string} fieldId:依赖字段id
     */
    cancelDependField(selectVal, fieldId) {
      let depend = this.formAttr.find((item) => item.fieldId === fieldId);
      if (depend) {
        if (!selectVal) {
          if (depend.bgcolor) {
            this.$set(depend, "bgcolor", false);
          }
          // 取消字段的必填
          if (depend.required) {
            this.$set(depend, "required", false);
          }
        } else {
          if (!depend.bgcolor) {
            this.$set(depend, "bgcolor", true);
          }
        }
      }
    },
    /**
     * 字段依赖方法：控制字段变化，依赖字段下拉值改变，选项列表单选需将第一条数据作为依赖字段值，如果没有配置下拉项，直接禁用
     * 需求变更：选项列表单选需将第一条数据作为依赖字段值取消
     * @param {object} fieldObj:formAttr中对应的字段字段信息
     * @param {object} dependField:控制字段数据中对应的依赖信息对象
     * @param {object} formData:表单对象数据
     * @param {boolean} initControl:是否是新建、编辑时初始化
     */
    clearDependFieldOption(
      fieldObj = {},
      dependField = {},
      formData = {},
      initControl = false
    ) {
      if (fieldObj.fieldId === dependField.dependfieldid) {
        let optList = [];
        // 记录下拉项中的val，用于回显值和默认值匹配使用
        let Qlist = [];
        dependField.vals.forEach((item) => {
          // key：是label，val：是value
          optList.push({ key: item.val, val: item.deflangval });
          Qlist.push(item.deflangval);
        });
        // 改变依赖字段的下拉项数据
        this.$set(this.optionList, [fieldObj.prop], optList);
        if (optList.length > 0) {
          // 处理依赖字段
          this.isDisableDpendField(fieldObj, true);
          // 查看当前选中的值是否在选项列表中（考虑到编辑时回显），如果不在，则清空，选项列表单选时，把下拉项中第一项作为依赖字段的值
          // 编辑初始化不执行
          if (!(initControl && this.changTypeContainer === "EDIT")) {
            // 编辑回显时，所选值即使不在选项列表中，也不用清空
            if (fieldObj.fieldType === "Q") {
              if (formData[fieldObj.apiname]) {
                let fieldVals = JSON.parse(
                  JSON.stringify(formData[fieldObj.apiname])
                );
                let isCloulude = fieldVals.every((item) => {
                  return Qlist.includes(item);
                });
                // 说明选择的内容有不属于下拉项中的，再判断默认值是否在选项列表中,直接清空
                if (!isCloulude || fieldVals.length === 0) {
                  if (
                    fieldObj.defaultValue &&
                    Qlist.includes(fieldObj.defaultValue)
                  ) {
                    // 存在默认值,并且在下拉项数据中
                    this.$set(formData, fieldObj.apiname, [
                      fieldObj.defaultValue,
                    ]);
                  } else if (fieldVals.length !== 0) {
                    // 直接清空
                    this.$set(formData, fieldObj.apiname, []);
                  }
                }
              }
            } else {
              // 选项列表单选先看默认值，如果默认值没有就将第一项选中
              // Qlist.includes(formData[fieldObj.apiname])成立，此时原来的值在下拉项中（编辑回显情况，什么也不做）

              if (!Qlist.includes(formData[fieldObj.apiname])) {
                if (
                  fieldObj.defaultValue &&
                  Qlist.includes(fieldObj.defaultValue)
                ) {
                  // 存在默认值,并且在下拉项数据中
                  this.$set(formData, fieldObj.apiname, fieldObj.defaultValue);
                } else {
                  // 清空
                  this.$set(formData, fieldObj.apiname, "");
                }
              }
            }
          }
        } else {
          // 处理依赖字段
          this.isDisableDpendField(fieldObj, false);
          // 编辑初始化不执行
          if (!(initControl && this.changTypeContainer === "EDIT")) {
            // 此时该依赖字段没有配置下拉项，清空值，禁用
            if (fieldObj.fieldType === "Q") {
              this.$set(formData, fieldObj.apiname, []);
            } else {
              this.$set(formData, fieldObj.apiname, "");
            }
          }
        }
        // 编辑初始化不执行
        if (!(initControl && this.changTypeContainer === "EDIT")) {
          // 如果该依赖字段是控制字段，递归调用
          if (fieldObj.isContrField) {
            this.controlFun(
              formData[fieldObj.apiname],
              fieldObj.fieldId,
              formData,
              initControl
            );
          }
        }
      }
    },

    /**
     * 统一处理依赖字段，仅限于控制字段改变，引发依赖字段清空这种情况使用
     * 1、依赖字段禁用和取消禁用
     * 2、依赖字段增加背景色和取消背景色
     * 3、依赖字段取消必填和增加填
     * @param {object} fieldObj：依赖字段信息
     * @param {boolean} isEdit：是否可编辑
     */
    isDisableDpendField(fieldObj = {}, isEdit) {
      if (fieldObj && typeof isEdit === "boolean") {
        if (!isEdit) {
          // 禁用
          if (fieldObj.DpendFieldEdit) {
            this.$set(fieldObj, "DpendFieldEdit", false);
          }

          // 背景色取消
          if (fieldObj.bgcolor) {
            this.$set(fieldObj, "bgcolor", false);
          }
          // 如果是必填，取消必填
          if (fieldObj.required) {
            this.$set(fieldObj, "required", false);
          }
        } else {
          // 取消禁用
          if (!fieldObj.DpendFieldEdit) {
            this.$set(fieldObj, "DpendFieldEdit", true);
          }
          // 增加背景色
          if (!fieldObj.bgcolor) {
            this.$set(fieldObj, "bgcolor", true);
          }
          // 如果是之前是必填，增加必填
          if (fieldObj.requiredCopy) {
            this.$set(fieldObj, "required", true);
          }
        }
        // 重置表单规则
        this.$nextTick(() => {
          this.$refs.baseForm?.resetRules(fieldObj);
        });
      }
    },
    /**
     * 县城下拉选择
     */
    getCounty(selectVal, fieldId, formData) {
      let dataArr = this.citycountyContainer?.find((item) => {
        return item.controlfield === fieldId;
      });
      if (!dataArr) return;
      let province = dataArr?.controlVals.find(
        (city) => city.val === selectVal
      );
      province?.dependFields.forEach((dependField) => {
        this.formAttr.forEach((controlAttr) => {
          //地址字段含有子级处理逻辑
          if (controlAttr.children) {
            controlAttr.children.forEach((controlAttr) => {
              this.clearDependFieldOption(controlAttr, dependField, formData);
            });
          }
          this.clearDependFieldOption(controlAttr, dependField, formData);
        });
      });
    },
    /**
     * 城市下拉
     */
    getCity(selectVal, fieldId, formData) {
      /**
       * selectVal 选择的城市名称
       */
      let dataArr = this.provincecityContainer?.find((item) => {
        return item.controlfield === fieldId;
      });
      if (!dataArr) return;
      let province = dataArr?.controlVals.find(
        (city) => city.val === selectVal
      );
      province?.dependFields.forEach((dependField) => {
        this.formAttr.forEach((controlAttr) => {
          //地址字段含有子级处理逻辑
          if (controlAttr?.children) {
            controlAttr.children.forEach((controlAttr) => {
              this.clearDependFieldOption(controlAttr, dependField, formData);
            });
          }
          this.clearDependFieldOption(controlAttr, dependField, formData);
        });
      });
    },
    /**
     * ! 重要
     * 地址字段重新选择后，重置数据方法
     */
    changeEventEmitToParent({ fieldId, formData }) {
      this.formAttr.forEach((controlAttr) => {
        if (!controlAttr?.children) return;
        controlAttr?.children.forEach((item) => {
          if (item.fieldId === fieldId) {
            controlAttr?.children.forEach((itemx) => {
              if (
                Number(itemx.seq) > Number(item.seq) &&
                Number(itemx.seq) !== 5
              ) {
                formData[itemx.prop] = "";
              }
            });
          }
        });
      });
    },
    /**
     * workAndServiceGoal
     * @param {string} selectVal:控制字段选项列表选中的值（或者复选框选中的值）
     * @param {string} fieldId:控制字段的字段id
     * @param {object} formData:this.$refs.baseForm.formData
     * @param {boolean} initControl:是否是新建、编辑时初始化
     */
    workAndServiceGoal(selectVal, fieldId) {
      // 工作规则处理
      if (this.objectApi == "WorkRule") {
        // 选的字段类型
        let curValue = this.resourceAttributeFields.find((item) => {
          return item.id === selectVal;
        });
        // 查找运算符
        let conditions =
          operator.TYPE[curValue.schemefieldType].getScreeningOpeTypes();
        this.formAttr.forEach((item) => {
          // 字段运算符
          if (item.apiname == "fieldoperator") {
            item.type = "select";
            this.optionList[item.prop] = conditions.map((ele) => {
              return {
                key: ele.name,
                val: ele.id,
              };
            });
          } else if (item.apiname == "resourcespropertyvalue") {
            // 字段类型对应不同的样式
            item.type = INPUTTYPE[curValue.schemefieldType];
            // 资源属性值
            if (
              curValue.schemefieldType == "L" ||
              curValue.schemefieldType == "Q"
            ) {
              createApi.getSelectValue({ fieldId: selectVal }).then((res) => {
                if (res.result && res.returnCode === "1") {
                  let options = [];
                  res.data.forEach((item) => {
                    options.push({
                      val: item.codekey,
                      key: item.codevalue,
                    });
                  });
                  this.$set(this.optionList, item.prop, options);
                } else {
                  this.$message.error(res.returnInfo);
                }
              });
            }
            if (curValue.schemefieldType == "P") {
              let decimal =
                curValue.decimalPlaces !== undefined &&
                curValue.decimalPlaces !== null
                  ? 1 / Math.pow(10, Number(curValue.decimalPlaces))
                  : 0;
              item.valueInterval = {
                min:
                  0 -
                  Math.pow(
                    10,
                    curValue.schemefieldLength - curValue.decimalPlaces - 1
                  ) +
                  decimal,
                max:
                  Math.pow(
                    10,
                    curValue.schemefieldLength - curValue.decimalPlaces - 1
                  ) - decimal,
              };
            }
          }
        });
      }
      // 服务目标，自定义目标类型 ----开始
      if (
        this.objectApi == "ServiceGoal" &&
        fieldId == "ffeServiceGoalstdf14"
      ) {
        // 注：接口返回的value就是中文
        if ("资源资格" == selectVal) {
          // 资格字段
          this.formAttr.forEach((item) => {
            if (item.apiname == "qualificationfield") {
              item.type = "select";
              this.resourceAttributeFields = [];
              this.optionList[item.prop] = [];
              createApi.getQualificationFields().then((res) => {
                res.data.forEach((itemo) => {
                  // 留着选中后匹配类型用
                  this.resourceAttributeFields.push(...itemo.fieldList);
                  // 组合数据
                  let obj = {};
                  obj.label = itemo.objName;
                  obj.options = itemo.fieldList.map((items) => {
                    return {
                      key: items.labelName,
                      val: items.id,
                    };
                  });
                  // 给资源属性字段赋值选项-且分组
                  this.optionList[item.prop].push(obj);
                });
              });
            }
          });
        } else {
          // 资格字段
          this.formAttr.forEach((item) => {
            if (item.apiname == "qualificationfield") {
              item.type = "input";
            }
          });
        }
      }
      // 服务目标----结束
    },
  },
};
</script>

<style>
</style>
