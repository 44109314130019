// 第三方登录授权
const thirdPartyAuth = [
    {
        path: "/thirdPartyLoginAuth",
        component: () => import("@/views/thirdPartyAuth/views/index.vue"),
        name: "第三方登录授权",
    },
    {
        path: "/thirdLogged",
        component: () => import("@/views/thirdPartyAuth/views/logeed/index.vue"),
        name: "已登录",
    },
    {
        path: "/thirdLogin",
        component: () => import("@/views/thirdPartyAuth/views/login/index.vue"),
        name: "未登录",
    },
    {
        path: "/thirdAuthorization",
        component: () => import("@/views/thirdPartyAuth/views/authorization/index.vue"),
        name: "授权",
    },
    {
        path:"/errorPageAuth",
        component: () => import("@/views/thirdPartyAuth/views/errorPageAuth/index.vue"),
        name: "错误页面",
    }
]

export default thirdPartyAuth
