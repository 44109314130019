/*
 * @Author: your name
 * @Date: 2021-12-03 14:36:43
 * @LastEditTime: 2022-01-14 17:29:47
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \lightning-web\src\utils\packageManage.js
 */
import Axios from "axios";
// import BaiduMap from "vue-baidu-map"; //百度地图组件，已经放入业务的view中引用，禁止在main.js中全局引入
// import draggable from "vuedraggable"; //拖拽，已经放入业务的view中引用，禁止在main.js中全局引入
// import jschardet from "jschardet"; //解析csv文件，已经放入业务的view中引用，禁止在main.js中全局引入
// import fullscreen from "vue-fullscreen"; //全屏，已经放入业务的view中引用，禁止在main.js中全局引入
// import { VueJsonp } from "vue-jsonp"; // 解析百度地图地址，已经放入业务的view中引用，禁止在main.js中全局引入
// import vueWangIview from "vue-wang-iview"; // 骨架屏，已经放入业务的view中引用，禁止在main.js中全局引入
// import "vue-wang-iview/dist/vue-wang-iview.css";，已经放入业务的view中引用，禁止在main.js中全局引入
// import VueClipboard from "vue-clipboard2"; // 剪贴板，已经放入业务的view中引用，禁止在main.js中全局引入
// VueClipboard.config.autoSetContainer = true;，已经放入业务的view中引用，禁止在main.js中全局引入
import qs from "qs";
import CloudccUiBeta from "cloudcc-ui-beta"; // cloudcc组件库
import RendererPopup from '@/components/Dialog/index.js' // 渲染器弹框
import VueCookies from "vue-cookies"; // cookie
import * as VueGoogleMaps from 'vue2-google-maps'//引入谷歌地图组件库
import globalRigisterFilter from "./globalRigisterFilter";// 全局的filter 全部放到单独文件管理
import dayjs from "@/utils/dayjs";// 时间格式化

export default (Vue) => {
  Vue.use(globalRigisterFilter);
  Vue.use(VueCookies);
  Vue.use(CloudccUiBeta);
  Vue.use(VueGoogleMaps, {
    load: {
      key: 'AIzaSyCyWXGrhpOd1py9eUCpzRAzbDjWtXQNqGU', // 此处填入谷歌地图申请的key
      libraries: 'places'
    }
  });

  Vue.prototype.$rendererDialog = RendererPopup.install
  Vue.prototype.$Axios = Axios;
  Vue.prototype.$qs = qs;
  Vue.prototype.$moment = dayjs; //赋值使用
  Vue.prototype.$dayjs = dayjs; //赋值使用
  Vue.component("v-chart", VueECharts);//echart图表组件不能删！！！

  // 设置公司title
  Vue.prototype.$setTitle = (titleName, isHiddenTitle) => {
    if (isHiddenTitle === false) {
      return titleName ? `${titleName}` : "";
    } else {
      return titleName ? `${titleName} | ${window.Glod.BASE_TITLE || "CloudCC"}` : window.Glod.BASE_TITLE || "CloudCC";
    }
  };
};



