// 聊天（坐席）路由文件
const workBench = [
  {
    path: "customerWorkbench/workbench",
    component: () => import("@/views/customerWorkbench/workbench.vue"),
    name: "客服工作台",
    meta: { workbench: true}
  },
]

export default workBench;
