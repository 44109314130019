/**
 * 加个手册对象路由文件
 */
const priceManual = [
    {
        path: "priceManual/price-manual",
        component: () => import("@/views/priceManual/priceManual.vue"),
        name: "价格手册",
    },
    {
        path: "priceManual/price-detail/:id",
        component: () => import("@/views/priceManual/priceDetail.vue"),
        name: "价格手册详情",
    },
];

export default priceManual;
