const pdfviewrouter = [
    {
        name: "PDF视图详情页",
        path: "commonObjects/pdfViewDetail/:id/",
        component: () => import("@/views/commonObjects/pdfViewDetail/index.vue"),
        meta: {
            keepAlive: true // 需要缓存
        },
        children: [
            {
                path: 'pdfInformation',
                name: 'PDF视图详情页右侧',
                component: () => import("@/views/commonObjects/pdfViewDetail/detaiView/detail.vue"),
            },
        ]
    },
];

export default pdfviewrouter;