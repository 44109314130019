// 主页菜单路由文件
const homePageObject = [
  {
    path: "homePageObject",
    redirect: () => "homePageObject/standardPage",
  },
  {
    path: "homePageObject/standardPage",
    component: () => import("@/views/homePage/standardPage/index.vue"),
    name: "标准主页",
  },
  {
    path: "homePageObject/personalPage",
    component: () => import("@/views/homePage/personalPage/index.vue"),
    name: "个人主页",
  },
  {
    path: "homePageObject/rolePage",
    component: () => import("@/views/homePage/rolePage"),
    name: "角色主页",
  },
  {
    path: "homePageObject/customPage",
    component: () => import("@/views/homePage/customPage"),
    name: "自定义主页",
  },
];

export default homePageObject;
