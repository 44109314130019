/**
 * 项目管理主页路由
 */
const projectManagement = [
  {
    path: "projectManagement",
    name: "项目管理",
    redirect: () => "projectManagement/homePage",
  },
  {
    path: "projectManagement/homePage",
    component: () => import("@/views/projectManagement/homePage/index.vue"),
    name: "主页",
    meta: {
      //keepAlive:true //需要缓存
    }
  },
]

export default projectManagement;
