import axios from "@/config/httpConfig";

/**
 * 请求获取用户信息
 * @param {data} 请求参数 
 * @param {enforce} 是否强制请求最新用户信息 
 * @returns 用户信息
 */
export function getUserInfo(data, enforce = false) {
  if (enforce || localStorage.getItem("userInformation") === null) {
    return axios.get('/user/getUserInfo', data)
  } else {
    return new Promise(function (resolve) {
      resolve(JSON.parse(localStorage.getItem("userInformation"))); // 回调成功返回
    })
  }
}

//关联列表点击新建-根据任务 问题 子任务 带出当前的任务或者问题
export function getProjectByTask(data) {
  return axios.post("/projectWorkList/getProjectByTask", data)
}
//获取项目下的里程碑、任务、子任务
export function getMixedInformation(data) {
  return axios.post("/projectWorkList/getMixedInformation", data)
}
//获取项目下的问题
export function getProblemInformation(data) {
  return axios.post("/problem/getProblemInformation", data)
}
// 保存呼叫中心设置
export function saveCCSetting(data) {
  return axios.post("callCenter/saveCCSetting", data);
}
// 获取呼叫中心设置
export function getCallCenterInfo(data) {
  return axios.post("callCenter/getCallCenterInfo", data);
}
// 查询坐席下拉列表
export function getClientList(data) {
  return axios.post("customerSet/getClientList", data);
}
// 查询绑定列表
export function getBindList(data) {
  return axios.post("customerSet/getBindList", data);
}
// 用户绑定坐席电话
export function bindClientUser(data) {
  return axios.post("customerSet/bindClientUser", data);
}
// 编辑绑定信息
export function updateClientUser(data) {
  return axios.post("customerSet/updateClientUser", data);
}
// 删除绑定信息
export function deleteClientUser(data) {
  return axios.post("customerSet/deleteClientUser", data);
}
// 批量绑定坐席
export function bindClientUsers(data) {
  return axios.post("customerSet/bindClientUsers", data);
}
// 同步绑定天润系统的电话号码
export function bindTel(data) {
  return axios.post("customerSet/bindTel", data);
}
// 发起绑定电话号码验证
export function launchBindTelCheck(data) {
  return axios.post("customerSet/launchBindTelCheck", data);
}
// 确认绑定电话号码验证
export function bindTelCheck(data) {
  return axios.post("customerSet/bindTelCheck", data);
}
// 根据坐席号或者用户查询绑定信息
export function getBindByCnoOrUserId(data) {
  return axios.post("customerSet/getBindByCnoOrUserId", data);
}
// 获取Amazon区域列表
export function getAwsRegions(data) {
  return axios.post("/callCenter/getAwsRegions", data);
}
// 天润查询通话录音记录
export function getCdr(data) {
  return axios.post("/customerSet/getCdr", data);
}
// 天润保存登入密码
export function saveTianRunLogin(data) {
  return axios.post("/customerSet/saveTianRunLogin", data);
}
// 查询手机号码归属地
export function getPhoneCity(data) {
  return axios.post("/customerSet/getPhoneCity", data);
}
// 获取amazon实例列表
export function getInstanceAlias(data) {
  return axios.post("callCenter/listInstance", data);
}
// 保存amzon实例名
export function saveInstance(data) {
  return axios.post("/callCenter/saveInstance", data);
}

// 呼叫中心访客创建规则
export function creatVisitor(data) {
  return axios.post("/callCenter/creatVisitor", data);
}
// 根据电话号码识别联系人与客户
export function IdentifyPhonenumber(data) {
  return axios.post("/callCenter/IdentifyPhonenumber", data);
}
// 呼叫中心保存个案
export function saveCase(data) {
  return axios.post("/callCenter/saveCase", data);
}
// 根据语音记录查询语音记录id
export function getVoiceByVoicerecording(data) {
  return axios.post("/customerSet/getVoiceByVoicerecording", data);
}
// 更新语音转接内容
export function updateTransfer(data) {
  return axios.post("/customerSet/updateTransfer", data);
}
// 获取aws语音录音
export function getConnectCallRecording(data) {
  return axios.post("/callCenter/getConnectCallRecording", data);
}
// 查询试用坐席号
export function getOnTrial(data) {
  return axios.post("/customerSet/getOnTrial", data);
}
// 天润结束免费试用
export function updateCnoActive(data) {
  return axios.post("/callCenter/updateCnoActive", data);
}
// 天润试用发送邮件
export function sendTianRunEmail(data) {
  return axios.post("/customerSet/sendTianRunEmail", data);
}
//获取相关列表数据及按钮和字段信息
export function getDetailRelatedItems(data) {
  return axios.post("objectdetail/getDetailRelatedItems", data);
}
// 获取新建事件相关项
export function getDialigItem(data) {
  return axios.post('activity/getQuickActivityPage', data)
}

// 获取选项列表值
export function getSelectValue(data) {
  return axios.post('/view/getSelectValue', data)
}

//获取文件大小限制
export function queryLimit(data) {
  return axios.post('/file/queryLimit', data)
}

// 获取对象权限
export function getObjectPermission(data) {
  return axios.post('objectdetail/getObjectPermission', data)
}

// 潜在客户热度设置信息
export function getLeadHeat(data) {
  return axios.post('leadscore/getLeadHeat', data)
}

//获取对象记录权限
export function getPermissionById(data) {
  return axios.post('/objectdetail/getPermissionById', data)
}

//天眼查是否启用设置
export function isOpen(data) {
  return axios.post('/tianyancha/isOpen', data)
}

// 获取查找信息
export function getLookupInfo(data) {
  return axios.post('/lookup/getLookupInfo', data)
}

// 获取查找数据
export function getLookupData(data) {
  return axios.post('/lookup/getLookupData', data)
}
//根据项目获取当前登录人在该项目下的成员情况
export function findMemberByProject(data) {
  return axios.post('/projectWorkList/findMemberByProject', data)
}
//计算任务工作时间的方法
export function getWorkHour(data) {
  return axios.post('/projectTask/getWorkHour', data)
}
//判断所选订单是否属于同一个币种以及价格手册
export function isSameCurencyAndPriceBook(data) {
  return axios.post('/generateInvoiceNew/isSameCurencyAndPriceBook', data)
}
// 判断全部订单是否为同一个客户
export function isSameCustomer(data) {
  return axios.post('/generateInvoiceNew/isSameCustomer', data)
}
// 获取订单或发票的地址
export function getCustomerAddress(data) {
  return axios.post('/customeraddress/getCustomerAddress', data)
}
// 查询客户是否存在默认客户地址
export function isDefaultAddress(data) {
  return axios.post('/customeraddress/isDefaultAddress', data)
}
// 点击默认按钮更改客户地址默认状态
export function updateAddressStatus(data) {
  return axios.post('/customeraddress/updateAddressStatus', data)
}
// 更新发票报价单地址
export function updateOrderOrInvoiceInfo(data) {
  return axios.post('/customeraddress/updateOrderOrInvoiceInfo', data)
}
// 获取视图列表记录
export function getViewListData(data) {
  return axios.post('/view/list/getViewListAjax', data)
}
// 判断业务机会，报价单，订单，发票，采购订单是否有子产品
export function existRelatedProductInfo(data) {
  return axios.post('/master/existRelatedProductInfo', data)
}
// 获取 服务资源以及服务区域对象下的所有标准和自定义...
export function getResourceAttributeFields(data) {
  return axios.post("/autoRecommendPolicy/getResourceAttributeFields", data);
}
// 获取 服务资源以及服务区域对象下的标准和自定义的数字/百分比/数字类型的公式字段类型字段
export function getQualificationFields(data) {
  return axios.post("/autoRecommendPolicy/getQualificationFields", data);
}
