const buttonWrapperRouter = [
  {
    path: "commonObjects/combination",
    component: () => import("@/components/ButtonWrapper/combination.vue"),
    name: "合并",
  },
  {
    path: "commonObjects/cnki",
    component: () => import("@/components/ButtonWrapper/cnki.vue"),
    meta: {
      keepAlive: true // 需要缓存
    },
    name: "查重合并",
  },
  {
    path: "commonObjects/cnkiResult/:objApi",
    component: () => import("@/components/ButtonWrapper/cnkiResult.vue"),
    name: "重复列表",
  },
  {
    path: "commonObjects/handleRepeat",
    component: () => import("@/components/ButtonWrapper/handleRepeat.vue"),
    name: "详细重复数据",
  },
  {
    path: "commonObjects/combinationRepeat",
    component: () => import("@/components/ButtonWrapper/combinationRepeat.vue"),
    name: "合并重复数据",
  },
]

export default buttonWrapperRouter
