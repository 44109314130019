const landingPage = [
  {
    path: "landingPage",
    component: () => import("@/views/landingPage/list/index.vue"),
    name: "landingPage",
    meta: { title: "landingPage" },
  },
  {
    path: "landingPage/detail",
    component: () => import("@/views/landingPage/detail/index.vue"),
    name: "landingPageDetail",
    meta: { title: "Detail" },
  },
  {
    path: "landingPage/create",
    component: () => import("@/views/landingPage/created/created.vue"),
    name: "create",
    meta: { title: "Create" },
  },
];

export default landingPage;

