const externalUsersRouterMap = [
  {
    path: "commonObjects/externalUsers/:id/:prefix/:objectApi",
    component: () => import("@/views/ExternalUsers/index.vue"),
    name: "外部用户",
  },
]

export default externalUsersRouterMap

