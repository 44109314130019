const lists = [
  {
    path: "lists",
    component: () => import("@/views/lists/lists/index.vue"),
    name: "lists",
    meta: { title: "lists" },
  },
  {
    path: "lists/createLists",
    component: () => import("@/views/lists/createLists/index.vue"),
    name: "createLists",
    meta: { title: "createLists" },
  },
  {
    path: "lists/particulars",
    component: () => import("@/views/lists/particulars/index.vue"),
    name: "particulars",
    meta: { title: "Particulars" },
  }
];

export default lists;
