// 多语言配置
/*
  语言	文件名称
  简体中文 Upload	SimpleChinese
  英语 Upload	English
  繁体中文 Upload	TraditionalChinese
  日语 Upload	Japanese
  泰语 Upload	Thai
  韩语 Upload	Korean
  德语 Upload	German
  英语（英式） Upload	BritishEnglish
  法语 Upload	French
  俄语 Upload	Russian
  意大利语 Upload	Italian
  西班牙语 Upload	Spanish
  荷兰语 Upload	Dutch
  波兰语 Upload	Polish
  葡萄牙语(巴西葡萄牙语除外) Upload	Portuguese
  葡萄牙语(巴西)	BrazilianPortuguese
*/

import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'

//引入Element的语言包
// import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
// import enLocale from 'element-ui/lib/locale/lang/en'
// import twLocale from 'element-ui/lib/locale/lang/zh-TW'
// import jaLocale from 'element-ui/lib/locale/lang/ja'
// import deLocale from 'element-ui/lib/locale/lang/de'
// import frLocale from 'element-ui/lib/locale/lang/fr'
// import esLocale from 'element-ui/lib/locale/lang/es'
Vue.use(VueI18n)

const i18n = new VueI18n({
  locale: '',
  messages: {}
})
// 调用次数
let applyCount = 0
const loadedLanguages = [];
export function loadLanguageAsync(lang) {
  /**
   * 1、查看用户档案中有没有设置语言
   * 2、如果没有设置语言，为了保证拉取默认语言，使用浏览器默认语言
   */
  lang = window.sessionStorage.getItem("ProfileLanguage")
  if (!lang) {
    lang = navigator.language === "zh-CN" ? "zh" : "en"
  }
  if (i18n.locale !== lang) {
    if (!loadedLanguages.includes(lang)) {
      return asyncPullFile(lang)
    } else {
      applyCount = applyCount + 1
      if (applyCount > 1) {
        throw "请注意，loadLanguageAsync 只能调用一次"
      }
    }
    return Promise.resolve(setI18nLanguage(lang));
  }
  return Promise.resolve(lang);
}

/**
 * 设置i18n的默认语言
 * @param {string} lang
 * @returns {string} lang
 */
function setI18nLanguage(lang) {
  i18n.locale = lang;
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

/**
 * 异步加载文件
*  @param {string} lang
 * @returns {string} lang
 */
const asyncPullFile = async lang => {
  let fileName, elementFileName, accountFileName
  switch (lang) {
    case 'zh':
      fileName = 'SimpleChinese';
      accountFileName = 'SimpleChinese';
      elementFileName = 'zh-CN'
      break;
    case 'en':
      fileName = 'English';
      accountFileName = 'English';
      elementFileName = 'en'
      break;
    case 'zh-TW':
      fileName = 'TraditionalChinese';
      accountFileName = 'TraditionalChinese';
      elementFileName = 'zh-TW'
      break;
    case 'ja-JP':
      fileName = 'Japanese';
      accountFileName = 'Japanese';
      elementFileName = 'ja'
      break;
    case 'jp':
      fileName = 'Japanese';
      accountFileName = 'Japanese';
      elementFileName = 'ja'
      break;
    case 'ge':
      fileName = 'German';
      accountFileName = 'German';
      elementFileName = 'de'
      break;
    case 'fr':
      fileName = 'French';
      accountFileName = 'French';
      elementFileName = 'fr'
      break;
    case 'es-ES':
      fileName = 'Spanish';
      accountFileName = 'Spanish';
      elementFileName = 'es'
      break;
    default:
      fileName = 'SimpleChinese';
      accountFileName = 'SimpleChinese';
      elementFileName = 'zh-CN'
      break
  }

  let cacheLanguage = JSON.parse(sessionStorage.getItem('cacheLanguage'))
  let cacheSetLang = sessionStorage.getItem('cacheSetLang')
  if (cacheSetLang !== lang || !cacheLanguage) {
    let msgs = await import(`@/utils/i18n/${fileName}`)
    let accountMsgs = await import(`/public/i18n/account/${accountFileName}`)
    let eleMsg = await import(`element-ui/lib/locale/lang/${elementFileName}`)
    sessionStorage.removeItem("cacheLanguage")
    sessionStorage.setItem("cacheLanguage", JSON.stringify({ ...msgs.default, ...eleMsg.default, ...accountMsgs.default }))
    sessionStorage.setItem('cacheSetLang', lang)
    cacheLanguage = { ...msgs.default, ...eleMsg.default, ...accountMsgs.default }
    i18n.setLocaleMessage(lang, cacheLanguage);

  } else {
    i18n.setLocaleMessage(lang, cacheLanguage);

  }

  // let msgs = await import(`@/utils/i18n/${fileName}`)
  // let eleMsg=await import(`element-ui/lib/locale/lang/${elementFileName}`)
  // i18n.setLocaleMessage(lang, { ...msgs.default, ...eleMsg.default });

  loadedLanguages.push(lang);
  return setI18nLanguage(lang);
}




ElementLocale.i18n((key, value) => i18n.t(key, value))
export default i18n
