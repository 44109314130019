import axios from "axios"
const robotJob = {
    getToken() {
        return axios.post('https://a1.easemob.com/szyd/cloudcccrm/token', { grant_type: "client_credentials", client_id: "YXA6X90aYIzBEeSya3tEL4_8DQ", client_secret: "YXA66A-pJGBu782qyNRwCg_0KsI65ls" })
    },
    sendMessage(groupId, msg, token) {
        return axios.post('https://a1.easemob.com/szyd/cloudcccrm/messages', { target_type: "chatgroups", target: [groupId], msg: { type: "custom", customEvent: 'CARD', customExts: msg }, from: localStorage.getItem('orgId'), ext: { type: "custom", customEvent: 'CARD', customExts: msg } }, {
            headers: {
                authorization: token
            }
        })
    }
}
export default robotJob