export default [
    // 立即支付
    {
        path: "/promptlyPayment",
        component: () => import("@/views/systemSettings/components/onlinePay/components/promptlyPayment.vue"),
        name: "立即支付"
    },
    // 邮件评价
    {
        path: "/mailEvaluation",
        component: () => import("@/views/systemSettings/components/mailEvaluation/evaluation.vue"),
        name: "邮件评价",
    },
    //打印发票
    {
        path: "/print-invoices/:objId/:id/:templateId/:type/:userId/:loginName/:orgId",
        name: "打印发票",
        component: () => import("@/views/systemSettings/components/onlinePay/components/printContent.vue"),
    },
    {
        path: "/systemSettings",
        component: () => import("@/views/systemSettings/components/settingTopMenu/index.vue"),
        name: "系统设置",
        children: [
            // 系统设置架构菜单
            {
                path: "/",
                component: () => import("@/views/systemSettings"),
                meta: {
                    title: "系统设置页"
                },
                redirect: () => 'setHomePage',
                children: [
                    // 设置主页
                    {
                        path: 'setHomePage',
                        name: 'setHomePage',
                        component: () => import("@/views/systemSettings/components/setHomePage"),
                    },
                    {
                        path: "setuppage/userPageSetup",
                        component: () => import("@/views/systemSettings/components/userPage/userPageSetup.vue"),
                        name: "用户界面"
                    },
                    {
                        path: "setuppage/objectTemplatePrint",
                        component: () => import("@/views/systemSettings/components/objectTemplate/templateSetting.vue"),
                        name: "产品模板列表页"
                    },
                    {
                        path: "setuppage/newObjectTemplate",
                        component: () => import("@/views/systemSettings/components/objectTemplate/newTemplate.vue"),
                        name: "新建产品模板"
                    },
                    {
                        path: "setuppage/TemplateDetail",
                        component: () => import("@/views/systemSettings/components/objectTemplate/editTemplate.vue"),
                        name: "模板详情页"
                    },
                    {
                        path: "setuppage/electronicSignature",
                        component: () => import("@/views/systemSettings/components/electronicSignature/electronicSignature.vue"),
                        name: "电子签章"
                    },
                    {
                        path: "setuppage/onlinePay",
                        component: () => import("@/views/systemSettings/components/onlinePay/onlinePay.vue"),
                        name: "在线支付"
                    },
                    //销售云设置
                    {
                        path: "setuppage/taxCategory",
                        component: () => import("@/views/systemSettings/components/taxCategory/taxCategory.vue"),
                        name: "税种设置"
                    },
                    // 通信模板设置
                    {
                        path: "setuppage/emailTemplate",
                        component: () => import("@/views/systemSettings/components/communicationTemplate/email-template.vue"),
                        name: "电子邮件模板"
                    },
                    {
                        path: "setuppage/messageTemplate",
                        component: () => import("@/views/systemSettings/components/communicationTemplate/message-template.vue"),
                        name: "短信模板"
                    },
                    // 客服云设置
                    {
                        path: "setuppage/callCenter",
                        component: () => import("@/views/systemSettings/components/callCenterSetting/call-center.vue"),
                        name: "电话"
                    },
                    // 客服云设置-客服坐席
                    {
                        path: "setuppage/chat/servicesSeat",
                        component: () => import("@/views/systemSettings/components/chatSetting/seat.vue"),
                        name: "在线坐席"
                    },
                    // 客服云设置-小组件
                    {
                        path: "setuppage/chat/littleWidget",
                        component: () => import("@/views/systemSettings/components/chatSetting/widget.vue"),
                        name: "小组件"
                    },
                    {
                        path: "setuppage/Administration",
                        component: () => import("@/views/systemSettings/components/interest/AuthorityManagement.vue"),
                        name: "权利管理"
                    },
                    // 合规性设置-GDPR合规性
                    {
                        path: "setuppage/gdprCompliance",
                        component: () => import("@/views/systemSettings/components/gdprSetting/gdprSetting.vue"),
                        name: "GDPR合规性"
                    },
                    { //产品设置
                        path: "setuppage/productSetting",
                        component: () => import("@/views/systemSettings/components/productSet/productSetting.vue"),
                        name: "产品系列设置"
                    },
                    { //跳转至权力管理页
                        path: "setuppage/blankPage",
                        component: () => import("@/views/systemSettings/components/interest/blankPage.vue"),
                        name: "空白页"
                    },
                    {
                        path: "setuppage/NewMajorEvents",
                        component: () => import("@/views/systemSettings/components/interest/NewMajorEvents.vue"),
                        name: "设置重大事件"
                    },
                    {
                        path: "setuppage/seePage",
                        component: () => import("@/views/systemSettings/components/interest/seePage.vue"),
                        name: "查看重大事件"
                    },
                    {
                        path: "setuppage/importantEvent",
                        component: () => import("@/views/systemSettings/components/interest/ImportantEvent.vue"),
                        name: "重大事件"
                    },
                    {
                        path: "setuppage/importantEvent/edit",
                        component: () => import("@/views/systemSettings/components/interest/editImportantEvent.vue"),
                        name: "新建重大事件"
                    },
                    {
                        path: "setuppage/processSettings",
                        component: () => import("@/views/systemSettings/components/process/ProcessSettings.vue"),
                        name: "进程设置"
                    },
                    {
                        path: "setuppage/newBuildProcess",
                        component: () => import("@/views/systemSettings/components/process/newBuildProcess.vue"),
                        name: "新建进程"
                    },
                    {
                        path: "setuppage/reportTemplate",
                        component: () => import("@/views/systemSettings/components/reportTemplate/templateSetting.vue"),
                        name: "服务报表模板设置"
                    },
                    {
                        path: "setuppage/newTemplate",
                        component: () => import("@/views/systemSettings/components/reportTemplate/newTemplate.vue"),
                        name: "新建模板"
                    },
                    {
                        path: "setuppage/editTemplate",
                        component: () => import("@/views/systemSettings/components/reportTemplate/editTemplate.vue"),
                        name: "编辑模板"
                    },
                    // 、、、、、小程序设置 star、、、、、、、
                    {
                        path: "setuppage/miniProgram/bind",
                        component: () => import("@/views/systemSettings/components/miniProgram/bind.vue"),
                        name: "绑定小程序"
                    },
                    {
                        path: "setuppage/miniProgram/slideshow",
                        component: () => import("@/views/systemSettings/components/miniProgram/slideshow.vue"),
                        name: "轮播图设置"
                    },
                    {
                        path: "setuppage/miniProgram/message",
                        component: () => import("@/views/systemSettings/components/miniProgram/message.vue"),
                        name: "品牌故事/消息设置"
                    },
                    {
                        path: "setuppage/miniProgram/editMessage/:type/:id",
                        component: () => import("@/views/systemSettings/components/miniProgram/editMessage.vue"),
                        name: "添加品牌故事/消息设置"
                    },
                    {
                        path: "setuppage/miniProgram/editSlideShow/:type/:id",
                        component: () => import("@/views/systemSettings/components/miniProgram/editSlideShow.vue"),
                        name: "添加轮播图"
                    },
                    // 、、、、、小程序设置 end、、、、、、、

                    // 生成二维码/条形码规则设置
                    {
                        path: "setuppage/qrCodeSetting",
                        component: () => import("@/views/systemSettings/components/qrCodeSetting/qrCodeSetting.vue"),
                        name: "生成二维码/条形码规则设置"
                    },
                    // 市场云设置
                    {
                        path: "setuppage/marketPage",
                        component: () => import("@/views/systemSettings/components/marketSetting/marketPage.vue"),
                        name: "页脚设置"
                    },

                    // 伙伴云设置
                    {
                        path: "setuppage/basicSetup",
                        component: () => import("@/views/systemSettings/components/partnerCloudSetting/basicSetting/index.vue"),
                        name: "基础设置"
                    },
                    {
                        path: "setuppage/siteSetup",
                        component: () => import("@/views/systemSettings/components/partnerCloudSetting/partnerSite/index.vue"),
                        name: "伙伴站点",
                        // meta:{
                        //     keepAlive:true //需要缓存
                        //   }
                    },
                    // 网页生成个案
                    {
                        path: "setuppage/customerServicepc",
                        component: () => import("@/views/systemSettings/components/casePc/customerServicePc.vue"),
                        name: "网页版客户服务门户"
                    },
                    {
                        path: "setuppage/editSite",
                        component: () => import("@/views/systemSettings/components/partnerCloudSetting/components/editSite.vue"),
                        name: "站点编辑",
                        meta: {
                            keepAlive: true //需要缓存
                        }
                    },
                    // /setuppage/financeSetting
                    {
                        path: "setuppage/financeSetting",
                        component: () => import("@/views/systemSettings/components/partnerCloudSetting/financeSetting/financeSetting.vue"),
                        name: "财务设置页面"
                    },
                    // 详情页报表图表
                    {
                        path: "setuppage/detailsPageChart",
                        component: () => import("@/views/systemSettings/components/detailsPageReportChart/detailsPageChart.vue"),
                        name: "详情页报表图表"
                    },
                    {
                        path: "setuppage/reportChart",
                        component: () => import("@/views/systemSettings/components/detailsPageReportChart/newReportChart.vue"),
                        name: "新建编辑",
                    },

                    // 客户满意度 评价设置
                    {
                        path: "setuppage/evaluationSet",
                        component: () => import("@/views/systemSettings/components/evaluationSetting/evaluationSetting.vue"),
                        name: "客户满意度"
                    },
                    // 接单规则设置
                    {
                        path: "setuppage/rulesOrderSetting",
                        component: () => import("@/views/systemSettings/components/rulesOrderSetting/rulesOrderSetting.vue"),
                        name: "接单规则"
                    },
                    {
                        path: "setuppage/marketSubscriptionType",
                        component: () => import("@/views/systemSettings/components/marketSetting/marketSubscriptionType.vue"),
                        name: "订阅类型"
                    },
                    {
                        path: "setuppage/marketSocialAccount",
                        component: () => import("@/views/systemSettings/components/marketSetting/marketSocialAccount.vue"),
                        name: "社交媒体账户设置"
                    },
                    {
                        path: "setuppage/marketDnsSetting",
                        component: () => import("@/views/systemSettings/components/marketSetting/marketDnsSetting.vue"),
                        name: "域名设置"
                    },
                    // 自定义页面，自定义组件
                    {
                        path: "setuppage/costomui/pageList",
                        component: () => import("@/views/systemSettings/components/customUI/pageList.vue"),
                        name: "自定义页面"
                    },
                    {
                        path: "setuppage/costomui/componentList",
                        component: () => import("@/views/systemSettings/components/customUI/componentList.vue"),
                        name: "自定义组件"
                    },
                    // ----------------增加-----------------------------
                    {
                        path: 'emailList',
                        name: 'EmailList', // 邮箱列表
                        component: () => import("@/views/Email/emailList.vue"),
                    },
                    // ----------------企业微信集成-----------------------------
                    {
                        path: 'setuppage/integrate/enterpriseWechat',
                        name: '企业微信集成', // 企业微信集成
                        component: () => import("@/views/systemSettings/components/integrationSettings/enterpriseWechat"),
                    },
                    {
                        path: 'setuppage/integrate/role',
                        name: '角色绑定', // 企业微信集成
                        component: () => import("@/views/systemSettings/components/integrationSettings/roleBinding"),
                    },
                    {
                        path: 'setuppage/integrate/user',
                        name: '用户绑定', // 企业微信集成
                        component: () => import("@/views/systemSettings/components/integrationSettings/userBinding"),
                    },
                    // ----------------钉钉集成设置-----------------------------
                   
                    {
                        path: 'setuppage/ccding/index',
                        name: '钉钉设置', // 钉钉设置
                        component: () => import("@/views/systemSettings/components/ccding"),
                    },

                ]
            },

        ],
    },

];
