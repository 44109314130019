/**
 * 报表对象路由文件
 */
const reportObject = [
  {
    path: "reportObject",
    redirect: () => "/reportObject/reportList",
    name: "报表菜单",
  },
  {
    path: "reportObject/reportList",
    component: () => import("@/views/report/reportList/index.vue"),
    name: "报表列表页",
  },
  {
    path: "reportObject/dataSource",
    component: () => import("@/views/report/dataSource/index.vue"),
    name: "数据源选择页",

  },
  {
    path: "reportObject/editReport/:type/:reportId?/:folderId?",
    // type：页面类型，是运行（run）还是编辑（edit）；reportId：报表id；folderId：文件夹id
    component: () => import("@/views/report/editReport/index.vue"),
    name: "编辑报表",
    meta: {
      keepAlive: true, //需要缓存
    },
  },
  {
    path: "reportObject/addReport/:type",
    // type：页面类型，是运行（run）还是编辑（edit）
    component: () => import("@/views/report/editReport/index.vue"),
    name: "新建报表",
  },

];

export default reportObject;
