/*
 * @Description: 
 * @Author: 张亚欣
 * @Date: 2021-06-07 10:18:09
 * @LastEditTime: 2021-07-22 14:32:06
 * @LastEditors: liutq
 * @Reference: 报价单相关路由
 */
const quotationRouterMap = [
  {
    path: "quotation/editQuotation/:id",
    component: () => import("@/views/quotation/editQuotation.vue"),
    name: "编辑报价单",
  },
  {
    path: "quotation/configQuotation/:pricebookid/:currency/:id",
    component: () => import("@/views/quotation/configQuotation.vue"),
    name: "配置产品"
  },
  {
    path: "quotation/blockPricing",
    component: () => import("@/views/quotation/blockPricing.vue"),
    name: "分块定价",
  },
  {
    path: "quotation/ladderDiscount",
    component: () => import("@/views/quotation/ladderDiscount.vue"),
    name: "阶梯折扣",
  },
]

export default quotationRouterMap