/**
 * 人员资源日历对象路由
 */

const resoursecalendar = [

  {
    path: "resoursecalendar/resoursecalendar",
    component: () => import("@/views/resoursecalendar/index.vue"),
    name: "人员资源日历",

  },

];

export default resoursecalendar;