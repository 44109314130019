// 主模块的actions

import * as requestReportObjectApi from "./api"; // 报表api
import { getUserInfo } from "./api";

export default {
  // 获取用户有无报表相关的权限的结果
  async getReportEnableList(context) {
    let reportOperation = {
      run: false, // 运行报表权限
      export: false, // 导出报表权限
      new: false, // 新建报表权限
    }
    let { data } = await requestReportObjectApi.getReportEnableList();
    data.map((item) => {
      switch (item.relateId) {
        case 'aab020001': // 创建和自定义报表
          reportOperation.new = JSON.parse(item.isenable);
          break;
        case 'aab020002': // 导出报表/视图
          reportOperation.export = JSON.parse(item.isenable);
          break;
        case 'aab020008': // 运行报表
          reportOperation.run = JSON.parse(item.isenable);
          break;
      }
    })
    context.commit('setReportOperation', reportOperation); // 提交mutation，修改state值
  },
  // 获取用户个人信息接口中的币种类型
  async getCurrencyCode(context) {
    let { data } = await getUserInfo();
    context.commit('setCurrencyCode', data.currencyCode); // 提交mutation，修改state值
  },
}
